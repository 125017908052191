<template>
  <!--  eslint-disable-->
  <div class="property property_type_list-select">
    <div class="select select_theme_dark">
      <span class="select__input-icon" v-if="property.icon" v-html="property.icon" @mousedown.prevent="toggleList" :class="{'active': isActive}"></span>
      <div class="select__input" @mousedown.prevent.stop="selectOpen = true">
        <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
          <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF" fill-rule="evenodd"></path>
        </svg>
      </div>
      <div class="select__body" v-show="selectOpen">
        <div class="select__body-scrollable">
          <div class="select__option" v-for="(v, k, index) in property.options" @mousedown.prevent="selectOption(k)">{{ v }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  inject: ['currentPage'],
  name: 'prop_list_select',
  mixins: [PropBase],
  props: ['propset'],
  data: function() {
    return {
      selectOpen: false
    };
  },
  computed: {
    isActive: function() {
      var ia, ref;
      ia = false;
      if (((ref = this.propValue) != null ? ref['list-type'] : 0) === this.property.name) {
        ia = true;
      }
      return ia;
    },
    propValue: function() {
      var val;
      val = {
        'list-type': 'none',
        'list-style-type': this.property.default
      };
      if ((this.propset != null)) {
        val['list-type'] = this.propset['list-type'];
        if (this.propset['list-style-type'] !== '') {
          val['list-style-type'] = this.property.default;
        }
      }
      return val;
    }
  },
  methods: {
    toggleList: function() {
      var list, lst, lt;
      if (this.propValue['list-type'] === this.property.name) {
        lt = 'none';
      } else {
        lt = this.property.name;
      }
      if (this.propValue['list-style-type'] != null) {
        lst = this.propValue['list-style-type'];
      } else {
        lst = this.property.default;
      }
      list = {
        'list-type': lt,
        'list-style-type': lst
      };
      this.updateProp('list', list);
    },
    selectOption: function(opt) {
      var list;
      this.propValue['list-style-type'] = opt;
      list = {
        'list-type': this.property.name,
        'list-style-type': opt
      };
      this.updateProp('list', list);
    }
  },
  watch: {
    'currentPage.mousePressed.left': function() {
      if (this.currentPage.mousePressed.left) {
        this.selectOpen = false;
      }
    }
  },
  created: function() {
    if ((this.property.default != null) && (this.propValue == null) && (this.property != null)) {
      // console.log @property
      this.propValue = this.property.default;
    }
  }
};
</script>

<style lang="scss">
.property_type_list-select{
  position:relative;
  display:block;

  .select{
    display:flex;
  }

  .select__input{
    position:relative;
    display:block;
    width: 17px;
    height: 18px;
    padding: 0;
    background:transparent;
    border-color:transparent;

    &-svg{
      right:3px;
    }

    &-icon{
      position:relative;
      display:block;
      cursor: pointer;
      margin-right: 4px;

      svg{
        position:relative;
        display:block;
        height: 18px;
        width: 18px;
        opacity: 0.5;
      }

      &:hover svg, &.active svg{
        opacity: 1;
      }
    }

    &:hover{
      background:rgba(255,255,255,.1);
      border-color:transparent;

      .select__input-icon svg{
        opacity: 1;
      }
    }
  }

  .select__body{
    position:absolute;
    display:block;
    width: 100px;
    top:20px;
  }
  .select__option:after{
    content: '';
    position:absolute;
    display:block;
    top:50%;
    left:30px;
    right: 15px;
    background:rgba(255,255,255, .2);
    height: 3px;
  }
}
</style>
