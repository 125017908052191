/* eslint-disable */

export default {
  object: [
    {
      title: 'addTextDoubleClick',
      event: 'addText',
      hidden: true
    },
    {
      title: 'copy',
      event: 'copy'
    },
    {
      title: 'cut',
      event: 'cut'
    },
    {
      title: 'delete',
      event: 'delete',
      split: true
    },
    {
      title: 'copyStyle',
      event: 'copyStyles'
    },
    {
      title: 'applyStyle',
      event: 'applyStyles',
      split: true
    },
    {
      title: 'group',
      event: 'group'
    },
    {
      title: 'ungroup',
      event: 'ungroup'
    }
  ],
  text: [
    {
      title: 'copy',
      event: 'textCopy'
    },
    {
      title: 'cut',
      event: 'textCut'
    },
    {
      title: 'paste',
      event: 'textPaste'
    }
  ],
  table: [
    {
      title: 'copy',
      event: 'tableCopy'
    },
    {
      title: 'paste',
      event: 'tablePaste'
    },
    {
      title: 'cut',
      event: 'tableCut',
      split: true
    },
    {
      title: 'mergeCells',
      event: 'mergeCells'
    },
    {
      title: 'splitHorizontal',
      event: 'splitHor'
    },
    {
      title: 'splitVertical',
      event: 'splitVer',
      split: true
    },
    {
      title: 'insertRowAbove',
      event: 'insertRowAbove'
    },
    {
      title: 'insertRowBelow',
      event: 'insertRowBelow'
    },
    {
      title: 'insertColumnRight',
      event: 'insertColRight'
    },
    {
      title: 'insertColumnLeft',
      event: 'insertColLeft',
      split: true
    },
    {
      title: 'deleteRow',
      event: 'deleteRow'
    },
    {
      title: 'deleteColumn',
      event: 'deleteCol'
    }
  ],
  slide: [
    {
      title: 'selectAll',
      event: 'selectAll'
    },
    {
      title: 'paste',
      event: 'paste',
      disabled: true
    }
  ],
  ruler: [
    {
      title: 'showRulers',
      event: 'toggleRulers',
      hidden: true
    },
    {
      title: 'hideRulers',
      event: 'toggleRulers'
    },
    {
      title: 'showGuides',
      event: 'toggleGuides',
      hidden: true
    },
    {
      title: 'hideGuides',
      event: 'toggleGuides',
      hidden: true
    },
    {
      title: 'addGuide',
      event: 'addGuide',
      hidden: true
    }
  ],
  dropMedia: [
    {
      title: 'setSlideBackground',
      event: 'dropMediaBackground'
    },
    {
      title: 'createNewObject',
      event: 'dropMediaNewObj'
    }
  ]
};
