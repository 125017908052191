<script>
/* eslint-disable */
import $ from 'jquery';

import TabMixin from '@/components/editor/MediaBrowser/components/TabMixin';
import { apiGet, customApiRequest } from '@/models/Api';
import axios from 'axios';

export default {
    name: 'iconfinder-tab',
    mixins: [TabMixin],
    data() {
        return {
            name: 'iconfinder',
            searchPlaceholder: 'Search icons...',
            iconfinderToken: ''
        };
    },
    computed: {
        searchResultsUrls() {
            return this.searchResults.map(function (r) {
                return r.raster_sizes[r.raster_sizes.length - 1].formats[0].preview_url;
            });
        }
    },
    methods: {
        itemSelected(id) {
            var res, sr;
            sr = this.searchResults;
            res = {
                url: sr[id].raster_sizes[sr[id].raster_sizes.length - 1].formats[0].preview_url,
                meta: {
                    id: sr[id].icon_id,
                    ref: 'iconfinder'
                }
            };
            this.$emit('itemselected', res);
        },
        async getIconfinderToken() {
            const res = await apiGet('/api/v1/token/iconfinder');

            if(res) {
                this.iconfinderToken = res.access_token;
            }
        },
        async validateIconfinderToken() {
            let a, cur, data, exp, expTs;
            if(!this.iconfinderToken) {
                await this.getIconfinderToken();
            }
            a = this.iconfinderToken.split('.');
            if(Array.isArray(a) && a.length === 3) {
                data = JSON.parse(atob(a[1]));
                // Нужно обновить токен за минуту до протухания
                expTs = data.exp * 1000 - 60000;
                exp = new Date(expTs);
                cur = new Date();
                if(cur > exp) {
                    await this.getIconfinderToken();
                }
            }
        },
        async browse(clearResults, initial) {
            const res = await apiGet(
                '/api/v1/iconfinder',
                {
                    query: this.search
                }
            );

            if(res && res?.icons) {
                this.searchResults = res.icons;
            }
        }
    }
};
</script>
