<template>
  <div class="property property__type_table-borders">
    <div class="property__btn" v-on:click="propValue = 'none'"
         v-bind:class="{'property__btn__active': propValue == 'none'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" opacity=".2" stroke-linecap="square">
          <path d="M7.5.5v14M14.5.5v14M.5.5v14M.5 7.5h14M.5 14.5h14M.5.5h14"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'all'"
         v-bind:class="{'property__btn__active': propValue == 'all'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14M14.5.5v14M.5.5v14M.5 7.5h14M.5 14.5h14M.5.5h14"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'outer'"
         v-bind:class="{'property__btn__active': propValue == 'outer'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14" opacity=".2"/>
          <path d="M14.5.5v14M.5.5v14"/>
          <path d="M.5 7.5h14" opacity=".2"/>
          <path d="M.5 14.5h14M.5.5h14"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'inner'"
         v-bind:class="{'property__btn__active': propValue == 'inner'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14"/>
          <path d="M14.5.5v14M.5.5v14" opacity=".2"/>
          <path d="M.5 7.5h14"/>
          <path d="M.5 14.5h14M.5.5h14" opacity=".2"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'top'"
         v-bind:class="{'property__btn__active': propValue == 'top'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14M14.5.5v14M.5.5v14M.5 7.5h14M.5 14.5h14" opacity=".2"/>
          <path d="M.5.5h14"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'bottom'"
         v-bind:class="{'property__btn__active': propValue == 'bottom'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14M14.5.5v14M.5.5v14M.5 7.5h14" opacity=".2"/>
          <path d="M.5 14.5h14"/>
          <path d="M.5.5h14" opacity=".2"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'left'"
         v-bind:class="{'property__btn__active': propValue == 'left'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14M14.5.5v14" opacity=".2"/>
          <path d="M.5.5v14"/>
          <path d="M.5 7.5h14M.5 14.5h14M.5.5h14" opacity=".2"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" v-on:click="propValue = 'right'"
         v-bind:class="{'property__btn__active': propValue == 'right'}">
      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
          <path d="M7.5.5v14" opacity=".2"/>
          <path d="M14.5.5v14"/>
          <path d="M.5.5v14M.5 7.5h14M.5 14.5h14M.5.5h14" opacity=".2"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'propTableBorders',
  mixins: [PropBase]
};
</script>

<style lang="scss">
.property__type_table-borders{
  padding: 10px 0 10px 18px;
  .property__btn{
    height: 19px;
    width: 19px;
    box-sizing: border-box;
    background: none;
    margin:0 2px 0 0;
    border:2px solid transparent;

    &:last-child{
      margin: 0;
    }

    svg{
      position:relative;
      display:block;
      width: 15px;
      // margin: 2px 0 0 2px;
    }
    &__active{
      //background: rgba(255,255,255, .2);
      border-color:#1695D2;
      svg{
        opacity: 1;
      }
    }
  }
}
</style>
