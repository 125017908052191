<template>
    <div class="property property__type_color" v-bind:class="{property__nested:nested}">
        <div class="property__name" v-if="property.title">
            {{ $t(property.title) }}
        </div>
        <div class="property__icon" v-if="property.icon" @mouseup.prevent="showTabs=true" @mousedown.prevent="">
            <span v-html="property.icon"></span>
        </div>
        <div class="property__flex-row">
            <div class="property__preview" @click.prevent="showTabs=true" v-show="!nested" @mousedown.prevent="">
                <div class="property__preview-fill" v-bind:style="'background:rgba('+getValidColor(propValue)+')'">
                </div>
            </div>
            <div
                class="property__btn property__btn__active"
                v-if="property.name == 'table-background-color'"
                @click="updateProp('table-background-color', null)"
                style="background-color: transparent; transform: scale(0.45) translate(-8px, -2px);"
                title="Reset to defaults">
                <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h48v48h-48zm0 0h48v48h-48zm0 0h48v48h-48z" fill="none"/>
                    <path
                        d="M36 28c0-8-12-21.6-12-21.6s-2.66 3.02-5.47 7.04l17.17 17.17c.19-.84.3-1.71.3-2.61zm-1.76 6.24l-9.24-9.24-14.46-14.46-2.54 2.55 6.64 6.64c-1.53 2.92-2.64 5.85-2.64 8.27 0 6.63 5.37 12 12 12 3.04 0 5.8-1.14 7.91-3l5.27 5.27 2.54-2.55-5.48-5.48z"
                        fill="#fff"/>
                </svg>
            </div>
        </div>
        <div class="property__tabs" v-show="isVisible" @mousedown="tabsMouseDown=true" @mouseup="tabsMouseDown=false">
            <input class="property__input__color" type="color" v-bind:id="'color_'+_uid" v-model="propValue"/>
            <div class="property__inputs-row">
                <div class="property__input-wrapper">
                    <div class="property__type_number">
                        <div class="property__name">Hex</div>
                        <div class="property__flex-row">
                            <span class="property__hash">#</span>
                            <input
                                class="property__input property__input_hex"
                                type="text"
                                v-model="hex"
                                size="7"
                                style="padding-left:12px"
                                @mousedown="hexMousedown($event)"
                                @keydown.enter="submitHex"
                                @blur="hexBlur"
                            />
                        </div>
                    </div>
                </div>
                <div class="property__input-wrapper" v-for="ch in ref" v-if="ch.name != 'a' || showAlpha">
                    <div class="property__name">{{ ch.name.toUpperCase() }}</div>
                    <component :is="propNumberType" :propset="colorProps" :property="ch" :length="3"></component>
                </div>
            </div>
            <br/>
            <div
                v-if="activePalette"
                @mouseup.stop.prevent=""
                @mousedown.stop.prevent=""
                class="palette-wrapper"
                :class="{'palette-wrapper_collapsed': showPalette != true}"
            >
                <div class="palette-wrapper-title" @click.self="togglePalette">
                    <svg width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                        <path
                            transform="rotate(180 23.5 7.024)"
                            d="M42 8l3 4.049h-6z"
                            fill="#FFF"
                            fill-rule="evenodd">
                        </path>
                    </svg> &nbsp;&nbsp;&nbsp;
                    {{ $t('projectColors') }}
                </div>
                <div class="palette">
                    <div class="palette-row" v-for="(main) in colorNaming.main">
                        <div
                            class="palette-item" v-for="(shade) in colorNaming.shades"
                            :style="{'background-color': 'rgba(' + activePalette[main+shade] + ')'}"
                            :class="{'palette-item_active': (propValue.palette == main+shade && !propValue.own) || property.name == main+shade}"
                            @click="paletteItemClick(main+shade)"
                        >
                        </div>
                    </div>
                    <div class="palette-row">
                        <div
                            class="palette-item"
                            v-for="(shade) in colorNaming.gsShades"
                            :style="{'background-color': 'rgba(' + activePalette['gs'+shade] + ')'}"
                            :class="{'palette-item_active': (propValue.palette == 'gs'+shade && !propValue.own) || property.name == 'gs'+shade}"
                            @click="paletteItemClick('gs'+shade)"></div>
                    </div>
                </div>
            </div>
            <div class="property__flex-row">
                <div
                    class="property__btn property__btn_eyedropper"
                    @mousedown.prevent="pickColor"
                    v-if="!themeEditingMode"
                >
                    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.774 2.048l-1.82-1.82a.774.774 0 0 0-1.096 0L8.43 2.656l-1.5-1.485-1.097 1.096L6.938 3.37.002 10.306V14h3.694l6.936-6.936 1.104 1.104 1.097-1.096-1.493-1.493 2.426-2.426a.777.777 0 0 0 .008-1.105zM3.05 12.445l-1.493-1.493 6.267-6.268 1.493 1.493-6.267 6.268z"
                            fill="#FFF" fill-rule="nonzero"/>
                    </svg>
                </div>
                <div
                    class="property__btn property__btn_confirm"
                    v-if="!themeEditingMode"
                    @mousedown.stop.prevent="confirm">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import _ from "lodash";
import Vue from "vue";
import $ from 'jquery';

import PropBase from "@/components/editor/property/PropBase";
import PropNumber from "@/components/editor/property/PropNumber";
import PropNumberText from "@/components/editor/property/PropNumberText";

export default {
    name: 'PropColor',
    inject: ['currentPage'],
    mixins: [PropBase],
    props: {
        nested: Boolean,
        showAlpha: {
            type: Boolean,
            default: true
        },
        currentPalette: Object
    },
    components: {
        prop_number: PropNumber,
        prop_number_text: PropNumberText
    },
    watch: {
        isVisible: function () {
            if (this.isVisible) {
                Vue.nextTick(function () {
                    return this.updateColorPicker();
                }, this);
            }
        },
        showPalette: {
            handler: function (val) {
                this.currentPage.setSetting('showPalette', val);
            },
            deep: true
        },
        propValue: function () {
            this.updateColorPicker();
        },
        'currentPage.mousePressed.left': function () {
            if (this.currentPage.mousePressed.left && !this.tabsMouseDown) {
                this.showTabs = false;
            }
        }
    },
    data: function () {
        return {
            showPalette: this.getShowPaletteSetting(),
            showTabs: false,
            tabsMouseDown: false,
            ref: {
                r: {
                    name: 'r',
                    min: 0,
                    max: 255
                },
                g: {
                    name: 'g',
                    min: 0,
                    max: 255
                },
                b: {
                    name: 'b',
                    min: 0,
                    max: 255
                },
                a: {
                    name: 'a',
                    min: 0,
                    max: 100
                }
            },
            colorNaming: {
                main: ['acc1', 'acc2', 'mut1', 'mut2'],
                shades: ['l10', 'l30', 'l50', 'l70', 'l90'],
                gsShades: ['l0', 'l25', 'l50', 'l75', 'l100']
            }
        };
    },
    computed: {
        activePalette: function () {
            var ref, ref1;
            if (this.currentPalette) {
                return this.currentPalette;
            } else {
                return (ref = this.$store.state.presentation) != null ? (ref1 = ref.theme) != null ? ref1.palette : undefined : undefined;
            }
        },
        themeEditingMode: function () {
            return this.currentPage.themesEditor;
        },
        isVisible: function () {
            return this.showTabs || this.nested;
        },
        colorProps: function () {
            var pv;
            pv = this.getValidColor(this.propValue);
            return {
                r: pv != null ? pv[0] : undefined,
                g: pv != null ? pv[1] : undefined,
                b: pv != null ? pv[2] : undefined,
                a: Math.round((pv != null ? pv[3] : undefined) * 100)
            };
        },
        propNumberType: function () {
            if (this.property.mode === 'text') {
                return 'prop_number_text';
            } else {
                return 'prop_number';
            }
        },
        rgbaOrRgb: function () {
            var res;
            res = 'RGB';
            if (this.showAlpha) {
                res += 'A';
            }
            return res;
        },
        hex: {
            get: function () {
                var b, g, hex, r;
                hex = '';
                if (this.propValue != null) {
                    r = Number(this.colorProps.r).toString(16);
                    if (r.length < 2) {
                        r = '0' + r;
                    }
                    g = Number(this.colorProps.g).toString(16);
                    if (g.length < 2) {
                        g = '0' + g;
                    }
                    b = Number(this.colorProps.b).toString(16);
                    if (b.length < 2) {
                        b = '0' + b;
                    }
                    hex = r + g + b;
                }
                return hex;
            },
            set: function (val) {
            }
        }
    },
    methods: {
        updateColorPicker: function () {
            // console.log 'updateColorPicker', @propValue
            this.input.spectrum('set', 'rgba(' + this.getValidColor(this.propValue) + ')');
            this.input.spectrum('reflow');
        },
        pickColor: function () {
            var that;
            that = this;
            this.currentPage.getPixelColor().then((result) => {
                // console.log result
                result[3] = 1;
                that.propValue = {
                    palette: false,
                    own: result
                };
                this.currentPage.clearSlideshot();
            }, (error) => {
                // console.log error
                this.currentPage.clearSlideshot();
            });
        },
        confirm: function (e) {
            this.$emit('confirm');
            if (!this.nested) {
                this.showTabs = false;
            }
        },
        recalc: function (row) {
            this.$emit('newpalette', this.calculatePalette(this.activePalette, row));
        },
        invertPalette: function () {
            var newPalette;
            newPalette = _.cloneDeep(this.activePalette);
            this.colorNaming.main.forEach(function (m) {
                [newPalette[m + 'l10'], newPalette[m + 'l90']] = [newPalette[m + 'l90'], newPalette[m + 'l10']];
                [newPalette[m + 'l30'], newPalette[m + 'l70']] = [newPalette[m + 'l70'], newPalette[m + 'l30']];
            });
            [newPalette.gsl0, newPalette.gsl100] = [newPalette.gsl100, newPalette.gsl0];
            [newPalette.gsl25, newPalette.gsl75] = [newPalette.gsl75, newPalette.gsl25];
            newPalette.isDark = !newPalette.isDark;
            this.$emit('newpalette', newPalette);
        },
        updateProp: function (k, v) {
            var newValue;
            if (v === null) {
                this.propValue = null;
                return;
            }
            if (this.themeEditingMode) {
                switch (k) {
                    case 'r':
                        newValue = [v, this.colorProps.g, this.colorProps.b];
                        break;
                    case 'g':
                        newValue = [this.colorProps.r, v, this.colorProps.b];
                        break;
                    case 'b':
                        newValue = [this.colorProps.r, this.colorProps.g, v];
                }
                this.propValue = newValue;
            } else {
                switch (k) {
                    case 'r':
                        newValue = [v, this.colorProps.g, this.colorProps.b, this.colorProps.a / 100];
                        break;
                    case 'g':
                        newValue = [this.colorProps.r, v, this.colorProps.b, this.colorProps.a / 100];
                        break;
                    case 'b':
                        newValue = [this.colorProps.r, this.colorProps.g, v, this.colorProps.a / 100];
                        break;
                    case 'a':
                        newValue = [this.colorProps.r, this.colorProps.g, this.colorProps.b, v / 100];
                }
                if (k !== 'a' && this.colorProps.a === 0) {
                    this.colorProps.a = 100;
                }
                this.propValue = {
                    palette: false,
                    own: newValue
                };
            }
        },
        // @$parent.updateProp(k, v)
        hexMousedown: function (e) {
            var ref;
            if (e.target === document.activeElement) {

            } else {
                e.preventDefault();
                if ((ref = this.currentPage.editingText) != null) {
                    ref.rememberSelection();
                }
                e.target.select();
            }
        },
        hexBlur: function (e) {
            var ref;
            if (this.tabsMouseDown) {
                this.submitHex(e);
                if ((ref = this.currentPage.editingText) != null) {
                    ref.restoreSelection();
                }
            }
        },
        submitHex: function (e) {
            var h, i, j, rgba, v, val;
            if ((e.key != null) && e.key !== 'Enter') {
                return;
            }
            val = e.currentTarget.value;
            rgba = [];
            for (i = j = 0; j <= 5; i = j += 2) {
                h = val.substr(i, 2);
                v = this.colorChannel(parseInt(h, 16));
                rgba.push(v);
            }
            if (!this.themeEditingMode) {
                if (this.colorProps.a === 0) {
                    this.colorProps.a = 100;
                }
                rgba.push(this.colorProps.a / 100);
                this.propValue = {
                    own: rgba,
                    palette: false
                };
            } else {
                this.propValue = rgba;
            }
        },
        colorChannel: function (val) {
            val = parseInt(val);
            switch (true) {
                case val < 0:
                    val = 0;
                    break;
                case val > 255:
                    val = 255;
                    break;
                case isNaN(val):
                    val = 0;
            }
            return val;
        },
        alphaChannel: function (val) {
            val = parseInt(val);
            switch (true) {
                case val < 0:
                    val = 0;
                    break;
                case val > 100:
                    val = 100;
                    break;
                case isNaN(val):
                    val = 0;
            }
            return val;
        },
        calcThemeStyles: function () {
            this.componentStyles = {
                '.property .property__tabs:before': {
                    'background-color': this.getColor('tabColor')
                },
                '.property__hash': {
                    'color': this.getColor('hashColor')
                }
            };
        },
        paletteItemClick: function (k) {
            var newPropValue;
            if (this.themeEditingMode) {
                this.$emit('propertychanged', k);
            } else {
                newPropValue = _.cloneDeep(this.propValue);
                newPropValue.own = false;
                newPropValue.palette = k;
                this.propValue = newPropValue;
            }
        },
        togglePalette: function () {
            this.showPalette = !this.showPalette;
        },
        getShowPaletteSetting: function () {
            var showPalette;
            showPalette = this.currentPage.getSetting('showPalette');
            if (showPalette == null) {
                showPalette = this.$store.state.defaultSettings.showPalette;
            }
            return showPalette;
        }
    },
    mounted: function () {
        var that;
        that = this;
        this.input = $(this.$el).find('.property__input__color');
        this.input.spectrum({
            preferredFormat: 'rgba',
            color: 'rgba(' + that.getValidColor(this.propValue) + ')',
            showInput: false,
            allowEmpty: false,
            showAlpha: this.showAlpha,
            showButtons: false,
            flat: true,
            appendTo: this.$el,
            move: function (color) {
                var arr, changingAlpha, newPropValue;
                if (that.themeEditingMode) {
                    arr = [Math.round(color._r), Math.round(color._g), Math.round(color._b)];
                    newPropValue = arr;
                } else {
                    arr = [Math.round(color._r), Math.round(color._g), Math.round(color._b), color._a];
                    changingAlpha = that.propValue[0] === arr[0] && that.propValue[1] === arr[1] && that.propValue[2] === arr[2];
                    if (arr[3] === 0 && !changingAlpha) {
                        arr[3] = 1;
                    }
                    newPropValue = _.cloneDeep(that.propValue);
                    newPropValue.own = arr;
                }
                that.propValue = newPropValue;
            }
        });
    }
};
</script>

<style lang="scss">
.property__type_color {
    // padding-top: 0;

    .property__input_hex {
        cursor: text;
    }

    .property__tabs {
        width: 210px;

        .prop-name_color-mode & {
            width: 100%;
        }
    }

    &:not(.property__nested) {
        .property__tabs {
            width: 230px;
            position: fixed;
            top: 48px;
            right: 0;
            left: auto;
            height: 100vh;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 10px;
        }
    }

    &.property__nested {
        .property__tabs {
            position: relative;
            top: initial;
            right: initial;
            left: initial;
            height: auto;
        }
    }

    // &.prop-name_text-background .property__tabs{
    //   left:-65px;
    // }

    .property__icon {
        position: relative;
        display: block;
        width: 40px;
        height: 35px;
        padding: 10px 0;
        box-sizing: border-box;
        cursor: pointer;

        svg {
            position: relative;
            display: block;
            margin: 0 auto;
        }
    }

    .property {
        padding: 0;

        &__name {
            margin-bottom: 0;
        }

        &__btn {
            background-color: #3e3f41;
            border-radius: 5px;
            height: 27px;
        }

        &__btn_eyedropper {
            position: relative;
            display: block;
            box-sizing: border-box;
            padding: 5px 0;
            width: 32px;

            &:hover {

                svg {
                    opacity: 1;
                }
            }

            svg {
                position: relative;
                display: block;
                height: 14px;
                width: 14px;
                margin: 0 auto;
                opacity: .3;
            }
        }

        &__btn_confirm {
            position: relative;
            display: block;
            width: 149px;
            margin-left: 7px;
        }
    }

    input[type="color"] {
        padding: 0;
        -webkit-appearance: none;

        &::-webkit-color-swatch-wrapper {
            padding: 0;
        }

        &::-webkit-color-swatch {
            border: none;
        }
    }

    .property__preview {
        position: relative;
        margin: 10px 0 0;
        height: 20px;
        width: 40px;
        cursor: pointer;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

        &-fill {
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    .property__hash {
        position: relative;
        display: block;
        width: 10px;
        margin-right: -6px;
        font-size: 12px;
        z-index: 1;
    }

    .property__inputs-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .property__input-wrapper {
        .property__name {
            margin-bottom: 4px;
        }
    }
}

.palette {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &-row {
        display: flex;
        flex-direction: row;
        margin-top: 3px;
        margin-bottom: 3px;

        &:first-child {
            margin-top: 10px;
        }
    }

    &-item {
        width: 30px;
        height: 25px;
        margin-right: 7px;
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        border: solid 1px rgba(255, 255, 255, 0.36);

        &:last-child {
            margin-right: 0;
        }

        &_active {
            border: solid 1px #fff;
        }
    }

    &-wrapper {
        position: relative;
        display: block;
        margin-bottom: 5px;
        box-sizing: border-box;
        color: #FFF;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
        line-height: 16px;
        padding: 5px 10px;
        margin-left: -10px;
        margin-right: -10px;

        svg {
            position: absolute;
            display: block;
            top: 9px;
            left: 6px;
            opacity: .2;
            transform-origin: center;
        }

        &-title {
            cursor: pointer;

            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        &_collapsed {
            height: 20px;
            overflow: hidden;
            margin-bottom: 15px;

            svg {
                transform: rotate(-90deg);
            }
        }
    }

}
</style>

<i18n>
{
    "en": {
        "Color": "Color",
        "Background": "Background",
        "projectColors": "Project colors",
        "Even": "Even",
        "Odd": "Odd",
        "Accent": "Accent"
    },
    "ru": {
        "Color": "Цвет",
        "Background": "Фон",
        "projectColors": "Цвета презентации",
        "Even": "Чётный",
        "Odd": "Нечётный",
        "Accent": "Акцент"
    }
}
</i18n>
