
export default {
    slideBackground: {
        groupName: 'Background',
        title: 'background',
        properties: ['background-image', 'color-mode']
    },
    order: {
        groupName: '',
        title: '',
        properties: ['z']
    },
    position: {
        groupName: '',
        title: 'position',
        groupClass: 'position',
        properties: ['x', 'y', 'x1', 'y1', 'x2', 'y2', 'height', 'width', 'rotate', 'z', 'lock-aspect']
    },
    tablePosition: {
        groupName: '',
        title: '',
        groupClass: 'position',
        properties: ['x', 'y', 'z']
    },
    align: {
        groupName: '',
        title: '',
        groupClass: 'align',
        properties: ['align']
    },
    shape: {
        groupName: 'Shape',
        title: 'shape',
        properties: ['corner-radius', 'top-offset', 'parallelogram-angle', 'chevron-angle', 'chevron-first', 'arrow-angle', 'arrow-thickness', 'arrow-direction']
    },
    fill: {
        groupName: 'Fill',
        title: 'fill',
        properties: [
            'background-image',
            'color-mode' // включает заливку градиентом и цветом
        ]
    },
    tableFill: {
        groupName: 'Fill',
        title: 'fill',
        properties: ['table-background-color']
    },
    tableFillTemplate: {
        groupName: 'Fill template',
        title: 'fillTemplate',
        properties: ['table-fill-template', 'table-bg2-color', 'table-bg1-color', 'table-acc-color']
    },
    stroke: {
        groupName: 'Stroke',
        title: 'stroke',
        properties: ['stroke-color', 'stroke-width', 'stroke-cap', 'stroke-dash', 'table-borders', 'table-stroke-color', 'table-stroke-width']
    },
    arrows: {
        groupName: 'Arrows',
        title: 'arrows',
        properties: ['arrows']
    },
    text: {
        groupClass: 'text',
        groupName: 'Text',
        title: 'text',
        properties: ['auto-height', 'list-type', 'font-family', 'font-size', 'line-height', 'letter-spacing', 'color', 'text-background', 'font-style', 'text-align', 'padding']
    },
    appearance: {
        groupName: 'Appearance',
        title: 'appearance',
        properties: ['opacity']
    },
    animation: {
        groupClass: 'animation',
        groupName: 'Animation',
        title: 'animation',
        properties: ['animation-autoplay', 'animation-autoplay-delay', 'animation-effect', 'animation-easing', 'animation-duration', 'animation-order']
    },
    seo: {
        groupClass: 'seo',
        groupName: 'Seo',
        title: 'seo',
        properties: ['seo-header', 'seo-text', 'seo-image']
    }
};
