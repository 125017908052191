/* eslint-disable */
import _ from "lodash";

export default {
  name: 'Data_URLs_mixin',
  data() {
    return {
      dataURLs: {},
      cachedMediaObjects: null
    };
  },
  watch: {
    'presentation.media': {
      deep: true,
      handler(val) {
        if (!this.cachedMediaObjects) {
          return;
        }
        const ids = Object.keys(val);
        ids.forEach((id) => {
          const cachedObject = this.cachedMediaObjects[id];
          const mediaObject = val[id];
          if (!cachedObject || (cachedObject.url !== mediaObject.url)) {
            this.generateDataURL(id);
          }
        });
        const cachedMediaObjects = _.cloneDeep(val);
      }
    }
  },
  mounted() {
    var clonedMediaObjects, ids;
    clonedMediaObjects = _.cloneDeep(this.presentation.media);
    ids = Object.keys(clonedMediaObjects);
    ids.forEach((id) => {
      this.generateDataURL(id);
    });
    this.cachedMediaObjects = clonedMediaObjects;
  },
  methods: {
    generateDataURL(id) {
      var canvas, dataURLs, excep, img, url, urlExceptions;
      url = this.presentation.media[id].url;
      urlExceptions = ['http', 'data', 'blob'];
      excep = urlExceptions.some(function (u) {
        return url.substring(0, 8).indexOf(u) === 0;
      });
      if (!excep) {
        url = this.$root.storageUrl + url;
      }
      canvas = document.createElement('canvas');
      img = new Image();
      img.crossOrigin = "Anonymous";
      dataURLs = this.$store.state.mediaDataURLs;
      img.onload = function () {
        var ctx, height, width;
        height = this.naturalHeight;
        width = this.naturalWidth;
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        dataURLs[id] = canvas.toDataURL('image/webp', 0.4);
      };
      img.src = url;
    }
  }
};
