/* eslint-disable */

import _ from "lodash";

import Polar from "@/mixins/editor/Polar";
import CoordsCommon from "@/mixins/editor/CoordsCommon";

export default {
  name: 'Coords_mixin',
  mixins: [CoordsCommon, Polar],
  methods: {
    sectionMiddle(a, b){
      return {
        x: (a.x + b.x)/2,
        y: (a.y + b.y)/2
      };
    },

    setSize(w, h){
      const cc = this.centerCoords;

      const r = this.opts.rotate;

      const newCoords = [{
        x: -w/2,
        y: -h/2
      }
        , {
          x: w/2,
          y: -h/2
        }
        , {
          x: w/2,
          y: h/2
        }
        , {
          x: -w/2,
          y: h/2
        }
      ];

      const newVirtualCoords = newCoords.map(c=> {
        const p = this.decart2polarCoords(c);
        p.fi += r;
        const d = this.polar2decartCoords(p);
        d.x += cc.x;
        d.y += cc.y;
        return d;
      });

      this.virtualCoords = newVirtualCoords;
    }
  },

  computed: {

    invertedAxis() {
      return {
        x: this.opts.coords[0].x > this.opts.coords[1].x,
        y: this.opts.coords[0].y > this.opts.coords[1].y
      };
    },

    mirror() {
      const {
        x
      } = this.invertedAxis;
      const {
        y
      } = this.invertedAxis;
      if (x && y) {
        return '-1, -1';
      }
      if (x) {
        return '-1, 1';
      }
      if (y) {
        return '1, -1';
      }
      return '1, 1';
    },


    // Виртуальные координаты с учетом поворота
    // 4 координаты по углам, начиная с верхнего левого угла
    virtualCoords: {

      get() {

        const that = this;
        const res = [];

        _.forEach(this.polarCoords, function(coords){

          const converted = that.polar2decartCoords(coords);

          converted.x += that.centerCoords.x;
          converted.y += that.centerCoords.y;

          res.push(converted);
        });

        return res;
      },

      // новые координаты виртуальных точек
      // newCoords: [{x,y},{x,y},{x,y},{x,y}]
      set(newCoords){
        const that = this;

        const res = [];

        // Вычисляем новые координаты центра
        const newCenterCoords = {
          x: (newCoords[0].x + newCoords[2].x) / 2,
          y: (newCoords[0].y + newCoords[2].y) / 2
        };

        // Вычисляем угол поворота
        // Если не округлить - возникают артефакты
        // при переходе через ось
        const tmp = {
          x: newCoords[2].x - newCoords[1].x,
          y: newCoords[2].y - newCoords[1].y
        };
        const polarTmp = this.decart2polarCoords(tmp);
        const newRotate = polarTmp.fi - 90;

        _.forEach(newCoords, function(coords){

          const converted = {};

          // Пересчитываем новые координаты
          // относительно нового центра
          converted.x = coords.x - newCenterCoords.x;
          converted.y = coords.y - newCenterCoords.y;

          // Переводим новые координаты в полярные
          // без учета угла поворота
          const convertedPolar = that.decart2polarCoords(converted);
          convertedPolar.fi -= that.opts.rotate;

          // Переводим новые полярные координаты в
          // новые реальные координаты
          const newCoord = that.polar2decartCoords(convertedPolar);

          // Учитываем смещение от центра
          newCoord.x += newCenterCoords.x;
          newCoord.y += newCenterCoords.y;

          res.push(newCoord);
        });

        this.opts.coords[0].x = res[0].x;
        this.opts.coords[0].y = res[0].y;
        this.opts.coords[1].x = res[2].x;
        this.opts.coords[1].y = res[2].y;

        let noRotate = true;
        if(
            (this.editingStateModifier == null) ||
            (this.editingStateModifier==='rotate')// ||
            // @editingStateModifier=='moving'
        ) {
          noRotate = false;
        }

        if (!noRotate) {
          this.opts.rotate = newRotate;
        }

      }
    },

    // Нужно ли зеркалить поворот
    invertedRotate() {
      let x;
      let y = (x = false);
      if (this.opts.coords[1].y < this.opts.coords[0].y) {
        y = true;
      }
      if (this.opts.coords[1].x < this.opts.coords[0].x) {
        x = true;
      }
      return x ^ y;
    },

    small() {
      const smallX = Math.abs(this.opts.coords[0].x - this.opts.coords[1].x) < 1;
      const smallY = Math.abs(this.opts.coords[0].y - this.opts.coords[1].y) < 1;

      if (smallX || smallY) {
        return true;
      }
      return false;
    },

    // 4 координаты объекта относительно его центра
    relativeCenterCoords() {
      return [{
        x: this.opts.coords[0].x - this.centerCoords.x,
        y: this.opts.coords[0].y - this.centerCoords.y
      }
        , {
          x: this.opts.coords[1].x - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        }
        , {
          x: this.opts.coords[1].x - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        }
        , {
          x: this.opts.coords[0].x - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        }
      ];
    },

    // Полярные координаты 4 точек
    polarCoords() {

      const that = this;
      const res = [];

      _.forEach(this.relativeCenterCoords, function(coords){

        const converted = that.decart2polarCoords(coords);
        converted.fi += that.opts.rotate;

        res.push(converted);
      });

      return res;
    }
  }
};
