
<script>
/* eslint-disable */
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Chevron_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'chevron'
    };
  },
  computed: {
    chevronPath: function() {
      var cc, res;
      cc = this.chevronCoords;
      res = "";
      cc.forEach(function(c, i) {
        var l;
        if (i === 0) {
          l = 'M';
        } else {
          l = 'L';
        }
        return res += `${l}${c.x} ${c.y}`;
      });
      res += "z";
      return res;
    },
    dx: function() {
      var angle, dx, negDx, posDx, rp;
      rp = this.rectParams;
      angle = this.styleProps['chevron-angle'];
      dx = Math.tan(this.deg2rad(angle)) * rp.height;
      posDx = Math.max(0, Math.min(dx, rp.width));
      negDx = Math.min(0, Math.max(dx, -rp.width));
      return {
        pos: posDx,
        neg: negDx
      };
    },
    chevronCoords: function() {
      var negDx, posDx, res, rp;
      rp = this.rectParams;
      posDx = this.dx.pos;
      negDx = this.dx.neg;
      res = [
        {
          x: rp.x + posDx,
          y: rp.y
        },
        {
          x: rp.width + negDx,
          y: rp.y
        },
        {
          x: rp.width - posDx,
          y: rp.y + rp.height / 2
        },
        {
          x: rp.width + negDx,
          y: rp.height
        },
        {
          x: rp.x + posDx,
          y: rp.height
        },
        {
          x: rp.x - negDx,
          y: rp.y + rp.height / 2
        }
      ];
      if (this.styleProps['chevron-first']) {
        if (this.styleProps['chevron-angle'] < 0) {
          res[5].x = rp.x;
        } else {
          res[2].x = rp.width;
        }
      }
      if (res[1].x <= res[0].x) {
        res[1].x = res[0].x + 1;
      }
      if (res[3].x <= res[4].x) {
        res[3].x = res[4].x + 1;
      }
      return res;
    },
    textLimiterParams: function() {
      var cc, diff, res, rp, w, x1, x2;
      cc = this.chevronCoords;
      rp = this.rectParams;
      if (this.styleProps['chevron-angle'] < 0) {
        x1 = cc[5].x;
        x2 = cc[1].x;
      } else {
        x1 = cc[0].x;
        x2 = cc[2].x;
      }
      if (x1 > x2) {
        diff = 0;
        w = rp.width;
      } else {
        diff = x1;
        w = x2 - x1;
      }
      res = {
        x: rp.x + diff,
        y: rp.y,
        height: rp.height,
        width: w
      };
      return res;
    },
    magnetCoordsDecart: function() {
      return [
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x + this.dx.pos + this.dx.neg) / 2 - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[1].x - this.centerCoords.x - this.dx.pos,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        },
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x + this.dx.pos + this.dx.neg) / 2 - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x - this.centerCoords.x - this.dx.neg,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
