<template>
    <canvas
        class="screenshot-container"
        style="transform-origin: top left; pointer-events: none; z-index: 1; left: 55px;"
        :style="{opacity: 0, 'pointer-events': 'none'}"
        :width="canvasWidth"
        :height="canvasHeight">
    </canvas>
</template>

<script>
/* eslint-disable */

import Polar from '@/mixins/editor/Polar';
import Color from '@/mixins/editor/Color';
import DataUrls from '@/mixins/editor/DataUrls';
import { CurrentPage } from '@/models/CurrentPage';

export default {
    name: 'ScreenshotGenerator',
    props: ['presentationOffset', 'presentation', 'boxed', 'vw', 'vh', 'slideEars'],
    mixins: [Polar, Color, DataUrls],
    data () {
        return {
            currentPage: CurrentPage(this),
            canvasHeight: 1,
            canvasWidth: 1,
            canvasHeightAddition: 0,
            canvasWidthAddition: 0,
            loadPromises: []
        };
    },
    watch: {
        'flags.screenshotGeneratorMode' (val) {
            if (this.flags.pdfGeneratorMode) {
                return;
            }
            if (val) {
                // First tick - change objects' state
                this.$nextTick(function () {
                    // Second tick - change texts' state
                    this.$nextTick(function () {
                        // Third tick - change texts' DOM
                        this.$nextTick(function () {
                            // Fourth tick - SVGs ready
                            this.$nextTick(function () {
                                this.getScreenshot();
                            });
                        });
                    });
                });
            } else {
                this.loadPromises = [];
            }
        }
    },
    computed: {
        flags () {
            return this.$store.state.flags;
        },
        size () {
            let height, width;
            if (this.boxed) {
                width = this.realSize.w;
                height = this.realSize.h;
            } else {
                width = parseInt(this.vw);
                height = parseInt(this.vh);
            }
            if (width === 0) {
                width = 320;
            }
            if (height === 0) {
                height = 200;
            }
            return { width, height };
        },
        realSize () {
            return this.presentation.size;
        },
        selfScale () {
            let key;
            const ratio = this.size.width / this.size.height;
            const realRatio = this.realSize.w / this.realSize.h;
            if (ratio > realRatio) {
                key = 'height';
            } else {
                key = 'width';
            }
            return this.size[key] / this.realSize[key[0]];
        },
        scale () {
            return this.currentPage.scale;
        }
    },
    methods: {
        imageLoadPromise (url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                if (!url) {
                    resolve(undefined);
                    return;
                }
                img.onload = function () {
                    resolve(this);
                };
                img.onerror = function () {
                    console.warn('cannot load image');
                    resolve(this);
                };
                return img.src = url;
            });
        },
        getScreenshot (slideIndex) {
            return new Promise((resolve, reject) => {
                var activeSlide, bgi, ctx, height, imageLoadPromise, media, mediaArray, mediaId, opts, scaleX, scaleY,
                    theCanvas, url, urlPrefix, width;
                theCanvas = this.$el;
                if (this.boxed) {
                    this.canvasHeightAddition = 0;
                    this.canvasWidthAddition = 0;
                } else {
                    this.canvasHeightAddition = this.presentation.size.h * this.slideEars.ver * 2 / 100;
                    this.canvasWidthAddition = this.presentation.size.w * this.slideEars.hor * 2 / 100;
                }
                this.canvasHeight = this.presentation.size.h + this.canvasHeightAddition;
                this.canvasWidth = this.presentation.size.w + this.canvasWidthAddition;
                height = this.canvasHeight;
                width = this.canvasWidth;
                ctx = theCanvas.getContext('2d');
                ctx.globalAlpha = 1;
                if (slideIndex == null) {
                    slideIndex = this.$store.getters.currentActiveSlide;
                }
                activeSlide = this.$store.state.presentation.slides[slideIndex];
                scaleX = 1;
                scaleY = 1;
                ctx.rect(0, 0, width, height);
                ctx.fillStyle = '#fff';
                ctx.fill();
                opts = activeSlide.bg;
                bgi = opts['background-image'];
                url = '';
                if (bgi) {
                    // Создаем объект для картинки
                    urlPrefix = this.currentPage.storageUrl;
                    mediaId = bgi;
                    mediaArray = this.$store.state.presentation.media;
                    media = mediaArray[mediaId];
                    if (media.temp) {
                        url = mediaArray[mediaId].url;
                    } else {
                        url = urlPrefix + mediaArray[mediaId].url;
                    }
                }
                imageLoadPromise = this.imageLoadPromise(url);
                this.loadPromises.push(imageLoadPromise);
                imageLoadPromise.then((img) => {
                    var bgAspect, canAng, cdx, cdy, centerX, centerY, cssAng, cx, cy, distFromCenter, ds, grad, h, i,
                        imageHeight,
                        imageWidth, imgAspect, imgRatio, invScaleX, invScaleY, j, k, l, left, m, maxShift, moreCols,
                        moreRows,
                        offsetMultiH, offsetMultiW, originX, originY, ratio, ref, ref1, ref2, ref3, sc, scale,
                        slideHeight,
                        slideRatio, slideWidth, t, top, w;
                    ctx.save();
                    // Если сплошная заливка
                    if (opts['color-mode'] === 'solid') {
                        ctx.rect(0, 0, width, height);
                        ctx.fillStyle = `rgba(${this.getValidColor(opts['background-color'])})`;
                        ctx.fill();
                    }
                    if (opts['color-mode'] === 'gradient') {
                        if (opts.gradient.type === 'linear') {
                            w = width;
                            h = height;
                            cx = w / 2;
                            cy = h / 2;
                            cssAng = opts.gradient.angle;
                            canAng = cssAng - 180;
                            ds = this.gradCoords(cx, cy, canAng);
                            grad = ctx.createLinearGradient(...ds);
                            opts.gradient.points.forEach((p) => {
                                var c, pos;
                                pos = p.position / 100;
                                c = 'rgba(' + this.getValidColor(p.color) + ')';
                                return grad.addColorStop(pos, c);
                            });
                            ctx.fillStyle = grad;
                            ctx.fillRect(0, 0, w, h);
                        }
                        if (opts.gradient.type === 'radial') {
                            distFromCenter = opts.gradient.center.map(function (v) {
                                return Math.abs(50 - v);
                            });
                            offsetMultiH = 1 + 2 * (distFromCenter[0] / 100);
                            offsetMultiW = 1 + 2 * (distFromCenter[1] / 100);
                            ratio = (height * offsetMultiW) / (width * offsetMultiH);
                            if (ratio < 1) {
                                scaleY = ratio;
                            } else {
                                scaleX = 1 / ratio;
                            }
                            invScaleX = 1 / scaleX;
                            invScaleY = 1 / scaleY;
                            ctx.setTransform(scaleX, 0, 0, scaleY, 0, 0);
                            w = width;
                            h = height;
                            cx = w * opts.gradient.center[0] / 100;
                            cy = h * opts.gradient.center[1] / 100;
                            cdx = w * (0.5 + distFromCenter[0] / 100);
                            cdy = h * (0.5 + distFromCenter[1] / 100);
                            grad = ctx.createRadialGradient(cx / scaleX, cy / scaleY, 0, cx / scaleX, cy / scaleY, Math.sqrt(cdx * cdx * (invScaleX * invScaleX) + cdy * cdy * (invScaleY * invScaleY)));
                            opts.gradient.points.forEach((p) => {
                                var c, pos;
                                pos = p.position / 100;
                                c = 'rgba(' + this.getValidColor(p.color) + ')';
                                return grad.addColorStop(pos, c);
                            });
                            ctx.fillStyle = grad;
                            ctx.fillRect(0, 0, w / scaleX, h / scaleY);
                        }
                    }
                    ctx.restore();
                    if ((img != null) && img.naturalWidth > 0 && img.naturalHeight > 0) {
                        ctx.save();
                        ctx.globalAlpha = opts['image-opacity'];
                        switch(opts['image-fill-mode']) {
                            case 'cover':
                                imgAspect = img.width / img.height;
                                bgAspect = width / height;
                                if (imgAspect > bgAspect) {
                                    // Вписываем по высоте
                                    scale = height / img.height;
                                    h = img.height * scale;
                                    w = img.width * scale;
                                    l = -(w - width) / 2;
                                    t = 0;
                                } else {
                                    // Вписываем по ширине
                                    scale = width / img.width;
                                    h = img.height * scale;
                                    w = img.width * scale;
                                    l = 0;
                                    t = -(h - height) / 2;
                                }
                                ctx.drawImage(img, l / scaleX, t / scaleY, w / scaleX, h / scaleY);
                                break;
                            case 'stretch':
                                ctx.drawImage(img, 0, 0, width / scaleX, height / scaleY);
                                break;
                            case 'tile':
                                sc = opts['image-scale'];
                                w = img.naturalWidth * sc;
                                h = img.naturalHeight * sc;
                                centerX = width / 2;
                                centerY = height / 2;
                                originX = centerX - w / 2;
                                originY = centerY - h / 2;
                                moreCols = originX / w;
                                moreRows = originY / h;
                                if (moreCols > 0) {
                                    moreCols = Math.ceil(moreCols);
                                } else {
                                    moreCols = 0;
                                }
                                if (moreRows > 0) {
                                    moreRows = Math.ceil(moreRows);
                                } else {
                                    moreRows = 0;
                                }
                                for (i = k = ref = -moreRows, ref1 = moreRows; k <= ref1; i = k += 1) {
                                    for (j = m = ref2 = -moreCols, ref3 = moreCols; m <= ref3; j = m += 1) {
                                        ctx.drawImage(img, (centerX - w / 2 + j * w) / scaleX, (centerY - h / 2 + i * h) / scaleY, w / scaleX, h / scaleY);
                                    }
                                }
                                break;
                            case 'custom':
                                ctx.globalAlpha = opts['image-opacity'];
                                imageWidth = opts['background-image-width'];
                                imageHeight = opts['background-image-height'];
                                imgRatio = imageWidth / imageHeight;
                                slideWidth = width;
                                slideHeight = height;
                                slideRatio = slideWidth / slideHeight;
                                if (imgRatio > slideRatio) { // фон шире, выступает вправо-влево
                                    imageHeight = slideHeight;
                                    imageWidth = imageHeight * imgRatio; // фон уже, выступает вверх-вниз
                                } else {
                                    imageWidth = slideWidth;
                                    imageHeight = imageWidth / imgRatio;
                                }
                                sc = opts['image-custom-scale'];
                                imageWidth *= sc;
                                imageHeight *= sc;
                                maxShift = {
                                    x: ((imageWidth / slideWidth) - 1) / 2,
                                    y: ((imageHeight / slideHeight) - 1) / 2
                                };
                                left = (0.5 + maxShift.x * opts['image-shift-x']) * slideWidth - 0.5 * imageWidth;
                                top = (0.5 + maxShift.y * opts['image-shift-y']) * slideHeight - 0.5 * imageHeight;
                                ctx.drawImage(img, left, top, imageWidth, imageHeight);
                        }
                        ctx.restore();
                    }
                    this.drawObjects(slideIndex);
                    Promise.all(this.loadPromises).then(() => {
                        this.loadPromises = [];
                        resolve('all loaded');
                    });
                });
            });
        },
        drawObjects (slideIndex) {
            let theSlide = this.$store.state.presentation.slides[slideIndex];
            let ctx = this.$el.getContext('2d');
            ctx.globalAlpha = 1;

            let svgExtend = this.$store.state.defaults.svgExtend;

            theSlide.objects.forEach((object) => {
                var ref, ref1;
                if (!object.vueObj.$refs.svg) {
                    return;
                }
                let vueObj = object.vueObj;
                let coords = object.coords;
                let opacity = (ref = object['styleProps']) != null ? ref.opacity : 0;

                if (opacity == null) {
                    opacity = (ref1 = object.tableData) != null ? ref1.style.opacity : 0;
                }

                let svgData = vueObj.getSvg();
                let rotate = vueObj.deg2rad(object.rotate);
                let DOMURL = window.URL || window.webkitURL || window;
                let imagePromise = new Promise((resolve, reject) => {
                    let img = new Image();
                    img.crossOrigin = 'anonymous';

                    let svg = new Blob([svgData], {
                        type: 'image/svg+xml;charset=utf-8'
                    });

                    // This is a known bug in Chrome: Canvas is tainted after drawing SVG including <foreignObject>.
                    // Meantime the canvas is not tainted if the same picture has been loaded from a data URI.
                    let reader = new FileReader();
                    reader.readAsDataURL(svg);
                    reader.onload = (e) => {
                        var svgDataURL;
                        svgDataURL = e.target.result;
                        img.onload = () => {
                            var rotatable, x, xScale, y, yScale;
                            // Applying rotation
                            ctx.save();
                            // Applying shapes mirroring
                            xScale = 1;
                            yScale = 1;
                            rotatable = vueObj.rotatable;
                            if (rotatable) {
                                if (object.coords[0].x > object.coords[1].x) {
                                    xScale = -1;
                                }
                                if (object.coords[0].y > object.coords[1].y) {
                                    yScale = -1;
                                }
                                x = coords[0].x + this.canvasWidthAddition / 2 - svgExtend * xScale;
                                if (xScale === -1) {
                                    x -= img.naturalWidth;
                                }
                                y = coords[0].y + this.canvasHeightAddition / 2 - svgExtend;
                            } else {
                                x = Math.min(coords[0].x, coords[1].x) - svgExtend + this.canvasWidthAddition / 2;
                                y = Math.min(coords[0].y, coords[1].y) - svgExtend + this.canvasHeightAddition / 2;
                            }
                            ctx.translate(x + img.naturalWidth / 2, y + img.naturalHeight / 2);
                            ctx.rotate(rotate);
                            ctx.scale(xScale, yScale);
                            ctx.globalAlpha = opacity;
                            ctx.drawImage(img, -img.naturalWidth / 2, -img.naturalHeight / 2);
                            ctx.restore();
                            resolve(img);
                        };
                        img.src = svgDataURL;
                    };
                });

                return this.loadPromises.push(imagePromise);
            });
        },
        getImageData (coords, radius) {
            const ctx = this.$el.getContext('2d');
            const iData = ctx.getImageData(coords.x + this.canvasWidthAddition / 2 - 2, coords.y + this.canvasHeightAddition / 2 - 2, radius, radius);
        },
        getCanvasDataURL () {
            const theCanvas = this.$el;
            return theCanvas.toDataURL('image/webp');
        }
    }
};
</script>

<style>

</style>
