<template>
  <div class="property property__type_number" :class="'property__mode_'+mode">
    <div class="property__name" v-if="property.title && property.name != 'animation-autoplay-delay'">
      {{ property.title }}
    </div>
    <div class="property__flex-row">
      <div class="property__text-before" v-if="property.title && property.name == 'animation-autoplay-delay'">
        {{ property.title }}
      </div>
      <input class="property__input" type="text" :name="property.title" :size="inputSize" v-model="displayValue"
             @blur="inputBlur" @focus="inputFocus" @mousedown="mouseDown" @dblclick="dblClick" @keydown.stop="keyDown"/>
      <div class="property__input-units" v-if="units">{{ units }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// Модификация prop_number для свойств font-size,
// line-height и letter-spacing, чтобы в них нельзя было поставить курсор
// если выделена часть текста, иначе слетает выделение
import PropNumber from "@/components/editor/property/PropNumber";

import _ from 'lodash';
import $ from 'jquery';

export default {
  name: 'propNumberText',
  inject: ['currentPage'],
  mixins: [PropNumber],
  methods: {
    dblClick: function(e) {
      var isTextProp, ref, ref1;
      isTextProp = _.indexOf(['font-size', 'line-height', 'letter-spacing'], this.property.name) >= 0;
      // selectionParams = @$root.editingText.getSelectionParams()
      // if selectionParams?.flags?
      //   partSelected = !(selectionParams.flags.allSelected or selectionParams.flags.selectionIsCollapsed)

      // if isTextProp and partSelected
      //   return
      this.mode = 'input';
      this.myTarget = (ref = this.currentPage.editingText) != null ? ref.getTextTarget() : undefined;
      if ((ref1 = this.myTarget) != null) {
        ref1.rememberSelection();
      }
      $(e.currentTarget).focus();
    },
    calcThemeStyles: function() {},
    inputFocus: function() {
      this.currentPage.doNotRestoreSelectionFlag = true;
    },
    inputBlur: function(e) {
      this.mode = 'drag';
      this.currentPage.doNotRestoreSelectionFlag = false;
      this.$nextTick(function() {
        var ref;
        if ((ref = this.myTarget) != null) {
          ref.restoreSelection();
        }
        delete this.myTarget;
      });
    }
  }
};
</script>

<style lang="scss">
.property__type_number{
  &.prop-name_chevron-angle, &.prop-name_arrow-angle, &.prop-name_arrow-thickness, &.prop-name_animation-autoplay-delay{
    width: 50%;
    display: inline-block;
    padding-right: 0;
  }

  &.prop-name_animation-autoplay-delay{
    padding-left: 0;
    .property__input-units{
      transform: translate(-70%, 1px);
    }
  }

  .property__name{
    margin-bottom: 0;
  }
  input{
    position:relative;
    display:block;
    font-size: 11px;
    cursor:move;
    max-width: 70px;

    &:hover, &:active, &:focus{
      background: rgba(0,0,0,.2);
    }
  }

  &.property__mode_input input{
    cursor:text;
  }

  .property__input-units{
    position:relative;
    display:block;
    margin-left: -3px;
    transform: translateX(-100%);
    font-size: 11px;
    pointer-events: none;
    color:#fff;
  }

  .property__text-before{
    position:relative;
    display:block;
    color:#777777;
    font-size: 13px;
    cursor: pointer;
    transform: translateX(-7px);
  }
}
</style>
