import Ajax from '@/mixins/Ajax';
import _ from 'lodash';
import $ from 'jquery';

import IntegrationThumb from '@/components/editor/MediaBrowser/components/IntegrationThumb';

const template = `
<div class="tabs__body-item tabs__body-item_integration" :class="selfClass">
    <div class="spinner" :class="{'spinner_active': busy}">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
        </svg>
    </div>
    <div class="media-browser__search"><input class="media-browser__input" type="text" v-model="search" @input="browseDebounced(true, true)" :placeholder="searchPlaceholder"/>
        <svg style="width:14px; height: 14px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
            <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd" opacity=".30000001" stroke-linecap="round" stroke-linejoin="round">
                <path d="M9.5355186 2.4644684c1.952621 1.95262098 1.952621 5.11844922 0 7.0710702-1.95262098 1.952621-5.11844922 1.952621-7.0710702 0-1.95262098-1.95262098-1.95262098-5.11844922 0-7.0710702 1.95262098-1.95262098 5.11844922-1.95262098 7.0710702 0M13.000007 13.000007L9.5333386 9.5333386"/>
            </g>
        </svg>
    </div>
    <div class="media-browser__items custom-scroll" @scroll="mediaScroll($event, 'browse')">
        <integration-thumb v-for="(thumb, index) in searchResultsUrls" :src="thumb" :id="index" :key="index" :selected="selectedId" @itemselected="itemSelected(index)"></integration-thumb>
    </div>
</div>
`;

export default {
    template: template,
    props: ['visible'],
    components: {
        integrationThumb: IntegrationThumb
    },
    mixins: [Ajax],
    created: function () {
        this.browseDebounced = _.debounce(this.browse, 700);
    },
    data: function () {
        return {
            busy: false,
            search: '',
            searchResults: [],
            selectedId: -1
        };
    },
    watch: {
        visible: function (val) {
            if (val) {
                this.browse(false, true);
            }
        }
    },
    computed: {
        selfClass: function () {
            var res;
            res = {};
            res['tabs__body-item_' + this.name] = true;
            return res;
        }
    },
    methods: {
        mediaScroll: function (e) {
            var pb;
            pb = parseInt(getComputedStyle(e.target)['padding-bottom']);
            if ($(e.target).height() + e.target.scrollTop >= e.target.scrollHeight - pb) {
                this.browse();
            }
        }
    }
};
