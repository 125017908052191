<template>
  <!-- eslint-disable -->
  <div class="meta-tool">
    <hints :hintsCondition="hintsCondition" :mouseCoords="mouseCoords" :scaledMouseCoords="scaledCoords"
           :allObjects="allObjects" :editingObjects="editingObjects" :useSelfCenter="editingStateModifier == 'moving'"
           :scale="scale" :zeroPointOffset="zeroPointOffset" :metaToolTracks="selfTracks"
           :metaToolUnscaledTracks="unscaledSelfTracks" :state="editingStateModifier" :rotate="editor.rotate"
           :virtualCoords="virtualCoords" :creating="state==='creating'" :shiftPressed="keyModifiers['shift']"
           @overlap="setOverlap" @clearOverlap="clearOverlap" ref="hints"></hints>
    <div class="background-tool" v-if="state == 'background'" @mousewheel="bgMousewheel($event)"
         @dblclick="$store.state.flags.bgEditingMode = false"
         @click.right.prevent.stop=""></div>
    <div class="selector-tool" v-if="selector.active" :style="selectorStyles"></div>
    <div class="editor-wrapper"
         v-else-if="(state == 'editing' && editor.active) || state == 'creating'|| state == 'table'"
         :class="{'editor-wrapper_blocked': currentPage.blockMetatool && !tableEditing, 'editor-wrapper_blocked-nodes': currentPage.blockMetatoolHandlers && !tableEditing}"
         @dragenter.prevent.stop="currentPage['droppingMedia'] = true">
      <div class="link-dots" v-if="canBeLinked">
        <div class="link-dot" v-for="(dot, index) in canBeLinked.vueObj.magnetCoords" v-bind:style="{top: pres2scaledCoords(dot).y + 'px', left: pres2scaledCoords(dot).x + 'px'}"></div>
      </div>
      <div class="animation-orders">
        <div class="animation-order" v-for="(order, index) in animationOrders" :style="{top: pres2scaledCoords(order).y + 'px', left: pres2scaledCoords(order).x + 'px'}">
          {{ order.i }}
        </div>
      </div>
      <div class="editor-tool" v-if="state == 'editing' && editor.active && !tableEditing"
           :style="editorStyles"
           :class="{'editor-tool__line': lineEditing, 'editor-tool__table': tableEditing, 'editor-tool__text': overText, 'editor-tool__saveaspect': saveAspect}"
           @dblclick="dblclick($event)" @click.right="setMenuTarget">
        <div class="editor-tool__background" v-if="!overText"></div>
        <div class="editor-tool__item editor-tool__border editor-tool__border__left border border__vertical"></div>
        <div class="editor-tool__item editor-tool__border editor-tool__border__right border border__vertical"></div>
        <div class="editor-tool__item editor-tool__border editor-tool__border__top border border__horizontal"></div>
        <div class="editor-tool__item editor-tool__border editor-tool__border__bottom border border__horizontal"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__top editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__top"
             v-if="!lineEditing && !autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.top"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottom editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottom"
             v-if="!lineEditing && !autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.bottom"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__left editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__left" v-if="!lineEditing"
             v-on:mousedown="editingStateModifier = stateInverter.left"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__right editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__right" v-if="!lineEditing"
             v-on:mousedown="editingStateModifier = stateInverter.right"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__topleft editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__topleft" v-if="!autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.top + stateInverter.left"
             :class="{'editor-tool__item_disabled': !(!tableInEditingObjects&&((!lineEditing)||(!(invertedAxis.y^invertedAxis.x))))}"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__topright editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__topright" v-if="!autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.top + stateInverter.right"
             :class="{'editor-tool__item_disabled': !(!tableInEditingObjects&&((!lineEditing)||(invertedAxis.y^invertedAxis.x)))}"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottomleft editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottomleft" v-if="!autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.bottom + stateInverter.left"
             :class="{'editor-tool__item_disabled': !(!tableInEditingObjects&&((!lineEditing)||(invertedAxis.y^invertedAxis.x)))}"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottomright editor-tool__node__rotate"
             v-if="!tableInEditingObjects && !lineEditing"
             v-on:mousedown="editingStateModifier = 'rotate'"></div>
        <div class="editor-tool__item editor-tool__node editor-tool__node__bottomright" v-if="!autoHeightEditing"
             v-on:mousedown="editingStateModifier = stateInverter.bottom + stateInverter.right"
             :class="{'editor-tool__item_disabled': !(!tableInEditingObjects&&((!lineEditing)||(!(invertedAxis.y^invertedAxis.x))))}"></div>
      </div>
      <template v-if="tableEditing">
        <div class="editor-tool editor-tool__noevents"
             :style="{'top': editorBounds.top - 10 + 'px', 'left': editorBounds.left - 10 + 'px', 'width': editorBounds.width + 'px', 'height': editorBounds.height+'px'}"></div>
        <div class="editor-tool__table_item editor-tool__table_item__move editor-tool__table_item__border"
             :style="{'top': editorBounds.top - 10 + 'px', 'left': editorBounds.left - 10 + 'px', 'width': editorBounds.width + 20 + 'px', 'height': '10px'}"
             v-on:mousedown="tableStateModifier = 'moving'"></div>
        <div class="editor-tool__table_item editor-tool__table_item__move editor-tool__table_item__border"
             :style="{'top': editorBounds.bottom+'px', 'left': editorBounds.left-10+'px', 'width':editorBounds.width+20+'px', 'height': '10px'}"
             v-on:mousedown="tableStateModifier = 'moving'"></div>
        <div class="editor-tool__table_item editor-tool__table_item__move editor-tool__table_item__border"
             :style="{'top': editorBounds.top-10+'px', 'left': editorBounds.left-10+'px', 'height':editorBounds.height+20+'px', 'width': '10px'}"
             v-on:mousedown="tableStateModifier = 'moving'"></div>
        <div class="editor-tool__table_item editor-tool__table_item__move editor-tool__table_item__border"
             :style="{'top': editorBounds.top-10+'px', 'left': editorBounds.right+'px', 'height':editorBounds.height+20+'px', 'width': '10px'}"
             v-on:mousedown="tableStateModifier = 'moving'"></div>
        <div class="editor-tool__table_item editor-tool__table_item__topleft editor-tool__table_item__corner editor-tool__table_item__node"
             :style="{'top': editorBounds.top-10+'px', 'left': editorBounds.left-10+'px', 'height':'10px', 'width': '10px', 'cursor': 'nw-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.top + stateInverter.left"></div>
        <div class="editor-tool__table_item editor-tool__table_item__topright editor-tool__table_item__corner editor-tool__table_item__node"
             :style="{'top': editorBounds.top-10+'px', 'left': editorBounds.right+'px', 'height':'10px', 'width': '10px', 'cursor': 'ne-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.top + stateInverter.right"></div>
        <div class="editor-tool__table_item editor-tool__table_item__bottomright editor-tool__table_item__corner editor-tool__table_item__node"
             :style="{'top': editorBounds.bottom+'px', 'left': editorBounds.right+'px', 'height':'10px', 'width': '10px', 'cursor': 'se-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.bottom + stateInverter.right"></div>
        <div class="editor-tool__table_item editor-tool__table_item__bottomleft editor-tool__table_item__corner editor-tool__table_item__node"
             :style="{'top': editorBounds.bottom+'px', 'left': editorBounds.left-10+'px', 'height':'10px', 'width': '10px', 'cursor': 'sw-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.bottom + stateInverter.left"></div>
        <div class="editor-tool__table_item editor-tool__table_item__left editor-tool__table_item__side editor-tool__table_item__node"
             :style="{'top': (editorBounds.bottom + editorBounds.top)/2-5+'px', 'left': editorBounds.left-10+'px', 'height':'10px', 'width': '10px', 'cursor': 'w-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.left"></div>
        <div class="editor-tool__table_item editor-tool__table_item__right editor-tool__table_item__side editor-tool__table_item__node"
             :style="{'top': (editorBounds.bottom + editorBounds.top)/2-5+'px', 'left': editorBounds.right+'px', 'height':'10px', 'width': '10px', 'cursor': 'e-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.right"></div>
        <div class="editor-tool__table_item editor-tool__table_item__top editor-tool__table_item__side editor-tool__table_item__node"
             :style="{'top': editorBounds.top-10+'px', 'left': (editorBounds.left + editorBounds.right)/2-5+'px', 'height':'10px', 'width': '10px', 'cursor': 'n-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.top"></div>
        <div class="editor-tool__table_item editor-tool__table_item__bottom editor-tool__table_item__side editor-tool__table_item__node"
             :style="{'top': editorBounds.bottom+'px', 'left': (editorBounds.left + editorBounds.right)/2-5+'px', 'height':'10px', 'width': '10px', 'cursor': 's-resize'}"
             v-on:mousedown="tableStateModifier = stateInverter.bottom"></div>
        <div class="editor-tool__table_item editor-tool__table_item__avatar editor-tool__table_item__avatar__vertical"
             v-if="tableStateModifier=='ver'"
             :style="{'top': editorBounds.top+'px', 'left': tableBorderAvatarCoord +'px', 'height': editorBounds.height +'px', 'width': '1px'}"></div>
        <div class="editor-tool__table_item editor-tool__table_item__avatar editor-tool__table_item__avatar__horizontal"
             v-if="tableStateModifier=='hor'"
             :style="{'top': tableBorderAvatarCoord+'px', 'left': editorBounds.left+'px', 'height': '1px', 'width': editorBounds.width + 'px'}"></div>
        <div
            class="editor-tool editor-tool__avatar"
            v-if="tableStateModifier && tableStateModifier != 'moving'"
            :style="editorAvatarStyles">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import _ from "lodash";
import $ from 'jquery';
import Coords from "@/mixins/editor/Coords";
import Hints from "@/components/editor/Hints";

let indexOf = [].indexOf;

export default {
  /*
  Главный инструмент редактирования презентации
  */
  // ¯\_(ツ)_/¯
  inject: ['currentPage'],
  name: 'MetaTool',
  mixins: [Coords],
  props: ['scale', 'mouseDown', 'mouseEvent', 'zeroPointOffset', 'presentationOffset', 'groups'],
  components: {
    hints: Hints
  },
  data() {
    return{
      initialCoords: {
        real: {
          x: 0,
          y: 0
        },
        scaled: {
          x: 0,
          y: 0
        }
      },

      selector: {
        active: false,
        coords:[{
          x: 0,
          y: 0
        }
          , {
            x: 0,
            y: 0
          }
        ]
      },

      editingObjects: [],
      editingObjectsCache: [],
      editorCachedCoords:[],
      selectedObject: undefined,
      respondedOnStart: undefined,

      editor: {
        active: false,
        coords:[{
          x: 0,
          y: 0
        }
          , {
            x: 0,
            y: 0
          }
        ],
        rotate: 0
      },
      editingStateModifier: 'moving',

      tableStateModifier: undefined,
      selectedTableBorder: undefined,


      canBeLinked: false,

      overText: false,

      // Блокирует любые действия
      // Активируется при выделении текста
      blocker: false,

      // Флаг активного процесса
      duringProcess: false,

      groupsChangeFlag: false,

      // Флаг переключается при начале и окончании
      // редактирования таблицы
      // для правильной прорисовки shadow редактора
      shadowEditor: false,

      keyModifiers: {
        'shift': false,
        'ctrl': false,
        'alt': false,
        'z': false,
        'y': false,
        'c': false,
        'v': false,
        'x': false,
        'space': false
      },

      // tracks:
      //   horizontal: []
      //   vertical: []

      // visibleTracks:
      //   horizontal: undefined
      //   vertical: undefined

      // centeringTracks:
      //   horizontal: []
      //   vertical: []

      vdotsMask: [{
        x: false,
        y: false
      }
        , {
          x: false,
          y: false
        }
        , {
          x: false,
          y: false
        }
        , {
          x: false,
          y: false
        }
      ],

      saveTimeout: undefined,

      restoreSelectionTimeout: undefined,

      stickyCoords: {
        x: 0,
        y: 0
      },

      creatingStickyCoords: {
        x: 0,
        y: 0
      }
    };
  },

  mounted() {

    // Hotkeys handler

    const metaTool = this;
    window.addEventListener(
        'keydown',
        function(e){
          const kc = e.keyCode;

          const ctrl = e.ctrlKey;
          const shift = e.shiftKey;
          const meta = e.metaKey;

          const codes = metaTool.currentPage.keyCodes.hotkeys;

          const inText = window.getSelection().type !== 'None';

          switch (kc) {
            case codes.cCode:
              if (!metaTool.mouseDown) {
                if (!inText) {
                  if (ctrl || meta) {
                    metaTool.copyObjects();
                  }
                }
              }
              break;
            case codes.vCode:
              if (!inText) {
                if (!metaTool.mouseDown) {
                  if (ctrl || meta) {
                    metaTool.pasteObjects();
                  }
                }
              }
              break;
            case codes.xCode:
              if (!inText) {
                if (!metaTool.mouseDown) {
                  if (ctrl || meta) {
                    metaTool.cutObjects();
                  }
                }
              }
              break;
            case codes.zCode:
              if (ctrl) {
                metaTool.undo();
              }
              if (meta) {
                if (shift) {
                  metaTool.redo();
                } else {
                  metaTool.undo();
                }
              }
              break;
            case codes.yCode:
              if (ctrl) {
                metaTool.redo();
              }
              break;
          }
        });

  },

  computed: {

    resizeFlag() {
      return this.currentPage.resizeFlag;
    },

    allObjects() {
      return this.$store.state.presentation.slides[this.currentActiveSlide].objects;
    },

    // Хак для работы виртуальных координат
    opts() {
      return this.editor;
    },
    centerCoords() {
      return this.editorCenter;
    },

    // Координаты мыши с учетом смещения контейнера презентации
    mouseCoords() {
      const rc = this.currentPage.rawCoords;
      const po = this.presentationOffset;
      return {
        x: rc.x - po.x,
        y: rc.y - po.y
      };
    },

    // Нажатые клавиши
    keyPressed() {
      return this.currentPage.keyPressed;
    },

    // Текущее состояние приложения
    // По умолчанию - выделение
    state() {

      if (this.$store.state.flags.bgEditingMode) {
        return 'background';
      }
      if (this.$store.state.toolbar.currentActive !== undefined) {
        return 'creating';
      }
      if (this.tableEditing) {
        return 'table';
      }
      if (this.editingObjects.length > 0) {
        return 'editing';
      }
      return 'selecting';
    },

    // Флаг, сообщающий о редактировании кривой
    lineEditing() {
      return (this.editingObjects.length === 1) && this.editingObjects[0].vueObj.isLine;
    },

    // Флаг, сообщающий о редактировании блока с автовысотой
    autoHeightEditing() {
      const eo = this.editingObjects;
      return (eo.length === 1) && eo[0].styleProps?.['adjust-to-text'];
    },

    // Флаг, сообщающий о редактировании таблицы
    tableEditing() {
      const res = (this.editingObjects.length === 1) && this.editingObjects[0].vueObj.isTable;
      if (res) {
        this.editingObjects[0].vueObj.dblClick();
      }
      return res;
    },

    // Стили области выделения
    selectorStyles() {
      const sc = this.selector.coords;
      return{
        top: `${Math.min(sc[0].y, sc[1].y)}px`,
        left: `${Math.min(sc[0].x, sc[1].x)}px`,
        width: `${Math.abs(sc[1].x - sc[0].x)}px`,
        height: `${Math.abs(sc[1].y - sc[0].y)}px`
      };
    },

    // Стили области редактирования
    editorStyles() {
      return{
        top: this.editorBounds.top + 'px',
        left: this.editorBounds.left + 'px',
        width: this.editorBounds.width + 'px',
        height: this.editorBounds.height + 'px',
        transform: `rotate(${this.editor.rotate}deg)`
      };
    },

    // Границы области редактирования
    editorBounds() {
      let res = {};

      // Запомненные границы до редактирования
      // для редактирования таблицы
      // (изменения отображаются не в основном метатуле
      // а в аватаре)
      if ((this.tableStateModifier != null) && (this.tableStateModifier !== 'moving') &&
          (this.editor.meta != null) && this.shadowEditor) {
        res = {
          top: Math.round(Math.min(
              this.editor.meta.rememberedCoords[0].y,
              this.editor.meta.rememberedCoords[2].y
          )),
          bottom: Math.round(Math.max(
              this.editor.meta.rememberedCoords[0].y,
              this.editor.meta.rememberedCoords[2].y
          )),
          left: Math.round(Math.min(
              this.editor.meta.rememberedCoords[0].x,
              this.editor.meta.rememberedCoords[2].x
          )),
          right: Math.round(Math.max(
              this.editor.meta.rememberedCoords[0].x,
              this.editor.meta.rememberedCoords[2].x
          )),
          width: Math.round(Math.abs(
              this.editor.meta.rememberedCoords[2].x -
              this.editor.meta.rememberedCoords[0].x
          )),
          height: Math.round(Math.abs(
              this.editor.meta.rememberedCoords[2].y -
              this.editor.meta.rememberedCoords[0].y
          ))
        };
      } else {
        res = {
          top: Math.round(Math.min(
              this.editor.coords[0].y, this.editor.coords[1].y
          )),
          bottom: Math.round(Math.max(
              this.editor.coords[0].y, this.editor.coords[1].y
          )),
          left: Math.round(Math.min(
              this.editor.coords[0].x, this.editor.coords[1].x
          )),
          right: Math.round(Math.max(
              this.editor.coords[0].x, this.editor.coords[1].x
          )),
          width: Math.round(Math.abs(
              this.editor.coords[1].x - this.editor.coords[0].x
          )),
          height: Math.round(Math.abs(
              this.editor.coords[1].y - this.editor.coords[0].y
          ))
        };
      }
      return res;
    },

    // Стили для аватара редактора таблиц
    editorAvatarStyles() {
      const res = {
        top: Math.min(
            this.editor.coords[0].y, this.editor.coords[1].y
        ) + 'px',
        left: Math.min(
            this.editor.coords[0].x, this.editor.coords[1].x
        ) + 'px',
        width: Math.abs(
            this.editor.coords[1].x - this.editor.coords[0].x
        ) + 'px',
        height: Math.abs(
            this.editor.coords[1].y - this.editor.coords[0].y
        ) + 'px'
      };
      return res;
    },

    // Центр области выделения
    editorCenter() {
      return{
        x: (this.editor.coords[0].x + this.editor.coords[1].x)/2,
        y: (this.editor.coords[0].y + this.editor.coords[1].y)/2
      };
    },

    editorScaledCenter() {
      return{
        x: (this.editorScaledCoords[0].x + this.editorScaledCoords[1].x)/2,
        y: (this.editorScaledCoords[0].y + this.editorScaledCoords[1].y)/2
      };
    },

    // Флаг нескольких объектов в области выделения
    multipleEditingObjects() {
      return this.editingObjects.length > 1;
    },

    oneEditingObject() {
      return this.editingObjects.length === 1;
    },

    // Флаг сохранения соотношения сторон
    saveAspect() {
      if (this.keyModifiers['shift']) {
        return true;
      }
      if (this.multipleEditingObjects) {

        let locked, table;
        let rotated = (table = (locked = false));
        this.editingObjects.forEach(function(o){

          if (o.tableData != null) {
            table = true;
          }

          if (o.styleProps?.['lock-aspect']) {
            locked = true;
          }

          if (o.rotate != null) {
            const angleMultipleOf90 = Math.abs(Math.round(o.rotate) % 90) === 0;
            if (!angleMultipleOf90) {
              rotated = true;
            }
          }
        });

        return rotated || table || locked;
      }

      if (this.oneEditingObject) {
        return this.editingObjects[0]?.styleProps?.['lock-aspect'];
      }
    },



    tableInEditingObjects() {
      let found = false;
      _.forEach(this.editingObjects, function(object){
        if (object.vueObj.isTable) {
          found = true;
        }
      });
      return found;
    },

    editorScaledCoords: {
      get() {
        if(this.editorCachedCoords.length>0) {
          return this.editorCachedCoords;
        }

        return [{
          x: (this.editor.coords[0].x - this.zeroPointOffset.x) / this.scale,
          y: (this.editor.coords[0].y - this.zeroPointOffset.y) / this.scale
        }
          , {
            x: (this.editor.coords[1].x - this.zeroPointOffset.x) / this.scale,
            y: (this.editor.coords[1].y - this.zeroPointOffset.y) / this.scale
          }
        ];
      },
      set(o){

        let rotate, x1, x2, y1, y2;
        const editorWidth = this.editorScaledCoords[1].x - this.editorScaledCoords[0].x;
        const editorHeight = this.editorScaledCoords[1].y - this.editorScaledCoords[0].y;

        if (this.saveAspect) {

          const aspect = editorWidth / editorHeight;

          if (o.key === 'height') {
            o.width = o.height * aspect;
          }

          if (o.key === 'width') {
            o.height = o.width / aspect;
          }
        }

        switch (o.key) {

          case 'x1': case 'x2': case 'y1': case 'y2':

            x1 = (o.x1*this.scale) + this.zeroPointOffset.x;
            x2 = (o.x2*this.scale) + this.zeroPointOffset.x;
            y1 = (o.y1*this.scale) + this.zeroPointOffset.y;
            y2 = (o.y2*this.scale) + this.zeroPointOffset.y;
            rotate = 0;
            break;

          default:

            if (Math.round(editorWidth) === o.width) {
              x1 = (o.x*this.scale) + this.zeroPointOffset.x;
              x2 = (o.x*this.scale) + (o.width*this.scale) + this.zeroPointOffset.x;
            } else {
              x1 = this.editorCenter.x - ((o.width*this.scale)/2);
              x2 = this.editorCenter.x + ((o.width*this.scale)/2);
            }

            if (Math.round(editorHeight) === o.height) {
              y1 = (o.y*this.scale) + this.zeroPointOffset.y;
              y2 = (o.y*this.scale) + (o.height*this.scale) + this.zeroPointOffset.y;
            } else {
              y1 = this.editorCenter.y - ((o.height*this.scale)/2);
              y2 = this.editorCenter.y + ((o.height*this.scale)/2);
            }

            rotate = parseInt(o.rotate);
        }

        this.editor.coords=[{
          x: x1,
          y: y1
        }
          , {
            x: x2,
            y: y2
          }
        ];
        this.editor.rotate = rotate;
      }
    },

    width() {
      return this.editor.coords[1].x - this.editor.coords[0].x;
    },

    height() {
      return this.editor.coords[1].y - this.editor.coords[0].y;
    },

    // Ссылка на активный слайд
    currentActiveSlide() {
      return this.$store.getters.currentActiveSlide;
    },

    // Проверка на клик
    geometryShiftNullSize() {
      return (this.initialCoords.real.x === this.mouseCoords.x) &&
          (this.initialCoords.real.y=== this.mouseCoords.y);
    },

    // Перевод координат мыши в координаты презентации
    // с учетом её нативного размера
    scaledCoords() {
      return{
        x: (this.mouseCoords.x - this.zeroPointOffset.x) / this.scale,
        y: (this.mouseCoords.y - this.zeroPointOffset.y) / this.scale
      };
    },

    // Зеркалирование состояний ресайза
    // в зависимости от координат редактора
    stateInverter() {
      const res = {
        top: 'top',
        bottom: 'bottom',
        left: 'left',
        right: 'right'
      };

      if (this.invertedAxis.x) {
        res.left = 'right';
        res.right = 'left';
      }

      if (this.invertedAxis.y) {
        res.top = 'bottom';
        res.bottom = 'top';
      }

      return res;
    },

    groupSelected() {

      this.groupsChangeFlag;

      const that = this;

      let groups = [];

      this.editingObjects.map(function(object){
        const group = that.objectIsInGroup(object.id);
        //if group != -1
        groups.push(group);
      });

      groups = _.uniq(groups);
      if (groups.length === 1) {
        return groups[0];
      }
      return -1;
    },

    unscaledSelfTracks() {

      const {
        selfTracks
      } = this;

      const res = {};

      Object.keys(selfTracks).forEach(ikey=> {

        const o = selfTracks[ikey];

        switch (ikey) {
          case 'withCenter': case 'withoutCenter':

            var horizontal = o.horizontal.map(coord=> {
              return{
                value: (coord.value - this.zeroPointOffset.y)/this.scale,
                root: (coord.root - this.zeroPointOffset.x)/this.scale
              };
            });
            var vertical = o.vertical.map(coord=> {
              return{
                value:  (coord.value - this.zeroPointOffset.x)/this.scale,
                root:  (coord.root - this.zeroPointOffset.y)/this.scale
              };
            });

            res[ikey] = {horizontal, vertical};
            break;

          case 'bySides':
            res[ikey] = {};
            Object.keys(o).forEach(jkey=> {
              const oo = o[jkey];
              switch (jkey) {
                case 'top': case 'bottom':
                  return res[ikey][jkey] = {
                    value: (oo.value - this.zeroPointOffset.y)/this.scale,
                    root: (oo.root - this.zeroPointOffset.x)/this.scale
                  };
                case 'left': case 'right':
                  return res[ikey][jkey] = {
                    value: (oo.value - this.zeroPointOffset.x)/this.scale,
                    root: (oo.root - this.zeroPointOffset.y)/this.scale
                  };
              }
            });
            break;
        }
      });

      return res;
    },

    tableBorderAvatarCoord() {

      // tsm - alias for tableStateModifier
      let ct;
      const tsm = this.tableStateModifier;
      // stb - alias for selectedTableBorder
      const stb = this.selectedTableBorder;

      // ct - alias for coordType
      if (tsm === 'hor') {
        ct = 'y';
      }
      if (tsm === 'ver') {
        ct = 'x';
      }

      const table = this.editingObjects[0].vueObj;
      const borders = table.bordersPercPositions;
      // c - alias for coords
      const c = this.editor.coords;

      // bp - alias for borderPerc
      const bp = borders[tsm][stb]/100;
      const prevBp = borders[tsm][stb - 1]/100;
      const nextBp = borders[tsm][stb + 1]/100;

      // Находим ограничения смещения аватара

      const treshold = 20;

      const minres = c[0][ct] + (prevBp*(c[1][ct]-c[0][ct])) + (treshold*this.scale);
      const maxres = (c[0][ct] + (nextBp*(c[1][ct]-c[0][ct]))) - (treshold*this.scale);

      // Находим начальное смещение аватара
      // Находим координату границы
      let res = c[0][ct] + (bp*(c[1][ct] - c[0][ct]));

      // Добавляем смещение мыши
      const mouseDiff = this.mouseCoords[ct] - this.initialCoords.real[ct];
      res += mouseDiff;

      // Проверям на ограничения
      if (res < minres) {
        return minres;
      }
      if (((ct!=='y') || (bp === 0)) && (res > maxres)) {
        return maxres;
      }

      return res;
    },

    animationsOrdersMapping() {
      const slide = this.$store.state.presentation.slides[this.currentActiveSlide];
      const animationsOrdersMapping = {};
      slide.animations.forEach(function(a, ai){
        a.forEach(function(i){
          animationsOrdersMapping[i] = ai;
        });
      });
      return animationsOrdersMapping;
    },


    animationOrders() {
      const slide = this.$store.state.presentation.slides[this.currentActiveSlide];
      const animationObjectsIds = _.flattenDeep(slide.animations);

      const res = [];

      this.allObjects.forEach(o=> {
        if (animationObjectsIds.includes(o.id)) {
          const v = o.vueObj;
          return res.push({
            y: v.virtualCoords[1].y - 22,
            x: v.virtualCoords[1].x + 22,
            i: this.animationsOrdersMapping[o.id] + 1
          });
        }
      });

      return res;
    },

    hintsCondition() {

      const case1 = (this.state === 'editing') || (this.state === 'table');
      const case2 = this.editingStateModifier !== 'rotate';
      const case3 = this.duringProcess;

      const case4 = this.editingObjects.length > 0;
      const case5 = this.keyModifiers['alt'];

      const case6 = this.state === 'creating';
      const case7 = (this.editingObjects[0] != null);

      const editing = case1 && case2 && case3;
      const alt = case4 && case5;
      const creating = case6 && case7;

      if (editing) { return 'editing'; }
      if (alt) { return 'alt'; }
      if (creating) { return 'creating'; }
      return false;
    },

    debouncedSaveHistory() {
      return _.debounce(this.saveHistory, 500);
    }
  },

  // Следим за событиями
  watch: {

    // Мыши:
    'mouseDown'() {
      if (this.currentPage.allowWorkspaceMoving) {
        return;
      }

      if (this.selectedObject && this.mouseDown && !this.currentPage.menuActive) {
        const objectHit = this.selectedObject.isSelfInDomElemsArray(this.punctureDOM());
        const selfHit = this.isSelfInDomElemsArray(this.punctureDOM());
        if (!objectHit && !selfHit) {
          // @selectedObject.isTextSelected = false
          this.selectedObject = undefined;
          // Коллбэк нужен из-за асинхронности метода
          this.restoreCachedObjects(()=> {
            this.drawSelectedSpace();
          });
        }
      }

      this.$nextTick(
          function(){
            if (this.mouseDown) {
              this.startProcess();
            } else {
              this.endProcess();
            }
          });

    },

    mouseCoords() {
      if (this.currentPage.blockMetatool) {
        return;
      }
      if (this.currentPage.allowWorkspaceMoving) {
        return;
      }
      this.coordsChanged();
      if ((this.state === 'creating') &&
          this.$store.state.toolbar.currentActive.opts.link) {
        this.showLinkDots();
      }
      if (this.currentPage.slideshot2dContext) {
        this.currentPage.drawMagnifier(this.scaledCoords);
      }
    },

    'editor.rotate'() {
      this.updateEditingObjects();
    },

    virtualCoords: {
      handler() {
        // У таблицы работает отдельный аватар (если не перемещение)
        if (!this.tableEditing ||
            (this.tableStateModifier === 'moving') ||
            (this.tableStateModifier === undefined)) {
          this.updateEditingObjects();
        }
      },
      deep: true
    },

    // Обработчик кодов клавиатуры
    keyPressed() {
      // Ловим клавиши delete и backspace
      if (((_.indexOf(this.keyPressed, 46) !== -1) ||
              (_.indexOf(this.keyPressed, 8) !== -1)) &&
          (this.editingStateModifier !== 'editing') &&
          !this.currentPage.blockMetatool) {
        this.deleteObjects();
      }
      // Ловим клавишу shift
      if (_.indexOf(this.keyPressed, 16) !== -1) {
        this.keyModifiers['shift'] = true;
      } else {
        this.keyModifiers['shift'] = false;
      }
      // Ловим клавишу alt
      if (_.indexOf(this.keyPressed, 18) !== -1) {
        this.keyModifiers['alt'] = true;
      } else {
        this.keyModifiers['alt'] = false;
      }
      // Ловим клавишу space
      if (_.indexOf(this.keyPressed, 32) !== -1) {
        this.keyModifiers['space'] = true;
        this.oldWorkspaceShifts = {
          tx: this.currentPage.navigate.tx,
          ty: this.currentPage.navigate.ty
        };
      } else {
        this.keyModifiers['space'] = false;
      }
      // Ловим клавишу ctrl
      if (this.currentPage.os === 'Mac') {
        if ((_.indexOf(this.keyPressed, 91) !== -1) ||
            (_.indexOf(this.keyPressed, 93) !== -1)) {
          this.keyModifiers['ctrl'] = true;
        } else {
          this.keyModifiers['ctrl'] = false;
        }
      } else {
        if (_.indexOf(this.keyPressed, 17) !== -1) {
          this.keyModifiers['ctrl'] = true;
        } else {
          this.keyModifiers['ctrl'] = false;
        }
      }

    },

    // Вызываем метод @whileEditing()
    // при нажатии на клавиши модификаторов
    // для пересчета редактора на лету
    'keyModifiers.shift'() {
      if (this.mouseDown) {
        if (this.editingStateModifier !== 'moving') {
          this.whileEditing();
        }
      }
    },
    'keyModifiers.ctrl'() {
      if (this.mouseDown) {
        if (this.editingStateModifier !== 'moving') {
          this.whileEditing();
        }
      }
    },

    // Отслеживаем изменение текущего слайда
    currentActiveSlide() {
      this.clearState();
    },

    // Ресайз:
    resizeFlag() {
      if ((this.state === 'editing') || (this.state === 'table')) {
        return this.drawSelectedSpace();
      }
    },

    editingObjects(val, oldVal){

      const pres = this.$store.state.presentation;
      pres.editingObjectsIds = this.editingObjects.map(o => o.id);

      // Для надежности
      oldVal.forEach(function(o){
        // o.vueObj.isTextSelected = false
        o.vueObj.isSelected = false;
      });
      if (val.length > 1) {
        val.forEach(function(o){
          o.vueObj.isSelected = true;
        });
      }

      // Поскольку таблица сама не может узнать о своем расфокусе,
      // сообщаем ей об этом
      if (oldVal[0]?.vueObj.isTable && (oldVal.length === 1) &&
          !(oldVal[0] === val[0])) {
        oldVal[0].vueObj.focusOut();
      }

      // Делаем ссылку на редактируемый текст в руте
      if (this.editingObjects.length === 1) {
        this.currentPage.editingText = this.editingObjects[0].vueObj?.$refs?.text;
      } else {
        this.currentPage.editingText = undefined;
      }

      // Делаем ссылку на редактируемую таблицу в руте
      if (this.tableEditing) {
        this.currentPage.editingTable = this.editingObjects[0].vueObj;
      } else {
        this.currentPage.editingTable = undefined;
      }

    },

    state(newVal){
      if (newVal === 'creating') {
        this.currentPage.drawingNewObject = true;
      } else {
        this.currentPage.drawingNewObject = false;
      }
    }
  },

  methods: {

    setMenuTarget(e){
      e.preventDefault();
      e.stopPropagation();
      this.currentPage.menuTarget='object';
      const groupItem = _.find(this.currentPage.menuItems.object, o => o.event === 'group');
      const ungroupItem = _.find(this.currentPage.menuItems.object, o => o.event === 'ungroup');
      const addTextItem = _.find(this.currentPage.menuItems.object, o => o.event === 'addText');
      if(this.editingObjects.length>1) {
        addTextItem.hidden = true;
        if(this.groupSelected !== -1) {
          groupItem.hidden = true;
          ungroupItem.hidden = false;
        } else {
          groupItem.hidden = false;
          groupItem.disabled = false;
          ungroupItem.hidden = true;
        }
        // if @tableInEditingObjects
        //   groupItem.disabled = true
        //   ungroupItem.hidden = true
        this.currentPage.showContextMenu(e);
      } else {
        this.editingObjects[0].vueObj.setMenuTarget(e, true);
      }
    },

    alignObjects(dir){
      let calcExtr, oneTo, severalTo, targetAxis, to;
      this.currentPage.logUserAction('metaTool', `alignObjects ${dir}`);
      const that = this;

      const esc = that.editorScaledCoords;
      const pSize = this.$store.state.presentation.size;

      switch (dir) {
        case 'left':

          severalTo = _.min(esc.map(coord => coord.x));
          oneTo = 0;

          calcExtr = o => _.min(o.vueObj.virtualCoords.map(coord => coord.x));

          targetAxis = 'x';
          break;

        case 'right':

          severalTo = _.max(esc.map(coord => coord.x));
          oneTo = pSize.w;

          calcExtr = o => _.max(o.vueObj.virtualCoords.map(coord => coord.x));

          targetAxis = 'x';
          break;

        case 'center':

          severalTo = (that.editorCenter.x - that.zeroPointOffset.x)/that.scale;
          oneTo = pSize.w / 2;

          calcExtr = o => o.vueObj.centerCoords.x;

          targetAxis = 'x';
          break;

        case 'top':

          severalTo = _.min(esc.map(coord => coord.y));
          oneTo = 0;

          calcExtr = o => _.min(o.vueObj.virtualCoords.map(coord => coord.y));

          targetAxis = 'y';
          break;

        case 'bottom':

          severalTo = _.max(esc.map(coord => coord.y));
          oneTo = pSize.h;

          calcExtr = o => _.max(o.vueObj.virtualCoords.map(coord => coord.y));

          targetAxis = 'y';
          break;

        case 'middle':

          severalTo = (that.editorCenter.y - that.zeroPointOffset.y)/that.scale;
          oneTo = pSize.h / 2;

          calcExtr = o => o.vueObj.centerCoords.y;

          targetAxis = 'y';
          break;
      }


      // Main function

      const oneObject = this.editingObjects.length === 1;

      if (oneObject) {
        to = oneTo;
      } else {
        to = severalTo;
      }

      // Собираем диффы для всех объектов

      const diffs = this.editingObjects.map(obj=> {
        const objExtr = calcExtr(obj);

        return to - objExtr;
      });

      this.editingObjects.forEach(function(obj, i){
        if ((obj.linkedDots == null) ||
            ((obj.linkedDots.start.length === 0) &&
                (obj.linkedDots.end.length === 0))) {
          const diff = diffs[i];
          const newCoords = obj.vueObj.virtualCoords.map(function(coord){
            coord[targetAxis] += diff;
            return coord;
          });
          obj.vueObj.virtualCoords = newCoords;
        }
      });
      this.$nextTick(function(){
        this.drawSelectedSpace();
      });
    },

    // метод вызывается при изменении параметров из propPanel
    updateProps(newProps){
      this.editorScaledCoords = newProps;
    },

    startProcess() {
      this.currentPage.logUserAction('metaTool', 'startProcess');
      this.duringProcess = true;

      // Блокируем дальнейшие действия
      // если клик пришелся на область с текстом
      if (this.overText) {
        this.blocker = true;
        return;
      }

      // Снимаем все выделения текста
      window.getSelection().removeAllRanges();

      this.initialCoords.real = {
        x: this.mouseCoords.x,
        y: this.mouseCoords.y
      };

      this.$store.state.vars.initialCoords = {
        x: this.mouseCoords.x,
        y: this.mouseCoords.y
      };

      this.initialCoords.scaled = {
        x: this.scaledCoords.x,
        y: this.scaledCoords.y
      };

      // Запоминаем по кому попали в начале процесса
      this.respondedOnStart = this.someInPuncture(
          this.punctureDOM(),
          this.editingObjects
      );

      switch (this.state) {
        case 'creating':
          this.startCreatingProcess();
          break;
        case 'selecting':
          var click = this.endSelectingProcess() >= 1;
          if (!click) {
            this.startSelectingProcess();
          } else {
            this.startEditingProcess();
          }
          break;
        case 'background':
          this.startBackgroundProcess();
          break;
        case 'editing':
          this.startEditingProcess();
          break;
        case 'movingWorkspace':
          this.startWorkspaceMovingProcess();
          break;
        case 'table':
          this.startTableProcess();
          break;
      }

    },

    coordsChanged() {

      if (this.blocker || !this.duringProcess) {
        return;
      }

      switch (this.state) {
        case 'creating':
          if (this.mouseDown) {
            this.whileCreating();
          }
          break;
        case 'selecting':
          if (this.mouseDown) {
            this.whileSelecting();
          }
          break;
        case 'background':
          this.whileBackgroundProcess();
          break;
        case 'editing':
          if (this.mouseDown) {
            if ((this.keyModifiers['ctrl'] || this.keyModifiers['shift']) && (this.editingStateModifier === 'moving') && !this.respondedOnStart) {
              this.whileSelecting();
            } else {
              this.whileEditing();
            }
          }
          break;
        case 'table':
          if (this.mouseDown) {
            this.whileTableProcess();
          }
          break;
      }
    },

    endProcess() {
      this.currentPage.logUserAction('metaTool', 'endProcess');
      if (!this.blocker && this.duringProcess) {
        switch (this.state) {
          case 'creating':
            this.endCreatingProcess();
            break;
          case 'selecting':
            this.endSelectingProcess();
            break;
          case 'background':
            this.endBackgroundProcess();
            break;
          case 'table':
            this.endTableProcess();
            break;
          case 'editing':
            if ((this.keyModifiers['ctrl'] || this.keyModifiers['shift']) && (this.editingStateModifier === 'moving')) {
              if (this.geometryShiftNullSize) {
                const responded = this.someInPuncture(
                    this.punctureDOM(),
                    this.editingObjects
                );
                if (!responded) {
                  const click = this.endSelectingProcess() >= 1;
                  if (!click) {
                    this.startSelectingProcess();
                  }
                } else {
                  this.removeObjectFromEditor(responded);
                }
              } else {
                this.endSelectingProcess();
              }
            } else {
              this.endEditingProcess();
            }
            break;
        }
      }
      this.duringProcess = false;
      this.respondedOnStart = undefined;
      this.blocker = false;

    },

    startCreatingProcess() {
      this.currentPage.logUserAction('metaTool', 'startCreatingProcess');
      const {
        opts
      } = this.$store.state.toolbar.currentActive;
      this.$store.commit('addComponent', opts);

      const storeObjs = this.$store.state.activeSlide.objects;
      const object = storeObjs[storeObjs.length - 1];
      this.editingObjects = [];
      this.editingObjectsCache = [];
      this.editingObjects.push(object);

      this.$refs.hints.calcObjectsTracks();
      this.creatingStickyCoords = this.$refs.hints.stickDot(this.initialCoords.real);

      this.$nextTick(function(){ return this.whileCreating(); });
    },

    whileCreating() {

      let absDiffX, absDiffY, diffX, diffY;
      if ((this.editingObjects[0].vueObj == null)) {
        return;
      }

      const that = this;

      if (this.keyModifiers['shift']) {
        diffX = this.initialCoords.scaled.x - this.scaledCoords.x;
        diffY = this.initialCoords.scaled.y - this.scaledCoords.y;
        absDiffX = Math.abs(diffX);
        absDiffY = Math.abs(diffY);
      }

      const coord1 = {};
      if (this.stickyCoords.x) {
        const sbx = (this.stickyCoords.x - this.zeroPointOffset.x) / this.scale;
        coord1.x = sbx;
      } else {
        coord1.x = this.scaledCoords.x;
      }
      if (this.stickyCoords.y) {
        const sby = (this.stickyCoords.y - this.zeroPointOffset.y) / this.scale;
        coord1.y = sby;
      } else {
        coord1.y = this.scaledCoords.y;
      }

      const coord2 = {
        x: this.initialCoords.scaled.x + this.creatingStickyCoords.x,
        y: this.initialCoords.scaled.y + this.creatingStickyCoords.y
      };

      // Для двумерных объектов
      // (чтобы не было отражения)
      if (this.editingObjects[0].vueObj.rotatable) {
        this.editingObjects[0].coords[0].x =
            Math.min(coord1.x, coord2.x);
        this.editingObjects[0].coords[0].y =
            Math.min(coord1.y, coord2.y);
        this.editingObjects[0].coords[1].x =
            Math.max(coord1.x, coord2.x);
        this.editingObjects[0].coords[1].y =
            Math.max(coord1.y, coord2.y);

        if (this.keyModifiers['shift']) {
          if (absDiffX > absDiffY) {
            this.editingObjects[0].coords[0].y =
                Math.min(this.initialCoords.scaled.y - diffX, this.initialCoords.scaled.y);
            this.editingObjects[0].coords[1].y =
                Math.max(this.initialCoords.scaled.y - diffX, this.initialCoords.scaled.y);
          }
          if (absDiffY > absDiffX) {
            this.editingObjects[0].coords[0].x =
                Math.min(this.initialCoords.scaled.x - diffY, this.initialCoords.scaled.x);
            this.editingObjects[0].coords[1].x =
                Math.max(this.initialCoords.scaled.x - diffY, this.initialCoords.scaled.x);
          }
        }

        // Для одномерных объектов
      } else {
        this.editingObjects[0].coords[0].x = coord2.x;
        this.editingObjects[0].coords[0].y = coord2.y;
        this.editingObjects[0].coords[1].x = coord1.x;
        this.editingObjects[0].coords[1].y = coord1.y;

        if (this.keyModifiers['shift']) {
          if (absDiffX > absDiffY) {
            this.editingObjects[0].coords[1].y = this.initialCoords.scaled.y;
          }
          if (absDiffY > absDiffX) {
            this.editingObjects[0].coords[1].x = this.initialCoords.scaled.x;
          }
        }

        this.showLinkDots();
      }

    },

    endCreatingProcess() {
      this.currentPage.logUserAction('metaTool', 'endCreatingProcess');
      const that = this;

      this.roundObjectsCoords(this.editingObjects);
      if (!this.editingObjects[0].vueObj.isLine) {
        let deltaX, deltaY;
        if (this.geometryShiftNullSize) {
          const pSize = this.$store.state.presentation.size;
          deltaX = pSize.w/2;
          deltaY = pSize.h/4;

          const eo0 = this.editingObjects[0];

          if (eo0.type === 'arrow') {
            const ad = eo0.styleProps['arrow-direction'];
            if ((ad === 'north') || (ad === 'south')) {
              [deltaX, deltaY] = [deltaY, deltaX];
            }
          }

          if (eo0.type === 'triangle') {
            deltaX = (deltaY = pSize.w/4);
          }

        } else {
          deltaX = (deltaY = 2);
        }
        const cs = this.editingObjects[0].coords;
        if ((cs[1].x - cs[0].x) < deltaX) {
          cs[1].x = cs[0].x + deltaX;
        }
        if ((cs[1].y - cs[0].y) < deltaY) {
          cs[1].y = cs[0].y + deltaY;
        }
      }

      this.drawSelectedSpace();
      this.$store.commit('saveHistory', 'endCreatingProcess');
      this.editingObjects[0].vueObj.setReady();
      // Запускаем необходимые после создания методы
      const {
        afterCreate
      } = this.$store.state.toolbar.currentActive.opts;
      if (afterCreate != null) {
        afterCreate.map(toRun=> {
          if (!toRun.immediate) {
            this.editingObjects[0].vueObj[toRun.method]();
          }
        });
        delete this.editingObjects[0].vueObj.opts.afterCreate;
      }

      if (this.editingObjects[0].vueObj.isLine) {

        const order = ['start', 'end'];

        // Пробуем привязать два конца кривой
        _.forEach(this.editingObjects[0].vueObj.opts.coords, (dot, i)=> {

          this.allObjects.map(obj=> {
            // treshold - размер области "приземления" точки
            let linkDot;
            const treshold = 10;

            // comparator - функция, проверяющая возможность
            // "приземления" точки
            const comparator = obj.vueObj.dotNearLinkDot;

            if (comparator) {
              linkDot = comparator(dot, treshold);
            }
            if (linkDot != null) {
              // TOREFACTOR?
              this.editingObjects[0].linkedDots[order[i]] =
                  [obj.id, linkDot];
            }
          });
        });
      }


      this.hideLinkDots();
      this.$store.commit('setActiveTool', undefined);
    },

    startSelectingProcess() {
      this.currentPage.logUserAction('metaTool', 'startSelectingProcess');
      this.selector.active = true;

      this.whileSelecting();
    },

    whileSelecting() {
      this.selector.coords[0].x = this.initialCoords.real.x;
      this.selector.coords[0].y = this.initialCoords.real.y;
      this.selector.coords[1].x = this.mouseCoords.x;
      this.selector.coords[1].y = this.mouseCoords.y;
    },

    endSelectingProcess() {
      this.currentPage.logUserAction('metaTool', 'endSelectingProcess');
      if ((this.$store.state.activeSlide == null)) {
        return;
      }
      const that = this;
      // Если было выделение кликом
      if(this.geometryShiftNullSize) {

        // Ищем по кому попали
        const elems = this.punctureDOM();

        const responded = this.someInPuncture(elems, this.$store.state.activeSlide.objects);

        if (responded != null) {
          this.addObjectToEditor(responded.id);
        }

        // Если было выделение областью
      } else {
        // Находим объекты, подходящие под область выделения
        _.each(this.$store.state.activeSlide.objects, function(object, index){

          // Вычисляем опорные точки объекта

          const objectPoints = object.vueObj.virtualCoords;

          const area = [{
            x: that.initialCoords.scaled.x,
            y: that.initialCoords.scaled.y
          }
            , {
              x: that.scaledCoords.x,
              y: that.scaledCoords.y
            }
          ];

          // Проверяем попадание хотя бы одной
          // точки в выделенную область
          let inside = false;
          _.each(objectPoints, function(coords){
            if (that.coordsInsideArea(coords, area)) {
              inside = true;
            }
          });

          if (inside) {
            // object.index = index
            that.addObjectToEditor(object.id);
          }

        });
      }

      //Рисуем границу выделения
      if (this.editingObjects.length > 0) {
        this.drawSelectedSpace();
      } else {
        this.editor.active = false;
      }

      this.selector.active = false;
      return this.editingObjects.length;
    },

    startBackgroundProcess() {
      this.$store.state.flags.bgEditingProcess = true;
    },

    whileBackgroundProcess() {

      const {
        initialCoords
      } = this.$store.state.vars;

      this.$store.state.vars.coordsDiff = {
        x: this.mouseCoords.x - initialCoords.x,
        y: this.mouseCoords.y - initialCoords.y
      };
    },

    endBackgroundProcess() {
      this.$store.state.flags.bgEditingProcess = false;
    },

    startEditingProcess() {
      this.currentPage.logUserAction(
          'metaTool',
          'startEditingProcess ' + this.editingStateModifier
      );
      this.clearOverlaps();
      if (this.editingStateModifier === 'moving') {

        if (this.clickOutOfSelection()) {
          return;
        }
      }

      // Запоминаем начальные координаты выбранных объектов
      if ((this.editor.meta == null)) {
        this.editor.meta = {};
      }

      this.rememberEditorCoords();

      this.$nextTick(function(){ return this.whileEditing(); });
    },

    whileEditing(manual){
      let saveAspect;
      const that = this;

      if(this.currentPage.editingText?.focusInside != null) {
        this.currentPage.editingText.focusInside = false
      }

      // Без этой проверки объекты дергаются при выделении
      // из-за дальнейшего расчета прилипания
      if (this.geometryShiftNullSize) {
        return;
      }

      // Вычисляем разницу между начальными и текущими координатами мыши
      let diff = {
        x: this.mouseCoords.x - this.initialCoords.real.x,
        y: this.mouseCoords.y - this.initialCoords.real.y
      };

      const axis = ['x', 'y'];
      axis.forEach(a=> {
        if (this.stickyCoords[a]) {
          diff[a] = this.stickyCoords[a] - this.initialCoords.real[a];
        }
      });

      // Ограничения перехода через 0 для таблицы
      if (this.tableEditing) {
        if (this.editingStateModifier.includes('right')) {
          if ((this.editor.meta.initialWidth + diff.x) < 10) {
            diff.x = -this.editor.meta.initialWidth + 10;
          }
        }
        if (this.editingStateModifier.includes('left')) {
          if (this.editor.meta.initialWidth < (diff.x + 10)) {
            diff.x = this.editor.meta.initialWidth - 10;
          }
        }
        if (this.editingStateModifier.includes('bottom')) {
          if ((this.editor.meta.initialHeight + diff.y) < 10) {
            diff.y = -this.editor.meta.initialHeight + 10;
          }
        }
        if (this.editingStateModifier.includes('top')) {
          if (this.editor.meta.initialHeight < (diff.y + 10)) {
            diff.y = this.editor.meta.initialHeight - 10;
          }
        }
      }

      // Меняем положение выделенной области

      // Выбираем, как конкретно изменять область выделения,
      // в зависимости от модификатора редактирования
      switch (this.editingStateModifier) {

        case 'rotate':
          if (this.activeSides != null) {
            delete this.activeSides;
          }

          var {
            initialPolarCoords
          } = this.editor.meta;

          var currentPolarCoords = this.decart2polarCoords({
            x: this.scaledCoords.x - this.editorScaledCenter.x,
            y: this.scaledCoords.y - this.editorScaledCenter.y
          });

          diff = currentPolarCoords.fi - initialPolarCoords.fi;

          var newRotate = this.editor.meta.rememberedRotate + diff;
          if (this.keyModifiers['shift']) {
            newRotate = Math.round(newRotate / 15) * 15;
          }

          this.editor.rotate = newRotate;
          break;

        case 'moving':

          if (this.keyModifiers['shift']) {
            if (Math.abs(diff.x) > Math.abs(diff.y)) {
              diff.y = 0;
            }
            if (Math.abs(diff.y) > Math.abs(diff.x)) {
              diff.x = 0;
            }
          }

          // Без этого дальше иногда почему-то возникает exception
          // Cannot read property 'rememberedCoords' of undefined
          if ((this.editor.meta == null)) {
            return;
          }

          this.virtualCoords = this.virtualCoords.map((c, i)=> {
            return{
              x: this.editor.meta.rememberedCoords[i].x + diff.x,
              y: this.editor.meta.rememberedCoords[i].y + diff.y
            };
          });

          this.activeSides = [0, 1, 2, 3];
          saveAspect = false;
          this.vdotsMask = [{
            x: true,
            y: true
          }
            , {
              x: true,
              y: true
            }
            , {
              x: true,
              y: true
            }
            , {
              x: true,
              y: true
            }
          ];
          break;

        case 'top':
          this.activeSides = [0];
          saveAspect = false;
          this.vdotsMask = [{
            x: true,
            y: true
          }
            , {
              x: true,
              y: true
            }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
          ];
          break;

        case 'right':
          this.activeSides = [1];
          saveAspect = false;
          this.vdotsMask = [{
            x: false,
            y: false
          }
            , {
              x: true,
              y: true
            }
            , {
              x: true,
              y: true
            }
            , {
              x: false,
              y: false
            }
          ];
          break;

        case 'bottom':
          this.activeSides = [2];
          saveAspect = false;
          this.vdotsMask = [{
            x: false,
            y: false
          }
            , {
              x: false,
              y: false
            }
            , {
              x: true,
              y: true
            }
            , {
              x: true,
              y: true
            }
          ];
          break;

        case 'left':
          this.activeSides = [3];
          saveAspect = false;
          this.vdotsMask = [{
            x: true,
            y: true
          }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
            , {
              x: true,
              y: true
            }
          ];
          break;

        case 'topright':
          this.activeSides = [0, 1];
          ({
            saveAspect
          } = this);
          this.vdotsMask = [{
            x: false,
            y: false
          }
            , {
              x: true,
              y: true
            }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
          ];
          break;

        case 'topleft':
          this.activeSides = [3, 0];
          ({
            saveAspect
          } = this);
          this.vdotsMask = [{
            x: true,
            y: true
          }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
          ];
          break;

        case 'bottomright':
          this.activeSides = [1, 2];
          ({
            saveAspect
          } = this);
          this.vdotsMask = [{
            x: false,
            y: false
          }
            , {
              x: false,
              y: false
            }
            , {
              x: true,
              y: true
            }
            , {
              x: false,
              y: false
            }
          ];
          break;

        case 'bottomleft':
          this.activeSides = [2, 3];
          ({
            saveAspect
          } = this);
          this.vdotsMask = [{
            x: false,
            y: false
          }
            , {
              x: false,
              y: false
            }
            , {
              x: false,
              y: false
            }
            , {
              x: true,
              y: true
            }
          ];
          break;
      }

      if ((this.activeSides != null) && (this.activeSides.length < 4)) {
        this.virtualCoords = this.resizeHandler(diff, this.activeSides, saveAspect);
      }

      // Показываем точки для присоединения кривой
      // (если есть)
      if (this.lineEditing && (this.editingStateModifier !== 'moving')) {
        this.showLinkDots();
      }

    },

    // метод обновляет размеры и положение объектов
    // в соответствии с координатами metaTool
    updateEditingObjects() {
      _.forEach(this.editingObjects, (obj) => {

        const newCoords = this.percToNativeCoords(
            obj.meta.percCoords,
            this.virtualCoords
        );

        obj.vueObj.virtualCoords = newCoords;

      });
    },

    endEditingProcess() {

      // Обнуляем привязки кривой в зависимости от типа перемещения
      if (this.lineEditing && !this.geometryShiftNullSize) {
        const curve = this.editingObjects[0];

        // Оба конца
        if (this.editingStateModifier === 'moving') {
          this.$store.state.presentation.slides[this.currentActiveSlide]
              .objects[curve.vueObj.index].linkedDots.start = [];
          this.$store.state.presentation.slides[this.currentActiveSlide]
              .objects[curve.vueObj.index].linkedDots.end = [];
        }

        // Начало
        if (this.editingStateModifier === 'topleft') {
          this.$store.state.presentation.slides[this.currentActiveSlide]
              .objects[curve.vueObj.index].linkedDots.start = [];
        }

        // Конец
        if (this.editingStateModifier === 'bottomright') {
          this.$store.state.presentation.slides[this.currentActiveSlide]
              .objects[curve.vueObj.index].linkedDots.end = [];
        }

        // Если есть объект,
        // с которым можно связаться
        if (this.canBeLinked) {

          const order = ['start', 'end'];

          // Пробуем привязать два конца кривой
          _.forEach(curve.vueObj.opts.coords, (dot, i)=> {

            // treshold - размер области "приземления" точки
            const treshold = 10;

            // comparator - функция, проверяющая возможность
            // "приземления" точки
            const comparator = this.canBeLinked.vueObj.dotNearLinkDot;

            const linkDot = comparator(dot, treshold);
            if (linkDot != null) {
              // TOREFACTOR?
              this.$store.state.presentation.slides[this.currentActiveSlide].
                  objects[curve.vueObj.index].linkedDots[order[i]] =
                  [this.canBeLinked.id, linkDot];
            }
          });
        }

        // Обязательно обнулить объект для связывания
        this.hideLinkDots();

        this.vdotsMask = [{
          x: false,
          y: false
        }
          , {
            x: false,
            y: false
          }
          , {
            x: false,
            y: false
          }
          , {
            x: false,
            y: false
          }
        ];
      }

      // Таблица обработается отдельно по завершении процесса
      // if @tableEditing && @tableStateModifier != 'moving'
      //   @updateEditingObjects()

      if (this.editingObjects.length === 1) {
        const o = this.editingObjects[0];
        const {
          text
        } = o.vueObj.$refs;
        if (text?.adjustToText) {
          text.emitOverflowEvent('metaTool');
        }
      }

      this.roundObjectsCoords(this.editingObjects);

      // Сбрасываем модификатор
      this.editingStateModifier = 'moving';
      this.editor.meta = undefined;
      this.activeSides = undefined;
      if (!this.geometryShiftNullSize) {
        this.$store.commit('saveHistory', 'endEditingProcess');
      }
      this.drawSelectedSpace();
    },

    endPanelProcess(){
      this.currentPage.logUserAction('metaTool', 'endPanelProcess');
      this.roundObjectsCoords(this.editingObjects);
      this.$store.commit('saveHistory', 'endPanelProcess');
      this.drawSelectedSpace();
    },

    // Перемещение границ таблицы
    startTableProcess(){
      this.currentPage.logUserAction('metaTool', 'startTableProcess');

      if (this.clickOutOfSelection()) {
        return;
      }

      const state = this.tableStateModifier;
      if (state) {
        this.editingStateModifier = state;

        if ((state === 'hor') || (state === 'ver')) {
          this.initialTableBorderAvatarCoord = this.tableBorderAvatarCoord;
        }

        this.startEditingProcess();

        this.shadowEditor = true;
      }

    },

    whileTableProcess(){
      const state = this.tableStateModifier;
      if (state) {
        this.whileEditing();
      }

    },

    endTableProcess(){
      this.currentPage.logUserAction('metaTool', 'endTableProcess');
      const state = this.tableStateModifier;

      if (state) {

        let param;
        if (state !== 'moving') {

          if ((state === 'hor') || (state === 'ver')) {
            const rawDelta = this.tableBorderAvatarCoord - this.initialTableBorderAvatarCoord;
            const scaledDelta = rawDelta / this.scale;
            this.initialTableBorderAvatarCoord = undefined;
            param = {
              type: 'inner',
              border: this.selectedTableBorder,
              borderType: state,
              delta: scaledDelta
            };

          } else {
            param = {
              type: 'outer',
              newCoords: _.cloneDeep(this.editorScaledCoords)
            };
          }
        }

        this.endEditingProcess();
        if (param != null) {
          this.editingObjects[0].vueObj.rebuildSelf(param);
        }

        this.editingObjects[0].vueObj.clearSelection();
      }

      this.editingStateModifier = 'moving';
      this.tableStateModifier = undefined;
      this.selectedTableBorder = undefined;
      this.shadowEditor = false;

    },

    roundObjectsCoords(objects){
      objects.forEach(function(o){
        o.coords = [{
          x: Math.round(o.coords[0].x),
          y: Math.round(o.coords[0].y)
        }
          , {
            x: Math.round(o.coords[1].x),
            y: Math.round(o.coords[1].y)
          }
        ];
        o.rotate = Math.round(o.rotate);
      });
    },

    clickOutOfSelection() {
      /*Проверяем, совпадают ли координаты клика
      с первым редактируемым объектом (для случая "выделил и потащил"),
      выделенными объектами
      или с самим редактором.
      Если нет - переключаемся на режим
      выделения (удаляем все выбранные объекты)*/

      let overlap;
      const elems = this.punctureDOM();

      const responded = this.someInPuncture(elems, this.$store.state.activeSlide.objects);

      if ((responded == null)) {
        if (this.isSelfInDomElemsArray(elems)) {
          overlap = true;
        }
      } else {
        if (this.editingObjects.includes(responded)) {
          overlap = true;
        } else {
          overlap = false;
        }
      }

      // Дополнительная проверка для таблицы
      // Иначе её границы при нахождении над другим объектом не срабатывают
      if (this.tableEditing && this.isSelfInDomElemsArray(elems)) {
        overlap = true;
      }

      // Не попали
      if (!overlap) {
        // Если не зажаты модификаторы, просто начинаем сначала
        if (!(this.keyModifiers['ctrl'] || this.keyModifiers['shift'])) {
          this.clearState();
          this.startProcess();
          // Если зажаты модификаторы, игнорируем результаты проверки
        } else {
          // Запускаем выделение только если не попали по объекту
          if (!responded) {
            this.startSelectingProcess();
          }
        }
      }
      return !overlap;
    },

    showLinkDots(){

      const that = this;

      let found = false;
      _.forEach(this.allObjects, function(obj){
        if (obj.vueObj.rotatable &&
            obj.vueObj.isDotInside(that.scaledCoords, 10)) {
          if (that.canBeLinked) {
            that.canBeLinked.showLinkDots = false;
          }
          that.canBeLinked = obj;
          that.canBeLinked.showLinkDots = true;
          found = true;
        }
      });

      if (!found && this.canBeLinked) {
        that.canBeLinked.showLinkDots = false;
        that.canBeLinked = false;
      }

    },

    hideLinkDots(){
      if (this.canBeLinked) {
        this.canBeLinked.showLinkDots = false;
        this.canBeLinked = false;
      }
    },

    groupObjects(){
      this.currentPage.logUserAction('metaTool', 'groupObjects');

      const that = this;

      const groupIndexes = [];
      const objectIndexes = [];

      this.editingObjects.map(function(object){
        const objectGroup = that.objectIsInGroup(object.id);
        if (objectGroup !== -1) {
          groupIndexes.push(objectGroup);
        } else {
          objectIndexes.push(that.objectIndexById(object.id));
        }
      });

      const req = {
        groupIndexes: _.sortBy(_.uniq(groupIndexes)),
        objectIndexes: _.sortBy(_.uniq(objectIndexes))
      };

      if ((req.groupIndexes.length > 1) || (req.objectIndexes.length > 0)) {

        this.$store.commit('group', req);
      }

      this.groupsChangeFlag = !this.groupsChangeFlag;

    },

    ungroupObjects(){
      this.currentPage.logUserAction('metaTool', 'ungroupObjects');
      const that = this;

      let groupIndexes = [];

      this.editingObjects.map(function(object){
        const objectGroup = that.objectIsInGroup(object.id);
        if (objectGroup !== -1) {
          groupIndexes.push(objectGroup);
        }
      });
      groupIndexes = _.uniq(groupIndexes);
      if (groupIndexes.length === 1) {
        this.$store.commit('ungroup', groupIndexes[0]);
      }

      this.groupsChangeFlag = !this.groupsChangeFlag;

    },

    //Служебные методы

    rememberEditorCoords(){
      // Запоминаем начальные координаты выбранных объектов
      if ((this.editor.meta == null)) {
        this.editor.meta = {};
      }

      this.editor.meta.rememberedCoords = [{
        x: this.virtualCoords[0].x,
        y: this.virtualCoords[0].y
      }
        , {
          x: this.virtualCoords[1].x,
          y: this.virtualCoords[1].y
        }
        , {
          x: this.virtualCoords[2].x,
          y: this.virtualCoords[2].y
        }
        , {
          x: this.virtualCoords[3].x,
          y: this.virtualCoords[3].y
        }
      ];
      this.editor.meta.rememberedRotate = this.editor.rotate;
      this.editor.meta.initialWidth = this.width;
      this.editor.meta.initialHeight = this.height;
      this.editor.meta.initialPolarCoords = this.decart2polarCoords({
        x: this.initialCoords.scaled.x - this.editorScaledCenter.x,
        y: this.initialCoords.scaled.y - this.editorScaledCenter.y
      });
    },

    //Метод проверяет попадание координат в границы области
    coordsInsideArea(coords, area){

      const borders = {
        top: Math.min(area[0].y, area[1].y),
        bottom: Math.max(area[0].y, area[1].y),
        left: Math.min(area[0].x, area[1].x),
        right: Math.max(area[0].x, area[1].x)
      };

      if ((coords.y >= borders.top) &&
          (coords.y <= borders.bottom) &&
          (coords.x >= borders.left) &&
          (coords.x <= borders.right)) {
        return true;
      } else {
        return false;
      }
    },

    //Метод вычисляет крайние координаты группы объектов
    extremeObjsCoords(objs){

      const that = this;

      const coords = [{},{}];

      // Если любой из объектов выступает за границы
      // - запоминаем новое значение
      _.forEach(objs, function(obj){
        const c = _.clone(obj.vueObj.virtualCoords);
        const startLinked = obj.vueObj.opts.linkedDots?.start.length;
        const endLinked = obj.vueObj.opts.linkedDots?.end.length;

        // # Попробовать отрисовывать метатул
        // # без учета привязанных точек
        // if objs.length > 1
        //   if startLinked || endLinked
        //     c[1].x = c[1].y = c[3].x = c[3].y = undefined
        //   if startLinked
        //     c[0].x = c[0].y = undefined
        //   if endLinked
        //     c[2].x = c[2].y = undefined

        const minY = _.min([c[0].y, c[1].y, c[2].y, c[3].y]);
        const minX = _.min([c[0].x, c[1].x, c[2].x, c[3].x]);
        const maxY = _.max([c[0].y, c[1].y, c[2].y, c[3].y]);
        const maxX = _.max([c[0].x, c[1].x, c[2].x, c[3].x]);

        if ((coords[0].y == null) || (minY < coords[0].y)) {
          coords[0].y = minY;
        }
        if ((coords[0].x == null) || (minX < coords[0].x)) {
          coords[0].x = minX;
        }
        if ((coords[1].y == null) || (maxY > coords[1].y)) {
          coords[1].y = maxY;
        }
        if ((coords[1].x == null) || (maxX > coords[1].x)) {
          coords[1].x = maxX;
        }

      });
      return coords;
    },

    // Метод рисует область выделения
    // first - флаг первого выделения
    // для нахождения группы
    drawSelectedSpace(noGroups){
      const that = this;

      if (this.editingObjectsCache.length === 0) {
        if (!noGroups) {
          // Находим сгруппированные объекты
          this.editingObjects.map(object=> {
            const groupIndex = this.objectIsInGroup(object.id);
            if(groupIndex !== -1) {
              const group = this.groupIds(groupIndex);
              this.addObjectsToEditor(group);
            }
          });
        }
      }

      const co = that.editor.coords;
      const ex = that.extremeObjsCoords(that.editingObjects);
      const sc = that.scale;
      const zpo = that.zeroPointOffset;
      co[0].y = (ex[0].y * sc) + zpo.y;
      co[0].x = (ex[0].x * sc) + zpo.x;
      co[1].y = (ex[1].y * sc) + zpo.y;
      co[1].x = (ex[1].x * sc) + zpo.x;

      // Поворачиваем область выделения
      // если в нее попал один объект
      // и он может быть повернут
      if ((this.editingObjects.length === 1) && this.editingObjects[0].vueObj.rotatable) {
        this.editor.rotate = this.editingObjects[0].rotate;
      } else {
        this.editor.rotate = 0;
      }

      // Запоминаем координаты объектов относительно области выделения
      _.forEach(this.editingObjects, function(obj, index){

        let i;
        if ((obj.meta == null)) {
          obj.meta = {};
        }

        // Если один объект - делаем его совпадающим с областью выделения
        // Если это условие убрать - объект поворачивается при выделении
        if (that.editingObjects.length === 1) {
          const newVirtualCoords = [];
          for (i = 0; i <= 3; i++) {
            newVirtualCoords[i] = that.pres2scaledCoords({
              x: obj.vueObj.virtualCoords[i].x,
              y: obj.vueObj.virtualCoords[i].y
            });
          }

          that.virtualCoords = newVirtualCoords;

          obj.meta.percCoords = [{
            x: 0,
            y: 0
          }
            , {
              x: 1,
              y: 0
            }
            , {
              x: 1,
              y: 1
            }
            , {
              x: 0,
              y: 1
            }
          ];

          // Если объектов несколько
        } else {
          obj.meta.percCoords = [];

          for (i = 0; i <= 3; i++) {
            obj.meta.percCoords[i] = {
              x: (obj.vueObj.virtualCoords[i].x - that.editorScaledCoords[0].x)/
                  (that.editorScaledCoords[1].x - that.editorScaledCoords[0].x),
              y: (obj.vueObj.virtualCoords[i].y - that.editorScaledCoords[0].y)/
                  (that.editorScaledCoords[1].y - that.editorScaledCoords[0].y)
            };
          }
        }

      });

      this.editor.active = true;
    },

    // Метод, переводящий процентные координаты объектов
    // в координаты презентации
    percToNativeCoords(percCoords, area){

      const that = this;

      const res = [];

      const areaVectors = {
        hor: {
          x: area[1].x - area[0].x,
          y: area[1].y - area[0].y
        },
        ver: {
          x: area[3].x - area[0].x,
          y: area[3].y - area[0].y
        }
      };

      _.forEach(percCoords, function(obj, index){

        const percVectors = {
          hor: {
            x: areaVectors.hor.x * percCoords[index].x,
            y: areaVectors.hor.y * percCoords[index].x
          },
          ver: {
            x: areaVectors.ver.x * percCoords[index].y,
            y: areaVectors.ver.y * percCoords[index].y
          }
        };

        const resVector = {
          x: percVectors.hor.x + percVectors.ver.x,
          y: percVectors.hor.y + percVectors.ver.y
        };

        res.push(that.scaled2presCoords({
          x: resVector.x + area[0].x,
          y: resVector.y + area[0].y
        }));

      });
      return res;
    },

    deleteObjects(ids){
      this.currentPage.logUserAction('metaTool', 'deleteObjects');
      const that = this;
      // Необходимо сначала сделать мап,
      // так как editingObjects меняется в процессе удаления
      if (ids == null) { ids = this.editingObjects.map(o => o.id); }
      ids.forEach(function(id){
        that.$store.commit('deleteObject', id);
      });
      this.editingObjects = [];
      this.editingObjectsCache = [];
      this.editor.active = false;
      this.$store.commit('cleanUnusedMedia');
      this.currentPage.actualizeMedia();
      this.$store.commit('saveHistory', 'deleteItems');
    },

    // Очистка всех состояний
    clearState() {
      this.editingObjects = [];
      // Нельзя здесь очищать кэш,
      // не восстанавливается выделение при ресайзе презы
      // @editingObjectsCache = []
      this.editor.active = false;
      this.$store.commit('setActiveTool', undefined);
    },

    // Методы для перевода координат из
    // координат презентации в координаты
    // отображения и обратно
    scaled2presCoords(scaled){
      return{
        x: (scaled.x - this.zeroPointOffset.x) / this.scale,
        y: (scaled.y - this.zeroPointOffset.y) / this.scale
      };
    },
    pres2scaledCoords(pres){
      return{
        x: (pres.x * this.scale) + this.zeroPointOffset.x,
        y: (pres.y * this.scale) + this.zeroPointOffset.y
      };
    },

    // Метод управляет превращением
    // вектора движения мыши
    // в изменение размера объектов
    // diff - вектор изменения координаты мыши
    // sides - редактируемые стороны(0-3)
    // bool saveAspect - сохранять соотношение сторон
    // bool doNotSplit - вектор diff уже разбит
    resizeHandler(diff, sides, saveAspect, doNotSplit){

      let splittedDiff;
      const that = this;

      const newVirtualCoords = [];

      if (doNotSplit != null) {
        splittedDiff = diff;
      } else {
        // Разбиваем вектор мыши
        // на 2 вектора, параллельные
        // осям области выделения
        // с учетом её поворота
        splittedDiff = this.splitVectorTo2AxisProections(
            [diff],
            (0 + that.editor.rotate)
        );
      }

      // Вектор а || оси x
      // Вектор b || оси y
      let {
        a
      } = splittedDiff;
      let {
        b
      } = splittedDiff;

      // Маска инвертирования  векторов
      // для вычислений при сохранении
      // соотношения сторон
      // [0] для горизонтального
      // [1] для вертикального
      const invMask = [1,1];

      // Инвертируем горизонтальный и вертикальный вектора
      // при наличии в модификаторе редактирования
      // верха(0) и лева(3) соответственно
      if (sides.includes(0)) {
        invMask[1] = -1;
      }
      if (sides.includes(3)) {
        invMask[0] = -1;
      }

      // Дополнительно инвертируем,
      // если инвертирована область редактирования
      if (this.invertedAxis.x) {
        invMask[0] = - invMask[0];
      }
      if (this.invertedAxis.y) {
        invMask[1] = - invMask[1];
      }

      // Если необходимо сохранить
      // соотношение сторон
      if (saveAspect) {

        const editorWidth = this.vectorLength([
          this.editor.meta.rememberedCoords[0],
          this.editor.meta.rememberedCoords[1]
        ]);
        const editorHeight = this.vectorLength([
          this.editor.meta.rememberedCoords[0],
          this.editor.meta.rememberedCoords[3]
        ]);

        const aspect = editorWidth / editorHeight;

        // Выбираем, какой из векторов вносит больший вклад
        // в изменение соотвествующей стороны (в %)
        // (с учетом маски инвертирования)

        // Вектор с меньшим вкладом высчитывается
        // по большему с учетом аспекта
        if (((invMask[0] * a) / editorWidth) > ((invMask[1] * b) / editorHeight)) {
          b = (invMask[0] * invMask[1] * a) / aspect;
        } else {
          a = invMask[0] * invMask[1] * b * aspect;
        }
      }

      // Заготовка для модификаторов 4 виртуальных координат
      // эти модификаторы будут применены к виртуальным
      // координатам области выделения после всех вычислений
      const modificators = [{x:0,y:0},{x:0,y:0},{x:0,y:0},{x:0,y:0}];

      // Обрабатываем каждую сторону
      // пришедшую в запросе на ресайз
      _.forEach(sides, function(side){

        // Номера виртуальных точек для изменения
        // Первые две точки используются в обычном режиме
        // Последние две(опционально) - при модификации относительно центра
        let oppositeVector, vector;
        const dots = [];
        dots[0] = side;
        dots[1] = that.quadNext(dots[0]);
        if (that.keyModifiers['ctrl']) {
          dots[3] = that.quadNext(dots[1]);
          dots[2] = that.quadNext(dots[3]);
        }

        // Выбираем какой вектор использовать
        // в зависимости от того,
        // вертикальная или горизонтальная сторона
        //
        // oppositeVector - вектор для изменения
        // противоположных точек
        // при модификации относительно центра
        if (that.isEven(side)) {
          vector = {x:0, y:b};
          oppositeVector = {x:0, y:-b};
        } else {
          vector = {x:a, y:0};
          oppositeVector = {x:-a, y:0};
        }

        // Рассчитываем модификаторы для точек
        _.forEach(dots, function(dot, i){

          let v;
          if (i < 2) {
            v = vector;
          } else {
            v = oppositeVector;
          }

          const x = that.vectorAxisProection([v], (- that.editor.rotate));
          const y = that.vectorAxisProection([v], (- that.editor.rotate + 90));

          modificators[dot].x += x;
          modificators[dot].y += y;
        });


      });

      // Применяем модификаторы
      for (let i = 0; i <= 3; i++) {

        const dx = modificators[i].x;
        const dy = modificators[i].y;

        newVirtualCoords[i] = {
          x: this.editor.meta.rememberedCoords[i].x + dx,
          y: this.editor.meta.rememberedCoords[i].y + dy
        };
      }

      return newVirtualCoords;
    },

    // Метод вычисляет длину вектора
    // по одной (от начала координат) или двум точкам
    vectorLength(dots){
      if ((dots[1] == null)) {
        dots[1] = {
          x: 0,
          y: 0
        };
      }
      const diff = {
        x: Math.abs(dots[0].x - dots[1].x),
        y: Math.abs(dots[0].y - dots[1].y)
      };
      const length = Math.sqrt((diff.y*diff.y) + (diff.x*diff.x));
      return length;
    },

    // Проверка числа на четность
    isEven(someNumber){
      let left;
      return ((left = (someNumber % 2) === 0)) != null ? left : {true : false};
    },

    // Проекция вектора на ось,
    // повернутую на угол
    // относительно оси X координат вектора
    vectorAxisProection(vector, targetAxisAngle){
      const vectorAngle = this.rad2deg(Math.atan2(vector[0].y, vector[0].x));
      const angle = targetAxisAngle - vectorAngle;
      return this.vectorLength(vector) * Math.cos(this.deg2rad(angle));
    },

    // Разбивает вектор на 2 проекции
    // на оси плоскости,
    // повернутой на угол относительно
    // оси Х презентации
    splitVectorTo2AxisProections(vector, xAxisAngle){

      const yAxisAngle = xAxisAngle + 90;

      return{
        a: this.vectorAxisProection(vector, xAxisAngle),
        b: this.vectorAxisProection(vector, yAxisAngle)
      };
    },

    // Маленький хелпер, возвращающий по кругу 0-3
    // следующее число
    quadNext(val){
      if (val === 3) {
        val = 0;
      } else {
        val++;
      }
      return val;
    },

    // "Прокол" DOM'а в точке
    punctureDOM(){
      // Если в аргументах есть координаты
      let x, y;
      if (arguments[0] != null) {
        x = arguments[0];
        y = arguments[1];

        // Иначе берем текущие координаты мыши
      } else {
        ({
          x
        } = this.currentPage.rawCoords);
        ({
          y
        } = this.currentPage.rawCoords);
      }

      let puncture = document.elementsFromPoint(x, y);
      // костыль для edge
      if (puncture instanceof NodeList) {
        const edgePuncture = [];
        puncture.forEach(p => edgePuncture.push(p));
        puncture = edgePuncture;
      }

      // Находим в массиве место первого слайда
      const slidePlace = _.findIndex(puncture, domEl => domEl.classList.contains('slide'));
      // и обрезаем всё после него
      puncture.splice(slidePlace);

      return puncture;
    },

    // Проверка наличия элеметов массива
    // в "проколе" DOM'а
    someInPuncture(puncture, objs){
      let res = undefined;
      _.each(objs, function(object, index){
        if (object.vueObj.isSelfInDomElemsArray(puncture)) {
          res = object;
        }
      });
      return res;
    },

    // Метод возвращает true если в массиве
    // есть .editor-tool или .editor-tool__item
    isSelfInDomElemsArray(array){
      return this.isClassInDomElemsArray(array, 'editor-tool') ||
          this.isClassInDomElemsArray(array, 'editor-tool__item') ||
          this.isClassInDomElemsArray(array, 'editor-tool__table_item');
    },

    // Метод возвращает vue объект если в массиве
    // есть className
    isClassInDomElemsArray(array, className){
      let found = false;
      _.each(array, function(elem, index){
        if ($(elem).hasClass(className)) {
          found = true;
        }
      });
      return found;
    },

    // Метод возвращает vue объект если в массиве
    // есть объект или его родитель с className
    isClassInDomElemsArrayInclParents(array, className){
      let found = false;
      _.each(array, function(elem, index){
        if ($(elem).hasClass(className)) {
          if (!found) {
            found = elem.__vue__;
          }
        } else {
          const parentsWithTargetClassName = $(elem).parents('.' + className);
          if (parentsWithTargetClassName.length > 0) {
            if (!found) {
              found = parentsWithTargetClassName[0].__vue__;
            }
          }
        }
      });
      return found;
    },

    // Метод возвращает true если на самом
    // верху (не считая editor tool)
    // находится текст
    isOverText(array){
      let dropCount = 0;
      for (let i = 0; i <= 2; i++) {
        if ($(array[i]).hasClass('editor-tool__background') ||
            $(array[i]).hasClass('editor-tool')) {
          dropCount++;
        }
      }
      if ($(array[0 + dropCount]).is('span, .text')) { return true; }
      return false;
    },

    // Работа с группами

    // Метод находит индекс объекта в массиве по его ID
    objectIndexById(id){
      const that = this;
      let res = -1;
      this.allObjects.map(function(object){
        if ((id == null)) {
          throw new Error('Undefined object index');
        }
        if (id === object.id) {
          res = that.allObjects.indexOf(object);
        }
      });
      return res;
    },

    // Метод находит индекс объекта в массиве по его ID
    objectById(id){
      const that = this;
      let res = undefined;
      this.allObjects.map(function(object){
        if ((id == null)) {
          throw new Error('Undefined object index');
        }
        if (id === object.id) {
          res = object;
        }
      });
      return res;
    },

    // Метод возвращает позицию группы в массиве, если объект
    // находится в какой-то группе
    objectIsInGroup(id){

      let res = undefined;

      this.groups.map(group=> {
        let needle;
        if ((needle = id, Array.from(_.flattenDeep(group)).includes(needle))) {
          return res = this.groups.indexOf(group);
        }
      });

      if (res != null) { return res; }
      return -1;
    },

    // Метод возвращает все ID элементов группы объекта
    // (в том числе и родительской)
    groupIds(index){

      return this.compressArray(this.groups[index]);
    },

    // Метод сжимает массив до уникальных значений
    compressArray(arr){
      return _.uniq(_.flattenDeep(arr));
    },

    // Метод пытается добавить объект к @editingObjects
    addObjectToEditor(id){
      const objectPosition = this.objectIndexById(id);
      const target = this.allObjects[objectPosition];
      if (target != null) {
        if (!Array.from(this.editingObjects).includes(target)) {
          this.editingObjects.push(target);
        }
      }
    },

    // Метод пытается добавить массив объектов к @editingObjects
    addObjectsToEditor(arr){
      arr.forEach(elem=> {
        this.addObjectToEditor(elem);
      });
    },

    // Метод пытается удалить объект из @editingObjects
    removeObjectFromEditor(obj){
      const index = this.editingObjects.indexOf(obj);
      if (index !== -1) {
        this.editingObjects.splice(index, 1);
        obj.vueObj.isSelected = false;
        this.$nextTick(function(){
          return this.drawSelectedSpace();
        });
      }
    },

    cacheEditingObjects(objectsOnly){
      this.editingObjectsCache = _.clone(this.editingObjects);

      if (!objectsOnly) {
        this.editorCachedCoords = _.clone(this.editorScaledCoords);
      }
    },

    // Коллбэк нужен из-за асинхронности метода
    restoreCachedObjects(cb){
      if (this.editingObjectsCache) {
        const that = this;
        this.editingObjects = _.clone(this.editingObjectsCache);
        this.$nextTick(function(){
          if (!that.currentPage.scaling) {
            that.editingObjectsCache = [];
            that.editorCachedCoords = [];
          }
          if (cb instanceof Function) {
            cb();
          }
          // Без этого таймаута прыгают значения размеров
          // в боковой панели при ресайзе
        });
      }
    },

    // Обработка двойного клика
    dblclick(e){
      this.currentPage.logUserAction('metaTool', 'dblclick');
      if (this.editingObjects.length === 1) {
        this.selectedObject = this.editingObjects[0].vueObj;
        this.selectedObject.dblClick();
      } else {
        const vueObj = this.isClassInDomElemsArrayInclParents(this.punctureDOM(), 'object');
        if (vueObj) {
          if (Array.from(this.editingObjects).includes(vueObj.myStoreObject)) {
            //@editingObjectsCache = _.clone(@editingObjects)
            this.cacheEditingObjects(true);
          }
          this.selectedObject = vueObj;
          this.editingObjects = [this.selectedObject.myStoreObject];
          this.drawSelectedSpace(true);
        }
      }
      // @selectedObject.isTextSelected = true
    },

    moveSelected(diffX, diffY){

      if (this.editingObjects.length === 0) {
        return;
      }

      const oldCoords = this.editorScaledCoords;

      const width = oldCoords[1].x - oldCoords[0].x;
      const height = oldCoords[1].y - oldCoords[0].y;

      let {
        rotate
      } = oldCoords;
      if (rotate == null) { rotate = 0; }

      this.editorScaledCoords = {
        key: 'move',
        height: Math.round(height),
        width: Math.round(width),
        x: Math.round(oldCoords[0].x + diffX),
        y: Math.round(oldCoords[0].y + diffY),
        rotate
      };

      if (this.saveTimeout) {
        clearTimeout(this.saveTimeout);
      }

      const that = this;

      this.saveTimeout = setTimeout(
          function(){
            that.$store.commit('saveHistory', 'moveSelected');
          },
          500
      );
    },

    // Метод для изменения высоты редактора
    // по нижней границе
    moveBottom(diff){
      this.rememberEditorCoords();
      // Делим разницу пополам,
      // так как её при зажатом ctrl
      // в два раза увеличит resizeHandler
      // (потянув за противоположную границу)
      if (this.keyModifiers.ctrl) {
        diff = diff / 2;
      }
      this.virtualCoords = this.resizeHandler({a: 0, b: diff}, [2], false, true);
      this.updateEditingObjects();
    },

    copySlide(sIndex){
      this.currentPage.logUserAction('metaTool', 'copySlide');

      const that = this;

      const {
        media
      } = this.$store.state.presentation;
      let mediaToCopy = [];
      const groupNumbers = [];

      const slide = this.$store.state.presentation.slides[sIndex];

      const copy = {
        slide: _.cloneDeepWith(slide, function(val, key){
          if (key === 'background-image') {
            mediaToCopy.push(val);
          }
          if ((key==='vueObj') || (key==='meta')) {
            return {};
          }
        }),
        media: {},
        schemaVersion: this.$store.state.presentation.schemaVersion,
        presentationId: this.$store.state.presentationId
      };

      mediaToCopy = _.uniq(mediaToCopy);
      mediaToCopy = _.compact(mediaToCopy);

      mediaToCopy.forEach(m=> {
        const mCopy = _.cloneDeep(media[m]);
        if (!mCopy.temp) {
          mCopy.url = this.currentPage.storageUrl + mCopy.url;
        }
        mCopy.temp = true;
        delete mCopy.id;
        copy.media[m] = mCopy;
      });

      copy.isPresentation = true;
      localStorage.setItem('slideClipboard', JSON.stringify(copy));
    },

    // Copy/Paste
    copyObjects(){
      this.currentPage.logUserAction('metaTool', 'copyObjects');

      if (this.editingObjects.length === 0) {
        return;
      }

      const that = this;

      const {
        media
      } = this.$store.state.presentation;

      let mediaToCopy = [];

      const groupNumbers = [];
      const copy = {
        objects: [],
        groups: [],
        media: {},
        schemaVersion: this.$store.state.presentation.schemaVersion,
        presentationId: this.$store.state.presentationId
      };

      this.allObjects.forEach(object=> {
        if (Array.from(this.editingObjects).includes(object)) {
          const cloned = _.cloneDeepWith(object, function(val, key){
            if (key === 'background-image') {
              mediaToCopy.push(val);
            }
            if ((key==='vueObj') || (key==='meta')) {
              return {};
            }
          });
          copy.objects.push(cloned);

          const group = this.objectIsInGroup(object.id);

          const inGroup = group !== -1;
          const oneObject = this.editingObjects.length === 1;
          const saved = groupNumbers.includes(group);

          if (inGroup && !oneObject && !saved) {
            groupNumbers.push(group);
          }
        }

      });

      groupNumbers.forEach(groupNumber=> {
        const storeGroups = this.$store.state.activeSlide.groups;
        copy.groups.push(
            _.cloneDeep(
                storeGroups[groupNumber]
            )
        );
      });

      mediaToCopy = _.uniq(mediaToCopy);
      mediaToCopy = _.compact(mediaToCopy);

      mediaToCopy.forEach(m=> {
        const mCopy = _.cloneDeep(media[m]);
        if (!mCopy.temp) {
          mCopy.url = this.currentPage.storageUrl + mCopy.url;
        }
        mCopy.temp = true;
        delete mCopy.id;
        copy.media[m] = mCopy;
      });

      copy.isPresentation = true;
      localStorage.setItem('objectsClipboard', JSON.stringify(copy));

    },

    cutObjects(){
      this.currentPage.logUserAction('metaTool', 'cutObjects');
      this.copyObjects();
      this.deleteObjects();

    },

    pasteObjects(){
      let copyJson;
      this.currentPage.logUserAction('metaTool', 'pasteObjects');
      const copy = localStorage.getItem('objectsClipboard');

      try {
        copyJson = JSON.parse(copy);
      } catch (e) {
        return false;
      }

      if (copyJson && copyJson.isPresentation) {
        const startId = this.$store.state.presentation.lastId;
        this.$store.commit('pasteObjects', copyJson);
        const endId = this.$store.state.presentation.lastId;

        const newObjectsIds = [];
        for (let i = startId, end = endId; i < end; i++) {
          newObjectsIds.push(i);
        }

        Vue.nextTick(
            function(){
              this.clearState();
              this.addObjectsToEditor(newObjectsIds);
              this.drawSelectedSpace();
              if (copy.presentationId === this.$store.state.presentationId) {
                this.copyObjects();
              }
              this.currentPage.resolveAllTempMedia();
            },
            this
        );
      }
    },

    pickColor() {
      this.currentPage.logUserAction('metaTool', 'pickColor');
      this.currentPage.getPixelColor().then(
          result=> {
            // console.log result
            this.currentPage.clearSlideshot();
          },
          error=> {
            // console.log error
            this.currentPage.clearSlideshot();
          });
    },

    undo(noOverride){
      if (this.currentPage.historyHandlerOverride && !noOverride) {
        this.currentPage.historyHandlerOverride.historyUndo();
        return;
      }
      this.currentPage.logUserAction('metaTool', "undo");
      this.clearState();
      this.$store.commit('historyUndo');
      const eoids = this.$store.state.presentation.editingObjectsIds;
      if (eoids != null) {
        this.restoreSelection(eoids);
      }
    },

    redo(noOverride){
      if (this.currentPage.historyHandlerOverride && !noOverride) {
        this.currentPage.historyHandlerOverride.historyRedo();
        return;
      }
      this.currentPage.logUserAction('metaTool', "redo");
      this.clearState();
      this.$store.commit('historyRedo');
      const eoids = this.$store.state.presentation.editingObjectsIds;
      if (eoids != null) {
        this.restoreSelection(eoids);
      }
    },

    restoreSelection(ids){

      if (this.restoreSelectionTimeout) {
        clearTimeout(this.restoreSelectionTimeout);
      }

      this.restoreSelectionTimeout = setTimeout(()=> {
            this.addObjectsToEditor(ids);
            this.drawSelectedSpace();
          },
          50
      );
    },

    selectLastObject() {
      this.editingObjects = [this.allObjects.last()];
      return this.drawSelectedSpace();
    },

    selectAll() {
      this.currentPage.logUserAction('metaTool', 'selectAll');
      this.editingObjects = this.allObjects;
      this.drawSelectedSpace();
    },

    setOverlap(data){
      this.stickyCoords[data.axis] = data.value;
      if (this.state === 'creating') {
        this.whileCreating();
      } else {
        this.whileEditing();
      }
    },

    clearOverlap(axis){
      this.stickyCoords[axis] = 0;
      if (this.state === 'creating') {
        this.whileCreating();
      } else {
        this.whileEditing();
      }
    },

    clearOverlaps() {
      this.clearOverlap('x');
      this.clearOverlap('y');
    },

    bgMousewheel(e){
      if (!e.ctrlKey && !e.metaKey) {
        e.stopPropagation();
        const target = this.currentPage.$refs.propPanel;

        const propName = 'image-custom-scale';

        let scale = target.styleProps[propName];
        scale -= e.deltaY / 10 / 100;
        if (scale < 1) {
          if (target.styleProps[propName] === 1) {
            return;
          } else {
            scale = 1;
          }
        }

        target.updateProp(propName, scale);
        this.debouncedSaveHistory(propName);
      } else {
        e.preventDefault();
      }
    },

    saveHistory(prop){
      this.$store.commit('saveHistory', prop);
    }
  }
};
</script>

<style lang="scss">
.selector-tool{
  position: absolute;
  right: 0;
  top: 0;
  width: 0px;
  height: 0px;
  background-color: #eee;
  outline: 1px solid #0002;
  z-index: 1;
  opacity: .5;
}

.link-dots{
  .link-dot{
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #f0f;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 50%;
  }
}

.animation-orders{
  .animation-order{
    position: absolute;
    width: auto;
    height: 16px;
    background-color: #10afff88;
    color: #fff;
    opacity: 1;
    font-size: 12px;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.editor-wrapper_blocked{
  pointer-events: none;
  // display: none;
  z-index: -1;
  .editor-tool{
    pointer-events: none;
  }
  .editor-tool__node{
    pointer-events: auto;
  }
}

.editor-wrapper_blocked-nodes{
  .editor-tool__node{
    pointer-events: none;
  }
}

.background-tool{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: move;
}

.editor-tool{
  position: absolute;
  right: 0;
  top: 0;
  width: 0px;
  height: 0px;
  z-index: 1;
  cursor: move;
  // mix-blend-mode: difference;
  &.editor-tool__line{
    cursor: default;
  }
  &.editor-tool__text{
    // cursor: text;
    pointer-events: none;
  }

  &__background{
    display: none;
  }

  &__avatar{
    border: 1px solid #99f;
    box-sizing: border-box;
  }

  &__item{
    position: absolute;
    box-sizing: border-box;
    &:before{
      content: '';
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: -7px;
      left: -7px;
      bottom: -7px;
      right: -7px;
      background-color: transparent;
    }
    &_disabled{
      opacity: .5;
      pointer-events: none;
      .editor-tool__line &{
        display: none;
      }
    }
  }

  &__noevents{
    pointer-events: none;
    background-color: transparent;
    border: 10px solid #fff;
    opacity: .3;
    mix-blend-mode: difference;
    box-sizing: unset;
    // border-radius: 10px;
  }

  &__table_item{
    position: absolute;
    opacity: 1;
    box-sizing: border-box;

    &__move{
      background: transparent;
    }
    &__node{
      background-color: transparent;
      z-index: 1;
      mix-blend-mode: difference;
      opacity: .5;
      &:hover{
        opacity: 1;
      }
    }
    &__side{
      background-color: transparent;
    }
    &__border{
      cursor: move;
    }
    &__right{
      border-right: 2px solid #fff;
    }
    &__left{
      border-left: 2px solid #fff;
    }
    &__top{
      border-top: 2px solid #fff;
    }
    &__bottom{
      border-bottom: 2px solid #fff;
    }
    &__topright{
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
    }
    &__bottomleft{
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
    }
    &__topleft{
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
    }
    &__bottomright{
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
    }
    &__avatar{
      background-color: #fff;
      opacity: .5;
      mix-blend-mode: difference;
    }
  }

  $node-border: 1px solid #0007;
  &__node{
    width: 5px;
    height: 5px;
    background-color: #fff;
    // outline: $node-border;

    &:after{
      content: '';
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: $node-border;
    }

    &__top{
      left: 50%;
      top: 0px;
      transform: translate(-3px, -3px);
      cursor: n-resize;
      .editor-tool__saveaspect &{
        display: none;
      }
      &.editor-tool__node__rotate{
        left: 50%;
        top: -30px;
        transform: translate(-50%, -3px);
      }
    }

    &__bottom{
      left: 50%;
      bottom: 0px;
      transform: translate(-3px, 3px);
      cursor: s-resize;
      .editor-tool__saveaspect &{
        display: none;
      }
      &.editor-tool__node__rotate{
        left: 50%;
        bottom: -30px;
        transform: translate(-50%, 3px);
      }
    }

    &__left{
      top: 50%;
      left: 0px;
      transform: translate(-3px, -3px);
      cursor: w-resize;
      .editor-tool__saveaspect &{
        display: none;
      }
      &.editor-tool__node__rotate{
        top: 50%;
        left: -30px;
        transform: translate(-3px, -50%);
      }
    }

    &__right{
      top: 50%;
      right: 0px;
      transform: translate(2px, -3px);
      cursor: w-resize;
      .editor-tool__saveaspect &{
        display: none;
      }
      &.editor-tool__node__rotate{
        top: 50%;
        right: -30px;
        transform: translate(3px, -50%);
      }
    }

    &__topleft{
      left: 0px;
      top: 0px;
      transform: translate(-3px, -3px);
      cursor: nw-resize;
      &.editor-tool__node__rotate{
        left: -30px;
        top: -30px;
      }
    }

    &__topright{
      right: 0px;
      top: 0px;
      transform: translate(2px, -3px);
      cursor: ne-resize;
      &.editor-tool__node__rotate{
        right: -30px;
        top: -30px;
      }
    }

    &__bottomleft{
      left: 0px;
      bottom: 0px;
      transform: translate(-3px, 3px);
      cursor: sw-resize;
      &.editor-tool__node__rotate{
        bottom: -30px;
        left: -30px;
      }
    }

    &__bottomright{
      right: 0px;
      bottom: 0px;
      transform: translate(2px, 3px);
      cursor: se-resize;
      &.editor-tool__node__rotate{
        bottom: -30px;
        right: -30px;
      }
    }

    &__rotate{
      background-color: transparent;
      width: 35px;
      height: 35px;
      cursor: wait;
      z-index: -1;
      outline: none;
      cursor: url("data:image/svg+xml,%3Csvg width='36' height='44' style='transform: scale(0.5)' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M18 12c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14' id='a'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke-opacity='.7' stroke='%23000' stroke-width='2' fill='%23FFF' d='M29.04 12.094L17 21.5v-19z'/%3E%3Cuse stroke-opacity='.7' stroke='%23000' stroke-width='7' xlink:href='%23a'/%3E%3Cuse stroke='%23FFF' stroke-width='3' xlink:href='%23a'/%3E%3C/g%3E%3C/svg%3E") 20 20, wait;
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
    }

  }


  &__border{
    background-color: #aaa4;
    &:after{
      content: '';
      position: absolute;
    }
    .editor-tool__line &{
      display: none;
    }
    .editor-tool__saveaspect &{
      pointer-events: none;
      // opacity: .5;
    }
    &__left{
      left: -1px;
      top: -1px;
    }
    &__right{
      right: 0px;
      top: -1px;
    }
    &__top{
      top: -1px;
      left: -1px;
    }
    &__bottom{
      left: -1px;
      bottom: -1px;
    }
  }


}

.border{
  &__vertical{
    height: calc(100% + 2px);
    width: 1px;
  }
  &__horizontal{
    width: calc(100% + 2px);
    height: 1px;
  }
}
</style>
