<template>
  <div class="property">
    <div class="property__name">{{ property.title }}</div>
    <input class="property__input" type="text" v-bind:name="property.title" v-bind:proppanel="proppanel" v-model="propValue"/>
  </div>
</template>

<script>
/* eslint-disable */
import UiColors from "@/mixins/editor/UiColors";
import Color from "@/mixins/editor/Color";

export default {
  name: "PropBase",
  props: ['property', 'propset'],
  mixins: [UiColors, Color],
  data() {
    return {
      // режим ввода значения propValue
      propMode: '' // insertLink
    };
  },
  computed: {
    propValue: {
      get() {
        if (this.propset != null) {
          return this.propset[this.property.name];
        }

        return undefined;
      },
      set(value) {
        this.$parent.updateProp(this.property.name, value);
      }
    }
  },
  methods: {
    updateProp(k, v) {
      this.$parent.updateProp(k, v);
    },
    inputBlur() {
      this.propValue = this.propValue; // это не бред, так надо
      this.propMode = '';
    },
    // Не удалять!
    submit() {
      this.inputBlur();
    }
  }
};
</script>

<style lang="scss">
.property{
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;

  &__flex-row{
    position:relative;
    display:flex;
    width: 100%;
    align-items:center;

    &_jcsb{
      justify-content: space-between;
    }

    .property{
      position:relative;
      display:block;
      width: 100%;
      margin-right: 20px;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__add, &__clear{
    position:absolute;
    display:block;
    top: 8px;
    right: 0px;
    height: 2px;
    width: 10px;
    background:#fff;
    cursor: pointer;
    opacity: 0.5;

    &:hover{
      opacity: 1;
    }

    &:before{
      content: '';
      height: 18px;
      width: 18px;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      position:absolute;
      display:block;
      // background:rgba(255,255,255, .1);
      border-radius: 50%;
    }
    &:after{
      content: '';
      height: 100%;
      width: 100%;
      position:absolute;
      display:block;
      transform: rotate(90deg);
      background:#fff;
    }
  }

  &__clear{
    transform: rotate(45deg);
  }

  &__add{
    top: 12px;
    right: 10px;
  }


  .col-25{
    position:relative;
    display:inline-block;
    width: 25%;
    //margin-right: -4px;
  }

  .col-50{
    position:relative;
    display:inline-block;
    width: 50%;
    //margin-right: -4px;
  }

  &.prop-name_x, &.prop-name_x1, &.prop-name_x2, &.prop-name_y, &.prop-name_y1, &.prop-name_y2, &.prop-name_width,
  &.prop-name_height, &.prop-name_stroke-color,
  &.prop-name_stroke-width, &.prop-name_stroke-dash, &.prop-name_table-stroke-dash,
  &.prop-name_stroke-cap, &.prop-name_table-stroke-color, &.prop-name_table-stroke-width{
    width: 50%;
    display:inline-block;
    padding-right: 0;
  }

  &.prop-name_table-bg1-color, &.prop-name_table-bg2-color, &.prop-name_table-acc-color, {
    width: 33%;
    display:inline-block;
    padding-right: 0;
  }

  &.prop-name_stroke-width, &.prop-name_table-stroke-width{
    padding-top: 0;
  }

  &.prop-name_stroke-color, &.prop-name_table-stroke-color{
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 10px;
    vertical-align: bottom;

    .property__preview{
      margin: 0;
      padding: 0;
    }
  }

  &.prop-name_animation-order{
    margin-bottom: 10px;
  }

  &__name{
    position: relative;
    font-size: 11px;
    margin-bottom: 5px;
  }

  &__btn{
    position:relative;
    display:inline-block;
    cursor: pointer;
    box-sizing: border-box;

    &__group{
      padding:0 10px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      line-height:20px;
    }

    &__action_add{
      position: absolute;
      display:none;
      right: 0;
      top:0;
      padding: 2px 5px;
      background: #ddd;
      border-radius: 2px;
    }

    svg{
      position:relative;
      display:block;
      width: 100%;
      opacity: .5;
    }

    &:hover{
      svg{
        opacity:1;
      }
    }
  }

  &__btn_confirm{
    height: 24px;
    border-radius: 2px;

    &:hover{
      background: rgba(255,255,255, .1);
      &:after{
        border-color:#B4C17B;
      }
    }

    &:after{
      content: '';
      position:absolute;
      display:block;
      height: 5px;
      width: 10px;
      top: 50%;
      left: 50%;
      transform:translate(-5px, -5px) rotate(-45deg);
      border-left: 2px solid rgba(255,255,255, .2);
      border-bottom: 2px solid rgba(255,255,255, .2);
    }
  }

  &__menu.context-menu{
    position:absolute;
    display:block;
    top:0;
    right: 0;
    z-index: 1;
    pointer-events:auto;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__input{
    position:relative;
    display:inline-block;
    box-sizing: border-box;
    height: 18px;
    width: auto;
    min-width: 0;
    margin: 0;
    padding: 5px;
    margin-left: -5px;
    border-radius: 3px;
    border: 0;
    background: transparent;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    outline:none;
    // font-family: 'Roboto Mono', monospace;
    font-family: 'Helvetica', sans-serif;
    letter-spacing: .05em;


    &:before{
      content: inherit;
      position:absolute;
      display:block;
      background: rgba(0,0,0, .2);
    }
  }

  .sp-container{
    position: relative;
    border:0;
    background: transparent;
    padding:0;
    width: 100%;
    z-index: 0;
  }

  .sp-picker-container{
    width: 100%;
    padding-left: 0;
    border:0;
  }

  .sp-replacer, .sp-dark.sp-replacer{
    position:relative;
    display:block;
    height: 20px;
    width: 40px;
    margin-bottom: 15px;
  }

  .property__tabs{
    position:absolute;
    display:block;
    top:0;
    left:10px;
    right: 10px;
    padding: 10px 10px 60px;
    box-sizing: border-box;
    z-index: 1;

    &:before{
      content: '';
      position:absolute;
      display:block;
      width: 100%;
      top:0;
      right:0;
      bottom:40px;
      left:0;
      border-radius: 3px;
      background:#333;
    }
  }

  &__nested{
    .property__tabs{
      position:relative;
      top:auto;
      left:auto;
      right: auto;
      padding: 0;
    }
  }

  .property__tab{
    position:relative;
    display:inline-block;
    border:2px solid transparent;
    margin-left: -2px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;

    &:hover{
      border-color: rgba(170,170,170, .3);
    }

    &__active, &__active:hover{
      border-color: #10AFFF;
    }
    &__active{
      margin-left: 0;
    }
  }

  .property__tabs-body{
    position:relative;
    display:block;

    .sp-dark.sp-container{
      position: relative;
      display: block;
      top:0;
      left:0;
    }
  }

  &__tip{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    svg{
      opacity: .5;
      transform-origin: center;
      transform: scale(1.1);
    }
    &-text{
      font-size: 10px;
      color: #c2bbc4;
      text-align: center;
      margin-top: 16px;
    }
  }
}

.property__lock-aspect{
  position:absolute;
  display:block;
  right:123px;
  top:71px;
  cursor: pointer;
  padding: 5px;

  svg{
    position:relative;
    display:block;
    opacity: 0.5;
    transform: scale(0.8);
  }

  &_locked{
    svg{
      opacity: 1;
    }
    &:before, &:after{
      content: '';
      width: 13px;
      height: 1px;
      background-color: #fff4;
      position: absolute;
      top: 12px;
    }

    &:before{
      left: -15px;
    }


    &:after{
      right: -15px;
    }
  }

  &:hover svg{
    opacity: 1;
  }

}

.property__hide-sibling + *{
  visibility: hidden;
}
</style>
