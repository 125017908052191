<template>
    <div
        class="slides-editor"
        :class="{'magnifier-on': slideshot2dContext, 'canWorkspaceMoving': spacePressed && !mousePressed.left, 'workspaceMoving': allowWorkspaceMoving}"
    >
        <ProcessMessage :message="processMessage" @updateMsg="p => processMessage[p.key] = p.val"/>
        <div v-if="slideshot2dContext" class="event-glass"></div>
        <div v-if="slideshot2dContext" class="magnifier">
            <div v-for="n in 25" :key="n" class="magnifier__dot"></div>
            <svg
                class="magnifier__grid"
                width="100"
                height="100"
                xmlns="http://www.w3.org/2000/svg"
                style="fill: transparent"
            >
                <g style="stroke: #888; stroke-width: 1px">
                    <line v-for="n in 4" :key="n" :x1="20*n" :x2="20*n" y1="0" y2="100"></line>
                </g>
                <g style="stroke: #888; stroke-width: 1px">
                    <line v-for="n in 4" :key="n" :y1="20*n" :y2="20*n" x1="0" x2="100"></line>
                </g>
                <g class="center" style="stroke: #10afff; stroke-width: 2px">
                    <line x1="40" y1="40" x2="60" y2="40"></line>
                    <line x1="60" y1="40" x2="60" y2="60"></line>
                    <line x1="60" y1="61" x2="40" y2="61"></line>
                    <line x1="39" y1="60" x2="39" y2="40"></line>
                </g>
            </svg>
        </div>

        <div class="header" @mousedown="checkBgEditingCondition">
            <div class="header__back-btn" @click="toAccount" @mouseenter="saveSlides">
                <inline-svg width="12" height="19" class="img-fluid" :src="require('@/assets/icon/back-btn.svg')"/>
            </div>
            <div class="header__title">
                <input
                    v-model="title"
                    class="header__title-input"
                    type="text"
                    spellcheck="false"
                    :size="titleSize"
                    @blur="updateTitle"
                />
            </div>
            <div v-if="developMode" class="header__nav-btn">
                <svg width="7" height="18" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.9 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM1.567 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm10.666 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        transform="rotate(90 1.5 3.5)"
                        fill="#FFF"
                        fill-rule="nonzero"
                    />
                </svg>
                <div class="context-menu header__nav">
                    <div class="context-menu__item">Copy</div>
                    <div class="context-menu__item">Delete</div>
                    <!--          <div class="context-menu__item" v-on:click="loadSlides">Load</div>-->
                    <!--          <div class="context-menu__item" v-on:click="saveSlides">Save</div>-->
                </div>
            </div>
            <Toolbar/>
            <PropScale ref="propScale"/>
            <PropHistory/>
            <ServiceBar ref="serviceBar"/>
        </div>

        <div class="main-block" :class="{'loading': !presentation}">
            <div class="workspace" :class="{'workspace_dropping': droppingMedia}">
                <rulers
                    v-if="showRulers"
                    ref="rulers"
                    :scale="scale"
                    :mouse-coords="rawCoords"
                    :mouse-pressed="mousePressed"
                />
                <div
                    class="canvas"
                    id="workspace-canvas"
                    v-bind:class="{'canvas__rulers-on' : showRulers && !isFullscreen, 'canvas__drawing-on' : drawingNewObject, 'canvas__fullscreen' : isFullscreen}"
                    v-on:wheel.prevent="mouseWheel"
                    v-on:mousemove="mouseMove($event)"
                    @click.right="setMenuTarget"
                    @click.right.prevent="showContextMenu"
                    @dragleave.prevent.stop="dropMedia"
                    @dragenter.prevent.stop="dropMedia"
                    @dragover.prevent.stop="dropMedia"
                    @drop.prevent.stop="dropMedia"
                    @mousedown.self="canvasmousedown($event)"
                >
                    <div class="bar top-bar" v-if="isFullscreen" :class="{'bar_active': showHorizontalBars}">
                        <div class="presentation-name" v-if="presentation">{{ title }}</div>
                    </div>
                    <div
                        class="bar bottom-bar"
                        v-if="isFullscreen"
                        :class="{'bar_active': showHorizontalBars}"
                    >
                        <div class="handlers">
                            <div
                                class="handlers__button handlers handlers__button_prev"
                                :class="{'handlers__button_disabled': atTimelineStart}"
                                @click.stop="$refs.animation_controller.prev()"
                            ></div>
                            <div class="handlers__current" v-if="$refs.animation_controller">
                                <span>{{ currentFrameText }}</span>
                                <span> / </span>
                                <span>{{ $refs.animation_controller.slides.length }}</span>
                            </div>
                            <div
                                class="handlers__button handlers handlers__button_next"
                                :class="{'handlers__button_disabled': atTimelineEnd}"
                                @click.stop="$refs.animation_controller.next()"
                            ></div>
                        </div>
                        <div class="service-bar">
                            <div
                                class="service-bar__item"
                                v-if="!isFullscreen"
                                @click.stop.prevent="gotoFullscreen()"
                                @mouseup.stop.prevent=""
                            >
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none">
                                        <path d="M0 0h20v20H0z"/>
                                        <path
                                            fill="#FFF"
                                            d="M8.333 6.667v6.666L12.5 10 8.333 6.667zm7.5-4.167H4.167C3.25 2.5 2.5 3.25 2.5 4.167v11.666c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667zm0 13.333H4.167V4.167h11.666v11.666z"
                                        />
                                    </g>
                                </svg>
                                <div class="service-bar__item-title">{{ $t('present') }}</div>
                            </div>
                            <div
                                class="service-bar__item"
                                v-if="isFullscreen"
                                @click.stop.prevent="exitFullscreen()"
                                @mouseup.stop.prevent=""
                            >
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none">
                                        <path d="M0 0h20v20H0z"/>
                                        <path
                                            fill="#FFF"
                                            d="M8.408 12.992l1.175 1.175L13.75 10 9.583 5.833 8.408 7.008l2.15 2.159H2.5v1.666h8.058l-2.15 2.159zM15.833 2.5H4.167c-.925 0-1.667.75-1.667 1.667V7.5h1.667V4.167h11.666v11.666H4.167V12.5H2.5v3.333c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667z"
                                        />
                                    </g>
                                </svg>
                                <div class="service-bar__item-title">{{ $t('exit') }}</div>
                            </div>
                        </div>
                    </div>

                    <presentation
                        v-if="presentation"
                        ref="presentation"
                        :navigate="navigate"
                        :bgScale="bgScale"
                        :vw="vw"
                        :vh="vh"
                    />

                    <animation_controller
                        v-if="$store.state.vjsons.slides.length > 0 && (showAnimations || isFullscreen)"
                        ref="animation_controller"
                        :vjsons="$store.state.vjsons"
                        :resizeFlag="resizeFlag"
                        :animationPreview="animationPreview"
                        :boxed="presentation.boxed"
                        @finishedpreview="closePreview()"
                        @init="acReady = true"
                        @destroyed="acReady = false"
                    />

                    <div
                        class="fullscreen-shield"
                        v-if="isFullscreen"
                        @mousedown.prevent.stop=""
                        @mouseup.prevent.stop=""
                        @click.prevent.stop=""
                        @click.right.prevent.stop="$refs.animation_controller.prev()"
                        @click.left.prevent.stop="$refs.animation_controller.next()"
                        @wheel.prevent.stop=""
                    ></div>
                </div>
                <context_menu
                    class="workspace__menu"
                    :active="menuActive"
                    :items="menuItems[menuTarget]"
                    :coords="menuCoords"
                    :maxCoords="getMenuMaxCoords()"
                    :width="menuWidth"
                    @context_menu_event="contextMenuEvent"
                />

                <slide-previews v-if="presentation"/>
            </div>
            <prop-panel ref="propPanel"/>
        </div>

        <div v-if="debugMode" class="debug-log">
            <div class="debug-log__toggle">{{ $t('showHideLog') }}</div>
            <div class="debug-log__container"></div>
        </div>
        <themes_editor v-if="themesEditor" ref="themesEditor"/>

        <!-- Export to PDF -->
        <PdfSlidePreview v-if="exportSlideIdx !== null" :idx="exportSlideIdx" />
    </div>
</template>

<script>
/* eslint-disable */
import ajv from 'ajv';
import Vue from 'vue';
import _ from 'lodash';
import $ from 'jquery';
import JSZip from 'jszip';
import jsPDF from 'jspdf';

import '@/components/editor/spectrum/spectrum.js';

import Ajax from '@/mixins/Ajax';
import common from '@/mixins/Common';
import Pdf from '@/mixins/export/Pdf';
import FontsMixin from '@/mixins/fonts';
import Analytics from '@/mixins/Analytics';
import Fullscreen from '@/mixins/Fullscreen';
import MenuItems from '@/mixins/editor/MenuItems';
import VersionsConverter from '@/mixins/VersionsConverter';

import Rulers from '@/components/editor/Rulers';
import Toolbar from '@/components/editor/Toolbar';
import ServiceBar from '@/components/editor/ServiceBar';
import ContextMenu from '@/components/editor/ContextMenu';
import Presentation from '@/components/editor/Presentation';
import ThemesEditor from '@/components/editor/ThemesEditor';
import SlidePreviews from '@/components/editor/SlidePreviews';
import PropScale from '@/components/editor/property/PropScale';
import PropPanel from '@/components/editor/PropPanel/PropPanel';
import ProcessMessage from '@/components/editor/ProcessMessage';
import PropHistory from '@/components/editor/property/PropHistory';
import AnimationController from '@/components/AnimationController';

import AppPackage from '@/../package.json';
import User from '@/models/User';
import CLS from '@/mixins/CustomLocalStorage';
import AnimationControllerBar from '@/mixins/AnimationControllerBar';
import { ApplySettings } from '@/models/EditorExt';
import presentationSchema from '@/schemas/presentation-schema.json';
import PropPanelSeparate from '@/components/editor/PropPanelSeparate';
import EditorUiColors from '@/mixins/editor/EditorUiColors';
import { apiGet, apiPost, apiPut } from '@/models/Api';
import { UserSingleton } from '@/models/UserSingleton';
import { ToastConfig } from '@/configs';
import { ProcessMsgType } from '@/enum/Message';
import PdfSlidePreview from '@/components/test/PdfSlidePreview';

const debug = ApplySettings();

export default {
    provide () {
        return {
            currentPage: this
        };
    },
    name: 'Editor',
    components: {
        PdfSlidePreview,
        presentation: Presentation,
        SlidePreviews,
        Toolbar,
        propPanel: PropPanel,
        propPanelSeparate: PropPanelSeparate, // ??? Непонятная штука, не вижу где она вообще используется...
        rulers: Rulers,
        PropScale,
        PropHistory,
        ServiceBar,
        context_menu: ContextMenu,
        ProcessMessage,
        themes_editor: ThemesEditor,
        animation_controller: AnimationController
    },
    mixins: [
        Pdf,
        Ajax,
        Analytics,
        Fullscreen,
        FontsMixin,
        EditorUiColors,
        AnimationControllerBar
    ],
    data () {
        return {
            isPage: true,
            justForTestTrue: true,
            justForTestFalse: false,


            who: 'editor',
            version: AppPackage.version,
            ukey: '',
            gkey: '',
            debugMode: debug,
            developMode: false, // - для загрузки локальной презы
            engine: common.detectEngine(),
            os: common.detectOs(),
            // id презентации для загрузки
            presentationId: 0,
            // Стили цветовой схемы, которые будут приходить от компонентов
            componentStylesGetter: {},
            // Координаты мыши
            rawCoords: {
                x: 0,
                y: 0
            },
            // Флаги нажатых кнопок мыши
            mousePressed: {
                left: false,
                right: false
            },
            // Флаг нажатых кнопок клавиатуры (массив при наличии нажатых)
            keyPressed: false,
            // Масштабирование презентации
            scale: 1,
            // Соотношение масштаба фона к автомасштабу презентации
            bgScale: 1,
            // Размеры канваса
            vh: 0,
            vw: 0,
            // Флаг изменения масштаба
            scaling: false,
            // Таймер флага
            scalingTimeout: {},
            // навигация по рабочей области, используется в presentation.coffee
            navigate: {
                tx: 0, // смещение презентации по х относительно центра
                ty: 0 // смещение презентации по у относительно центра
            },
            transformOrigin: [50, 50],
            transformOriginDelta: [0, 0],
            // Флаг ресайза
            resizeFlag: false,
            // Флаг загрузки презентации
            presentationLoadFlag: false,
            droppingMedia: false,
            overText: false,
            blockMetatool: false,
            blockMetatoolHandlers: false,
            blockMetatoolSnapshot: false,
            doNotRestoreSelectionFlag: false,
            mouseEvent: undefined,
            editingText: undefined,
            editingTable: undefined,
            allowWorkspaceMoving: false,
            spacePressed: false,
            html2CanvasHack: false,
            slideshot2dContext: false,
            pickedColor: false,
            pickedColorCandidate: false,
            // Для изменения курсора при рисовании нового объекта
            drawingNewObject: false,
            // родительский Vue объект открытого меню
            // activeNav:{} - удалить нах
            asyncAjax: true,
            showRulers: false,
            showGuides: false,
            // propOrderTargets: {}
            presentation: undefined,
            shareLink: '',
            shareLinkActive: false,
            linkCopyAnimation: false,
            normalSaveTick: 20000,
            errorSaveTick: 10000,
            showTransitions: false,
            showAnimations: false,
            animationPreview: false,
            supportedMedia: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg+xml'],
            menuTarget: 'object',
            menuActive: false,
            menuItems: MenuItems,
            menuCoords: {
                x: 0,
                y: 0
            },
            menuWidth: 200,
            keyCodes: {
                modifiers: {
                    'delete': 46,
                    'backspace': 8,
                    'shift': 16,
                    'space': 32,
                    'cmd1': 91,
                    'cmd2': 93,
                    'ctrl': 17,
                    'alt': 18
                },
                hotkeys: {
                    zCode: 90,
                    yCode: 89,
                    cCode: 67,
                    vCode: 86,
                    xCode: 88
                }
            },
            processMessage: {
                on: true,
                text: '',
                ok: false,
                count: '',
                type: null,
                loop: true,
                percentage: 0,
                cancel: false,
                cancellable: false,
                name: this.$t('loading'),
            },
            historyHandlerOverride: null,
            windowOutOfFocus: false,
            installer: {
                osName: '',
                url: '',
                size: 0
            },
            isOnline: true,
            themesEditor: false,
            jsonConverter: new VersionsConverter(),
            whatsNew: AppPackage.whatsNew,
            user: new User()
        };
    },
    watch: {
        showTransitions (val) {
            if (!val) {
                this.resize();
            }
        },

        // Не удалять! Без этого не закрывается контекстное муню
        'mousePressed.left' () {
            // console.log 'ROOT '+@mousePressed.left
            const that = this;
            if (this.mousePressed.left) {

                if (this.showTransitions) {
                    // Detect workspace-canvas event match
                    // and close transitions tab if necessary
                    const canvasEl = document.getElementById('workspace-canvas');
                    const canvasElRect = canvasEl.getBoundingClientRect();
                    const x1 = canvasElRect.left;
                    const y1 = canvasElRect.top;
                    const x2 = x1 + canvasElRect.width;
                    const y2 = y1 + canvasElRect.height;
                    const me = this.mouseEvent;
                    const cx = me.clientX;
                    const cy = me.clientY;
                    if ((cx >= x1) && (cx <= x2) && (cy >= y1) && (cy <= y2)) {
                        this.$refs.propPanel.showTransitions = false;
                    }
                }

                setTimeout(function () {
                        that.menuActive = false;
                        that.shareLinkActive = false;
                    }
                    , 50);
            } else {
                this.droppingMedia = false;
            }
        },

        presentation (newVal, oldVal) {
            if ((oldVal === undefined) && (typeof newVal === 'object')) {
                setTimeout(
                    () => {
                        this.processMessage.on = false;
                        this.checkWhatsNewConditions();
                    },
                    1000
                );
            }
        },

        componentStylesGetter: {
            handler () {
                if (this.themeStylesCss != null) {
                    this.themeStylesCss();
                }
            }
        },

        navigate: {
            handler () {
                this.transformOriginCalc();
                this.resize();
            },
            deep: true
        },

        transformOriginDelta () {
            if (this.transformOriginDelta[0] !== 0) {
                this.navigate.tx = Math.round(this.navigate.tx + this.transformOriginDelta[0]);
                this.transformOriginDelta[0] = 0;
            }
            if (this.transformOriginDelta[1] !== 0) {
                this.navigate.ty = Math.round(this.navigate.ty + this.transformOriginDelta[1]);
                this.transformOriginDelta[1] = 0;
            }
        },

        '$store.state.presentation' () {
            if (this.$store.state.presentation.slides?.length > 0) {
                this.showRulers = this.getRulersSettings();
                this.showGuides = this.getGuidesSettings();
            }
            this.metaTool?.clearState();
        },

        isOnline (val) {
            if (val) {
                this.saveSlides();
                this.resolveAllTempMedia();
            }
        },

        showRulers (val) {
            this.setSetting('showRulers', val);
        },

        showGuides (val) {
            this.setSetting('showGuides', val);
        }
    },
    computed: {
        titleSize () {
            let ts = this.title?.length + 1;
            if ((ts == null) || (ts < 5)) {
                ts = 5;
            }
            if (ts > 20) {
                ts = 20;
            }
            return ts;
        },

        keyCodesArrays () {
            const res = {};
            for (let k in this.keyCodes) {
                const v = this.keyCodes[k];
                res[k] = Object.entries(v).map(k => k[1]);
            }
            return res;
        },

        // стили в формате JSON которые будут собираться из того,
        // что пришло от компонентов в @componentStylesGetter
        themeStyles () {
            let ts = {};
            _.each(this.componentStylesGetter, function (cs) {
                ts = _.extend(ts, cs);
            });
            return ts;
        },

        title: {
            get () {
                return this.$store.state.title;
            },
            set (val) {
                this.$store.commit('setTitle', val);
            }
        },

        editingObjects () {
            this.presentationLoadFlag;
            if ((this.$refs.presentation?.$refs.metaTool == null)) {
                return [];
            } else {
                return this.$refs.presentation.$refs.metaTool.editingObjects;
            }
        },

        metaToolProps: { // свойства из метатула для propPanel (coords, rotate)
            get () {
                let props;
                const mt = this.$refs.presentation.$refs.metaTool;
                if (!mt.lineEditing) {
                    props = mt.editorScaledCoords;
                    props.rotate = Math.round(mt.editor.rotate);
                } else {
                    props = {
                        x1: mt.editorScaledCoords[0].x,
                        x2: mt.editorScaledCoords[1].x,
                        y1: mt.editorScaledCoords[0].y,
                        y2: mt.editorScaledCoords[1].y
                    };
                }
                return props;
            }
        },

        metaTool () {
            this.presentationLoadFlag;
            const res = this.$refs.presentation?.$refs.metaTool;

            return res;
        }
    },
    methods: {
        init () {
            let tick;
            const that = this;

            this.presentation = this.$store.state.presentation;

            var timer = setTimeout(
                (tick = function () {

                    let tickTime;
                    if (that.error) {
                        tickTime = that.errorSaveTick;
                    } else {
                        tickTime = that.normalSaveTick;
                    }

                    if (!that.developMode) {
                        that.saveSlides().catch(e => console.log('cannot save'));
                    }

                    timer = setTimeout(tick, tickTime);
                }),
                that.normalSaveTick
            );

            // Resolving temp media, if exist
            this.resolveAllTempMedia();

            // Validate backgrounds
            this.validateBgSizes();

            Vue.nextTick(
                function () {

                    // Следующая строка нужна для реактивности
                    // размера презентации
                    this.resize();
                    // Следующая строка нужна для реактивности
                    // vueObj's
                    this.presentationLoadFlag = !this.presentationLoadFlag;

                    this.getUserData();
                    this.getSlideTemplates();
                },
                this
            );

        },

        resolveAllTempMedia () {
            const { mediaBrowser } = this.$refs.propPanel.$refs;

            for (let k in this.presentation.media) {
                const v = this.presentation.media[k];
                if (this.presentation.media[k].temp) {
                    mediaBrowser.resolveTempMedia(k);
                }
            }
        },

        validateBgSizes () {
            const {
                presentation
            } = this.$store.state;
            const {
                slides
            } = presentation;
            const {
                media
            } = presentation;

            const app = this;

            const handler = async function (target) {
                const imgId = target['background-image'];
                const imgHeight = target['background-image-height'];
                const imgWidth = target['background-image-width'];

                if (imgId && !(imgWidth && imgHeight)) {
                    const {
                        url
                    } = media[imgId];
                    const size = await app.getImgSize(url);
                    Vue.set(target, 'background-image-height', size.height);
                    Vue.set(target, 'background-image-width', size.width);
                }

            };

            slides.forEach(s => {

                const {
                    bg
                } = s;
                if (bg != null) {
                    handler(bg);
                }

                const {
                    objects
                } = s;
                objects.forEach(o => {
                    const {
                        styleProps
                    } = o;
                    if (styleProps != null) {
                        handler(styleProps);
                    }
                });
            });

        },

        getImgSize (url) {
            return new Promise((resolve, reject) => {
                // вычисляем размеры картинок
                const img = new Image();
                const that = this;
                img.onload = function () {
                    resolve({ height: this.naturalHeight, width: this.naturalWidth });
                };
                img.onerror = function () {
                    reject('no image');
                };
                if ((url.indexOf('http') !== 0) && (url.indexOf('data') !== 0)) {
                    url = this.storageUrl + url;
                }
                return img.src = url;
            });
        },

        async updateTitle () {
            let route = `/api/v1/presentations/${this.presentationId}`;
            if (this.isTemplate === 'true') {
                route = `/api/v1/template/${this.presentationId}`;
            }

            await apiPut(route, { name: this.title });
        },

        toAccount () {
            this.asyncAjax = false;
            this.saveSlides();
            this.$router.push('/account');
        },

        showError (errorText, type) {
            if (!type) {
                type = 'warn';
            }

            if (type === 'warn') {
                window.App.$toast.warning(window.App.$t(errorText), ToastConfig);
            } else {
                window.App.$toast.error(window.App.$t(errorText), ToastConfig);
            }
        },

        showMessage (text) {
            window.App.$toast.success(window.App.$t(text), ToastConfig);
        },

        showMedia (opts) {
            if (opts == null) {
                opts = {};
            }
            if (opts.newImage == null) {
                opts.newImage = false;
            }

            this.$refs.propPanel.showMedia = true;
            this.$refs.propPanel.newImage = opts.newImage;
        },

        setMenuTarget () {
            this.menuTarget = 'slide';
            const objectsClipboard = localStorage.getItem('objectsClipboard');
            const pasteItem = _.find(this.menuItems.slide, o => o.event === 'paste');
            if (objectsClipboard != null) {
                pasteItem.disabled = false;
            } else {
                pasteItem.disabled = true;
            }
        },

        showContextMenu (e) {
            this.menuActive = true;
            this.menuCoords = {
                x: e.clientX + 2,
                y: e.clientY + 2
            };
        },

        // ограничения координат контекстного меню
        getMenuMaxCoords () {
            if ((this.$refs.presentation == null)) {
                return {
                    x: 0,
                    y: 0
                };
            }
            const canvasEl = this.$refs.presentation.$el.parentElement.getBoundingClientRect();
            const maxX = canvasEl.right;
            const maxY = canvasEl.bottom;
            // console.log maxX
            return {
                x: maxX,
                y: maxY
            };
        },

        contextMenuEvent (e) {
            const {
                metaTool
            } = this.$refs.presentation.$refs;
            switch(e) {
                case 'toggleRulers':
                    this.showRulers = !this.showRulers;
                    break;
                case 'copy':
                    metaTool.copyObjects();
                    break;
                case 'paste':
                    metaTool.pasteObjects();
                    break;
                case 'cut':
                    metaTool.cutObjects();
                    break;
                case 'tableCopy':
                case 'textCopy':
                case 'tablePaste':
                case 'textPaste':
                case 'tableCut':
                case 'textCut':
                    this.showHotkeysNotWebAvailibleDialog();
                    break;
                case 'delete':
                    metaTool.deleteObjects();
                    break;
                case 'splitVer':
                    this.editingTable.splitCell('x');
                    break;
                case 'splitHor':
                    this.editingTable.splitCell('y');
                    break;
                case 'mergeCells':
                    this.editingTable.mergeCells();
                    break;
                case 'insertRowAbove':
                    this.editingTable.insertColOrRow('row', 'before');
                    break;
                case 'insertRowBelow':
                    this.editingTable.insertColOrRow('row', 'after');
                    break;
                case 'insertColRight':
                    this.editingTable.insertColOrRow('col', 'after');
                    break;
                case 'insertColLeft':
                    this.editingTable.insertColOrRow('col', 'before');
                    break;
                case 'deleteRow':
                    this.editingTable.deleteRow();
                    break;
                case 'deleteCol':
                    this.editingTable.deleteCol();
                    break;
                case 'ungroup':
                    metaTool.ungroupObjects();
                    break;
                case 'group':
                    metaTool.groupObjects();
                    break;
                case 'addText':
                    this.editingObjects[0].vueObj.addTextEntity();
                    break;
                case 'selectAll':
                    metaTool.selectAll();
                    break;
                case 'dropMediaBackground':
                    this.dropMediaBackground();
                    break;
                case 'dropMediaNewObj':
                    this.dropMediaNewObj();
                    break;
                case 'copyStyles':
                    var copy = {
                        styleProps: _.cloneDeep(this.$refs.propPanel.styleProps),
                        textProps: _.cloneDeep(this.$refs.propPanel.textProps)
                    };
                    if (copy.styleProps == null) {
                        copy.styleProps = {};
                    }
                    if (copy.textProps == null) {
                        copy.textProps = {};
                    }
                    delete copy.textProps['list-type'];
                    delete copy.textProps['list-style-type'];
                    delete copy.textProps['list-level'];
                    delete copy.styleProps['adjust-to-text'];
                    delete copy.styleProps['lock-aspect'];
                    delete copy.styleProps['background-image'];
                    delete copy.styleProps['background-image-width'];
                    delete copy.styleProps['background-image-height'];
                    localStorage.setItem('stylesClipboard', JSON.stringify(copy));
                    break;
                case 'applyStyles':
                    var stylesStr = localStorage.getItem('stylesClipboard');
                    if (stylesStr) {
                        const styles = JSON.parse(stylesStr);
                        this.editingObjects.forEach(function (o) {
                            o.vueObj.applyStyles(styles);
                        });
                    }
                    this.$store.commit('saveHistory', 'applyStyles');
                    break;

                default:
                    window.log('Command ' + e + ' is not implemented yet');
            }
        },

        // Содержимое тэга style цветовой схемы (для динамической вставки в head)
        themeStylesCss () {
            if ((this.themeStylesEl == null)) {
                this.themeStylesEl = document.createElement('style');
                this.themeStylesEl.type = 'text/css';
                document.getElementsByTagName('head')[0].appendChild(this.themeStylesEl);
            }
            let css = '';
            _.each(this.themeStyles, function (elem, key) {
                let elemStyles = '';
                _.each(elem, function (val, key) {
                    elemStyles += (key + ': ' + val + ';');
                });
                css += (key + '{' + elemStyles + '}');
            });
            $(this.themeStylesEl).html(css);
            return css;
        },

        mouseWheel (e) {
            if (e.ctrlKey) {
                const s = (this.scale * 100) - ((e.deltaY / Math.abs(e.deltaY)) * 10 * this.scale);
                this.setScale(s);
            } else {
                this.navigate.tx = Math.round(this.navigate.tx - (e.deltaX * 2));
                this.navigate.ty = Math.round(this.navigate.ty - (e.deltaY * 2));
                this.checkNavigateOverflow();
            }
        },

        mouseMove (e) {
            if (this.allowWorkspaceMoving) {
                this.navigate.tx = Math.round(this.navigate.tx + e.movementX);
                this.navigate.ty = Math.round(this.navigate.ty + e.movementY);
                this.checkNavigateOverflow();
            }
        },

        checkNavigateOverflow () {
            if ((this.presentation == null)) {
                return;
            }

            if (((this.presentation.size.w * this.scale) < this.vw) &&
                ((this.presentation.size.h * this.scale) < this.vh)) {
                this.navigate.tx = 0;
                this.navigate.ty = 0;
                return;
            }

            const xLimiter = (this.presentation.size.w / 2) * this.scale;
            const yLimiter = (this.presentation.size.h / 2) * this.scale;

            if (this.navigate.tx > xLimiter) {
                this.navigate.tx = xLimiter;
            }

            if (this.navigate.tx < -xLimiter) {
                this.navigate.tx = -xLimiter;
            }

            if (this.navigate.ty > yLimiter) {
                this.navigate.ty = yLimiter;
            }

            if (this.navigate.ty < -yLimiter) {
                this.navigate.ty = -yLimiter;
            }

        },

        setScale (val) {
            this.transformOriginCalc();
            const that = this;
            if (20 <= val && val < 1000) {
                if (this.metaTool.editingObjects.length > 0) {
                    this.metaTool.cacheEditingObjects();
                    this.metaTool.clearState();
                }

                this.scale = parseFloat((val / 100).toFixed(2));
                this.scaling = true;
                that.resize();
                clearTimeout(this.scalingTimeout);
                this.transformOriginDeltaCalc();

                this.scalingTimeout = setTimeout(function () {
                        that.scaling = false;
                        that.resize();
                        that.metaTool.restoreCachedObjects();
                    }
                    , 100);
            }
        },

        fitScreen () {
            this.navigate = {
                tx: 0,
                ty: 0
            };
            this.calcScale();
            if (this.metaTool != null) {
                this.metaTool.restoreCachedObjects();
            }
        },

        transformOriginCalc () {
            const that = this;
            if (this.scaling) {
                return;
            }
            Vue.nextTick(function () {
                if ($(that.$el).find('.backgrounds')[0] != null) {
                    const rect = $(that.$el).find('.backgrounds')[0].getBoundingClientRect();
                    const dx = that.rawCoords.x - rect.left;
                    const dy = that.rawCoords.y - rect.top;
                    const h = rect.bottom - rect.top;
                    const w = rect.right - rect.left;
                    const toX = Math.round((dx / w) * 100);
                    const toY = Math.round((dy / h) * 100);
                    that.transformOrigin = [toX, toY];
                }
            });
        },

        transformOriginDeltaCalc () {
            const that = this;
            Vue.nextTick(function () {
                if ($(that.$el).find('.backgrounds')[0] != null) {
                    const rect = $(that.$el).find('.backgrounds')[0].getBoundingClientRect();
                    const h = rect.bottom - rect.top;
                    const w = rect.right - rect.left;
                    const dx = that.rawCoords.x - (rect.left + ((w * that.transformOrigin[0]) / 100));
                    const dy = that.rawCoords.y - (rect.top + ((h * that.transformOrigin[1]) / 100));
                    that.transformOriginDelta = [dx, dy];
                }
            });
        },

        // Загрузка презентации из базы
        async loadSlides (develop) {
            let route = `/api/v1/presentation/${this.presentationId}`;
            if (this.isTemplate === 'true') {
                route = `/api/v1/template/${this.presentationId}`;
            }

            let res;
            try {
                res = await apiGet(route, {}, false);
            } catch (e) {
                this.processMessage.text += 'Network error occured(${e.error}), trying to reconnect';
                setTimeout(() => this.loadSlides(), 10000);
            }
            console.log('loadSlides', res);

            if (res) {
                const zip = await JSZip.loadAsync(res.body, { base64: true });
                const json = await zip.file(Object.keys(zip.files)[0]).async('string');
                let presentation = JSON.parse(json);
                this.$store.commit('setTitle', res.name);
                const clsJson = await CLS.getItem(this.presentationId);

                let lsState;
                try {
                    lsState = JSON.parse(clsJson);
                } catch (e) {
                }

                if (presentation != null) {
                    presentation = this.validateJson(presentation);

                    if (!presentation) {
                        return;
                    }

                    if (presentation.timeStamp < lsState?.timeStamp) {
                        presentation = lsState;
                    }

                    this.$store.commit('setPresentation', presentation);
                    this.$nextTick(() => this.init());

                    return this.fitScreen();
                }

            } else {
                this.showError(`Error while getting presentation from server: ${res.error}`, 'error');
            }
        },

        async loadThemes () {
            const res = await apiGet('/api/v1/themes');

            if (res) {
                const themes = {};
                res.forEach(i => themes[i.id] = JSON.parse(i.body));
                this.$store.state.themes = themes;
            }
        },

        * stringGenerator () {
            const charRanges = [97, 122];

            let fake = [96];
            while (true) {
                var stop = false;
                fake.forEach(function (val, i, arr) {
                    if (!stop) {
                        if (val < charRanges[1]) {
                            arr[i]++;
                            return stop = true;
                        } else {
                            return arr[i] = charRanges[0];
                        }
                    }
                });
                if (!stop) {
                    fake = [charRanges[0]].concat(fake);
                }
                yield String.fromCharCode(..._.reverse(_.clone(fake)));
            }
        },

        compressObject (theObj) {
            if (!(theObj instanceof Array || theObj instanceof Object)) {
                return;
            }
            // Собираем строки
            const strings = {};

            const addString = function (str) {
                if (!(typeof str === 'string')) {
                    return false;
                }
                if (strings[str] != null) {
                    strings[str] = strings[str] + 1;
                } else {
                    strings[str] = 1;
                }
                return true;
            };

            var getStringsFromKeysAndVals = function (obj) {
                _.forEach(obj, function (val, key) {
                    if (!(theObj instanceof Array)) {
                        addString(key);
                    }
                    addString(val);
                    if (val instanceof Object) {
                        return getStringsFromKeysAndVals(val);
                    }
                });
            };

            getStringsFromKeysAndVals(theObj);

            let stringsCounted = [];

            _.forEach(strings, function (val, key) {
                // if val == 1 || key.length < 3
                //   return
                const o = {};
                o.str = key;
                o.count = val;
                stringsCounted.push(o);

            });

            stringsCounted = _.reverse(_.sortBy(stringsCounted, ['count']));

            const sg = this.stringGenerator();

            const exchangeRules = {};
            const exchanges = [];

            _.forEach(stringsCounted, function (val, key) {
                const newFake = sg.next().value;
                exchangeRules[val.str] = newFake;
                exchanges.push(val.str);

            });

            const theRes = this.objMapper(theObj, exchangeRules);

            let exchangesStr = '';

            exchanges.forEach(function (val) {
                const res = btoa(unescape(encodeURIComponent(val)));
                let charArr = [...res];

                // Убираем конечные =
                for (let c = 0; c <= 1; c++) {
                    if (charArr[charArr.length - 1] === '=') {
                        charArr.pop();
                    }
                }

                charArr = charArr.map(function (char, index) {
                    let payload;
                    if (index & 1) {
                        payload = 42;
                    } else {
                        payload = 86;
                    }
                    return char.charCodeAt(0) + payload;
                });

                const puzzle = String.fromCharCode(...charArr);

                exchangesStr += puzzle;
                exchangesStr += String.fromCharCode(2048);

            });

            return JSON.stringify([
                theRes,
                exchangesStr
            ]);
        },

        decompressObject (theStr) {
            const req = JSON.parse(theStr);

            req[1] = req[1].split(String.fromCharCode(2048));

            req[1] = req[1].map(function (val) {

                let charArr = [...val];

                charArr = charArr.map(function (char, index) {
                    let payload;
                    if (index & 1) {
                        payload = 42;
                    } else {
                        payload = 86;
                    }
                    return char.charCodeAt(0) - payload;
                });

                const normalString = String.fromCharCode(...charArr);

                return decodeURIComponent(escape(window.atob(normalString)));
            });

            const sg = this.stringGenerator();
            const exchangeRules = {};

            req[1].forEach(function (val) {
                exchangeRules[sg.next().value] = val;

            });
            req[1] = exchangeRules;

            const theRes = this.objMapper(req[0], exchangeRules);

            return theRes;
        },

        objMapper (obj, exchangeRules) {
            let mapped;
            if (!obj instanceof Object) {
                throw new Error('Obj is not an object');
                return;
            }

            const isArr = obj instanceof Array;

            if (isArr) {
                mapped = [];
            } else {
                mapped = {};
            }

            _.forEach(obj, (val, key) => {
                let k, v;
                if (!isArr) {
                    if (exchangeRules[key] != null) {
                        k = exchangeRules[key];
                    } else {
                        k = key;
                    }
                }
                if ((typeof val === 'string') && (exchangeRules[val] != null)) {
                    v = exchangeRules[val];
                } else {
                    if (val instanceof Object) {
                        v = this.objMapper(val, exchangeRules);
                    } else {
                        v = val;
                    }
                }

                if (isArr) {
                    mapped.push(v);
                } else {
                    mapped[k] = v;
                }
            });
            return mapped;
        },

        // Сохранение презентации в базу
        saveSlides () {
            return new Promise((resolve, reject) => {
                let that = this;

                if (!this.isOnline) {
                    reject(false);
                    return;
                }

                that = this;
                if (!document.hidden && (localStorage.getItem('savedToServer') !== 'true')) {
                    return CLS.getItem(this.presentationId).then(res => {
                        if (!res) {
                            reject('no data in database yet');
                            return;
                        }
                        const copy = res;
                        const data = JSON.parse(copy);
                        try {
                            this.validateJson(data);
                        } catch (e) {
                        }

                        localStorage.setItem('savedToServer', 'true');

                        const zip = new JSZip();
                        zip.file('presentation.base64', copy);
                        zip.generateAsync({
                            type: 'base64',
                            compression: 'DEFLATE',
                            compressionOptions: {
                                level: 9
                            }
                        }).then(async base64 => {
                            let route = `/api/v1/presentation/${this.presentationId}`;

                            if (this.isTemplate === 'true') {
                                route = `/api/v1/template/${this.presentationId}`;
                            }

                            const res = await apiPut(route, { data: base64 });

                            if (res) {
                                return true;
                            } else {
                                localStorage.setItem('savedToServer', 'false');

                                return false;
                            }
                        });
                    });
                }
            });
        },
        removeKeys (obj, keys) {
            let index = undefined;
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    switch(typeof obj[prop]) {
                        case 'string':
                            index = keys.indexOf(prop);
                            if (index > -1) {
                                delete obj[prop];
                            }
                            break;
                        case 'object':
                            index = keys.indexOf(prop);
                            if (index > -1) {
                                delete obj[prop];
                            } else {
                                this.removeKeys(obj[prop], keys);
                            }
                            break;
                    }
                }
            }
        },
        // Расчет масштабирования презентации
        // для вписывания в рабочую область
        calcScale (onlyBg) {
            if ((this.$store.state.presentation.size == null)) {
                return;
            }
            if (this.$el !== undefined) {
                const canvas = $(this.$el).find('.canvas');
                let {
                    scale
                } = this;

                const ph = this.$store.state.presentation.size.h;
                const pw = this.$store.state.presentation.size.w;

                const vh = canvas.height();
                const vw = canvas.width();

                if ((pw / ph) > (vw / vh)) {
                    scale = vw / pw;
                }

                if ((pw / ph) <= (vw / vh)) {
                    scale = vh / ph;
                }

                if (!onlyBg) {
                    this.scale = scale;
                }
                this.bgScale = 1 / scale;

                this.vh = vh;
                this.vw = vw;
                return;
            }
        },
        setStatus (status) {
            if (status.destroy) {
                this.processMessage.on = false;
            } else {
                this.processMessage.loop = status.loop;
                this.processMessage.text = status.text;
                this.processMessage.type = status.type;
                this.processMessage.count = status.count;
                this.processMessage.percentage = status.percentage;
            }
        },
        makePdf () {
            const screenshotGenerator = this.$refs.presentation.$refs.screenshot_generator;

            this.$store.state.flags.screenshotGeneratorMode = true;
            this.$store.state.flags.pdfGeneratorMode = true;

            const imgsArr = [];

            this.$nextTick(() => {
                // Second tick - change texts' state
                this.$nextTick(() => {
                    // Third tick - change texts' DOM
                    this.$nextTick(() => {
                        // Fourth tick - SVGs ready
                        this.$nextTick(() => {
                            this.processMessage.on = true;
                            this.processMessage.name = this.$t('exportToPdf');
                            this.processMessage.text = 'generatingSlides';
                            this.processMessage.loop = true;
                            this.processMessage.cancellable = false;

                            let promisesChain = new Promise(resolve => resolve('initial'));

                            const ptw = this.presentation.size.w * 0.75;
                            const pth = this.presentation.size.h * 0.75;

                            const unit = 'pt';
                            const orientation = 'l';
                            const format = [ptw, pth];
                            const doc = new jsPDF({ orientation, unit, format });

                            this.presentation.slides.forEach((s, si, sarr) => {
                                promisesChain = promisesChain.then(() => {
                                    return new Promise(async (resolve, reject) => {

                                        this.setStatus({
                                            loop: false,
                                            text: 'processingSlide',
                                            type: ProcessMsgType.COUNT,
                                            percentage: ((si + 1) / sarr.length) * 100,
                                            count: (si + 1) + '/' + sarr.length
                                        });

                                        await screenshotGenerator.getScreenshot(si);

                                        if (si !== 0) {
                                            doc.addPage(format, orientation);
                                        }

                                        doc.addImage(
                                            screenshotGenerator.$el,
                                            'JPEG',
                                            0,
                                            0,
                                            ptw,
                                            pth,
                                            undefined,
                                            'FAST'
                                        );

                                        if (si === (sarr.length - 1)) {
                                            doc.save(`${this.$store.state.title}.pdf`);
                                            this.setStatus({ destroy: true });
                                            this.clearSlideshot();
                                        }
                                        resolve();
                                    });
                                });
                            });
                        });
                    });
                });
            });

        },
        makeSlideshot () {
            const {
                screenshot_generator
            } = this.$refs.presentation.$refs;
            this.$store.state.flags.screenshotGeneratorMode = true;
            this.slideshot2dContext = screenshot_generator.$el.getContext('2d');
        },
        clearSlideshot () {
            this.html2CanvasHack = false;
            this.slideshot2dContext = false;
            this.pickedColor = false;
            this.pickedColorCandidate = false;
            this.$store.state.flags.screenshotGeneratorMode = false;
            this.$store.state.flags.pdfGeneratorMode = false;

            this.blockMetatool = this.blockMetatoolSnapshot;

            $('.screenshot-container').each(function () {
                this.getContext('2d').clearRect(0, 0, this.width, this.height);
            });

        },

        cancelProcessMessage () {
            this.processMessage.on = false;
            this.processMessage.cancel = false;
            this.clearSlideshot();
        },

        getPixelColor () {
            return new Promise((resolve, reject) => {
                this.makeSlideshot();
                return this.$watch('pickedColor', function (newVal, oldVal) {
                    if (newVal === undefined) {
                        reject('Cancelled colorpicker');
                        return;
                    }
                    if (newVal[3] === 0) {
                        reject('No color');
                        return;
                    } else {
                        resolve(newVal);
                        return;
                    }
                });
            });
        },

        getSlideshotPixels (coords) {
            const ctx = this.slideshot2dContext;
            if (!ctx) {
                return undefined;
            } else {

                let iData;
                try {
                    const {
                        canvasWidthAddition,
                        canvasHeightAddition
                    } = this.$refs.presentation.$refs.screenshot_generator;
                    iData = ctx.getImageData(
                        (coords.x - 2) + (canvasWidthAddition / 2),
                        (coords.y - 2) + (canvasHeightAddition / 2),
                        5,
                        5
                    );
                } catch (e) {
                    return undefined;
                }


                const pixelsData = iData.data;

                const res = [];
                for (let i = 0; i < 25; i++) {
                    const pixel = [];
                    for (let j = 0; j < 4; j++) {
                        pixel.push(pixelsData[(i * 4) + j]);
                    }
                    res.push(pixel);
                }

                return res;
            }
        },

        drawMagnifier (coords) {

            if ((coords == null)) {
                coords = this.rawCoords;
            }

            const pixels = this.getSlideshotPixels(coords);
            $('.magnifier').css('left', this.rawCoords.x + 'px');
            $('.magnifier').css('top', this.rawCoords.y + 'px');

            if ((pixels == null)) {
                this.pickedColorCandidate = undefined;
                return;
            }

            for (let i = 0; i < 25; i++) {
                var val;
                const pixel = pixels[i];
                if (i === 12) {
                    this.pickedColorCandidate = pixel;
                }
                if (pixel[3] === 0) {
                    val = '';
                } else {
                    val = 'rgba(' + pixel + ')';
                }
                $('.magnifier__dot').eq(i).css('background-color', val);
            }

        },

        hex2rgb (hex) {
            if ((hex == null)) {
                return;
            }
            const hexVals = hex.match(/#?([0-9A-F]{2})([0-9A-F]{2})([0-9A-F]{2})/i);
            if ((hexVals == null)) {
                return '0,0,0';
            }
            const rgb = hexVals.map(function (val) {
                const converted = parseInt(val, 16);
                if (!isNaN(converted)) {
                    return converted;
                }
            }).join(',').substr(1);
            return rgb;
        },

        resize () {
            const that = this;
            this.calcScale(true); // onlyBg = true - для пересчета масштаба фона
            if (this.scaling) {
                return;
            }
            that.resizeFlag = !that.resizeFlag;
            // Задержка переключения флага для завершения прорисовки элементов
            // (на всякий случай,
            // если элемент не отрисовался по предыдущему изменению)
            return _.delay(
                function () {
                    that.resizeFlag = !that.resizeFlag;
                    that.checkNavigateOverflow();
                },
                10
            );
        },

        getObjectById (id, slide) {
            const {
                objects
            } = this.$store.state.presentation.slides[slide];
            let res = false;

            objects.map(function (object) {
                if (object.id === id) {
                    res = object;
                }
            });

            return res;
        },

        calcThemeStyles () {
            return this.componentStyles = {
                '.workspace .canvas': {
                    background: this.getColor('background')
                },
                '.header': {
                    background: this.getColor('headerBg')
                }
            };
        },

        sendPresentationToRenderer (callback) {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', 'http://localhost:8086/save', true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            const pres = JSON.stringify(
                _.cloneDeepWith(this.$store.state.presentation, function (val, key) {
                    if (key === 'vueObj') {
                        return {};
                    }
                })
            );
            xhr.send(pres);
            xhr.onreadystatechange = () => {
                if ((xhr.readyState === 4) && (xhr.status === 200)) {
                    const json = JSON.parse(xhr.responseText);
                    callback();
                }
            };
        },

        play () {
            this.isFullscreen = true;
            this.$store.commit('setActiveSlide', 0);
            this.fullscreen($('.canvas')[0]);
        },

        playLocal () {
            this.sendPresentationToRenderer(() => window.open('http://localhost:3000/viewer.html'));
        },

        validateJson (json) {
            json = this.jsonConverter.actualizeJsonSchema(json, this.$store);
            const a = new ajv();
            const valid = a.validate(presentationSchema, json);

            a.errors;

            if (!valid) {
                if (debug) {
                    const errorText = 'Invalid presentation data';
                    this.showError(errorText);
                }
                this.$store.state.sentry?.captureException(a.errors);
            }
            // return false
            return json;
        }, //valid

        logUserAction (from, action) {
            const log = {
                from,
                action,
                mouseCoords: _.cloneDeep(this.rawCoords),
                mousePressed: _.cloneDeep(this.mousePressed),
                keyPressed: _.cloneDeep(this.keyPressed),
                time: Date.now()
            };
            this.$store.commit('logUserAction', log);
        },
        toggleRulers () {
            this.showRulers = !this.showRulers;
            this.$nextTick(() => this.resize());
        },
        toggleGuides () {
            this.showGuides = !this.showGuides;
        },
        //DEPRECATE
        actualizeMedia () {
            // console.log 'actualizeMedia'
            // @$refs.propPanel.$refs.mediaBrowser.actualizeMedia()
        },
        dropMedia (e) {
            if (e.type === 'dragover') {
                this.droppingMedia = true;
            }
            if ((e.type === 'drop') && (e.dataTransfer.files[0] != null)) {
                this.droppingMedia = false;
                if (this.supportedMedia.indexOf(e.dataTransfer.files[0].type) === -1) {
                    this.showError('File type is not supported');
                    return;
                }
                this.menuTarget = 'dropMedia';
                this.droppedFile = e.dataTransfer.files[0];
                this.showContextMenu(e);
            }
        },
        dropMediaBackground () {
            this.metaTool.editingObjects = [];
            this.metaTool.drawSelectedSpace();
            this.$refs.propPanel.$refs.mediaBrowser.dropFile(this.droppedFile);
        },
        dropMediaNewObj () {
            this.metaTool.editingObjects = [];
            this.metaTool.drawSelectedSpace();
            const {
                propPanel
            } = this.$refs;
            propPanel.newImage = true;
            const {
                mediaBrowser
            } = propPanel.$refs;
            mediaBrowser.dropFile(this.droppedFile);
        },
        handleMenuEvent (evt) {
            // console.log evt
            switch(evt) {
                case 'share':
                    this.shareLinkActive = true;
                    break;

                case 'pdf':
                    this.exportToPdf();
                    break;

                case 'save':
                    this.saveSlides();
                    break;

                case 'undo':
                    this.metaTool.undo();
                    break;

                case 'redo':
                    this.metaTool.redo();
                    break;

                case 'cut':
                    if (window.getSelection().type !== 'None') {
                        document.execCommand('cut');
                    } else {
                        if (this.editingObjects.length > 0) {
                            this.metaTool.cutObjects();
                        }
                    }
                    break;

                case 'copy':
                    if (window.getSelection().type !== 'None') {
                        document.execCommand('copy');
                    } else {
                        if (this.editingObjects.length > 0) {
                            this.metaTool.copyObjects();
                        }
                    }
                    break;

                case 'paste':
                    if (window.getSelection().type !== 'None') {
                        document.execCommand('paste');
                    } else {
                        this.metaTool.pasteObjects();
                    }
                    break;

                case 'selectAll':
                    if (window.getSelection().type !== 'None') {
                        document.execCommand('selectAll');
                    } else {
                        this.metaTool.selectAll();
                    }
                    break;

                case 'group':
                    this.metaTool.groupObjects();
                    break;

                case 'ungroup':
                    this.metaTool.ungroupObjects();
                    break;

                case 'transitions':
                    this.showTransitions = true;
                    break;

                case 'rulers':
                    this.toggleRulers();
                    break;

                case 'scale100':
                    this.$refs.propScale.scale100();
                    break;

                case 'fitCanvas':
                    this.$refs.propScale.fitScreen();
                    break;
            }

        },
        checkWhatsNewConditions () {
            let showWhatsNew = false;
            const userVersion = localStorage.getItem('slidelab_version');

            if (typeof userVersion === 'string') {
                const userVersionArr = userVersion.split('.');
                const versionArr = this.version.split('.');

                if (
                    versionArr[0] > userVersionArr[0]
                    || (versionArr[0] === userVersionArr[0] && versionArr[1] > userVersionArr[1])
                ) {
                    showWhatsNew = true;
                }
            } else {
                showWhatsNew = true;
            }


            if (showWhatsNew) {
                localStorage.setItem('slidelab_version', this.version);

                const name = `${this.$t('newInVersion')} ${this.version}`;
                const text = this.whatsNew;
                this.showDialog({ text, name, type: ProcessMsgType.LIST });
            }
        },
        showDialog (data) {
            this.processMessage.on = true;
            this.processMessage.ok = true;
            this.processMessage.text = data?.text || '';
            this.processMessage.name = data?.name || '';
            this.processMessage.type = data?.type || null;
        },

        showNotWebAvailibleDialog (text) {
            if ((text == null)) {
                if (this.installer.osName && this.installer.url) {
                    text = `Download <a href='${this.installer.url}'>SlideLab app for ${this.installer.osName}</a> to use this feature`;
                } else {
                    text = 'Unfortunately, this feature is unavailible for your system';
                }
            }
            const name = 'Unavailable in web version (browser restrictions)';
            this.showDialog({ text, name });
        },
        showHotkeysNotWebAvailibleDialog () {
            let text = '<span class=\'dialog-text\'>Use hotkeys instead</span>';
            text += '<table class=\'dialog-table\' rules=\'rows\' cellpadding=\'10px\' cellspacing=\'20px\' width=\'100%\'><tr><th>Ctrl+C</th><th>Ctrl+V</th><th>Ctrl+X</th></tr><tr><td>to copy</td><td>to paste</td><td>to cut</td></tr></table>';
            this.showNotWebAvailibleDialog(text);
        },
        showProjectSettings () {
            this.metaTool.clearState();
            this.$refs.propPanel.showProjectSettings = true;
        },
        // Little hack for handling mouseclick
        // outside actual presentation position
        // (when scale > 1)
        canvasmousedown (e) {
            this.$refs.presentation?.onmousedown(e);
        },
        getSettings () {
            let settings;
            const settingsStr = localStorage.getItem('settings');
            try {
                settings = JSON.parse(settingsStr);
            } catch (e) {
            }

            return settings;
        },
        getSetting (key) {
            const settings = this.getSettings();

            return settings?.[key];
        },

        setSetting (key, val) {
            let settings = this.getSettings();
            if (settings == null) {
                settings = {};
            }
            settings[key] = val;
            const settingsStr = JSON.stringify(settings);
            localStorage.setItem('settings', settingsStr);
        },

        getRulersSettings () {
            let setting = this.getSetting('showRulers');
            if (setting == null) {
                setting = this.$store.state.defaultSettings.showRulers;
            }
            return setting;
        },

        getGuidesSettings () {
            let setting = this.getSetting('showGuides');
            if (setting == null) {
                setting = this.$store.state.defaultSettings.showGuides;
            }
            return setting;
        },

        preview (targets) {
            this.$store.commit('saveCopyToLocalStorage');
            this.animationPreview = {
                slide: this.$store.getters.currentActiveSlide,
                objects: []
            };

            if (targets) {
                if (Array.isArray(targets)) {
                    this.animationPreview.objects = targets.map(t => t.id);
                } else {
                    this.animationPreview.objects = [targets.id];
                }
            }

            this.showAnimations = true;
        },

        closePreview () {
            this.showAnimations = false;
            return this.animationPreview = false;
        },

        checkBgEditingCondition () {
            const {
                flags
            } = this.$store.state;
            if (flags.bgEditingMode) {
                flags.bgEditingMode = false;
            }
        },

        // Дублируется в Account
        async getUserData () {
            const user = await UserSingleton.getInstance();

            if (user) {
                this.user.setUserData(user);
            }
        },
        async getSlideTemplates () {
            const slideTemplatesArray = await apiPost('/api/v1/slide_template');

            if (slideTemplatesArray && Array.isArray(slideTemplatesArray)) {
                slideTemplatesArray.forEach(async slideTemplate => {
                    const zip = await JSZip.loadAsync(slideTemplate.body, { base64: true });
                    const str = await zip.file(Object.keys(zip.files)[0]).async('string');
                    let vjson;
                    const p = JSON.parse(str);
                    if (p != null) {
                        vjson = this.validateJson(p);
                    }
                    delete vjson.animation;
                    delete vjson.editingObjectsIds;
                    delete vjson.guides;
                    delete vjson.lastId;
                    delete vjson.theme;
                    delete vjson.timeStamp;
                    vjson.slides = [vjson.slides[0]];

                    vjson.slides[0].objects.forEach(object => {
                        const wRatio = this.presentation.size.w / vjson.size.w;
                        const hRatio = this.presentation.size.h / vjson.size.h;

                        object.coords[0].x = Math.round(object.coords[0].x * wRatio);
                        object.coords[1].x = Math.round(object.coords[1].x * wRatio);
                        object.coords[0].y = Math.round(object.coords[0].y * hRatio);
                        object.coords[1].y = Math.round(object.coords[1].y * hRatio);
                    });

                    vjson.size = _.clone(this.presentation.size);

                    this.$store.state.slideTemplates.push(vjson);
                });
            }
        }
    },
    async created () {
        await this.loadThemes();
        await this.loadSlides();
    },
    mounted () {
        document.body.classList.add('editor-body', 'notranslate');
        const that = this;
        if (typeof Sentry !== 'undefined' && Sentry !== null) {
            this.$store.state.sentry = Sentry;
        }

        this.calcScale();
        this.themeStylesCss();

        // Проверяем фокус страницы
        setInterval(() => {
            if (this.presentation) {
                if (document.hasFocus() && this.windowOutOfFocus && !this.developMode) {
                    this.$store.commit('checkPresentationRelevance');
                    this.windowOutOfFocus = false;
                }

                if (!document.hasFocus()) {
                    this.windowOutOfFocus = true;
                    this.keyPressed = false;
                }
            }
        }, 1000);

        // Обработчики событий
        // Оставить здесь, ибо иначе не будут работать
        // события за пределами окна браузера

        // Клавиатура
        document.addEventListener(
            'keydown',
            function (e) {
                // console.log e.keyCode
                // Корректно обрабатываем таб
                // Не отправляем его в систему событий браузера
                // (чтобы курсор не прыгал по объектам),
                // но добавляем его в текст в виде пробела
                // console.log e.keyCode

                // Чтобы alt не фокусил меню
                let multiplier;
                if (e.keyCode === 18) {
                    e.preventDefault();
                }

                // Чтобы alt со стрелками не переходил по истории
                if (((e.keyCode === 37) || (e.keyCode === 39)) && e.altKey) {
                    e.preventDefault();
                }

                /* REMOTE CLICKER EVENTS */

                if (that.isFullscreen) {
                    switch(e.keyCode) {
                        // PageUp
                        case 34:
                            that.$refs.animation_controller.next();
                            break;
                        // PageDown
                        case 33:
                            that.$refs.animation_controller.prev();
                            break;
                        // Space, ArrowLeft, ArrBottom
                        case 32:
                        case 39:
                        case 40:
                            that.$refs.animation_controller.next();
                            break;
                        // BackSpace, ArrowRight, ArrTop
                        case 8:
                        case 37:
                        case 38:
                            that.$refs.animation_controller.prev();
                            break;
                        // ,
                        case 188:
                            that.$refs.animation_controller.gotoFrame(0);
                            break;
                        // .
                        case 190:
                            that.$refs.animation_controller.gotoLastSlide();
                            break;
                    }
                    return;
                }

                /* END OF REMOTE CLICKER EVENTS */

                /* OPACITY HANDLER */

                if (48 <= e.keyCode && e.keyCode <= 57) {
                    let v = e.keyCode - 48;
                    if (v === 0) {
                        v = 10;
                    }
                    const { propPanel } = that.$refs;

                    if (Object.keys(propPanel.styleProps).includes('opacity')) {
                        propPanel.updateProp('opacity', v / 10);
                        that.$store.commit('saveHistory', 'opacity key');
                    }
                }

                /* END OF OPACITY HANDLER */

                if (e.keyCode === 116) {
                    e.preventDefault();
                    that.play();
                }

                if (e.shiftKey) {
                    multiplier = 10;
                } else {
                    multiplier = 1;
                }

                // ArrLeft
                if (e.keyCode === 37) {
                    that.metaTool.moveSelected(-1 * multiplier, 0);
                }

                // ArrRight
                if (e.keyCode === 39) {
                    that.metaTool.moveSelected(1 * multiplier, 0);
                }

                // ArrTop
                if (e.keyCode === 38) {
                    that.metaTool.moveSelected(0, -1 * multiplier);
                }

                // ArrBottom
                if (e.keyCode === 40) {
                    that.metaTool.moveSelected(0, 1 * multiplier);
                }

                // Ctrl+0
                if ((e.keyCode === 48) && (e.metaKey || e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault(); // чтобы не срабатывали шорткаты браузера, переключающие вкладки
                    that.fitScreen();
                }
                // Ctrl+1
                if ((e.keyCode === 49) && (e.metaKey || e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault(); // чтобы не срабатывали шорткаты браузера, переключающие вкладки
                    that.setScale(100);
                    that.navigate = {
                        tx: 0,
                        ty: 0
                    };
                }
                // Ctrl +
                if (((e.keyCode === 187) || (e.keyCode === 107)) && (e.metaKey || e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    that.setScale(that.scale * 120);
                }
                // Ctrl -
                if (((e.keyCode === 189) || (e.keyCode === 109)) && (e.metaKey || e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    that.setScale(Math.round(that.scale * 80));
                }
                // Ctrl A
                if ((e.keyCode === 65) && (e.metaKey || e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                    that.metaTool.selectAll();
                }
                // Tab
                if (e.keyCode === 9) {
                    e.preventDefault();
                    // if that.editingText?
                    //   that.editingText.insertText(' ')
                    return;
                }
                // Esc, Enter
                if ((e.keyCode === 27) || (e.keyCode === 13)) {
                    if (that.$store.state.flags.bgEditingMode) {
                        that.$store.state.flags.bgEditingMode = false;
                    }
                    if (that.$store.state.flags.screenshotGeneratorMode) {
                        that.clearSlideshot();
                    }
                }

                if ((e.keyCode === 32) &&
                    (window.getSelection().type === 'None')) {
                    that.spacePressed = true;
                }
                // Не генерируем событие, если оно пришло от инпута
                if ($(e.target).attr('contenteditable') === 'true') {
                    return;
                }
                if (that.keyPressed === false) {
                    that.keyPressed = [];
                }
                // При зажатой клавише события генерятся постоянно
                // Чтобы не дублировать коды клавиш в массиве:
                if (_.indexOf(that.keyPressed, e.keyCode) === -1) {
                    that.keyPressed.push(e.keyCode);
                }
                // console.log that.keyPressed
            });
        document.addEventListener(
            'keyup',
            function (e) {
                if ((that.keyPressed == null) || (that.keyPressed === false)) {
                    return;
                }
                const position = _.indexOf(that.keyPressed, e.keyCode);
                that.keyPressed.splice(position, 1);
                if (e.keyCode === 32) {
                    that.spacePressed = false;
                }
                if (that.keyPressed.length === 0) {
                    that.keyPressed = false;
                }
            });

        // Мышь
        window.addEventListener('mousedown', (e) => {
            if (this.slideshot2dContext) {
                e.preventDefault();
                return false;
            }
            if (e.which === 1) {
                // Необходимо перезаписывать объект целиком
                // для нормальной работы вотчеров
                // в случае превентов мышиных событий
                // вложенными компонентами
                // (вотчер не сработает на примитиве, если
                // он не поменялся, перезапись объекта вызывает
                // реактивность даже если поля объектов идентичны)
                this.mousePressed = {
                    left: true,
                    right: this.mousePressed.right
                };

                // Для снятия выделения с текстовых элементов
                // ПЕРЕДЕЛАТЬ К ХЕРАМ!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                // Для текста в таблицах не будет нормально работать редактирование
                if (this.blockMetatool && this.metaTool.tableEditing) {
                    this.blockMetatool = false;
                }
            }

            // this.$store.commit('saveHistory')
            // this.$root.editingText = undefined
            if (e.which === 3) {
                this.mousePressed = {
                    left: this.mousePressed.left,
                    right: true
                };
            }

            if ((_.indexOf(this.keyPressed, 32) !== -1) && window.getSelection().type === 'None') {
                this.allowWorkspaceMoving = true;
            }
            this.mouseEvent = e;
        });

        window.addEventListener('mouseup', (e) => {
            this.blockMetatoolHandlers = false;
            if (this.slideshot2dContext) {
                if (e.which === 1) {
                    this.pickedColor = _.clone(this.pickedColorCandidate);
                }
                if (e.which === 3) {
                    this.pickedColor = undefined;
                }
                e.preventDefault();
                return false;
            }

            // Необходимо перезаписывать объект целиком
            // для нормальной работы вотчеров
            // в случае превентов мышиных событий
            // вложенными компонентами
            // (вотчер не сработает на примитиве, если
            // он не поменялся, перезапись объекта вызывает
            // реактивность даже если поля объектов идентичны)
            if (e.which === 1) {
                this.mousePressed = {
                    left: false,
                    right: this.mousePressed.right
                };
                // Если событие сработало за пределами активного текста,
                // нужно для отработки алгоритма сбора данных текущего выделения
                if (this.editingText != null) {
                    if (this.editingText.mousepressed) {
                        this.editingText.mouseup(e);
                    }
                }
            }

            if (e.which === 3) {
                this.mousePressed = {
                    left: this.mousePressed.left,
                    right: false
                };
            }

            this.allowWorkspaceMoving = false;
        });

        window.addEventListener('mousemove', (e) => {
            this.rawCoords.x = e.clientX;
            this.rawCoords.y = e.clientY;
        });

        // Ресайз окна
        window.addEventListener('resize', (e) => {
            this.resize();
        });

        this.presentationId = this.$store.state.presentationId;
        this.isTemplate = localStorage.getItem('isTemplate');
        // @$store.commit('setPresentation', {})
        // if developPresentation?
        //   @$store.commit('setPresentation', developPresentation)
        localStorage.setItem('developMode', this.developMode);
        // @getStorageUrl()
        // @loadSlides(@developMode)

        // Отлов ошибок
        window.onerror = function (msg, url, lineNo, columnNo, error) {
            // console.log(msg);
            return false;
        };
        window.addEventListener('online', () => {
            this.isOnline = true;
            return true;
        });
        window.addEventListener('offline', () => {
            this.isOnline = false;
            return false;
        });

        // Сохранение презы при выходе курсора за пределы окна
        document.addEventListener('mouseleave', () => {
            return this.saveSlides().catch((e) => {
                return; // console.log('cannot save');
            });
        });
    }
};
</script>

<style lang="scss">
@import "../assets/scss/editor/index.scss";
//@import "../assets/css/editor.css";
@import "../components/editor/spectrum/spectrum.css";
</style>

<style scoped lang="scss">
.header {
    &__back-btn {
        width: 22px;
        height: 24px;
        display: flex;
        cursor: pointer;
        position: relative;
        align-items: center;
        justify-content: center;

        svg {
            opacity: 0.5;
            margin: 0 auto;
        }

        &:hover svg {
            opacity: 1;
        }
    }

    &__nav {
        position: absolute;
        display: block;
        top: 16px;
        right: 0;
    }
}
</style>

<i18n>
{
    "en": {
        "exit": "Exit",
        "present": "Present",
        "loading": "Loading...",
        "exportToPdf": "Export to PDF",
        "showHideLog": "Show/hide log",
        "newInVersion": "What's new in version"
    },
    "ru": {
        "exit": "Выход",
        "present": "Презентация",
        "loading": "Загрузка...",
        "exportToPdf": "Экспорт в PDF",
        "showHideLog": "Показать/скрыть логи",
        "newInVersion": "Что нового в версии"
    }
}
</i18n>
