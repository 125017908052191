export default {
    'x': {
        name: 'x',
        title: 'X',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'y': {
        name: 'y',
        title: 'Y',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'x1': {
        name: 'x1',
        title: 'X1',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'y1': {
        name: 'y1',
        title: 'Y1',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'x2': {
        name: 'x2',
        title: 'X2',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'y2': {
        name: 'y2',
        title: 'Y2',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'z': {
        name: 'z',
        title: 'Order',
        type: 'prop_order'
    },
    'width': {
        name: 'width',
        title: 'Width',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'height': {
        name: 'height',
        title: 'Height',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'length': {
        name: 'length',
        title: 'Length',
        type: 'prop_number',
        step: 1,
        units: 'px'
    },
    'rotate': {
        name: 'rotate',
        title: 'Rotate',
        type: 'prop_number',
        min: -360,
        max: 360,
        units: '°'
    },
    'background-color': {
        name: 'background-color',
        title: 'Color',
        type: 'prop_color'
    },
    'table-background-color': {
        name: 'table-background-color',
        title: 'Color',
        type: 'prop_color'
    },
    'background-image': { // свойство слайда
        name: 'background-image',
        title: 'Image',
        type: 'prop_image'
    },
    'image-opacity': { // свойство слайда
        name: 'image-opacity',
        title: 'Opacity',
        type: 'prop_number',
        min: 0,
        max: 1,
        step: 0.1,
        k: 100, // множитель отображаемого значения
        units: '%'
    },
    'image-boxing': { // свойство слайда
        name: 'image-boxing',
        title: 'Boxing',
        type: 'prop_checkbox'
    },
    'image-fill-mode': {
        name: 'image-fill-mode',
        title: 'Fill mode',
        type: 'prop_select',
        options: {
            stretch: 'Stretch',
            cover: 'Cover',
            tile: 'Tile',
            custom: 'Custom'
        },
        default: 'cover'
    },
    'image-scale': {
        name: 'image-scale',
        title: 'Scale',
        type: 'prop_number',
        min: 0,
        max: 1,
        step: 0.1,
        k: 100,
        units: '%'
    },
    'image-custom-scale': {
        name: 'image-custom-scale',
        title: 'Scale',
        type: 'prop_number',
        min: 1,
        step: 0.1,
        k: 100,
        units: '%'
    },
    'image-shift-y': {
        name: 'image-shift-y',
        title: 'Y',
        type: 'prop_number',
        min: -1,
        max: 1,
        step: 0.1,
        k: 100,
        units: '%'
    },
    'image-shift-x': {
        name: 'image-shift-x',
        title: 'X',
        type: 'prop_number',
        min: -1,
        max: 1,
        step: 0.1,
        k: 100,
        units: '%'
    },
    'background-video': { // свойство слайда
        name: 'background-video',
        title: 'Video',
        type: 'prop_video'
    },
    'video-opacity': { // свойство слайда
        name: 'video-opacity',
        title: 'Opacity',
        type: 'prop_number',
        min: 0,
        max: 1,
        step: 0.1,
        k: 100
    },
    'background-iframe': { // свойство слайда
        name: 'background-image',
        title: 'URL',
        type: 'prop_base'
    },
    'iframe-opacity': { // свойство слайда
        name: 'image-opacity',
        title: 'Opacity',
        type: 'prop_number',
        min: 0,
        max: 1,
        step: 0.1,
        k: 100
    },
    'stroke-color': {
        name: 'stroke-color',
        title: 'Color',
        type: 'prop_color'
    },
    'table-stroke-color': {
        name: 'table-stroke-color',
        title: 'Color',
        type: 'prop_color'
    },
    'stroke-width': {
        name: 'stroke-width',
        title: 'Weight',
        type: 'prop_number',
        min: 0,
        max: 500,
        step: 1,
        units: 'px'
    },
    'table-stroke-width': {
        name: 'table-stroke-width',
        title: 'Weight',
        type: 'prop_number',
        min: 0,
        step: 1,
        units: 'px'
    },
    'stroke-cap': {
        name: 'stroke-cap',
        title: 'Caps',
        type: 'prop_caps'
    },
    // options:['butt', 'square', 'round']
    'stroke-join': {
        name: 'stroke-join',
        title: 'Corners',
        type: 'prop_select',
        options: ['miter', 'round', 'bevel']
    },
    'stroke-dash': {
        name: 'stroke-dash',
        title: 'Dash Gap',
        type: 'prop_dash'
    },
    // units:'px'
    'table-stroke-dash': {
        name: 'table-stroke-dash',
        title: 'Dash Gap',
        type: 'prop_dash'
    },
    // units:'px'
    'table-borders': {
        name: 'table-borders',
        title: '',
        type: 'prop_table_borders'
    },
    // units:'px'
    'corner-radius': {
        name: 'corner-radius',
        title: 'Corner radius',
        type: 'prop_corners',
        min: 0,
        step: 1
    },
    // units:'px'
    'gradient': {
        name: 'gradient',
        title: 'Gradient',
        type: 'prop_gradient'
    },
    'color-mode': {
        name: 'color-mode',
        title: 'Color',
        type: 'prop_color_mode'
    },
    'align': {
        name: 'align',
        title: '',
        type: 'prop_align'
    },
    'list-type': {
        name: 'list-type',
        title: 'List',
        type: 'prop_list',
        default: 'none'
    },
    'ul': {
        icon: '<svg width="16" height="14" xmlns="http://www.w3.org/2000/svg" style="top:2px; left:2px;"><g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M4 1h12M4 7h12M4 13h12M0 13h2M0 7h2M0 1h2"/></g></svg>',
        name: 'ul',
        title: '',
        type: 'prop_list_select',
        options: {
            '●': '●',
            '○': '○',
            '■': '■',
            '□': '□',
            '◆': '◆',
            '◇': '◇'
        },
        default: '●',
        maxlevel: 5
    },
    'ol': {
        icon: '<svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" style="top:-1px;"><g fill="none" fill-rule="evenodd"><path d="M7 4h12M7 10h12M7 16h12" stroke="#FFF" stroke-width="2"/><path d="M3.442.904V6H2.034V2.66a4.28 4.28 0 0 1-.662.421c-.213.107-.48.21-.801.308V2.247c.474-.153.842-.336 1.104-.55.262-.214.467-.479.615-.793h1.152zM4.361 12H.185c.047-.412.193-.8.435-1.164.243-.363.698-.792 1.366-1.287.408-.303.669-.533.783-.69.113-.157.17-.307.17-.448a.523.523 0 0 0-.169-.391.59.59 0 0 0-.425-.163.593.593 0 0 0-.436.168c-.113.112-.189.309-.227.591L.287 8.503c.055-.392.155-.697.3-.917.147-.22.352-.389.618-.506.265-.118.633-.176 1.102-.176.49 0 .871.056 1.143.167.273.112.487.283.643.515.156.231.234.49.234.777 0 .306-.09.597-.268.875-.179.278-.504.584-.976.916-.28.194-.468.33-.562.407a5.15 5.15 0 0 0-.333.304H4.36V12zM1.637 14.438l-1.316-.235c.11-.42.32-.74.63-.964.312-.224.752-.335 1.322-.335.654 0 1.127.122 1.418.366.292.243.438.55.438.919 0 .216-.06.412-.178.588a1.51 1.51 0 0 1-.536.461c.193.048.341.104.444.168.166.102.296.237.388.405.092.167.138.367.138.6 0 .291-.076.571-.229.839a1.534 1.534 0 0 1-.66.618c-.287.145-.664.217-1.13.217-.456 0-.816-.053-1.08-.16a1.61 1.61 0 0 1-.649-.47 2.16 2.16 0 0 1-.39-.778l1.39-.184c.055.28.14.474.255.582a.615.615 0 0 0 .44.163.604.604 0 0 0 .466-.205c.124-.137.186-.32.186-.547 0-.233-.06-.413-.18-.54a.635.635 0 0 0-.487-.192c-.109 0-.26.028-.45.082l.071-.994c.077.011.138.017.181.017a.635.635 0 0 0 .456-.174.552.552 0 0 0 .183-.414.494.494 0 0 0-.136-.366.508.508 0 0 0-.376-.136.551.551 0 0 0-.4.148c-.103.1-.172.273-.209.521z" fill="#FFF"/></g></svg>',
        name: 'ol',
        title: '',
        type: 'prop_list_select',
        options: {
            'arabicPeriod': '1.',
            'arabicParenR': '1)',
            'romanUcPeriod': 'I.',
            'alphaUcPeriod': 'A.',
            'alphaLcParenR': 'a)',
            'alphaLcPeriod': 'a.',
            'romanLcPeriod': 'i.'
        },
        default: 'arabicPeriod',
        maxlevel: 5
    },
    'font-family': {
        name: 'font-family',
        title: 'Typeface',
        type: 'prop_font_family'
    },
    'font-size': {
        name: 'font-size',
        title: 'Size',
        type: 'prop_number_text',
        step: 1,
        min: 0,
        max: 999,
        units: 'px'
    },
    'line-height': {
        name: 'line-height',
        title: 'Line',
        type: 'prop_number_text',
        step: 1,
        min: 0,
        max: 999,
        units: 'px'
    },
    'font-style': {
        name: 'font-style',
        title: 'Style',
        type: 'prop_font_style'
    },
    'letter-spacing': {
        name: 'letter-spacing',
        title: 'Letter',
        type: 'prop_number_text',
        min: 0,
        max: 999,
        units: 'px'
    },
    'color': {
        name: 'color',
        title: 'Color',
        type: 'prop_color',
        mode: 'text',
        icon: '<svg width="15" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M9.15 9.566H4.023l-.898 2.09c-.221.515-.332.899-.332 1.153 0 .201.096.379.288.532.192.153.607.252 1.245.298V14H.156v-.361c.554-.098.912-.225 1.074-.381.333-.313.7-.947 1.104-1.904L6.992.455h.342l4.61 11.016c.37.885.707 1.46 1.01 1.723.303.264.724.412 1.265.445V14H8.994v-.361c.527-.026.884-.114 1.07-.264a.68.68 0 0 0 .278-.547c0-.286-.13-.739-.39-1.357L9.15 9.566zm-.273-.722L6.631 3.492 4.326 8.844h4.551z" fill="#FFF" fill-rule="evenodd"/></svg>'
    },
    'text-background': {
        name: 'text-background',
        title: 'Background',
        type: 'prop_color',
        mode: 'text',
        icon: '<svg width="18" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M13.558 8.94L4.618 0 3.204 1.415l2.38 2.38L.438 8.939a1.5 1.5 0 0 0 0 2.12l5.5 5.5c.29.295.674.44 1.06.44.384 0 .77-.145 1.06-.44l5.499-5.5a1.5 1.5 0 0 0 0-2.12zM2.204 10l4.794-4.79L11.793 10h-9.59zm13.794 1.5s-2 2.164-2 3.499a2 2 0 1 0 4 0c0-1.335-2-3.5-2-3.5z" fill="#FFF" fill-rule="nonzero"/></svg>'
    },
    'text-align': {
        name: 'text-align',
        title: 'Align',
        type: 'prop_text_align'
    },
    'padding': {
        name: 'paddings', // оставить во множественном числе!
        title: 'Padding',
        type: 'prop_padding',
        units: 'px'
    },
    'auto-height': {
        name: 'auto-height',
        title: 'Auto height',
        type: 'prop_checkbox'
    },
    'arrows': {
        name: 'arrows',
        title: '',
        type: 'prop_arrows',
        options: ['none', 'simpleArrow', 'openArrow', 'concaveArrow', 'rhombus', 'circle'],
        default: 'none'
    },
    'top-offset': {
        name: 'top-offset',
        title: 'Top shift',
        type: 'prop_number',
        min: 0,
        max: 100,
        step: 1,
        d: -50
    },
    'parallelogram-angle': {
        name: 'parallelogram-angle',
        title: 'Skew',
        type: 'prop_number',
        min: -90,
        max: 90,
        step: 1,
        units: '°'
    },
    'chevron-angle': {
        name: 'chevron-angle',
        title: 'Skew',
        type: 'prop_number',
        min: -90,
        max: 90,
        step: 1,
        units: '°'
    },
    'chevron-first': {
        name: 'chevron-first',
        title: 'Is first',
        type: 'prop_checkbox'
    },
    'arrow-angle': {
        name: 'arrow-angle',
        title: 'Angle',
        type: 'prop_number',
        min: 0,
        max: 90,
        step: 1,
        units: '°'
    },
    'arrow-thickness': {
        name: 'arrow-thickness',
        title: 'Thickness',
        type: 'prop_number',
        min: 0,
        max: 100,
        step: 1,
        units: '%'
    },
    'arrow-direction': {
        name: 'arrow-direction',
        title: 'Direction',
        type: 'prop_select',
        options: {
            north: 'North',
            east: 'East',
            south: 'South',
            west: 'West'
        },
        default: 'east'
    },
    'lock-aspect': {
        name: 'lock-aspect',
        title: 'Lock aspect',
        type: 'prop_checkbox'
    },
    'opacity': {
        name: 'opacity',
        title: 'Opacity',
        type: 'prop_number',
        min: 0,
        max: 1,
        step: 0.1,
        k: 100, // множитель отображаемого значения
        units: '%'
    },
    'animation-autoplay': {
        name: 'animation-autoplay',
        title: 'Autoplay',
        type: 'prop_checkbox'
    },
    'animation-autoplay-delay': {
        name: 'animation-autoplay-delay',
        title: 'in ',
        type: 'prop_number',
        min: 0,
        // max:3600000
        step: 1,
        units: 'ms'
    },
    'animation-effect': {
        name: 'animation-effect',
        title: 'Effect',
        type: 'prop_select',
        options: {
            none: 'None',
            right: 'From right',
            left: 'From left',
            top: 'From top',
            bottom: 'From bottom',
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
            fade: 'Fade'
        },
        default: 'right'
    },
    'animation-easing': {
        name: 'animation-easing',
        title: 'Easing',
        type: 'prop_select',
        options: {
            none: 'None',
            easeIn: 'EaseIn',
            easeOut: 'EaseOut',
            easeInOut: 'EaseInOut'
        },
        default: 'easeInOut'
    },
    'animation-duration': {
        name: 'animation-duration',
        title: 'Duration(ms)',
        type: 'prop_number',
        min: 0,
        max: 5000,
        step: 1
    },
    'animation-order': {
        name: 'animation-order',
        title: 'Order',
        type: 'prop_select'
    },
    'table-fill-template': {
        name: 'table-fill-template',
        title: '',
        type: 'prop_table_template'
    },
    'table-bg1-color': {
        name: 'table-bg1-color',
        title: 'Even',
        type: 'prop_color'
    },
    'table-bg2-color': {
        name: 'table-bg2-color',
        title: 'Odd',
        type: 'prop_color'
    },
    'table-acc-color': {
        name: 'table-acc-color',
        title: 'Accent',
        type: 'prop_color'
    },
    'seo-header': {
        name: 'seo-header',
        title: 'Use as header',
        type: 'prop_checkbox'
    },
    'seo-text': {
        name: 'seo-text',
        title: 'Use as text',
        type: 'prop_checkbox'
    },
    'seo-image': {
        name: 'seo-image',
        title: 'Use image',
        type: 'prop_checkbox'
    }
};
