<template>
  <div class="slide" :style="{opacity: + active}" :class="{'slide_active': active}">
    <div
      class="slide__content-trimmer"
      :style="contentTrimmerStyles"
      :class="{'slide__content-trimmer_transparent': flags.screenshotGeneratorMode}">
      <div class="slide__content" :style="contentShift" ref="slideContent">
        <component
          v-for="(obj, index) in objects"
          :is="obj.type + '_component'"
          :key="obj.id"
          :opts="obj"
          :index="index"
          :slideindex="slideIndex"
          :zi="index"
          :customMedia="customMedia">
        </component>

        <table_component
          v-if="shadowTable && !currentPage.isRenderer"
          :opts="shadowTable"
          :index="-1"
          :slideindex="slideIndex"
          :zi="-1">
        </table_component>
      </div>
    </div>
    <div
      class="slide__content-trimmer slide__content-trimmer_clone"
      :style="contentTrimmerStyles"
      v-show="flags.screenshotGeneratorMode"
      ref="slideContentCloneWrapper">
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Line from "@/components/editor/SlideComponents/Line";
import Arrow from "@/components/editor/SlideComponents/Arrow";
import Table from "@/components/editor/SlideComponents/Table";
import Circle from "@/components/editor/SlideComponents/Circle";
import Rhombus from "@/components/editor/SlideComponents/Rhombus";
import Chevron from "@/components/editor/SlideComponents/Chevron";
import Triangle from "@/components/editor/SlideComponents/Triangle";
import Rectangle from "@/components/editor/SlideComponents/Rectangle";
import Parallelogram from "@/components/editor/SlideComponents/Parallelogram";

export default {
  name: "SlideComponent",
  inject: ['currentPage'],
  props: ['slideContent', 'slideIndex', 'scale', 'active', 'contentTrimmerStyles', 'bgAndContentDifference', 'customMedia'],
  components: {
    circle_component: Circle,
    rectangle_component: Rectangle,
    rhombus_component: Rhombus,
    triangle_component: Triangle,
    parallelogram_component: Parallelogram,
    chevron_component: Chevron,
    arrow_component: Arrow,
    line_component: Line,

    table_component: Table
  },
  data() {
    return {
      html: '',
      shadowTable: false,
      isSlide: true,
    };
  },
  watch: {
    finished() {
      if (this.finished) {
        this.$emit('finishedAnimation');
      } else {
        this.$emit('startedAnimation');
      }
    },
    'flags.screenshotGeneratorMode'(val) {
      if (this.active) {
        if (val) {
          this.getClonedDOM();
        } else {
          this.clearClonedDOM();
        }
      }
    }
  },
  computed: {
    flags() {
      return this.$store.state.flags;
    },
    opts() {
      return {
        index: this.slideIndex,
        vue: this
      };
    },
    preview() {
      return this.html;
    },
    objects() {
      return this.slideContent.objects;
    },
    contentShift() {
      if (this.currentPage.isRenderer || this.isFake) {
        return {
          top: '0px',
          left: '0px'
        };
      }
      return {
        top: `calc(0px - ${this.contentTrimmerStyles.top})`,
        left: `calc(0px - ${this.contentTrimmerStyles.left})`
      };
    },
    isFake() {
      return this.slideIndex == null;
    }
  },
  mounted() {
    this.html = this.$el;
    if (this.isFake) {
      return true;
    }

    this.updateVueLink();
  },
  updated() {
    if (this.isFake) {
      return true;
    }
    return this.updateVueLink();
  },
  methods: {
    updateVueLink() {
      if (this.opts.index != null) {
        this.$store.commit('setSlideVue', this.opts);
      }
    },
    drawShadowTable(opts) {
      opts.shadow = true;
      this.shadowTable = opts;
    },
    destroyShadowTable() {
      this.shadowTable = false;
    },
    getClonedDOM() {
      var clonedEl, el;
      el = this.$refs.slideContent;
      clonedEl = el.cloneNode(true);
      this.$refs.slideContentCloneWrapper.appendChild(clonedEl);
    },
    clearClonedDOM() {
      return this.$refs.slideContentCloneWrapper.innerHTML = '';
    }
  }
};
</script>

<style lang="scss">
.slide{
  position:absolute;
  display:block;
  top:0;
  right:0;
  bottom:0;
  left:0;
  box-sizing: border-box;
  pointer-events:none;

  &_active{
    pointer-events:auto;
    .curve{
      svg{
        &>*{
          pointer-events: all;
        }
      }
    }
  }

  &__content{
    position:absolute;
    display:block;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }

  &__content-trimmer{
    overflow: hidden;
    position: relative;
    &_transparent{
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }

  &__content-shifter{
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>
