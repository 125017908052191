
<script>
/* eslint-disable */
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Parallelogram_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'parallelogram'
    };
  },
  computed: {
    parallelogramPath: function() {
      var pc, res;
      pc = this.parallelogramCoords;
      res = "";
      pc.forEach(function(c, i) {
        var l;
        if (i === 0) {
          l = 'M';
        } else {
          l = 'L';
        }
        return res += `${l}${c.x} ${c.y}`;
      });
      res += "z";
      return res;
    },
    dx: function() {
      var angle, dx, negDx, posDx, rp;
      rp = this.rectParams;
      angle = this.styleProps['parallelogram-angle'];
      dx = Math.tan(this.deg2rad(angle)) * rp.height;
      posDx = Math.max(1, Math.min(dx, rp.width - 1));
      negDx = Math.min(1, Math.max(dx, -rp.width - 1));
      return {
        pos: posDx,
        neg: negDx
      };
    },
    parallelogramCoords: function() {
      var negDx, posDx, res, rp;
      rp = this.rectParams;
      posDx = this.dx.pos;
      negDx = this.dx.neg;
      res = [
        {
          x: rp.x + posDx,
          y: rp.y
        },
        {
          x: rp.width + negDx,
          y: rp.y
        },
        {
          x: rp.width - posDx,
          y: rp.height
        },
        {
          x: rp.x - negDx,
          y: rp.height
        }
      ];
      return res;
    },
    textClipMaskCoords: function() {
      return this.parallelogramCoords;
    },
    textLimiterParams: function() {
      var angle, dx, pc, res, rp, theAngle, w, wx;
      pc = this.parallelogramCoords;
      rp = this.rectParams;
      theAngle = this.styleProps['parallelogram-angle'];
      angle = 90 - this.styleProps['parallelogram-angle'];
      wx = Math.abs(pc[1].x - pc[0].x) - this.styleProps['stroke-width'];
      w = Math.sin(this.deg2rad(angle)) * wx;
      dx = (rp.width - w) / 2;
      res = {
        x: rp.x + dx,
        y: rp.y,
        height: rp.height,
        width: w
      };
      return res;
    },
    magnetCoordsDecart: function() {
      return [
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x + this.dx.pos + this.dx.neg) / 2 - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[1].x - this.centerCoords.x - this.dx.pos / 2 + this.dx.neg / 2,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        },
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x - this.dx.pos - this.dx.neg) / 2 - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x - this.centerCoords.x + this.dx.pos / 2 - this.dx.neg / 2,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
