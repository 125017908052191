<template>
    <div @mouseup.stop="" @mousedown.stop="" class="service-bar__share share-options"
         :class="{'service-bar__share_on': shareLink}">
        <p class="options-title">{{ $t('generalAccess') }}</p>

        <div class="share-switches-wrapper">
            <div class="share-switch">
                <span>{{ $t('byLink') }}</span>
                <HtmlSwitch :value="accessibleByLink" @input="() => toggleShareOption(accessType.BY_LINK)" />
            </div>

            <div class="share-switch">
                <span>{{ $t('forAll') }}</span>
                <HtmlSwitch :value="publicPresentation" @input="() => toggleShareOption(accessType.PUBLIC)" />
            </div>
        </div>

        <button
            v-if="shareLink"
            class="copy-share-link"
            @click.stop="copyShareLink()"
            :class="{'share-link-copied': linkCopyAnimation}"
        >
            <inline-svg width="20" height="20" :src="require('@/assets/icon/copy.svg')"/>
            <span>{{ $t('copyLink') }}</span>
        </button>

        <hr>

        <div v-if="shareLink" class="embed">
            <span class="embed__title">{{ $t('copyEmbed') }}</span>
            <div class="embed__size">
                <div class="input-wrapper">
                    <input type="number" value="100" disabled>
                    <span>{{ $t('width') }}</span>
                </div>
                <span class="delimeter">×</span>
                <div class="input-wrapper">
                    <input type="number" v-model="embed.height">
                    <span>{{ $t('height') }}</span>
                </div>
            </div>

            <button
                v-if="shareLink"
                class="copy-share-link mb-0"
                @click.stop="copyEmbed"
                :class="{'share-link-copied': linkEmbedAnimation}"
            >
                <inline-svg width="20" height="20" :src="require('@/assets/icon/copy.svg')"/>
                <span>{{ $t('copy') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { apiPost } from '@/models/Api';
import { isAccessibleByLink, isPublic, PresentationAccessTypes } from '@/enum/PresentationAccessTypes';

import HtmlSwitch from '@/components/BaseElements/HtmlSwitch';

export default {
    name: 'PresentationAccessOptions',
    components: { HtmlSwitch },
    data: () => ({
        access: 0,
        shareLink: '',
        linkCopyAnimation: false,
        linkEmbedAnimation: false,
        embed: {
            width: '100',
            height: 420
        },
        accessType: PresentationAccessTypes
    }),
    computed: {
        ...mapState({
            presentationId: state => state.presentationId
        }),
        publicPresentation () {
            return isPublic(this.access);
        },
        accessibleByLink () {
            return isAccessibleByLink(this.access);
        }
    },
    methods: {
        async getShareLink () {
            const res = await apiPost(`/api/v1/presentation/${this.presentationId}/public/url`, {}, false);

            this.access = res?.access || 0;
            this.shareLink = res?.url || '';
        },
        copyShareLink () {
            navigator.clipboard.writeText(this.shareLink);
            this.linkCopyAnimation = true;

            setTimeout(() => {
                this.linkCopyAnimation = false;
            }, 300);
        },
        async toggleShareOption (type) {
            const res = await apiPost(`/api/v1/presentation/${this.presentationId}/public`, {
                status: type,
                action: (this.access & type) ? 0 : 1
            });

            this.access = res?.access;
            this.shareLink = res?.url;
        },
        copyEmbed () {
            navigator.clipboard.writeText(`<iframe src="https://slide-lab.ru/presentation-embed/${this.$store.state.presentationId}/${this.embed.height}px" width="100%" height="${this.embed.height}px" frameborder="0"></iframe>`);
            this.linkEmbedAnimation = true;

            setTimeout(() => {
                this.linkEmbedAnimation = false;
            }, 300);
        }
    },
    created () {
        this.getShareLink();
    }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/rfs.scss';

.options-title {
    opacity: 0.75;
    font-size: 16px;
    margin: 0 0 7.5px;
}

.share-switches-wrapper {
    display: flex;
    font-size: 14px;
    position: relative;
    margin-bottom: 7.5px;
    align-items: flex-start;

    .share-switch {
        flex: 1;
        display: flex;
        align-items: center;

        span {
            opacity: .5;
            margin-right: 10px;
        }
    }
}

.copy-share-link {
    width: 100%;
    height: 32px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    background-color: transparent;
    transition: opacity, transform 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.5);

    svg {
        width: 20px;
        height: 20px;
        opacity: 0.5;
        display: block;
        position: relative;
        margin-right: 5px;
    }

    span {
        opacity: .5;
        color: #fff;
        font-size: 17px;
    }

    &.share-link-copied {
        transform: scale(1.075);
    }

    &:hover {
        svg, span {
            opacity: 0.75;
        }

        border: 1px solid rgba(255, 255, 255, 0.75);
    }
}

hr {
    opacity: 0.5;
    margin-bottom: 10px;
}

.embed {
    .embed__title {
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 8px;
        @include font-size(17px);
        color: rgba(255, 255, 255, 0.5);
    }

    .embed__size {
        display: flex;
        margin-bottom: 10px;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;

        .input-wrapper {
            width: 42%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        input {
            color: #fff;
            width: 100%;
            max-width: 100%;
            min-width: auto;
            min-height: 24px;
            line-height: 20px;
            padding: 1px 10px;
            border-radius: 3px;
            text-align: center;
            background: #333638;
            margin-bottom: 3.5px;
            @include font-size(14px);
            border: 1px solid #404447;
            color: rgba(255, 255, 255, 0.5);

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        .delimeter {
            color: #fff;
            opacity: 0.5;
            @include font-size(20px);
        }

        span {
            color: #fff;
            opacity: 0.5;
            @include font-size(12px);
        }
    }
}
</style>

<i18n>
{
    "en": {
        "share": "Share",
        "copy": "Copy",
        "width": "Width (%)",
        "height": "Height (px)",
        "forAll": "For All",
        "byLink": "By link",
        "copyLink": "Copy link",
        "copyEmbed": "Embed on site",
        "generalAccess": "General access:",
        "publicLink": "Public link"
    },
    "ru": {
        "share": "Поделиться",
        "copy": "Скопировать",
        "width": "Ширина (%)",
        "height": "Высота (px)",
        "forAll": "Для всех",
        "byLink": "По ссылке",
        "copyLink": "Скопировать ссылку",
        "copyEmbed": "Вставить на сайт",
        "generalAccess": "Общий доступ:",
        "publicLink": "Публичная ссылка"
    }
}
</i18n>
