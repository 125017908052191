<template>
  <div class="slide-bg" :style="bgStyles" :class="{'slide-bg_active': active}" :data-rdata="getRendererData()">
    <div class="slide-bg__color" :style="cssProps"></div>
    <div class="slide-bg__image" v-if="bgMode=='image'" :style="imageCssProps"></div>
    <div class="slide-bg__video" v-if="bgMode=='video'">
      <video loop="loop" :style="videoCssProps" :src="styles['background-video']"></video>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";

import Bg from "@/mixins/editor/Bg";
import Color from "@/mixins/editor/Color";
import {CurrentPage} from "@/models/CurrentPage";

export default {
  name: 'SlideBackground',
  props: ['active', 'bgIndex', 'styles', 'boxed', 'slideEars', 'customMedia'],
  mixins: [Color, Bg],
  data() {
    return {
      html: '',
      isBg: true
    };
  },
  computed: {
    media() {
      if (this.customMedia != null) {
        return this.customMedia;
      } else {
        return this.$store.state.presentation.media;
      }
    },
    opts() {
      return {
        index: this.bgIndex,
        vue: this
      };
    },
    preview() {
      return this.html;
    },
    bgMode() {
      return this.styles['background-mode'];
    },
    // взято из shape.coffee, вынести в миксин
    gradientParams() {
      var gradCss, gradient, styles;
      gradient = this.styleProps['gradient'];
      gradCss = '';
      switch (gradient.type) {
        case 'linear':
          gradCss += 'linear-gradient(';
          gradCss += gradient.angle + 'deg';
          break;
        case 'radial':
          gradCss += 'radial-gradient(at ';
          gradCss += gradient.center[0] + '% ' + gradient.center[1] + '%';
      }
      _.each(gradient.points, (point) => {
        gradCss += ', rgba(' + this.getValidColor(point.color) + ') ' + point.position + '%';
      });
      gradCss += ')';
      styles = {
        'background-image': gradCss,
        'width': '100%',
        'height': '100%'
      };
      //console.log styles
      return styles;
    },
    // набор данных, с которыми работает propPanel
    styleProps() {
      return Object.assign({}, this.styles, {
        'image-boxing': this.boxed,
        'is-slide-background': true
      });
    },
    // CSS стили отображения фона
    cssProps() {
      var bgc, color;
      if (this.styleProps['color-mode'] === 'solid') {
        bgc = this.styles['background-color'];
        color = this.getValidColor(bgc);
        return {
          'background-color': `rgba(${color})`
        };
      } else {
        return this.gradientParams;
      }
    },
    // CSS стили отображения фоновой картинки
    imageCssProps() {
      var bgImageFitStyles, bgImageStyles, bgUrl, bgi, excep, urlExceptions;
      // Внимание! Логика дублируется в scaledBg@animation_slide
      bgImageStyles = {};
      bgi = this.styles['background-image'];
      if (bgi > 0) {
        if (this.media[bgi] == null) {
          return {};
        }
        bgUrl = this.media[bgi].url;
        urlExceptions = ['http', 'data', 'blob'];
        excep = urlExceptions.some(function(u) {
          return bgUrl.indexOf(u) === 0;
        });
        if (!excep) {
          bgUrl = CurrentPage(this).storageUrl + bgUrl;
        }
        bgImageStyles['opacity'] = this.styles['image-opacity'];
        bgImageStyles['background-image'] = `url(${bgUrl})`;
      }
      bgImageFitStyles = this.fitBackgroundImage({
        fillMode: this.styles['image-fill-mode'],
        containerWidth: this.$parent.size.w,
        imgWidth: this.styles['background-image-width'],
        imageScale: this.styles['image-scale'],
        imageShiftX: this.styles['image-shift-x'],
        imageShiftY: this.styles['image-shift-y'],
        diff: !this.boxed ? this.$parent.bgAndContentDifference.x : 1
      });
      Object.assign(bgImageStyles, bgImageFitStyles);
      return bgImageStyles;
    },
    // CSS стили отображения фонового видео
    videoCssProps() {
      return {
        'opacity': this.styles['video-opacity']
      };
    },
    bgStyles() {
      return {
        opacity: +this.active
      };
    },
    dataForBgMixin() {
      var pSize;
      pSize = _.clone(this.$store.state.presentation.size);
      return {
        styles: this.styles,
        containerWidth: pSize.w,
        containerHeight: pSize.h
      };
    }
  },
  mounted() {
    this.html = this.$el;
    this.updateVueLink();
  },
  updated() {
    this.updateVueLink();
  },
  methods: {
    updateVueLink() {
      if (this.opts.index != null) {
        this.$store.commit('setBgVue', this.opts);
      }
    },
    checkDynamicMedia(v) {
      var isGif, last3CharsOfUrl, m, typeGif, urlGif;
      isGif = false;
      if (v == null) {
        v = this.styles['background-image'];
      }
      m = this.media[v];
      if (m == null) {
        return false;
      }
      last3CharsOfUrl = m.url.slice(-3);
      if (m.type) {
        typeGif = m.type.indexOf('gif') !== -1;
      }
      urlGif = last3CharsOfUrl.toLowerCase() === 'gif';
      isGif = typeGif || urlGif;
      return isGif;
    },
    setProperty(k, v) {
      if (k === 'background-image') {
        if (this.isWebgl) {
          const dynamic = this.checkDynamicMedia(v);
          if (dynamic) {
            const messageText = 'Gif images on slide background are incompatible with WebGL animation effects (with * symbol) due to performance issues. Slide animation has been reset to default';
            CurrentPage(this).showDialog(messageText, 'Slide animation conflict');
            this.$store.commit('resetAnimation');
          }
        }
      }
      if (k === 'image-boxing') {
        this.$store.state.presentation.boxed = v;
        return;
      }
      this.styles[k] = v;
    },
    getVjson() {
      var res, styles;
      styles = _.cloneDeepWith(this.styles, function(val, key) {
        if (key === 'vueObj') {
          return {};
        }
      });
      res = {
        colorStyles: _.cloneDeep(this.cssProps),
        styles
      };
      if (this.bgMode === 'image') {
        res.imageStyles = _.cloneDeep(this.imageCssProps);
        delete res.imageStyles['background-size'];
      }
      return res;
    },
    getRendererData() {
      var res, vjson;
      if (CurrentPage(this).isRenderer) {
        vjson = this.getVjson();
        res = {
          bg: vjson,
          animations: this.$store.state.presentation.slides[this.bgIndex].animations
        };
      } else {
        res = false;
      }
      return JSON.stringify(res);
    }
  }
};

</script>

<style lang="scss">
.slide-bg{
  overflow: hidden;
  &__screen{
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    &_top{
      transform: translateY(-100%);
    }
    &_bottom{
      transform: translateY(100%);
    }
    &-fragment{
      position: absolute;
      height: 100%;
      top: 0px;
      // background: #fff;
    }
  }
}
</style>
