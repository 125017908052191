<template>
  <div class="property property__type_video">
    <div class="property__name">{{ property.title }}
      <div class="property__btn__action_add" v-on:click.stop="showNav">+</div>
    </div>
    <input
        class="property__input"
        type="text"
        v-bind:name="property.title"
        v-bind:proppanel="proppanel"
        v-model="propValue"
        v-if="propMode=='insertLink'"
        v-on:keyup.enter="submit"
        v-on:blur="inputBlur"
        v-focus="v-focus"/>
    <div class="property__preview" v-on:click.stop="showNav">
      <video loop="loop" v-bind:src="propValue"></video>
    </div>
    <div class="context-menu property__menu" v-bind:class="{'context-menu_active':navActive}">
      <div class="context-menu__item" v-on:click="propMode='insertLink'">Insert link</div>
      <div class="context-menu__item">Upload video</div>
      <div class="context-menu__item">Choose from storage</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'prop_video',
  mixins: [PropBase],
  data: function() {
    return {};
  },
  computed: {
    propValue: {
      get: function() {
        var val;
        if (this.propset != null) {
          val = this.propset[this.property.name];
        }
        return val;
      },
      set: function(value) {
        this.proppanel.updateProp(this.propset, this.property.name, value);
        this.proppanel.updateProp(this.propset, 'background-mode', 'video');
      }
    }
  }
};
</script>

<style lang="scss">
.property__type_video{
  .property__preview{
    position:relative;
    display:block;
    cursor: pointer;

    &:hover{
      opacity: .8;
    }

    video{
      position:relative;
      display:block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .context-menu{
    top:auto;
    bottom:0;
  }
}
</style>
