/**
 * 0 - Непубличная презентация
 * 1 - Презентация доступна по ссылке
 * 2 - Презентация публичная для всех
 * 3 - Презентация публичная для всех и по ссылке
 * @type {{}}
 */
export const PresentationAccessTypes = {
    NON_PUBLIC: 0,
    BY_LINK: 1,
    PUBLIC: 2
};

export const isPublic = access => {
    return (access & PresentationAccessTypes.PUBLIC) === PresentationAccessTypes.PUBLIC;
};

export const isPrivate = access => {
    return access === 0;
};

export const isAccessibleByLink = access => {
    return (access & PresentationAccessTypes.BY_LINK) === PresentationAccessTypes.BY_LINK;
};
