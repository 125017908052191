/* eslint-disable */

import _ from "lodash";

import Ajax from "@/mixins/Ajax";
import Bg from "@/mixins/editor/Bg";
import Coords from "@/mixins/editor/Coords";
import ObjectMixin from "@/mixins/editor/Object";
import {CurrentPage} from "@/models/CurrentPage";

export default {
  name: 'Shape_mixin',
  props: ['opts', 'zi', 'index', 'slideindex', 'customMedia'],
  mixins: [
    ObjectMixin,
    Coords,
    Ajax,
    Bg
  ],
  data() {
    return {
      currentPage: CurrentPage(this),
      rotatable: true,
      textAvailable: true,
      showLinkDots: false,
      droppingMedia: false,
      noTransform: false,
      textSvg: ''
    };
  },
  render(createElement) {
    var defsTag, display, dropFn, dropMediaArea, height, imageDivBaseStyle, imageFO, linearGradientDefinition,
      mainGWrapper, paramsObj, placeholderBorder, radialGradientDefinition, ref, renderedTextG, rootEl, shapeBorder,
      shapeSelectedBorder, styleObject, svgElem, textBlock, textFO, textOnObj, textPlaceholderFO, width, x, y;
    // Gradient definition
    if (this.svgGradientParams) {
      if (this.svgGradientParams.linear) {
        linearGradientDefinition = createElement('linearGradient', {
          attrs: {
            'id': `grad${this.svgId}`,
            'gradientUnits': 'objectBoundingBox',
            'gradientTransform': this.svgGradientParams.rotate
          }
        }, this.gradientStopsGenerator(createElement));
      }
      if (this.svgGradientParams.radial) {
        radialGradientDefinition = createElement('radialGradient', {
          attrs: {
            'id': `grad${this.svgId}`,
            'gradientUnits': 'userSpaceOnUse',
            'gradientTransform': this.svgGradientParams.rotate,
            'cx': this.svgGradientParams.c.x,
            'cy': this.svgGradientParams.c.y,
            'r': this.svgGradientParams.c.r
          }
        }, this.gradientStopsGenerator(createElement));
      }
    }
    // endif @svgGradientParams

    // Defs tag
    defsTag = createElement('defs', {}, [
      createElement('clipPath',
        {
          attrs: {
            'id': `clip${this.svgId}`,
            'clipPathUnits': "userSpaceOnUse"
          }
        },
        [this.shapePathElementGenerator(createElement)])
    ]);
    // Image foreignObject
    if (this.opts.styleProps['background-image']) {
      switch (this.type) {
        case 'arrow':
        case 'chevron':
        case 'parallelogram':
        case 'rhombus':
        case 'triangle':
          styleObject = this.rectParams;
          width = this.innerRectSize.width;
          height = this.innerRectSize.height;
          break;
        case 'circle':
          styleObject = this.ellipParams;
          width = this.ellipParams.cx * 2;
          height = this.ellipParams.cy * 2;
          break;
        case 'rectangle':
          styleObject = this.rectParams;
          width = this.innerRectSize.width + this.border.width;
          height = this.innerRectSize.height + this.border.width;
      }
      // Styles has to be injected inline, XMLSerializer cannot apply styles from CSS
      imageDivBaseStyle = {
        'position': 'absolute',
        'display': 'block',
        'top': 0,
        'right': 0,
        'bottom': 0,
        'left': 0,
        'background-position': 'center'
      };
      imageFO = createElement('foreignObject', {
        style: styleObject.styles,
        attrs: {
          'clip-path': `url(#clip${this.svgId})`,
          'x': "0",
          'y': "0",
          'width': width,
          'height': height
        }
      }, [
        createElement('div',
          {
            class: {
              'shape__bg-image': true
            },
            style: Object.assign(imageDivBaseStyle,
              styleObject.bgImageStyles),
            attrs: {
              xmlns: "http://www.w3.org/1999/xhtml"
            }
          },
          // Hack: without tmpty string vue-ssr can render self-closed tag (not tested)
          // which cause wrong rendering of further html in browser
          [''])
      ]);
    }
    if (!this.flags.screenshotGeneratorMode) {
      if (!this.droppingMedia) {
        display = 'none';
      }
      dropFn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dropMedia(e);
      };
      dropMediaArea = createElement('div', {
        class: {
          'shape__drop-media': true
        },
        style: {
          'display': display
        },
        on: {
          dragover: dropFn,
          dragenter: dropFn,
          drop: dropFn,
          dragleave: (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.droppingMedia = false;
          }
        }
      });
      if (this.placeholderCondition) {
        // Placeholder for default text
        textPlaceholderFO = createElement('foreignObject', {
          attrs: {
            'x': this.border.width,
            'y': this.border.width,
            'width': Math.max(this.svgParams.width - this.border.width * 2, 0),
            'height': Math.max(this.svgParams.height - this.border.width * 2, 0)
          }
        }, [
          createElement('div',
            {
              class: {
                'text-placeholder': true
              },
              style: {
                'font-size': this.placeholder.fz + 'px',
                'line-height': this.placeholder.lh + 'px',
                'font-weight': this.placeholder.fw
              }
            },
            this.$t(this.placeholder.text))
        ]);
        // Placeholder border
        placeholderBorder = this.borderGenerator({
          'stroke': 'rgba(200,200,222,1)',
          'stroke-width': '1',
          'fill': 'transparent'
        }, {
          'shape__border': true
        }, {}, createElement);
      }
      // endif @placeholderCondition

      // Shape selected border
      shapeSelectedBorder = this.borderGenerator({}, {
        'shape__selected-border': true
      }, {}, createElement);
    }
    // endif !@flags.screenshotGeneratorMode

    // Text foreignObject
    if (this.opts.text.ps.length > 0) {
      switch (this.type) {
        case 'arrow':
        case 'chevron':
        case 'parallelogram':
        case 'rhombus':
        case 'triangle':
          x = this.textLimiterParams.x;
          y = this.textLimiterParams.y;
          width = this.textLimiterParams.width;
          height = this.textLimiterParams.height;
          break;
        case 'circle':
        case 'rectangle':
          x = this.border.width;
          y = this.border.width;
          width = Math.max(this.svgParams.width - this.border.width * 2, 0);
          height = Math.max(this.svgParams.height - this.border.width * 2, 0);
      }
      textOnObj = {
        'textfocus': this.textfocus,
        'textfocusout': this.textfocusout
      };
      // Handling overflow event for auto-height
      if (this.type === 'rectangle') {
        textOnObj.overflow = this.overflow;
      }
      textBlock = createElement('text_block', {
        props: {
          'opts': this.opts.text,
          'slideindex': this.slideindex,
          'zi': this.zi,
          'index': this.index,
          'size': {x, y, width, height}
        },
        ref: 'text',
        on: textOnObj
      });
      textFO = createElement('foreignObject', {
        attrs: {
          'x': x,
          'y': y,
          'width': width,
          'height': height
        }
      }, [textBlock]);
      // Rendered text <g>
      if ((ref = this.$refs.text) != null ? ref.svgData : 0) {
        renderedTextG = createElement('g', {
          domProps: {
            innerHTML: this.textSvg
          }
        });
      }
    }
    // endif @opts.text.ps.length > 0

    // Shape border
    if (this.type === 'circle') {
      paramsObj = this.ellipParams;
    } else {
      paramsObj = this.rectParams;
    }
    shapeBorder = this.borderGenerator(paramsObj.strokeStyles, {
      'shape__border': true
    }, {
      'stroke-dasharray': paramsObj['stroke-dash']
    }, createElement);
    // Main <g> wrapper
    mainGWrapper = createElement('g', {
      style: {
        transform: `translate(${this.a}px,${this.a}px)`
      }
    }, [this.shapePathElementGenerator(createElement), imageFO, textPlaceholderFO, textFO, renderedTextG, placeholderBorder, shapeBorder, shapeSelectedBorder]);
    // SVG elem
    svgElem = createElement('svg', {
      style: Object.assign({
        overflow: 'visible',
        position: 'absolute'
      }, this.fadedStyle),
      attrs: {
        'version': '1.1',
        'baseProfile': 'full',
        'xmlns': 'http://www.w3.org/2000/svg',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        'xmlns:ev': 'http://www.w3.org/2001/xml-events',
        'width': this.svgParams.width + this.a * 2,
        'height': this.svgParams.height + this.a * 2,
        'viewBox': `0 0 ${this.svgParams.width + this.a * 2} ${this.svgParams.height + this.a * 2}`
      },
      ref: 'svg'
    }, [defsTag, linearGradientDefinition, radialGradientDefinition, mainGWrapper]);
    // @$el
    rootEl = createElement('div', {
      class: Object.assign({
        object: true,
        shape: true
      }, this.shapeClasses),
      style: this.posStyles,
      attrs: {
        'data-rdata': this.getRendererData()
      },
      on: {
        contextmenu: (e) => {
          this.setMenuTarget(e);
        },
        dragenter: (e) => {
          this.droppingMedia = true;
          e.stopPropagation();
          e.preventDefault();
        }
      }
    }, [svgElem, dropMediaArea]);
    return rootEl;
  },
  watch: {
    'flags.screenshotGeneratorMode'(val) {
      if (val) {
        this.noTransform = true;
        // First tick - change self state
        this.$nextTick(function() {
          // Second tick - change text state
          this.$nextTick(function() {
            // Third tick - change text DOM
            return this.$nextTick(function() {
              if (val) {
                this.generateTextSVG();
                this.noTransform = false;
              }
            });
          });
        });
      }
    }
  },
  computed: {
    media() {
      if (this.customMedia != null) {
        return this.customMedia;
      } else {
        return this.$store.state.presentation.media;
      }
    },
    shapeClasses() {
      const res = {
        'text-selected': this.isTextSelected,
        'selected': this.isSelected
      };
      res[`shape__${this.type}`] = true;
      return res;
    },
    text() {
      if (this.$refs.text != null) {
        return this.$refs.text;
      }

      return undefined;
    },
    border() {
      var w;
      w = parseInt(this.styleProps['stroke-width']);
      if (this.styleProps['stroke-on'] === false) {
        w = 0;
      }
      return {
        width: w,
        color: 'rgba(' + this.getValidColor(this.styleProps['stroke-color']) + ')'
      };
    },
    svgParams() {
      return {
        'height': Math.round(this.displayCoords[1].y - this.displayCoords[0].y),
        'width': Math.round(this.displayCoords[1].x - this.displayCoords[0].x)
      };
    },
    svgId() {
      return `${this.currentPage.presentationId}_${this._uid}`;
    },
    commonShapeParams() {
      var bgImageFitStyles, bgImageStyles, bgImg, bgc, bgi, fill, ifm;
      bgImageStyles = {};
      bgc = this.getValidColor(this.styleProps['background-color']);
      fill = `rgba(${bgc})`;
      if (this.svgGradientParams) {
        fill = `url(#grad${this.svgId})`;
      }
      if (this.styleProps['fill-on'] === false) {
        fill = 'rgba(0,0,0,0)';
      } else {
        bgi = this.opts.styleProps['background-image'];
        if (bgi > 0) {
          if (this.media[bgi] == null) {
            // если нет такого медиа ресурса - удаляем из свойств
            this.setProperty('background-image', '');
          } else {
            if (this.$store.state.flags.screenshotGeneratorMode) {
              bgImg = this.$store.state.mediaDataURLs[bgi];
            } else {
              bgImg = this.media[bgi].url;
              if (bgImg.indexOf('http') !== 0 && bgImg.indexOf('data') !== 0 && bgImg.indexOf('blob') !== 0) {
                bgImg = this.currentPage.storageUrl + bgImg;
              }
            }
            bgImageStyles['background-image'] = `url(${bgImg})`;
          }
        }
        ifm = this.opts.styleProps['image-fill-mode'];
        bgImageFitStyles = this.fitBackgroundImage({
          fillMode: ifm,
          containerWidth: this.svgParams.width,
          imgWidth: this.opts.styleProps['background-image-width'],
          imageScale: this.opts.styleProps['image-scale'],
          imageShiftX: this.opts.styleProps['image-shift-x'],
          imageShiftY: this.opts.styleProps['image-shift-y']
        });
        Object.assign(bgImageStyles, bgImageFitStyles);
        bgImageStyles['opacity'] = this.opts.styleProps['image-opacity'];
      }
      return {
        styles: {
          'fill': fill
        },
        strokeStyles: {
          'fill': 'transparent',
          'stroke': this.border.color,
          'stroke-width': this.border.width
        },
        bgImageStyles: bgImageStyles
      };
    },
    // Delete?
    rotate() {
      var rotate;
      rotate = this.opts.rotate;
      if (this.invertedRotate) {
        rotate = -rotate;
      }
      return rotate;
    },
    // Координаты центра объекта
    centerCoords() {
      return {
        x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2,
        y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2
      };
    },
    // переменные стилей, с которыми
    // работает панель свойств объекта propPanel
    // styleProps:->
    //   return @opts.styleProps

    // переменные позиционирования объекта,
    // переведенные в стили CSS
    posStyles() {
      const mirror = this.posParams.mirror;
      const rotate = this.posParams.rotate;
      const transform = this.noTransform ? '' : `scale(${mirror}) rotate(${rotate}deg)`;

      return {
        'height': this.posParams.height + 'px',
        'width': this.posParams.width + 'px',
        'top': this.posParams.top + 'px',
        'left': this.posParams.left + 'px',
        'opacity': this.posParams.opacity,
        'z-index': this.posParams['z-index'],
        'transform': transform
      };
    },
    posParams() {
      return {
        'height': Math.round(this.displayCoords[1].y - this.displayCoords[0].y),
        'width': Math.round(this.displayCoords[1].x - this.displayCoords[0].x),
        'top': Math.round(this.displayCoords[0].y),
        'left': Math.round(this.displayCoords[0].x),
        'opacity': this.styleProps.opacity,
        'z-index': this.zi,
        'mirror': this.mirror,
        'rotate': this.rotate
      };
    },
    svgGradientParams() {
      var centerCoords, corners, diffs, gradient, height, res, width;
      if (this.styleProps['color-mode'] === 'solid') {
        return false;
      }
      gradient = this.styleProps['gradient'];
      res = {};
      res.points = [];
      _.each(gradient.points, (point) => {
        res.points.push({
          offset: point.position + '%',
          color: 'rgba(' + this.getValidColor(point.color) + ')'
        });
      });
      switch (gradient.type) {
        case 'linear':
          res.linear = true;
          res.rotate = 'rotate(' + (gradient.angle - 90) + ', .5, .5)';
          break;
        case 'radial':
          res.radial = true;
          width = Math.abs(this.opts.coords[1].x - this.opts.coords[0].x);
          height = Math.abs(this.opts.coords[1].y - this.opts.coords[0].y);
          centerCoords = {
            x: width * gradient.center[0] / 100,
            y: height * gradient.center[1] / 100
          };
          corners = [[0, 0], [width, 0], [width, height], [0, height]];
          diffs = corners.map(function (corner) {
            return Math.hypot(corner[0] - centerCoords.x, corner[1] - centerCoords.y);
          });
          res.c = {
            x: centerCoords.x,
            y: centerCoords.y,
            r: Math.max(...diffs)
          };
      }
      return res;
    },
    gradientParams() {
      var gradCss, gradient, styles;
      if (this.styleProps['color-mode'] === 'solid') {
        return {
          display: 'none'
        };
      }
      gradient = this.styleProps['gradient'];
      gradCss = '';
      switch (gradient.type) {
        case 'linear':
          gradCss += 'linear-gradient(';
          gradCss += gradient.angle + 'deg';
          break;
        case 'radial':
          gradCss += 'radial-gradient(at ';
          gradCss += gradient.center[0] + '% ' + gradient.center[1] + '%';
      }
      _.each(gradient.points, (point) => {
        gradCss += ', rgba(' + this.getValidColor(point.color) + ') ' + point.position + '%';
      });
      gradCss += ')';
      styles = {
        'background-image': gradCss,
        'width': '100%',
        'height': '100%'
      };

      return styles;
    },
    magnetCoords() {
      var polar, res, that;
      that = this;
      polar = [];
      _.forEach(this.magnetCoordsDecart, function (coords) {
        var converted;
        converted = that.decart2polarCoords(coords);
        converted.fi += that.opts.rotate;
        polar.push(converted);
      });
      res = [];
      _.forEach(polar, function (coords) {
        var converted;
        converted = that.polar2decartCoords(coords);
        converted.x += that.centerCoords.x;
        converted.y += that.centerCoords.y;
        res.push(converted);
      });
      return res;
    },
    innerRectSize() {
      var height, width;
      if (this.opts.type === 'rectangle') {
        width = Math.max(this.svgParams.width - this.border.width, 0);
        height = Math.max(this.svgParams.height - this.border.width, 0);
      } else {
        width = this.svgParams.width;
        height = this.svgParams.height;
      }
      return {width, height};
    },
    bgRectSize() {
      var height, width;
      ({width, height} = this.innerRectSize);
      width += this.border.width;
      height += this.border.width;
      return {width, height};
    },
    rectParams() {
      var height, minSide, objToAssign, res, width;
      ({width, height} = this.innerRectSize);
      if (this.opts.type === 'rectangle') {
        minSide = Math.min(this.selfSize.width, this.selfSize.height);
        res = {
          x: this.border.width / 2,
          y: this.border.width / 2,
          rx: Math.min(this.styleProps['corner-radius'][0], Math.round(minSide / 2)),
          ry: Math.min(this.styleProps['corner-radius'][0], Math.round(minSide / 2)),
          width: width,
          height: height
        };
      } else {
        res = {
          x: 0,
          y: 0,
          rx: 0,
          ry: 0,
          width: width,
          height: height
        };
      }
      if (this.styleProps['stroke-type'] === 'dashed') {
        res['stroke-dash'] = this.styleProps['stroke-dash'].join(', ');
      }
      if (!this.ready) {
        objToAssign = _.cloneDeepWith(this.commonShapeParams, function (value, key) {
          if (key === 'stroke') {
            return 'rgba(200,200,222,1)';
          }
          if (key === 'stroke-width') {
            return 1;
          }
        });
      } else {
        objToAssign = this.commonShapeParams;
      }
      return _.assign(res, objToAssign);
    },
    dataForBgMixin() {
      return {
        styles: this.opts.styleProps,
        containerWidth: this.bgRectSize.width,
        containerHeight: this.bgRectSize.height
      };
    }
  },
  methods: {
    dropMedia(e) {
      var fileType, mediaBrowser, supportedMedia;
      if (e.type !== 'drop') {
        return;
      }
      this.droppingMedia = false;
      if (e.dataTransfer.files[0] != null) {
        this.currentPage.droppingMedia = false;
        supportedMedia = this.currentPage.supportedMedia;
        fileType = e.dataTransfer.files[0].type;
        if (supportedMedia.indexOf(fileType) === -1) {
          this.currentPage.showError('File type is not supported');
          return;
        }
        this.currentPage.metaTool.editingObjects = [this.myStoreObject];
        this.currentPage.metaTool.drawSelectedSpace(true);
        mediaBrowser = this.currentPage.$refs.propPanel.$refs.mediaBrowser;
        mediaBrowser.dropFile(e.dataTransfer.files[0]);
      }
    },
    dotsAreNeighbours(dots, treshold) {
      var diff;
      if (!treshold) {
        treshold = 1;
      }
      diff = {
        x: Math.abs(dots[0].x - dots[1].x),
        y: Math.abs(dots[0].y - dots[1].y)
      };
      return diff.x <= treshold && diff.y <= treshold;
    },
    dotNearLinkDot(dot, treshold) {
      var res, that;
      that = this;
      if (!treshold) {
        treshold = 1;
      }
      res = undefined;
      _.forEach(this.magnetCoords, function (coords, index) {
        if (that.dotsAreNeighbours([dot, coords], treshold)) {
          res = index;
        }
      });
      return res;
    },
    getSvg() {
      let svgData = this.$refs.svg.outerHTML;
      let svgDoc = new DOMParser().parseFromString(svgData, 'image/svg+xml');
      let fo = svgDoc.getElementsByClassName('text-wrapper');
      
      if (fo[0]) {
        fo[0].remove();
      }
      // const svgNS = "http://www.w3.org/2000/svg";
      let str = new XMLSerializer().serializeToString(svgDoc.documentElement);
      // uri = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(str)
      return str;
    },
    generateTextSVG() {
      var svgData;
      if (this.$refs.text) {
        svgData = this.$refs.text.generateSvg();
        this.textSvg = svgData;
      }
    },
    borderGenerator(styleObject, classObject, attrsObj, createElementFn) {
      var attrs, pathName, tag;
      switch (this.type) {
        case 'arrow':
        case 'chevron':
        case 'parallelogram':
        case 'rhombus':
        case 'triangle':
          tag = 'path';
          pathName = `${this.type}Path`;
          attrs = {
            'd': this[pathName]
          };
          break;
        case 'circle':
          tag = 'ellipse';
          attrs = {
            'rx': this.ellipParams.rx,
            'ry': this.ellipParams.ry,
            'cx': this.ellipParams.cx,
            'cy': this.ellipParams.cy
          };
          break;
        case 'rectangle':
          tag = 'rect';
          attrs = {
            'x': this.border.width / 2,
            'y': this.border.width / 2,
            'rx': this.rectParams.rx,
            'ry': this.rectParams.ry,
            'width': this.rectParams.width,
            'height': this.rectParams.height
          };
      }
      return createElementFn(tag, {
        class: classObject,
        style: styleObject,
        attrs: Object.assign(attrsObj, attrs)
      });
    },
    shapePathElementGenerator(createElementFn) {
      var pathName, shapePathElement;
      switch (this.type) {
        case 'arrow':
        case 'chevron':
        case 'parallelogram':
        case 'rhombus':
        case 'triangle':
          pathName = `${this.type}Path`;
          shapePathElement = createElementFn('path', {
            style: Object.assign({
              'stroke-miterlimit': 10
            }, this.rectParams.styles),
            attrs: {
              'd': this[pathName]
            }
          });
          break;
        case 'circle':
          shapePathElement = createElementFn('ellipse', {
            style: this.ellipParams.styles,
            attrs: {
              'rx': this.ellipParams.rx,
              'ry': this.ellipParams.ry,
              'cx': this.ellipParams.cx,
              'cy': this.ellipParams.cy
            }
          });
          break;
        case 'rectangle':
          shapePathElement = createElementFn('rect', {
            style: this.rectParams.styles,
            attrs: {
              'x': this.border.width / 2,
              'y': this.border.width / 2,
              'width': this.rectParams.width,
              'height': this.rectParams.height,
              'rx': this.rectParams.rx,
              'ry': this.rectParams.ry
            }
          });
      }
      return shapePathElement;
    },
    gradientStopsGenerator(createElementFn) {
      return this.svgGradientParams.points.map((point) => {
        return createElementFn('stop', {
          key: point.id,
          attrs: {
            'offset': point.offset,
            'stop-color': point.color
          }
        });
      });
    }
  }
};
