<template>
    <div class="property property__type_integration-select" :class="{'active': active}">
        <div class="select select_theme_dark">
            <div class="select__input select__input_nopadding">
                <div class="select__option select__option_integration" @click.stop="clickSelect">
                    {{ propset[property][current] }}
                </div>
                <div class="select__svg-wrapper">
                    <svg
                        class="select__input-svg"
                        width="10"
                        height="8"
                        xmlns="http://www.w3.org/2000/svg"
                        @click.stop="selectOpen=true"
                    >
                        <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF" fill-rule="evenodd" />
                    </svg>
                </div>
            </div>
            <div class="select__body" v-show="selectOpen">
                <div class="select__body-scrollable">
                    <div
                        v-for="(v, k) in propset[property]"
                        @click.stop="selectIntegration(k)"
                        class="select__option select__option_integration"
                        :class="{'select__option_current': k == current}"
                    >
                        {{ v }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from '@/components/editor/property/PropBase';

export default {
    inject: ['currentPage'],
    name: 'prop_integration_select',
    mixins: [PropBase],
    props: ['current', 'active'],
    data: function() {
        return {
            selectOpen: false
        };
    },
    methods: {
        selectIntegration: function(key) {
            if(!this.active) {
                this.$emit('selectclicked');
            }
            this.$emit('integrationselected', key);
        },
        clickSelect: function() {
            if(!this.active) {
                this.$emit('selectclicked');
                return;
            }
            this.selectOpen = true;
        }
    },
    watch: {
        'currentPage.mousePressed.left': function() {
            if(!this.currentPage.mousePressed.left) {
                this.selectOpen = false;
            }
        }
    }
};
</script>

<style lang="scss">
.property__type_integration-select {
  margin-left: 42px;
  padding-bottom: 6px;

  &.active {
    border-bottom: 2px solid;
    border-color: #10AFFF;

    .select__input {
      .select__option {
        color: #fff;
      }
    }
  }

  padding-left: 0;
  padding-right: 0;

  .select__option {
    padding: 10px 40px 10px 25px;
  }

  .select__body {
    position: absolute;
    margin-top: 17px;
    right: -20px;
  }

  .select__input {
    background: transparent;
    border: none;

    &:hover {
      background: transparent;
      border: none;
    }

    .select__option {
      padding-right: 25px;
      background: transparent;
      color: #fff8;

      &:hover {
        color: #fff;
      }

      &_integration {
        padding-bottom: 0;
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        line-height: 21px;

        & ~ .select__svg-wrapper {
          right: -26px;
          top: 2px;
          position: absolute;
          width: 20px;
          height: 20px;

          &:before {
            content: '';
            position: absolute;
            background-color: transparent;
            top: 0;
            left: -4px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
