<template>
    <div class="property property__type_text-align">
        <div class="property__name">{{ $t(property.title) }}</div>
        <div class="property__flex-row">
            <div
                class="property__btn" title="Left" @mousedown.prevent="setProp('left')"
                v-bind:class="{'property__btn__active': propValue.h=='left'}">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                        <path d="M0 1h16M0 6h8M0 11h13"/>
                    </g>
                </svg>
            </div>
            <div
                class="property__btn" title="Center" @mousedown.prevent="setProp('center')"
                v-bind:class="{'property__btn__active': propValue.h=='center'}">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                        <path d="M0 1h16M4 6h8M2 11h13"/>
                    </g>
                </svg>
            </div>
            <div
                class="property__btn" title="Right" @mousedown.prevent="setProp('right')"
                v-bind:class="{'property__btn__active': propValue.h=='right'}">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                        <path d="M0 1h16M8 6h8M3 11h13"/>
                    </g>
                </svg>
            </div>
            <div
                class="property__btn" title="Top" @mousedown.prevent="setProp('top')"
                v-bind:class="{'property__btn__active': propValue.v=='top'}">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#FFF" fill-rule="nonzero" d="M0 0h16v2H0z"/>
                        <path fill="#FFF" d="M7 5h2v7H7z"/>
                        <path stroke="#FFF" stroke-width="2" d="M11.07 7.035L8.035 4 5 7.035"/>
                    </g>
                </svg>
            </div>
            <div
                class="property__btn" title="Middle" @mousedown.prevent="setProp('middle')"
                v-bind:class="{'property__btn__active': propValue.v=='middle'}">
                <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#FFF" fill-rule="nonzero" d="M0 6h16v2H0z"/>
                        <path
                            stroke="#FFF"
                            stroke-width="2"
                            d="M5 1l3.035 3.035L11.07 1M11.07 13.035L8.035 10 5 13.035"/>
                    </g>
                </svg>
            </div>
            <div
                class="property__btn" title="Bottom" @mousedown.prevent="setProp('bottom')"
                v-bind:class="{'property__btn__active': propValue.v=='bottom'}">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#FFF" fill-rule="nonzero" d="M16 12H0v-2h16z"/>
                        <path fill="#FFF" d="M9 7H7V0h2z"/>
                        <path stroke="#FFF" stroke-width="2" d="M4.93 4.965L7.965 8 11 4.965"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
    name: 'prop_text_align',
    mixins: [PropBase],
    data: function () {
        return {};
    },
    computed: {
        target: function () {
            return this.proppanel.target;
        },
        propValue: {
            get: function () {
                var ref, ref1, val;
                val = {
                    h: 'center',
                    v: 'middle'
                };
                if ((((ref = this.propset) != null ? ref['text-align'] : 0) != null)) {
                    val.h = this.propset['text-align'];
                }
                if ((((ref1 = this.propset) != null ? ref1['vertical-align'] : 0) != null)) {
                    val.v = this.propset['vertical-align'];
                }
                return val;
            }
        }
    },
    methods: {
        setProp: function (val) {
            var key;
            if (val === 'left' || val === 'center' || val === 'right') {
                key = 'text-align';
            }
            if (val === 'top' || val === 'middle' || val === 'bottom') {
                key = 'vertical-align';
            }
            this.updateProp(key, val);
        }
    }
};
</script>

<style lang="scss">
.property__type_text-align {

    .property__flex-row {
        align-items: center;
    }

    .property__btn {
        text-align: center;
        padding: 3px 0;
        margin-right: 18px;
        box-sizing: border-box;

        &:last-child {
            margin-right: 0;
        }

        &__active svg {
            opacity: 1;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "Align": "Align"
    },
    "ru": {
        "Align": "Выравнивание"
    }
}
</i18n>
