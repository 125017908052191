/* eslint-disable */
// Generated by CoffeeScript 2.6.1
import Vue from "vue";
import _ from "lodash";
import $ from 'jquery';

export default {
  name: 'Text_entity_mixin',
  methods: {
    // Добавляет текст к объекту
    addTextEntity() {
      // Не продолжаем, если нельзя добавлять текст
      if (!this.textAvailable) { return; }

      // Заготовка для спана
      const newP = _.cloneDeep(this.$store.getters.blanks.p);

      const obj = this.myStoreObject;

      const that = this;
      // Делаем только если ещё нет текста
      if (obj.text.ps.length === 0) {
        obj.text.ps.push(newP);
      }

      // Таймаут для отрисовки элементов в DOM'e
      Vue.nextTick(
          function(){
            const targetText = $(that.$el).find('.text')[0];

            if (targetText) {

              // Последовательность именно такая!
              // Сначала ставим фокус на блок с тектом,
              // потом создаем выделение в спане
              targetText.focus();

              const targetP = targetText.lastChild;
              const targetSpan = targetP.lastChild;
              const targetNode = targetSpan.firstChild;

              window.getSelection().removeAllRanges();
              const newRange = document.createRange();


              newRange.setStart(targetNode, targetNode.length);
              newRange.setEnd(targetNode, targetNode.length);

              window.getSelection().addRange(newRange);
            }

          },
          this
      );

      // if !@$parent.isTable
      //   @isTextSelected = true

    }
  }
};
