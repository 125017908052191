/* eslint-disable */

import _ from "lodash";
import $ from 'jquery';

import Polar from "@/mixins/editor/Polar";
import Object from "@/mixins/editor/Object";
import CoordsCommon from "@/mixins/editor/CoordsCommon";

export default {
    name: 'Line_mixin',
    inject: ['currentPage'],
    props: ['opts', 'zi', 'index', 'slideindex'],
    mixins: [Object, CoordsCommon, Polar],
    data: function() {
        return {
            rotatable: false,
            isLine: true,
            renderHack: false
        };
    },
    watch: {
        'opts.linkedDots': {
            handler: function() {
                return this.updateDots();
            },
            deep: true
        },
        linkedDots: function() {
            this.updateDots();
        },
        'strokeWidth': function() {
            this.renderHack = !this.renderHack;
        }
    },
    computed: {
        strokeWidth: function() {
            return this.lineParams["stroke-width"];
        },
        lineParams: function() {
            var styles;
            styles = {
                'stroke': 'rgba(' + this.getValidColor(this.styleProps['stroke-color']) + ')',
                'stroke-width': this.styleProps['stroke-width'],
                'stroke-linecap': this.styleProps['stroke-cap']
            };
            if (this.styleProps['stroke-type'] === 'dashed') {
                styles['stroke-dasharray'] = this.styleProps['stroke-dash'].join(' ');
            }
            return styles;
        },
        virtualCoords: {
            get: function() {
                return this.fourCoords;
            },
            set: function(newCoords) {
                // Условия для перерисовки привязанных точек
                if (this.opts.linkedDots.start.length === 0 || this.currentPage.editingObjects.length === 1) {
                    if (!isNaN(newCoords[0].x)) {
                        this.opts.coords[0].x = newCoords[0].x;
                    }
                    if (!isNaN(newCoords[0].y)) {
                        this.opts.coords[0].y = newCoords[0].y;
                    }
                }
                if (this.opts.linkedDots.end.length === 0 || this.currentPage.editingObjects.length === 1) {
                    if (!isNaN(newCoords[2].x)) {
                        this.opts.coords[1].x = newCoords[2].x;
                    }
                    if (!isNaN(newCoords[2].y)) {
                        this.opts.coords[1].y = newCoords[2].y;
                    }
                }
            }
        },
        // Координаты центра объекта
        centerCoords: function() {
            return {
                x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2,
                y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2
            };
        },
        linkedDots: function() {
            var end, object, slideObjects, start, vueObj;
            if (this.currentPage.isRenderer) {
                return;
            }
            start = undefined;
            end = undefined;
            // Для реактивности
            this.slideindex;
            if (this.$store.state.presentation.slides[this.slideindex] != null) {
                slideObjects = this.$store.state.presentation.slides[this.slideindex].objects;
                if (this.opts.linkedDots != null) {
                    if (this.opts.linkedDots.start.length > 0) {
                        object = this.currentPage.getObjectById(this.opts.linkedDots.start[0], this.slideindex);
                        vueObj = object.vueObj;
                        if (vueObj == null) {
                            this.opts.linkedDots.start = [];
                        } else {
                            // Для реактивности
                            vueObj.zi;
                            if ((vueObj != null ? vueObj.magnetCoords : 0) != null) {
                                start = vueObj.magnetCoords[this.opts.linkedDots.start[1]];
                            }
                        }
                    }
                    if (this.opts.linkedDots.end.length > 0) {
                        object = this.currentPage.getObjectById(this.opts.linkedDots.end[0], this.slideindex);
                        vueObj = object.vueObj;
                        if (vueObj == null) {
                            this.opts.linkedDots.end = [];
                        } else {
                            // Для реактивности
                            vueObj.zi;
                            if ((vueObj != null ? vueObj.magnetCoords : 0) != null) {
                                end = vueObj.magnetCoords[this.opts.linkedDots.end[1]];
                            }
                        }
                    }
                }
            }
            return {
                start: start,
                end: end
            };
        }
    },
    methods: {
        // Метод возвращает true если в массиве
        // есть любой элемент внутри svg $el'а
        isSelfInDomElemsArray: function(array) {
            var found, targets;
            targets = $(this.$el).find('svg>g>*');
            found = false;
            _.forEach(targets, function(elem) {
                if (array.includes(elem)) {
                    found = true;
                }
            });
            return found;
        },
        updateDots: function() {
            if (this.linkedDots.start != null) {
                this.opts.coords[0].x = this.linkedDots.start.x;
                this.opts.coords[0].y = this.linkedDots.start.y;
            }
            if (this.linkedDots.end != null) {
                this.opts.coords[1].x = this.linkedDots.end.x;
                this.opts.coords[1].y = this.linkedDots.end.y;
            }
        },
        getSvg: function() {
            var str, svgData, svgDoc, svgNS;
            svgData = this.$refs.svg.outerHTML;
            svgDoc = new DOMParser().parseFromString(svgData, 'image/svg+xml');
            svgNS = "http://www.w3.org/2000/svg";
            str = new XMLSerializer().serializeToString(svgDoc.documentElement);
            // uri = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(str)
            return str;
        }
    }
};
