/* eslint-disable */
export default {
  name: 'Coords_common_mixin',
  computed: {
    // Delete?
    displayCoords() {
      return [{
        x: Math.min(this.opts.coords[0].x, this.opts.coords[1].x),
        y: Math.min(this.opts.coords[0].y, this.opts.coords[1].y)
      }
        , {
          x: Math.max(this.opts.coords[0].x, this.opts.coords[1].x),
          y: Math.max(this.opts.coords[0].y, this.opts.coords[1].y)
        }
      ];
    },

    // 4 координаты объекта
    fourCoords() {
      return [{
        x: this.opts.coords[0].x,
        y: this.opts.coords[0].y
      }
        , {
          x: this.opts.coords[1].x,
          y: this.opts.coords[0].y
        }
        , {
          x: this.opts.coords[1].x,
          y: this.opts.coords[1].y
        }
        , {
          x: this.opts.coords[0].x,
          y: this.opts.coords[1].y
        }
      ];
    },

    selfTracks() {
      let coords;
      const c = {
        min: {
          ver: undefined,
          hor: undefined
        },
        max: {
          ver: undefined,
          hor: undefined
        }
      };
      if (this.virtualCoords != null) {
        coords = this.virtualCoords;
      } else {
        coords = this.fourCoords;
      }
      coords.forEach((coords, i)=> {

        if (c.min.ver == null) { c.min.ver = coords; }
        if (c.max.ver == null) { c.max.ver = coords; }
        if (coords.x < c.min.ver.x) { c.min.ver = coords; }
        if (coords.x > c.max.ver.x) { c.max.ver = coords; }

        if (c.min.hor == null) { c.min.hor = coords; }
        if (c.max.hor == null) { c.max.hor = coords; }
        if (coords.y < c.min.hor.y) { c.min.hor = coords; }
        if (coords.y > c.max.hor.y) { c.max.hor = coords; }

      });
      // Нужно добавить информацио о точке, из которой вышел трек
      const hor = [
        this.createTrack(c.min.hor.y, c.min.hor.x),
        this.createTrack(c.max.hor.y, c.max.hor.x)
      ];
      const ver = [
        this.createTrack(c.min.ver.x, c.min.ver.y),
        this.createTrack(c.max.ver.x, c.max.ver.y)
      ];
      const roundedCenterX = this.createTrack(this.centerCoords.x, this.centerCoords.y);
      const roundedCenterY = this.createTrack(this.centerCoords.y, this.centerCoords.x);

      const values = {
        horizontal: hor,
        vertical: ver
      };

      const valuesWithCenter = {
        horizontal: [...values.horizontal, roundedCenterY],
        vertical: [...values.vertical, roundedCenterX]
      };

      const res = {
        withCenter: valuesWithCenter,
        withoutCenter: values,
        bySides: {
          top: hor[0],
          bottom: hor[1],
          left: ver[0],
          right: ver[1]
        }
      };
      return res;
    }
  },


  methods: {
    isDotInside(dot, treshold){
      let coords;
      const that = this;

      if (!treshold) {
        treshold = 0;
      }

      // Флаг отсутствия угла поворота
      let noangle = false;

      // Экстремальные значения виртуальных координат
      const ex = {
        minX: undefined,
        maxX: undefined,
        minY: undefined,
        maxY: undefined
      };

      const tmpDots = [];

      if (this.virtualCoords != null) {
        coords = this.virtualCoords;
      } else { coords = this.fourCoords; }
      // Находим экстремальные значения виртуальных координат
      // Поворот отсутсвует, если одно и то же экстремальное
      // значение у нескольких точек
      _.forEach(coords, function(coords, index){

        if((ex.minX == null) || (coords.x < ex.minX)) {
          ex.minX = coords.x;
          tmpDots[3] = coords;
        } else if (coords.x===ex.minX) {
          noangle = true;
        }
        if((ex.maxX == null) || (coords.x > ex.maxX)) {
          ex.maxX = coords.x;
          tmpDots[1] = coords;
        } else if (coords.x===ex.maxX) {
          noangle = true;
        }
        if((ex.minY == null) || (coords.y < ex.minY)) {
          tmpDots[0] = coords;
          ex.minY = coords.y;
        } else if (coords.y===ex.minY) {
          noangle = true;
        }
        if((ex.maxY == null) || (coords.y > ex.maxY)) {
          ex.maxY = coords.y;
          tmpDots[2] = coords;
        } else if (coords.y===ex.maxY) {
          noangle = true;
        }

      });

      // Если фигура не повернута
      if(noangle) {
        if(
            (dot.x >= (ex.minX - treshold)) &&
            (dot.x <= (ex.maxX + treshold)) &&
            (dot.y >= (ex.minY - treshold)) &&
            (dot.y <= (ex.maxY + treshold))
        ) {
          return true;
        } else {
          return false;
        }
      }

      // Если фигура повернута
      // Находим 4 ограничивающих значения y

      const ys = [];

      // Функция возвращает значение y при известном x
      // на прямой, проходящей через 2 точки
      const func = (x, x1, x2, y1, y2) => y1 + (((x-x1)*(y2-y1))/(x2-x1));

      _.forEach(tmpDots, function(coords, index){


        let dot2;
        const dot1 = coords;
        if (index === (tmpDots.length - 1)) {
          dot2 = tmpDots[0];
        } else {
          dot2 = tmpDots[index + 1];
        }

        const y = func(dot.x, dot1.x, dot2.x, dot1.y, dot2.y);

        ys.push(y);

      });

      // Проверяем попадание координаты y
      // под ограничения
      return (
          (dot.y >= (ys[0] - treshold)) &&
          (dot.y <= (ys[1] + treshold)) &&
          (dot.y <= (ys[2] + treshold)) &&
          (dot.y >= (ys[3] - treshold))
      );
    },

    createTrack(trackCoord, rootCoord){
      return {
        value: trackCoord,
        root: rootCoord
      };
    }
  }
};
