<template>
    <div class="property property__type_theme-select">
        <div class="property__name">
            {{ $t('colorTheme') }}
        </div>
        <div class="select select_theme_dark">
            <div class="select__input select__input_nopadding"
                @mouseup.stop=""
                @mousedown.stop="selectOpen = !selectOpen">
                <div class="select__option select__option_theme" v-if="!activePalette">Select theme</div>
                <div class="select__option select__option_theme" v-if="activePalette">
                    <div
                        class="select__theme-color"
                        v-for="color in colors"
                        :style="{'background-color': 'rgb(' + activePalette[color] + ')'}"
                    />
                </div>
                <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                    <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF" fill-rule="evenodd" />
                </svg>
            </div>
            <div class="select__body" v-show="selectOpen">
                <div class="select__body-scrollable">
                    <div
                        class="select__option select__option_theme" v-for="(v) in $store.state.themes"
                        @mouseup="selectColorTheme(v.id)"
                        @mouseenter="previewColorTheme(v.id)"
                        :class="{'select__option_current': v.id == $store.state.presentation.theme.id}"
                    >
                        <div
                        class="select__theme-color" v-for="color in colors"
                        :style="{'background-color': 'rgb(' + v.palette[color] + ')'}" />
                    </div>
                    <div class="select__option select__option_add-new" v-if="themeEditingMode" @click="addNewTheme">+</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from '@/components/editor/property/PropBase';
import { apiPut } from '@/models/Api';

export default {
    inject: ['currentPage'],
    name: 'prop_theme_select',
    mixins: [PropBase],
    data: function() {
        return {
            selectOpen: false,
            colors: ['acc1l50', 'acc2l50', 'mut1l50', 'mut2l50'],
            editingThemeId: undefined
        };
    },
    methods: {
        selectColorTheme: function(id) {
            if(this.themeEditingMode) {
                this.editingThemeId = id;
                this.$emit('editingthemechanged', id);
            } else {
                this.$store.commit('changeColorTheme', id);
            }
        },
        previewColorTheme: function(id) {
            this.$store.commit('previewColorTheme', id);
        },
        restoreColorTheme: function() {
            this.$store.commit('restoreColorTheme');
        },
        async addNewTheme() {
            let newId = 1;
            while(this.$store.state.themes[newId] != null) {
                newId++;
            }
            this.$store.commit('addNewTheme', newId);
            this.editingThemeId = newId;

            const res = await apiPut(
                `/api/v1/themes/${newId}`,
                {
                    data: JSON.stringify(this.$store.state.themes[newId])
                }
            );

            if(!res) {
                this.currentPage.showError('Connection error, trying to reconnect');
            }
        },
        async saveTheme() {
            if(this.editingThemeId != null) {
                const res = await apiPut(
                    `/api/v1/themes/${this.editingThemeId}`,
                    {
                        data: JSON.stringify(this.$store.state.themes[this.editingThemeId])
                    }
                )

                if(!res) {
                    this.currentPage.showError('Connection error, trying to reconnect');
                }
            }
        }
    },
    computed: {
        activePalette: function() {
            var ref;
            if(this.themeEditingMode) {
                return (ref = this.$store.state.themes[this.editingThemeId]) != null ? ref.palette : undefined;
            } else {
                return this.$store.state.presentation.theme.palette;
            }
        },
        themeEditingMode: function() {
            return this.currentPage.themesEditor;
        }
    },
    watch: {
        'currentPage.mousePressed.left': function() {
            if(!this.currentPage.mousePressed.left) {
                this.selectOpen = false;
            }
        },
        selectOpen: function(val) {
            if(!val) {
                this.restoreColorTheme();
            }
        }
    },
    beforeDestroy: function() {
        this.restoreColorTheme();
    }
};
</script>

<style lang="scss">
.select {
  &__option {
    &_theme {
      position: relative;
      display: flex;
      flex-direction: row;
    }

    &_add-new {
      font-size: 24px;
      color: #fff;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &__theme-color {
    position: relative;
    width: 30px;
    height: 22px;
    border-radius: 3px;
    border: solid 1px #979797;
    margin-right: 7px;
  }
}
</style>

<i18n>
{
    "en": {
        "colorTheme": "Color theme"

    },
    "ru": {
        "colorTheme": "Цветовая тема"
    }
}
</i18n>
