<template>
    <div
        class="slide-templates__item"
        :style="{width: previewWidth + 'px'}"
        @click="templateClick($event)"
        :class="{'slide-templates__item_blank': isBlank}"
    >
        <template v-if="!isBlank">
            <SlideBackground
                :bgIndex="0"
                :active="true"
                :order="0"
                :styles="slide.bg"
                :boxed="true"
                :slideEars="false"
                :customMedia="customMedia"
            />
            <Slide
                :style="slideStyle"
                :contentTrimmerStyles="contentTrimmerStyles"
                :bgAndContentDifference="{x: 1, y: 1}"
                :slideIndex="undefined"
                :slideContent="slide"
                :order="0"
                :active="true"
                :customMedia="customMedia"
            />
        </template>
    </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import SlideBackground from '@/components/editor/SlideBackground';
import Slide from '@/components/editor/Slide';

export default {
    name: 'SlideTemplates',
    props: ['previewWidth', 'templateData', 'templateIndex'],
    components: {
        Slide,
        SlideBackground
    },
    data () {
        return {
            scale: 1
        };
    },
    computed: {
        isBlank () {
            return this.templateIndex === -1;
        },
        slide () {
            return this.templateData.slides[0];
        },
        bg () {
            return this.templateData.slides[0].bg;
        },
        size () {
            return this.templateData.size;
        },
        slideStyle () {
            const s50 = 50 * this.scale;
            return {
                height: this.size.h + 'px',
                width: this.size.w + 'px',
                transform: `translate(-${s50}%, -${s50}%) scale(${this.scale})`,
                'backface-visibility': 'hidden'
            };
        },
        contentTrimmerStyles () {
            return {
                top: '0px',
                left: '0px',
                width: this.size.w + 'px',
                height: this.size.h + 'px'
            };
        },
        customMedia () {
            return _.cloneDeepWith(this.templateData.media, (val, key) => {
                if (key === 'url') {
                    const fullUrlArr = val.split('/');
                    const fileNamePosition = fullUrlArr.length - 1;
                    fullUrlArr[fileNamePosition] = 'preview_' + fullUrlArr[fileNamePosition];

                    return fullUrlArr.join('/');
                }
            });
        }
    },
    methods: {
        templateClick (e) {
            e.stopPropagation();
            e.preventDefault();
            this.$emit('templateclicked');
        }
    },
    mounted () {
        if (this.isBlank) {
            return;
        }

        const ph = this.size.h;
        const pw = this.size.w;
        const vh = $(this.$el).height();
        const vw = $(this.$el).width();

        let scale;
        if (pw / ph > vw / vh) {
            scale = vw / pw;
        }
        if (pw / ph <= vw / vh) {
            scale = vh / ph;
        }
        this.scale = scale;
    }
};
</script>

<style>

</style>
