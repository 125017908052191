<template>
    <div
            class="thumbs-bar__item"
            :data-id="slideId"
            @mousemove="mousemove"
            @mouseup.left="mouseup"
            @mousedown.left="mousedown"
            :style="style"
    >
        <slide_preview_content :previewHtml="previewHtml" :vjson="vjson" :size="presentation.size"/>
        <div
                class="menu-btn thumbs-bar__menu-btn"
                @click.stop="showNav"
                @mousedown.stop="currentPage.checkBgEditingCondition"
                @mouseup.stop=""
        >
            <svg width="7" height="18" xmlns="http://www.w3.org/2000/svg">
                <path
                        d="M6.9 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM1.567 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm10.666 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        transform="rotate(90 1.5 3.5)"
                        fill="#fff"
                        fill-rule="nonzero"
                />
            </svg>
        </div>
        <div
                class="context-menu thumbs-bar__menu"
                :class="{'context-menu_active':navActive}"
                :style="{'transform': 'translate(' + (previewWidth - menuShift - delta) + 'px, -100px)'}"
        >
            <div class="context-menu__item" @mouseup.stop="" @mousedown.stop="addSlide">
                {{ $t('addSlide') }}
            </div>
            <div class="context-menu__item" @mouseup.stop="" @mousedown.stop="cloneSlide">
                {{ $t('cloneSlide') }}
            </div>
            <div class="context-menu__item" @mouseup.stop="" @mousedown.stop="copySlide">
                {{ $t('copySlide') }}
            </div>
            <div class="context-menu__item" @mouseup.stop="" @mousedown.stop="pasteSlide">
                {{ $t('pasteSlide') }}
            </div>
            <div class="context-menu__item" @mouseup.stop="" @mousedown.stop="deleteSlide">
                {{ $t('deleteSlide') }}
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import $ from 'jquery';

import SlidePreviewContent from "@/components/SlidePreviewContent";

export default {
    inject: ['currentPage'],
    name: "SlidePreview",
    props: ['slide', 'index', 'checkdrop', 'previewHtml', 'delta', 'vjson', 'previewWidth'],
    components: {
        slide_preview_content: SlidePreviewContent
    },
    data() {
        return {
            mount: false,
            dragging: false,
            dragTreshold: 3,
            mousePressed: false,
            navActive: false,
            order: 0,
            menuShift: 13,
            mouseClickCoords: {
                x: 0,
                y: 0
            },
            center: {
                x: 0,
                y: 0
            }
        };
    },
    watch: {
        'currentPage.resizeFlag'() {
            this.getCenter();
        },
        globalMouseDown() {
            if (!this.globalMouseDown) {
                this.mousePressed = false;
                this.dragging = false;
            }
        },
        dragging() {
            this.$emit('updatedrag', {
                dragging: this.dragging,
                // dragDropId: this.index,
                dropShadowOrder: this.index,
                dragTarget: this.index
            });
        },
        dropHere() {
            let dropShadowOrder;
            if (this.dropHere) {
                if (this.index > this.currentPage.dragTarget) {
                    dropShadowOrder = this.index + 1;
                } else {
                    dropShadowOrder = this.index;
                }

                return this.$emit('updatedrag', {
                    dragDropId: this.index,
                    dropShadowOrder
                });
            }
        }
    },
    computed: {
        style() {
            let cursor, position, that, x, y, z;
            if (this.dragging) {
                x = this.currentPage.rawCoords.x - $(this.$el).width() / 2 + 'px';
                y = this.currentPage.rawCoords.y - $(this.$el).height() / 2 + 'px';
                z = 1000;
                cursor = 'move';
                position = 'fixed';
            } else {
                x = 0;
                y = 0;
                z = '';
                cursor = '';
                position = 'relative';
            }

            return {
                position,
                top: y,
                left: x,
                'z-index': z,
                cursor,
                order: this.slideId,
                width: this.previewWidth + 'px',
                'min-width': this.previewWidth + 'px'
            };
        },
        globalMouseDown() {
            const that = this;
            // eslint-disable-next-line vue/no-async-in-computed-properties
            setTimeout(function () {
                that.navActive = false;
            }, 50);
            return this.currentPage.mousePressed.left;
        },
        dropHere() {
            let dx, dy;
            if (!this.checkdrop) {
                return false;
            }

            dx = this.center.x - this.currentPage.rawCoords.x;
            dy = this.center.y - this.currentPage.rawCoords.y;
            dx = Math.abs(dx);
            dy = Math.abs(dy);
            if (dy < 50 && dx < Math.max(this.previewWidth / 5, 1)) {
                return true;
            } else {
                return false;
            }
        },
        slideId() {
            return this.index;
        },
        presentation() {
            return this.$store.state.presentation;
        }
    },
    mounted() {
        this.mount = true;
        this.getCenter();
    },
    methods: {
        mousedown(e) {
            this.mousePressed = true;
            this.mouseClickCoords = {
                x: this.currentPage.rawCoords.x,
                y: this.currentPage.rawCoords.y
            };
        },
        mousemove(e) {
            if (!this.mousePressed) {
                // ToDo:  здесь может быть ошибка, здесь был просто return
                return null;
            } else if (Math.abs(this.mouseClickCoords.x - this.currentPage.rawCoords.x) > this.dragTreshold || Math.abs(this.mouseClickCoords.y - this.currentPage.rawCoords.y) > this.dragTreshold) {
                this.dragging = true;
                this.$emit('previewcoordschanged', e);
            }
        },
        mouseup(e) {
            if (!this.mousePressed) {
                return;
            }
            const canvasBg = this.currentPage.$refs.presentation.$refs.canvasBg;
            if ((canvasBg != null) && !canvasBg.finished) {
                return;
            }
            if (!this.dragging) {
                this.$store.commit('setActiveSlide', this.index);
                if (this.currentPage.who === 'editor') {
                    this.$nextTick(function () {
                        this.$store.commit('replaceHistory', 'slideThumb');
                    });
                }
            } else {
                this.$store.commit('moveSlide', {
                    id: this.index,
                    newPosition: this.$parent.dragDropId
                });
            }
        },
        getCenter() {
            const rect = this.$el.getBoundingClientRect();
            this.center = {
                x: rect.x + rect.width / 2,
                y: rect.y + rect.height / 2
            };
        },
        showNav(e) {
            this.$emit('menuclicked');
            this.$nextTick(function () {
                this.navActive = true;
            });
        },
        // @$root.activeNav.navActive = false
        // @$root.activeNav = @
        closeNav() {
            this.navActive = false;
        },
        addSlide() {
            this.$store.commit('addNewSlide', {
                afterIndex: this.index + 1
            });
            this.closeNav();
        },
        copySlide() {
            this.currentPage.metaTool.copySlide(this.index);
            this.closeNav();
        },
        pasteSlide() {
            this.currentPage.logUserAction('metaTool', 'pasteSlide');
            this.$store.commit('pasteSlide', {
                afterIndex: this.index + 1
            });
            this.$nextTick(function () {
                this.currentPage.resolveAllTempMedia();
            });
            this.closeNav();
        },
        deleteSlide() {
            this.currentPage.metaTool.clearState();
            this.$store.commit('removeSlide', this.index);
            this.closeNav();
        },
        cloneSlide() {
            this.$store.commit('cloneSlide', this.index);
            this.closeNav();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "addSlide": "Add slide",
        "cloneSlide": "Clone slide",
        "copySlide": "Copy slide",
        "pasteSlide": "Paste slide",
        "deleteSlide": "Delete slide"
    },
    "ru": {
        "addSlide": "Добавить слайд",
        "cloneSlide": "Клонировать слайд",
        "copySlide": "Копировать слайд",
        "pasteSlide": "Вставить слайд",
        "deleteSlide": "Удалить слайд"
    }
}
</i18n>
