<template>
  <!-- eslint-disable -->
  <div class="property property__type_gradient" :class="type">
    <div class="gradient-input">
      <div class="gradient-input__preview" :style="previewStyle"></div>
      <div class="gradient-input__items" @mouseup.self="addPoint">
        <button class="gradient-input__item" v-for="(point, index) in points"
            :style="'background:rgba('+getValidColor(point.color)+'); left:'+point.position+'%'"
            @keydown.stop="keyDown" @mousedown="itemMouseDown" @mouseup="selectPoint"
            :class="{'gradient-input__item__active':index == selectedPoint}"></button>
      </div>
    </div>
    <div class="gradient__params" :class="'type_'+type" v-show="!showColorPicker">
      <div class="gradient__params__center" v-if="type=='radial'" :style="gradientStyle" @mousedown="updateCenter"
          @mousemove="mouseMoveRadial" @mouseup="paramsMouseUp">
        <div class="guide-line__x" :style="'left:'+center[0]+'%'"></div>
        <div class="guide-line__y" :style="'top:'+center[1]+'%'"></div>
      </div>
      <div class="gradient__params__angle" v-if="type=='linear'" :style="gradientStyle" @mousedown="updateAngle"
          @mousemove="mouseMoveLinear" @mouseup="paramsMouseUp">
        <div class="guide-line" :style="'transform:rotate('+angle+'deg)'"></div>
      </div>
    </div>
    <div class="btn property__btn_confirm" v-show="!showColorPicker" @mousedown="$emit('confirm')"></div>
    <prop_color v-show="showColorPicker" ref="propColor" :propset="colorProps" :property="color" :nested="true"
        :showAlpha="showAlpha" @confirm="showColorPicker = false; $emit('picker_closed')"></prop_color>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import $ from 'jquery';

import PropBase from '@/components/editor/property/PropBase';
import PropColor from '@/components/editor/property/PropColor';

export default {
    inject: ['currentPage'],
    name: 'prop_gradient',
    mixins: [PropBase],
    components: {
        prop_color: PropColor
    },
    props: {
        showAlpha: {
            type: Boolean,
            default: true
        }
    },
    data: function() {
        return {
            mouseDown: false,
            showColorPicker: false, // показывать ColorPicker для градиента
            dragging: false,
            selectedPoint: 0,
            dragStart: 0,
            itemClickFlag: false, // для отличия click от drag
            color: { // Справочник для вложенного компонента prop_color
                name: 'color'
            },
            clonedDragPoints: undefined
        };
    },
    watch: {
        'currentPage.mousePressed.left': function(val) {
            if(!val) {
                this.dragging = false;
                if(this.mouseDown) {
                    this.mouseDown = false;
                }
            }
        },
        dragging: function(val) {
            if(!val) {
                this.clonedDragPoints = undefined;
            }
        },
        dragPos: function() {
            console.log(this.clonedDragPoints, this.points);
            if((this.clonedDragPoints == null) && this.dragging) {
                this.clonedDragPoints = _.cloneDeep(this.points);
            }

            console.log(this.clonedDragPoints, this.points);
            if(this.dragPos === 0) {
                return;
            }

            let p = this.points;
            const currentP = this.clonedDragPoints[this.selectedPoint];

            const offset = this.dragPos - $(this.$el).offset().left - 3;
            const barWidth = $(this.$el).find('.gradient-input__items').width();

            let newPos = ((offset) / barWidth) * 100;
            newPos = Math.round(newPos);
            if(this.itemClickFlag) {
                if(Math.abs(this.dragStart - newPos) > 2) {
                    this.itemClickFlag = false;
                    this.showColorPicker = false;
                } else {
                    return;
                }
            }
            if(newPos > 100) {
                newPos = 100;
            } else if(newPos < 0) {
                newPos = 0;
            }

            p[this.selectedPoint].position = newPos;
            p = _.sortBy(p, ['position']);
            this.clonedDragPoints[this.selectedPoint].position = newPos;
            this.clonedDragPoints = _.sortBy(this.clonedDragPoints, ['position']);

            const pv = _.clone(this.propValue);
            pv.points = p;
            this.propValue = pv;
            const newIdx = _.indexOf(this.clonedDragPoints, currentP);
            if(newIdx !== this.selectedPoint) {
                this.selectedPoint = newIdx;
            }
            return;
        }
    },
    computed: {
        colorProps: function() { // propset для вложенного компонента prop_color
            var ref;
            return {
                color: (ref = this.points) != null ? ref[this.selectedPoint].color : 0
            };
        },
        points: {
            get: function() {
                if(this.propValue != null) {
                    return this.propValue.points;
                }
            }
        },
        type: {
            get: function() {
                if(this.propValue != null) {
                    return this.propValue.type;
                }
            },
            set: function(val) {
                this.propValue.type = val;
            }
        },
        angle: {
            get: function() {
                return this.propValue.angle;
            },
            set: function(val) {
                this.propValue.angle = val;
            }
        },
        center: {
            get: function() {
                return this.propValue.center;
            },
            set: function(val) {
                this.propValue.center = val;
            }
        },
        dragPos: function() {
            if(!this.dragging) {
                return 0;
            } else {
                return this.currentPage.rawCoords.x;
            }
        },
        previewStyle: function() {
            var gradCss, gradient, styles;
            if(this.propValue == null) {
                return;
            }
            gradient = this.propValue;
            gradCss = '';
            gradCss += 'linear-gradient(90deg';
            _.each(gradient.points, (point) => {
                var pointColor;
                pointColor = this.getValidColor(point.color);
                gradCss += `, rgba(${pointColor}) ${point.position}%`;
            });
            gradCss += ')';
            styles = {
                'background-image': gradCss
            };
            return styles;
        },
        gradientStyle: function() {
            var gradCss, gradient, styles;
            gradient = this.propValue;
            gradCss = '';
            switch(gradient.type) {
                case 'linear':
                    gradCss += 'linear-gradient(';
                    gradCss += gradient.angle + 'deg';
                    break;
                case 'radial':
                    gradCss += 'radial-gradient(at ';
                    gradCss += `${gradient.center[0]}% ${gradient.center[1]}%`;
            }
            _.each(gradient.points, (point) => {
                var pointColor;
                pointColor = this.getValidColor(point.color);
                gradCss += `, rgba(${pointColor}) ${point.position}%`;
            });
            gradCss += ')';
            styles = {
                'background-image': gradCss
            };
            return styles;
        }
    },
    methods: {
        updateProp: function(k, v) {
            var pv;
            if(k === 'color') {
                pv = _.clone(this.propValue);
                pv.points[this.selectedPoint].color = v;
                this.propValue = pv;
            }
        },
        calcMidColor: function(points, idx) {
            var c, c1, c2, i, j, p, pCur, pNext, pPrev;
            c1 = this.getValidColor(points[idx - 1].color);
            c2 = this.getValidColor(points[idx + 1].color);
            c = [];
            pNext = points[idx + 1].position;
            pPrev = points[idx - 1].position;
            pCur = points[idx].position;
            p = (pNext - pPrev) / 100 * pCur / 100;
            for(i = j = 0; j <= 3; i = ++j) {
                c[i] = c1[i] + (c2[i] - c1[i]) * p;
                if(i < 3) {
                    c[i] = Math.round(c[i]);
                }
            }
            return {
                own: c,
                palette: false
            };
        },
        selectPoint: function(e) {
            this.dragging = false;
            if(!this.itemClickFlag) {
                return;
            }
            this.selectedPoint = $(e.currentTarget).index();
            this.showColorPicker = true;
            this.$nextTick(function() {
                this.$refs.propColor.updateColorPicker();
            });
        },
        addPoint: function(e) {
            var clx, ctol, ctw, idx, newColor, newPoint, newPos, p, pv;
            clx = e.clientX;
            ctol = $(e.currentTarget).offset().left;
            ctw = $(e.currentTarget).width();
            newPos = (clx - ctol - 5) / ctw * 100;
            if(newPos > 100) {
                newPos = 100;
            } else if(newPos < 0) {
                newPos = 0;
            }
            p = this.points;
            newColor = {
                own: [255, 255, 255, 0],
                palette: false
            };
            newPoint = {
                position: newPos,
                color: newColor
            };
            p.push(newPoint);
            p = _.sortBy(p, ['position']);
            idx = _.indexOf(p, newPoint);
            this.selectedPoint = idx;
            $('.gradient-input__item').eq(this.selectedPoint).focus();
            switch(true) {
                case idx === 0:
                    newColor = _.cloneDeep(p[1].color);
                    break;
                case idx === p.length - 1:
                    newColor = _.cloneDeep(p[idx - 1].color);
                    break;
                default:
                    newColor = this.calcMidColor(p, idx);
            }
            newPoint.color = newColor;
            pv = _.clone(this.propValue);
            pv.points = p;
            this.propValue = pv;
        },
        updateCenter: function(e) {
            var px, py, x, y;
            this.mouseDown = true;
            x = e.pageX - $(e.currentTarget).offset().left;
            y = e.pageY - $(e.currentTarget).offset().top;
            px = parseInt(x / $(e.currentTarget).width() * 100);
            py = parseInt(y / $(e.currentTarget).height() * 100);
            this.center = [px, py];
            this.propValue = this.propValue;
        },
        updateAngle: function(e) {
            var cth, cto, ctw, dx, dy;
            this.mouseDown = true;
            cto = $(e.currentTarget).offset();
            ctw = $(e.currentTarget).width();
            cth = $(e.currentTarget).height();
            dx = e.pageX - cto.left - ctw / 2;
            dy = e.pageY - cto.top - cth / 2;
            this.angle = Math.round(Math.atan2(dy, dx) * 57.2958);
            this.propValue = this.propValue;
        },
        mouseMoveRadial: function(e) {
            if(!this.mouseDown) {
                return;
            }
            this.updateCenter(e);
        },
        mouseMoveLinear: function(e) {
            if(!this.mouseDown) {
                return;
            }
            this.updateAngle(e);
        },
        paramsMouseUp: function(e) {
            this.mouseDown = false;
            this.propValue = this.propValue;
        },
        itemMouseDown: function(e) {
            this.itemClickFlag = true;
            this.selectedPoint = $(e.currentTarget).index();
            this.dragging = true;
            this.dragStart = this.points[this.selectedPoint].position;
        },
        keyDown: function(e) {
            var pv;
            switch(e.key) {
                case 'Backspace':
                case 'Delete':
                    if(this.points.length <= 2) {
                        return;
                    }
                    pv = _.clone(this.propValue);
                    delete pv.points[this.selectedPoint];
                    pv.points = _.compact(pv.points);
                    if(pv.points.length <= this.selectedPoint) {
                        this.selectedPoint = pv.points.length - 1;
                        $('.gradient-input__item').eq(this.selectedPoint).focus();
                    }
                    this.propValue = pv;
                    this.$store.commit('saveHistory', 'gradPoint ' + e.key);
            }
        }
    }
};
</script>

<style lang="scss">
.property__type_gradient {
  padding: 0;

  > .property__btn_confirm {
    margin-top: 10px;
    position: relative;
    display: block;
    background: rgba(255, 255, 255, .1);
    height: 35px;
    box-sizing: border-box;
  }

  .gradient-input {
    position: relative;
    margin: 10px 0;
    height: 16px;
    width: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

    &__preview, &__items {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
    }

    &__items {
      width: auto;
      left: 0;
      right: 10px;
      cursor: crosshair;
    }

    &__item {
      position: absolute;
      display: block;
      // box-sizing: border-box;
      top: 0;
      height: 20px;
      width: 12px;
      margin: 0;
      padding: 0;
      border: 2px solid rgba(255, 255, 255, .3);
      border-radius: 2px;
      transform: translate(-2px, -2px);
      cursor: move;
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;

      &__active {
        border-color: #fff;
        // border-color: #10AFFF;
      }
    }
  }

  .property__input__center {
    display: inline-block;
    width: 45%;
  }

  .grp-handler-drag {
    border: 0;
    width: 10px;
    border-radius: 2px;
    margin-top: -2px;
    border: 2px solid rgba(255, 255, 255, .3);
    cursor: move;
  }

  .grp-handler.grp-handler-selected .grp-handler-drag {
    border-color: #fff;
  }

  .grp-handler-close-c {
    display: none;
  }

  .grp-handler-cp-wrap {
    display: none;
  }

  .sp-replacer {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 20px;

    &.hidden {
      height: 0;
      width: 0;
      margin: 0;
    }

    .sp-preview {
      width: 100%;
      border: 0;

      .sp-preview-inner {
      }
    }

    .sp-dd {
      display: none;
    }
  }
}

.gradient__params {
  position: relative;
  display: block;
  padding-top: 90%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

  &__center {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: crosshair;

    .guide-line__x {
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 0;
      border-left: 1px dashed #fff;
      //mix-blend-mode: difference;
      opacity: .5;
    }

    .guide-line__y {
      position: absolute;
      display: block;
      left: 0;
      height: 0;
      width: 100%;
      border-top: 1px dashed #fff;
      //mix-blend-mode: difference;
      opacity: .5;
    }
  }

  &__angle {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: crosshair;
    overflow: hidden;

    .guide-line {
      position: absolute;
      display: block;
      left: -10%;
      top: 50%;
      height: 0;
      width: 120%;
      border-top: 1px dashed #fff;
      //mix-blend-mode: difference;
      opacity: .5;
    }
  }
}
</style>
