<template>
    <div class="property property__type_select">
        <div class="property__name">
            {{ $t(property.title) }}
        </div>
        <div class="select select_theme_dark">
            <div class="select__input" @click="selectOpen = true">
                {{ $t(options[value]) }}
                <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        transform="rotate(180 23.5 7.024)"
                        d="M42 8l3 4.049h-6z"
                        fill="#FFF"
                        fill-rule="evenodd"
                    />
                </svg>
            </div>
            <div class="select__body" v-show="selectOpen">
                <div class="select__body-scrollable">
                    <div
                        class="select__option" v-for="(v, k) in options"
                        @click="selectOption(k)"
                        :class="{'select__option_asterisk': property.asterisk ? property.asterisk[k] : false, 'select__option_blocked': property.blocked ? property.blocked[k] : false}"
                        :title="property.asterisk ? $t(property.asterisk[k]) : ''">
                        {{ $t(v) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from '@/components/editor/property/PropBase';

export default {
    name: 'prop_select',
    inject: ['currentPage'],
    mixins: [PropBase],
    data() {
        return {
            selectOpen: false
        };
    },
    computed: {
        options() {
            if(typeof this.propValue === 'string') {
                return this.property.options;
            } else {
                return this.propValue.options;
            }
        },
        value() {
            if(this.multiple) {
                return -1;
            }
            if(typeof this.propValue === 'string') {
                return this.propValue;
            } else {
                return this.propValue.value;
            }
        },
        multiple() {
            if(this.propset.multipleValuesKeys == null) {
                return false;
            }
            return this.propset.multipleValuesKeys.includes(this.property.name);
        }
    },
    methods: {
        selectOption: function (type) {
            var ref;
            if((ref = this.property.blocked) != null ? ref[type] : undefined) {
                return;
            }
            this.propValue = type;
            this.$store.commit('saveHistory', this.property.title);
        }
    },
    watch: {
        'currentPage.mousePressed.left': function () {
            if(!this.currentPage.mousePressed.left) {
                this.selectOpen = false;
            }
        }
    }
};

</script>

<i18n>
{
    "en": {
        "Easing": "Easing",
        "Order": "Order",
        "Effect": "Effect",
        "Fill mode": "Fill mode",
        "None": "None",
        "From right": "From right",
        "From left": "From left",
        "From top": "From top",
        "From bottom": "From bottom",
        "Zoom in": "Zoom in",
        "Zoom out": "Zoom out",
        "Fade": "Fade",
        "Stretch": "Stretch",
        "Cover": "Cover",
        "Tile": "Tile",
        "Custom": "Custom",
        "EaseIn": "EaseIn",
        "EaseOut": "EaseOut",
        "EaseInOut": "EaseInOut",
        "1": "1",
        "2": "2",
        "Direction": "Direction",
        "Horizontal": "Horizontal",
        "Vertical": "Vertical",
        "Type": "Type",
        "Slide": "Slide",
        "Vortex": "Vortex",
        "Morphing": "Morphing",
        "Screen": "Screen",
        "May run slowly on low-end devices": "May run slowly on low-end devices",

        "North": "Up",
        "East": "Right",
        "South": "Down",
        "West": "Left"
    },
    "ru": {
        "Easing": "Движение",
        "Order": "Порядок",
        "Effect": "Эффект",
        "Fill mode": "Заливка",
        "None": "Нет",
        "From right": "Справа",
        "From left": "Слева",
        "From top": "Сверху",
        "From bottom": "Снизу",
        "Zoom in": "Приблизить",
        "Zoom out": "Отдалить",
        "Fade": "Исчезание",
        "Stretch": "Растянуть",
        "Cover": "Покрыть",
        "Tile": "Повторение",
        "Custom": "Указать",
        "EaseIn": "EaseIn",
        "EaseOut": "EaseOut",
        "EaseInOut": "EaseInOut",
        "1": "1",
        "2": "2",
        "Direction": "Направление",
        "Horizontal": "Горизонтально",
        "Vertical": "Вертикально",
        "Type": "Тип",
        "Slide": "Слайд",
        "Vortex": "Vortex",
        "Morphing": "Morphing",
        "Screen": "Экран",
        "May run slowly on low-end devices": "Может работать медленно на слабых устройствах",

        "North" : "Вверх",
        "East" : "Вправо",
        "South" : "Вниз",
        "West" : "Влево"
    }
}
</i18n>
