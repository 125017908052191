<template>
    <div class="project-settings" @mousedown="mousePressed = true" @mouseup="mouseUp">
        <div class="project-settings__title">
            {{ $t('projectSettings') }}
        </div>
        <div class="close project-settings__close" @click="$parent.showProjectSettings = false" />
        <prop_theme_select
            v-if="$store.state.presentation && $store.state.presentation.theme && $store.state.themes"
            :propset="themeProps"
            :property="'theme'"
        />
    </div>
</template>

<script>
// Этот компонент закомментировал не Ильдар
// `import prop_font_family from '../property/prop_font_family/prop_font_family.coffee'`
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";
import PropThemeSelect from "@/components/editor/property/PropThemeSelect";

export default {
  inject: ['currentPage'],
  name: 'ProjectSettings',
  mixins: [PropBase],
  components: {
    // prop_font_family:prop_font_family
    prop_theme_select: PropThemeSelect
  },
  data: function() {
    return {
      mousePressed: false
    };
  },
  computed: {
    themeProps: function() {
      return {
        theme: this.$store.state.presentation.theme
      };
    }
  },
  methods: {
    mouseUp: function() {
      return setTimeout(() => {
        this.mousePressed = false;
      }, 0);
    }
  },
  watch: {
    'currentPage.mousePressed.left': function() {
      if (this.currentPage.mousePressed.left) {
        if (!this.mousePressed) {
          this.$parent.showProjectSettings = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.project-settings{
  position:absolute;
  display:block;
  box-sizing: border-box;
  top:0;
  right:0;
  bottom:0;
  width: 230px;
  padding: 0 20px;
  background: #2A2C2D;
  color: #777777;
  height: 100%;
  z-index: 1;

  &__title{
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    line-height: 16px;
    padding: 5px 0;
    color:#fff;
    flex-grow:0;
  }

  &__close{
    position:absolute;
    display:block;
    top: 4px;
    right: 8px;
  }

  .property__tabs{
    left: -8px;
    top: 60px;
  }

  .property{
    padding: 10px 0;
  }
}
</style>

<i18n>
{
    "en": {
        "projectSettings": "Presentaion settings"

    },
    "ru": {
        "projectSettings": "Настройки презентации"
    }
}
</i18n>
