/* eslint-disable */

import _ from 'lodash';
import DarkTheme from '@/themes/DarkTheme.js';

export default {
  data() {
    return {
      componentStyles: {}
    };
  },
  methods: {
    calcThemeStyles() { // метод описывается отдельно в каждом компоненте, наследующим миксин
      this.componentStyles = {};
      // console.log @$options.name
      // console.log 'Styles not defined'
      return {};
    },

    submitThemeStyles() {
      if(this.componentStylesGetter[this.$options.name] != null) {
        return;
      }
      if((this.calcThemeStyles == null)) {
        return;
      }
      this.calcThemeStyles();
      const s = _.cloneDeep(this.componentStylesGetter);
      s[this.$options.name] = this.componentStyles;
      this.componentStylesGetter = s;
    },

    getColor(item){
      const n = DarkTheme.layout[this.$options.name][item];
      const c = DarkTheme.colors[n];
      return c;
    }
  },
  created() {
    this.submitThemeStyles();
  }
};
