<template>
    <div class="property property__type_font-family">
        <div class="property__name" v-if="property.title">
            {{ $t(property.title) }}
        </div>
        <div class="select select_theme_dark">
            <div class="select__input" @mousedown.prevent.stop="selectOpen = true">{{ propValue }}
                <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF"
                        fill-rule="evenodd">
                    </path>
                </svg>
            </div>
            <div class="select__body" v-show="selectOpen">
                <div class="select__body-scrollable">
                    <div
                        class="select__option"
                        v-for="font in currentPage.fonts"
                        :style="'font-family:' + font"
                        @mousedown.prevent="selectOption(font)">{{ font }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PropBase from '@/components/editor/property/PropBase';

export default {
    name: 'propFontFamily',
    inject: ['currentPage'],
    mixins: [PropBase],
    data () {
        return {
            selectOpen: false
        };
    },
    methods: {
        selectOption (font) {
            this.propValue = font;
        }
    },
    watch: {
        'currentPage.mousePressed.left' () {
            if (!this.currentPage.mousePressed.left) {
                this.selectOpen = false;
            }
        }
    }
};

</script>

<i18n>
{
    "en": {
        "Typeface": "Typeface"
    },
    "ru": {
        "Typeface": "Шрифт"
    }
}
</i18n>

