<template>
  <div class="property property__type_dash">
    <div class="col-50" v-for="r in ref">
      <prop_number v-bind:propset="strokeProps" v-bind:property="r"></prop_number>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import PropBase from "@/components/editor/property/PropBase";
import PropNumber from "@/components/editor/property/PropNumber";

export default {
  inject: ['currentPage'],
  name: 'prop_dash',
  mixins: [PropBase],
  components: {
    prop_number: PropNumber
  },
  data: function() {
    return {
      dashGapRatio: 1,
      ref: {
        dash: {
          name: 'dash',
          title: 'Dash',
          min: 0,
          max: 100
        },
        //units:'px'
        gap: {
          name: 'gap',
          title: 'Gap',
          min: 0,
          max: 100
        }
      }
    };
  },
  //units:'px'
  computed: {
    dragPos: function() {
      if (!this.currentPage.mousePressed.left) {
        this.dragging = false;
      }
      if (!this.dragging || this.mode === 'input') {
        return this.dragStart;
      } else {
        return this.currentPage.rawCoords.x;
      }
    },
    strokeProps: function() {
      if (this.propValue == null) {
        return {
          dash: 0,
          gap: 0
        };
      }
      return {
        dash: this.propValue[0],
        gap: this.propValue[1]
      };
    }
  },
  methods: {
    updateProp: function(k, v) {
      if (this.propValue == null) {
        return;
      }
      // console.log 'propValue undefined'
      if (k === 'dash') {
        this.propValue = [v, v * this.dashGapRatio];
      }
      if (k === 'gap') {
        this.propValue = [this.propValue[0], v];
        if (this.propValue[0] === 0 || this.propValue[1] === 0) {
          this.dashGapRatio = 1;
        } else {
          this.dashGapRatio = this.propValue[1] / this.propValue[0];
        }
      }
      if (this.propValue[0] === 0 || this.propValue[1] === 0) {
        this.$parent.updateProp('stroke-type', 'solid');
      } else {
        this.$parent.updateProp('stroke-type', 'dashed');
      }
    }
  }
};
</script>

<style lang="scss">
.property__type_dash{
  padding-right: 0;
  .property{
    padding: 0;
  }
  .col-50{
    padding-right: 0;
  }
}
</style>
