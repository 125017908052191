<script>
/* eslint-disable */
import Vue from "vue";
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'RectangleShape',
  mixins: [Shape],
  data() {
    return {
      type: 'rectangle'
    };
  },
  methods: {
    overflow(e) {
      if (this.currentPage.metaTool.invertedAxis.y) {
        e = -e;
      }
      this.currentPage.metaTool.moveBottom(e);
    },
    changeAdjustToTextFlag(val) {
      Vue.set(this.opts.styleProps, 'adjust-to-text', val);
      if (val && this.opts.styleProps['lock-aspect']) {
        Vue.set(this.opts.styleProps, 'lock-aspect', false);
      }
      this.$refs.text.emitOverflowEvent();
    },
    adjustChildText() {
      return this.$nextTick(function () {
        this.$refs.text.emitOverflowEvent();
      });
    }
  },
  computed: {
    placeholder() {
      if (this.currentPage.isRenderer || this.currentPage.who !== 'editor') {
        return false;
      }
      return this.opts.placeholder;
    },
    placeholderCondition() {
      let ref;
      if (this.currentPage.isRenderer) {
        return false;
      }
      const text = this.opts.text;
      const meIsTarget = ((ref = this.currentPage.editingObjects[0]) != null ? ref.vueObj : undefined) === this;
      const emptyText = text.ps.length === 0 || (text.ps.length === 1 && text.ps[0].spans.length === 1 && text.ps[0].spans[0].content === '');
      const isSelected = this.isSelected || meIsTarget;
      return this.placeholder && emptyText && !isSelected;
    },
    magnetCoordsDecart() {
      return [
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[1].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        },
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.shape{
  position: absolute;
  box-sizing: border-box;
  background-clip: padding-box;
  backface-visibility: hidden;
  // overflow: hidden;

  &__circle{
    border-radius: 50%;
  }

  &__drop-media{
    position:absolute;
    display:block;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background: #10AFFF33;
    z-index: 2;
  }

  &__selected-border{
    opacity: 0;
    fill: transparent;
    stroke-width: 2;
    stroke: #4BFF;
    pointer-events: none;
  }

  &.selected{
    .shape__selected-border{
      opacity: 0.7;
    }
  }

  &:not(.text-selected) {
    .text-wrapper{
      cursor: default;
      pointer-events: none;
    }
  }

  &>svg{
    overflow: visible;
    position: absolute;
    path{
      stroke-miterlimit: 10;
    }
  }

  &__gradient, &__bg-image{
    position:absolute;
    display:block;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }
  &__bg-image{
    background-position: center;
  }
  &__border{
    pointer-events: none;
  }
  .text-placeholder{
    padding: 10px;
  }
  foreignObject{
    overflow: hidden;
  }
}
</style>
