<template>
    <div class="service-bar__item">
        <div class="service-bar__title-wrapper" @mousedown.stop="$emit('toogle-list')">
            <slot name="activator"></slot>
        </div>
        <slot name="options"></slot>
    </div>
</template>

<script>
export default {
    name: 'ServiceBarDropdown'
};
</script>

<style scoped lang="scss">

</style>
