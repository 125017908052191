<template>
    <div class="thumbs-bar" @click.right.stop.prevent="">
        <div class="thumbs-bar__scroller custom-scroll" @mousewheel.prevent="wheel" @scroll.prevent="scroll"
             @mousedown="$parent.checkBgEditingCondition">
            <div class="thumbs-bar__item thumbs-bar__drop-shadow" v-if="dragging" :style="dropShadowStyle"></div>
            <template v-if="slidesPreviews">
                <slide-preview
                    v-for="(slidePreview, slideIndex) in slidesPreviews"
                    ref="slidePreviews"
                    :delta="delta"
                    :key="slideIndex"
                    :index="slideIndex"
                    :slide="presentation.slides[slideIndex]"
                    :previewHtml="slidePreview"
                    :checkdrop="dragging"
                    :vjson="$store.state.vjsons.slides[slideIndex]"
                    :previewWidth="previewWidth"
                    :class="{'thumbs-bar__item__active': presentation.slides[slideIndex] && presentation.slides[slideIndex].active}"
                    @updatedrag="updateDrag($event)"
                    @previewcoordschanged="previewcoordschanged"
                    @menuclicked="closeAllMenus">
                </slide-preview>
            </template>

            <div
                class="thumbs-bar__item thumbs-bar__add-new-slide" @click="addNewSlide"
                :style="{order:1000, width: previewWidth + 'px', 'min-width': previewWidth + 'px'}"
                @mouseenter="addNewSlideMouseenter" @mouseleave="addNewSlideMouseleave" ref="addNewSlideButton">
                <slide-templates
                    :isVisible="showTemplates" :previewWidth="previewWidth" :delta="delta"
                    :rightBound="rightTemplatesBound" :leftBound="leftTemplatesBound"
                    :leftMargin="addNewSlideButtonLeftMargin" @templateselected="templateselected">
                </slide-templates>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import _ from "lodash";
import $ from 'jquery';

import UiColors from "@/mixins/editor/UiColors";
import SlidePreview from "@/components/editor/SlidePreview";
import SlideTemplates from "@/components/editor/SlideTemplates";

export default {
    name: "SlidePreviews",
    mixins: [UiColors],
    components: {
        slidePreview: SlidePreview,
        slideTemplates: SlideTemplates
    },
    data () {
        return {
            dragging: false,
            dragDropId: 0,
            dragTarget: 0,
            dropShadowOrder: 0,
            delta: 0,
            scrollWanted: {
                left: false,
                right: false
            },
            scrollWantedIntervals: {
                left: null,
                right: null
            },
            previewHeight: 60,
            showTemplates: false,
            showTemplatesTimeout: 50,
            showTemplatesTimeoutObject: undefined,
            addNewSlideButtonLeftMargin: 12,
            selfRect: null,
            addNewSlideButtonRect: null
        };
    },
    watch: {
        dragging (val) {
            this.$refs.slidePreviews.forEach(function (target) {
                target.getCenter();
            });

            if (!val) {
                this.scrollWanted.left = false;
                this.scrollWanted.right = false;
                clearInterval(this.scrollWantedIntervals.left);
                clearInterval(this.scrollWantedIntervals.right);
            }
        },
        scrollWanted: {
            deep: true,
            handler (val) {
                const directions = ['left', 'right'];
                directions.forEach((d) => {
                    if (val[d]) {
                        return this.scrollWantedIntervals.left = setInterval(() => {
                            let cs, delta, newCs, newVal, scroller;
                            scroller = $(this.$el).find('.thumbs-bar__scroller');
                            cs = scroller.scrollLeft();
                            if (d === 'left') {
                                newVal = cs - 5;
                            }
                            if (d === 'right') {
                                newVal = cs + 5;
                            }
                            scroller.scrollLeft(newVal);
                            newCs = scroller.scrollLeft();
                            delta = cs - newCs;
                            this.$refs.slidePreviews.forEach(function (target) {
                                let currentCenter, newCenter;
                                currentCenter = target.center;
                                newCenter = {
                                    y: currentCenter.y,
                                    x: currentCenter.x + delta
                                };
                                target.center = newCenter;
                            });
                        }, 16);
                    } else {
                        return clearInterval(this.scrollWantedIntervals[d]);
                    }
                });
            }
        }
    },
    mounted () {
        this.customWatch();
    },
    computed: {
        presentation () {
            return this.$store.state.presentation;
        },
        slidesPreviews () {
            return this.$store.state.previewsHtmls;
        },
        // scale:->
        //   scale = 0.0714286
        //   return scale
        dropShadowStyle () {
            return {
                order: this.dropShadowOrder,
                width: this.previewWidth + 'px',
                'min-width': this.previewWidth + 'px'
            };
        },
        previewWidth () {
            const presentationSize = this.$store.state.presentation.size;
            const presentationAspectRatio = presentationSize.w / presentationSize.h;

            return this.previewHeight * presentationAspectRatio;
        },
        slideTemplates () {
            return this.$store.state.slideTemplates;
        },
        rightTemplatesBound () {
            let ref;
            return (ref = this.selfRect) != null ? ref.right : 0;
        },
        leftTemplatesBound () {
            let ref;
            return ((ref = this.addNewSlideButtonRect) != null ? ref.left : 0) + this.addNewSlideButtonLeftMargin;
        }
    },
    methods: {
        customWatch () {
            this.$watch(function () {
                return [this.$parent.resizeFlag, this.delta];
            }, function () {
                this.getAllRects();
            }, {
                immediate: true,
                deep: true
            });
        },
        addNewSlide () {
            this.$store.commit('addNewSlide');

            // eslint-disable-next-line vue/valid-next-tick
            Vue.nextTick(function () {
                return this.scrollToEnd();
            }, this);
        },
        wheel (e) {
            let cs, scroller, valueUsed;
            scroller = $(this.$el).find('.thumbs-bar__scroller');
            cs = scroller.scrollLeft();
            if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
                valueUsed = 'deltaY';
            } else {
                valueUsed = 'deltaX';
            }
            scroller.scrollLeft(cs + e[valueUsed]);
        },
        // При скролле необходимо вычислять смещение контекстного меню превьюхи
        scroll (e) {
            this.delta = $(this.$el).find('.thumbs-bar__scroller').scrollLeft();
        },
        scrollToEnd () {
            let tb, that;
            tb = $(this.$el).find('.thumbs-bar__scroller');
            that = this;
            setTimeout(function () {
                tb.scrollLeft(tb[0].scrollWidth - tb[0].clientWidth + 1000);
            }, 50);
        },
        updateDrag (e) {
            _.each(e, (val, key) => {
                return this[key] = val;
            });
        },
        previewcoordschanged (e) {
            var clx, scroller, scrollerWidth, treshold;
            scroller = $(this.$el).find('.thumbs-bar__scroller')[0];
            scrollerWidth = scroller.getBoundingClientRect().width;
            treshold = 50;
            clx = e.clientX;
            if (clx < treshold) {
                this.scrollWanted.left = true;
            } else {
                this.scrollWanted.left = false;
            }
            if (clx > scrollerWidth - treshold) {
                this.scrollWanted.right = true;
            } else {
                this.scrollWanted.right = false;
            }
        },
        closeAllMenus () {
            this.$refs.slidePreviews.forEach(function (t) {
                return t.closeNav();
            });
        },
        calcThemeStyles () {
            return this.componentStyles = {
                '.thumbs-bar': {
                    background: this.getColor('background')
                },
                '.thumbs-bar .thumbs-bar__item__active, .thumbs-bar .thumbs-bar__item__active:hover': {
                    'border-color': this.getColor('activeColor')
                },
                '.thumbs-bar__mode__active': {
                    'border-left-color': this.getColor('activeColor')
                }
            };
        },
        addNewSlideMouseenter (e) {
            this.clearShowTemplatesTimeout();
            this.showTemplatesTimeoutObject = setTimeout(() => {
                this.showTemplates = true;
            }, this.showTemplatesTimeout);
        },
        addNewSlideMouseleave (e) {
            this.clearShowTemplatesTimeout();
            this.showTemplates = false;
        },
        clearShowTemplatesTimeout () {
            clearTimeout(this.showTemplatesTimeoutObject);
        },
        templateselected () {
            this.clearShowTemplatesTimeout();
            this.showTemplates = false;
        },
        getAllRects () {
            this.getSelfRect();
            return this.getAddNewSlideButtonRect();
        },
        getSelfRect () {
            this.selfRect = this.$el.getBoundingClientRect();
        },
        getAddNewSlideButtonRect () {
            this.addNewSlideButtonRect = this.$refs.addNewSlideButton.getBoundingClientRect();
        }
    }
};
</script>

<style lang="scss">
.thumbs-bar {
    position: relative;
    height: 94px;
    // margin-bottom: -20px;
    border-top: 1px solid rgba(170, 170, 170, .1);
    z-index: 1;

    &__scroller {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        // left: 42px;
        left: 0;
        padding: 15px 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: scroll;
        overflow-y: hidden;
        box-sizing: border-box;
    }

    &__modes {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        border-right: 1px solid rgba(255, 255, 255, .1);
    }

    &__mode {
        position: relative;
        display: block;
        height: 47px;
        width: 100%;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        box-sizing: border-box;
        cursor: pointer;

        svg {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.5;
        }

        &:hover svg {
            opacity: 1
        }

        &__active svg {
            opacity: 1
        }
    }

    &__menu.context-menu {
        position: fixed;
        display: block;
        z-index: 1;
        width: auto;
        max-width: 320px;
    }

    &__menu-btn {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        opacity: 0;
        z-index: 1;
        overflow: hidden;
        // background: #000;

        svg {
            position: relative;
            display: block;
            margin: 5px auto;
        }
    }

    &__item {
        position: relative;
        display: block;
        margin: 0 5px;
        border: 2px solid transparent;
        height: 60px;
        min-width: 90px;
        width: 90px;
        cursor: pointer;
        box-sizing: content-box;

        &:hover {
            border-color: rgba(255, 255, 255, .2);

            .thumbs-bar__item-inner:after {
                background: #0015;
            }
        }

        &-inner {
            position: absolute;
            display: block;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: all .3s;
            }
        }

        &:hover {
            .thumbs-bar__menu-btn {
                opacity: 1;
            }
        }

        .slide-bg {
            opacity: 1;
        }

        .slide {
            transform-origin: 0 0;
            opacity: 1;
            left: 50%;
            top: 50%;
            right: auto;
            bottom: auto;
        }

        &.thumbs-bar__drop-shadow {
            background: rgba(0, 0, 0, 0.3);
            border-radius: 5px;
        }
    }

    & &__add-new-slide {
        border: 2px dashed rgba(170, 170, 170, 1);
        border-radius: 5px;
        height: 56px;
        width: 86px;
        top: 2px;
        opacity: 0.5;

        &:hover {
            opacity: 1;

            .expand-templates-button {
                opacity: 0.3;
            }
        }

        &:after {
            content: '+';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgba(170, 170, 170, 1);

            font-size: 24px;
            line-height: 24px;
            text-align: center;
            height: 24px;
        }

        &:before {
            content: '';
            position: absolute;
            display: block;
            height: 100%;
            width: 20px;
            right: -20px;
            pointer-events: none;
        }
    }

    .slide-thumb__delete-slide {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: red;
        right: -10px;
        top: -10px;
    }

    // Компенсация затухания объектов при редатировании фона
    .object svg {
        opacity: 1 !important;
    }
}
</style>
