<template>
    <div class="side-bar side-bar_right" @keydown.stop="" @keyup.stop="">
        <media_browser v-show="showMedia" :visible="showMedia" ref="mediaBrowser"></media_browser>
        <div class="prop-panel custom-scroll" v-if="currentPage.presentation">
            <template v-if="!showTextPanelOnly && !flags.bgEditingMode">
                <prop_group
                    v-for="(group, index) in posPropGroups"
                    :key="'p'+target.id+'_'+index"
                    :group="group"
                    :propset="posProps"
                    :mode="mode"
                />
                <div class="prop-panel__actions" v-if="mode=='group'">
                    <div class="btn btn_editor-btn" @click="groupBtnClick">{{ groupBtn }}</div>
                </div>
                <prop_group v-for="(group, index) in stylePropGroups" v-if="Object.keys(styleProps).length > 0"
                            :key="'s' + target.id + '_' + index" :group="group" :propset="styleProps"
                            :mode="mode"></prop_group>
            </template>
            <prop_group
                v-if="$store.state.flags.bgEditingMode"
                :group="stylePropGroups.filter(g => g.groupName === 'Fill' || g.groupName === 'Background')[0]"
                :propset="styleProps"
                :mode="mode"
            />
            <prop_group v-if="textProps && !flags.bgEditingMode" :group="textPropGroup" :propset="textProps"
                        :mode="mode"></prop_group>
            <prop_group v-if="animationProps && !showTextPanelOnly && !flags.bgEditingMode"
                        :group="animationPropGroup" :propset="animationProps" @preview="previewAnimation"
                        :mode="mode"></prop_group>
            <prop_group
                v-if="seoProps && !showTextPanelOnly && !flags.bgEditingMode && currentPage.user.isAdmin()"
                :group="seoPropGroup" :propset="seoProps" :mode="mode"></prop_group>
            <div
                v-if="mode=='slide' && !flags.bgEditingMode"
                class="property__btn property__btn_transitions"
                @click="showTransitions = true"
            >
                {{ $t('Transitions') }}
            </div>
            <transitions v-if="showTransitions"></transitions>
            <project_settings v-if="$store.state.presentation && showProjectSettings"></project_settings>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import Vue from "vue";

import propTypes from './prop_types';
import PGR from './prop_groups_reference';
import TPGR from './types_prop_groups_reference';

import UiColors from "@/mixins/editor/UiColors";
import PropGroup from "@/components/editor/PropGroup";
import Transitions from "@/components/editor/Transitions";
import PropOrder from "@/components/editor/property/PropOrder";
import ProjectSettings from "@/components/editor/ProjectSettings";
import MediaBrowser from "@/components/editor/MediaBrowser/MediaBrowser";

export default {
    name: "PropPanel",
    mixins: [UiColors],
    inject: ['currentPage'],
    components: {
        prop_order: PropOrder,
        prop_group: PropGroup,
        media_browser: MediaBrowser,
        transitions: Transitions,
        project_settings: ProjectSettings
    },

    watch: {
        'currentPage.mousePressed'() {
            // Почему эта логика здесь???????
            if (!this.currentPage.mousePressed.left) {
                if ((this.propEditing != null) && this.propEditing) {
                    if (this.posPropsNames.includes(this.propEditing)) {
                        this.currentPage.metaTool.endPanelProcess();
                    } else {
                        if (!this.currentPage.historyHandlerOverride) {
                            this.$store.commit('saveHistory', this.propEditing);
                        }
                    }
                    this.propEditing = false;
                }
                if (this.textPropEditing && (this.currentPage.editingText != null)) {
                    this.textPropEditing = false;
                    this.currentPage.editingText.setProperty('mouseUp');
                }
            }
        },

        showTransitions() {
            this.currentPage.showTransitions = this.showTransitions;
            if (!this.showTransitions) {
                this.$store.commit('saveHistory', 'transitions');
            }
        },

        groupsCollapsed: {
            handler(val) {
                this.currentPage.setSetting('groupsCollapsed', val);
            },
            deep: true
        }
    },


    data() {
        return {
            // propOrderTargets:{}
            showTransitions: false,
            showMedia: false, //false / true
            showProjectSettings: false, //false / true
            textPropEditing: false,
            mode: 'slide', // / object / group
            groupsCollapsed: this.getCollapsedGroupsSettings(),
            posPropsNames: [
                'x',
                'y',
                'x1',
                'y1',
                'x2',
                'y2',
                'height',
                'width',
                'rotate'
                // 'lock-aspect'
            ],
            textPropsNames: [
                'auto-height',
                'font-family',
                'font-size',
                'font-weight',
                'font-style',
                'text-decoration',
                'text-transform',
                'line-height',
                'letter-spacing',
                'color',
                'text-background',
                'paddings',
                'list-level',
                'list',
                'text-align',
                'vertical-align'
            ],
            tablePropsNames: [
                'table-background-color',
                'table-stroke-color',
                'table-stroke-width',
                'table-stroke-dash',
                'table-borders',
                'table-fill-template',
                'table-bg1-color',
                'table-bg2-color',
                'table-acc-color'
            ],
            seoPropsNames: [
                'seo-header',
                'seo-text',
                'seo-image'
            ]
        };
    },

    computed: {
        flags() {
            return this.$store.state.flags;
        },
        metaTool() {
            return this.currentPage.metaTool;
        },
        posPropGroups() {
            const groups = [];
            if (this.target != null) {
                let names;
                if (this.mode === 'object') {
                    if ((this.target.vueObj.opts == null)) {
                        // console.log 'new object'
                        return [];
                    }
                    if ((TPGR[this.target.vueObj.opts.type] == null)) {
                        // console.log 'invalid object type'
                        return [];
                    }
                    // console.log '!!! '+@metaTool.tableInEditingObjects
                    if (this.metaTool.tableInEditingObjects) {
                        names = 'tablePos';
                    } else {
                        if (this.target.vueObj.opts.type === 'line') {
                            names = 'linePos';
                        } else {
                            names = 'shapePos';
                        }
                    }
                }
                if (this.mode === 'group') {
                    if (this.metaTool.tableInEditingObjects) {
                        names = 'tablePos';
                    } else {
                        names = 'groupPos';
                    }
                }
                _.each(TPGR[names], function (gn) {
                    const group = {
                        title: PGR[gn]['title'],
                        groupName: PGR[gn]['groupName'],
                        groupClass: PGR[gn]['groupClass'],
                        properties: PGR[gn]['properties'].map(propType => propTypes[propType])
                    };
                    groups.push(group);
                });
                return groups;
                //if @target?
                //  return @target.vueObj.posPropGroups
            } else {
                return [];
            }
        },
        textPropGroup() {
            return {
                title: PGR['text']['title'],
                groupName: PGR['text']['groupName'],
                groupClass: PGR['text']['groupClass'],
                properties: PGR['text']['properties'].map(propType => propTypes[propType])
            };
        },
        animationPropGroup() {
            return {
                title: PGR['animation']['title'],
                groupName: PGR['animation']['groupName'],
                groupClass: PGR['animation']['groupClass'],
                properties: PGR['animation']['properties'].map(propType => propTypes[propType])
            };
        },
        seoPropGroup() {
            return {
                title: PGR['seo']['title'],
                groupClass: PGR['seo']['groupClass'],
                properties: PGR['seo']['properties'].map(propType => propTypes[propType])
            };
        },
        stylePropGroups() {
            const groups = [];

            if (this.target != null) {
                //console.log @target.vueObj.opts.type
                let names;
                if (this.mode === 'object') {
                    if ((this.target.vueObj.opts == null)) {
                        // console.log 'new object'
                        return [];
                    }

                    if (!TPGR[this.target.vueObj.opts.type]) {
                        // console.log 'invalid object type'
                        return [];
                    }
                    names = this.target.vueObj.opts.type;
                }

                if (this.mode === 'slide') {
                    names = 'slide';
                }

                if (this.mode === 'group') {
                    names = 'group';
                }

                _.each(TPGR[names], gn => {

                    if (gn === 'animation') {
                        return;
                    }

                    const group = {
                        title: PGR[gn]['title'],
                        groupName: PGR[gn]['groupName'],
                        groupClass: PGR[gn]['groupClass'],
                        properties: PGR[gn]['properties'].map(propType => propTypes[propType])
                    };

                    if (this.mode === 'group') {

                        const suitableGroups = _.filter(PGR, function (g) {
                            if (g.groupName === group.groupName) {
                                return true;
                            }
                            return false;
                        });

                        let suitableProps = suitableGroups.map(sg => sg.properties);
                        suitableProps = _.flatten(suitableProps);
                        const actualProps = Object.keys(this.styleProps);
                        const intersection = _.intersection(actualProps, suitableProps);

                        if (intersection.length > 0) {
                            groups.push(group);
                        }
                        return;

                    } else {
                        groups.push(group);
                    }

                });

                return groups;
            } else {
                return [];
            }
        },

        target() {
            if (this.currentPage.editingObjects?.length > 1) { //выделено несколько объектов
                this.mode = 'group';
                return this.currentPage.editingObjects;
            }

            if (this.currentPage.editingObjects?.length === 1) { //выделен 1 объект
                this.mode = 'object';
                return this.currentPage.editingObjects[0];
            }

            // Ищем в закэшированных объектах, если не нашли в обычных
            if (this.metaTool?.editingObjectsCache?.length === 1) {
                this.mode = 'object';
                return this.metaTool.editingObjectsCache[0];
            }

            if (this.metaTool?.editingObjectsCache?.length > 1) {
                this.mode = 'group';
                return this.currentPage.editingObjects;
            }

            // ничего не выделено, показываем свойства активного слайда
            if (this.currentPage.editingObjects?.length === 0) {
                this.mode = 'slide';
                return this.$store.state.activeSlide;
            }
        },

        // переменные позиционирования, с которыми
        // работает панель свойств объекта propPanel
        posProps: {
            get() {
                const props = this.currentPage.metaToolProps;
                const res = {};
                if ((props[0] != null) && (props[1] != null)) {
                    res.x = Math.round(props[0].x);
                    res.y = Math.round(props[0].y);
                    res.width = Math.round(props[1].x - props[0].x);
                    res.height = Math.round(props[1].y - props[0].y);
                    res.rotate = props.rotate;
                } else {
                    res.x1 = Math.round(props.x1);
                    res.y1 = Math.round(props.y1);
                    res.x2 = Math.round(props.x2);
                    res.y2 = Math.round(props.y2);
                }

                if ((this.mode === 'object') && (this.styleProps['lock-aspect'] != null)) {
                    res['lock-aspect'] = this.styleProps['lock-aspect'];
                }

                if (this.styleProps['opacity'] != null) {
                    res['opacity'] = this.styleProps['opacity'];
                }

                return res;
            },
            set(val) {
                // console.log val
            }
        },

        // переменные стилей, с которыми
        // работает панель свойств объекта propPanel
        styleProps() {
            if (this.currentPage.editingTable?.tableProps != null) {
                const table = this.currentPage.editingTable;
                return Object.assign({}, table.tableProps, table.tableFillProps);
            }
            if ((this.mode === 'object') && (this.target.vueObj.styleProps != null)) {
                return this.target.vueObj.styleProps;
            }
            if ((this.mode === 'slide') && (this.target.bg.vueObj.styleProps != null)) {
                return this.target.bg.vueObj.styleProps;
            }
            if (this.mode === 'group') {

                const styleProps = {};

                const eachObjectStyles = this.target.map(function (t) {
                    if (t.styleProps != null) {
                        return Object.keys(t.styleProps);
                    }
                    if (t.tableData?.style != null) {
                        return Object.keys(t.tableData.style);
                    } else {
                        return [];
                    }
                });
                const jointStyles = _.intersection(...eachObjectStyles);

                jointStyles.forEach(s => {

                    const styleTarget = this.target.find(function (t) {
                        if (t.styleProps?.[s] != null) {
                            return true;
                        }
                        return false;

                    });

                    styleProps[s] = styleTarget.styleProps[s];

                });

                const onFlags = ['fill-on', 'stroke-on'];

                onFlags.forEach(f => {
                    if (styleProps[f] != null) {

                        const indexOfObjectWithFlagOn = this.target.findIndex(function (t) {
                            if (t.styleProps[f]) {
                                return true;
                            }
                            return false;
                        });

                        if (indexOfObjectWithFlagOn !== -1) {
                            styleProps[f] = true;
                        }
                    }

                });

                // styleProps['animation-on'] = true
                // styleProps['animation-autoplay'] = true
                // styleProps['animation-autoplay-delay'] = 5000
                // styleProps['animation-effect'] = 'right'
                // styleProps['animation-easing'] = 'none'
                // styleProps['animation-duration'] = 2000
                // styleProps['animation-order'] =
                //   options: {1: 1, 2: 2, 3: 3, 4: 4, 5: 5}
                //   value: 3


                return styleProps;
            }

            return [];
        },

        textProps() {

            if (this.mode === 'group') {

                const sp = this.target.find(t => t.vueObj?.$refs.text != null)?.vueObj.$refs.text.textProps;

                const res = _.cloneDeep(sp);

                if (res instanceof Object) {

                    delete res['auto-height'];
                    delete res['list-level'];
                    delete res['list-type'];
                    delete res['list-style-type'];
                }

                return res;


            } else {

                const tp = this.currentPage.editingText?.textProps;

                if ((tp != null) && (Object.keys(tp).length > 0)) {
                    return this.currentPage.editingText.textProps;
                }
            }
        },

        animationProps() {

            let slide, targetObjects, targetsIds;
            const {
                target
            } = this;
            // Расчет @target меняет @mode
            // обязательно его вызвать до следующих расчетов

            switch (this.mode) {
                case 'slide':
                    return;
                    break;

                case 'object':
                case 'group':

                    // Собираем id'шники объектов
                    if (this.mode === 'object') {
                        slide = target.vueObj.mySlideObject;

                        if ((slide == null)) {
                            return;
                        }
                        targetsIds = [target.id];
                        targetObjects = [target];
                    } else {
                        if (target.length === 0) {
                            return;
                        }
                        slide = target[0].vueObj.mySlideObject;
                        targetsIds = target.map(t => t.id);
                        targetObjects = target;
                    }

                    // Ищем id'шники объектов, у которых есть анимации
                    var targetAnimations = [];
                    var animationOnObjectsIds = [];

                    if (Array.isArray(slide.animations)) {
                        animationOnObjectsIds = _.flattenDeep(slide.animations);
                        targetAnimations = slide.animations.map(
                            (a, ai) => _.intersection(
                                a,
                                targetsIds
                            ));
                    }

                    // Собираем опции для селектора очередности
                    var animationOrder =
                        {options: {}};

                    for (let i = 1, end = targetAnimations.length + 1; i <= end; i++) {
                        animationOrder.options[i] = i;
                    }

                    var selectedAnimationsSteps = targetAnimations.map(function (a, ai) {
                        if (a.length > 0) {
                            return ai + 1;
                        } else {
                            return false;
                        }
                    });
                    selectedAnimationsSteps = _.compact(selectedAnimationsSteps);

                    switch (selectedAnimationsSteps.length) {
                        case 0:
                            animationOrder.value = targetAnimations.length + 1;
                            break;
                        case 1:
                            animationOrder.value = selectedAnimationsSteps[0];
                            break;
                        default:
                            animationOrder.value = -1;
                    }

                    var animation =
                        {'animation-order': animationOrder};

                    // Собираем остальные опции

                    var multipleValuesKeys = [];
                    var noAnimationsObjectsFlag = false;

                    targetObjects.forEach(function (o) {
                        if (o.animation != null) {
                            let overwrite;
                            if (animationOnObjectsIds.includes(o.id)) {
                                animation['animation-on'] = true;
                                overwrite = true;
                            } else {
                                overwrite = false;
                            }
                            Object.keys(o.animation).forEach(function (k) {
                                if (animation[k] != null) {
                                    if (animation[k] !== o.animation[k]) {
                                        multipleValuesKeys.push(k);
                                    }
                                }
                                if ((animation[k] == null) || overwrite) {
                                    animation[k] = o.animation[k];
                                }
                            });
                        } else {
                            noAnimationsObjectsFlag = true;
                        }
                    });

                    if (animation['animation-on'] && noAnimationsObjectsFlag) {
                        multipleValuesKeys.push('animation-on');
                    }

                    animation.multipleValuesKeys = multipleValuesKeys;

                    var defaultAnimation = this.$store.state.animationBlank;

                    Object.keys(defaultAnimation).forEach(function (k) {
                        if (animation[k] == null) {
                            animation[k] = defaultAnimation[k];
                        }
                    });
                    if (animation['animation-on'] == null) {
                        animation['animation-on'] = false;
                    }

                    return animation;
                    break;
            }

        },

        pureAnimationProps() {

            const res = {};

            Object.keys(this.animationProps).map(ak => {
                switch (ak) {
                    case 'animation-order':
                    case 'animation-on':
                    case 'multipleValuesKeys':
                        return;
                        break;
                    default:
                        res[ak] = this.animationProps[ak];
                }
            });

            return res;
        },

        seoProps() {
            switch (this.mode) {
                case 'slide':
                case 'group':
                    return;
                    break;

                case 'object':
                    var {
                        target
                    } = this;
                    // Расчет @target меняет @mode
                    // обязательно его вызвать до следующих расчетов

                    var targetVueObj = target.vueObj;
                    if (targetVueObj.isLine || targetVueObj.isTable) {
                        return;
                    }

                    var seoObj = target.seo;

                    var res = {};

                    var seoKeys = [...PGR.seo.properties];

                    seoKeys.forEach(function (seoKey) {
                        if (seoObj) {
                            res[seoKey] = seoObj[seoKey];
                        } else {
                            res[seoKey] = false;
                        }
                    });

                    return res;
                    break;
            }

        },

        groupBtn() {
            if (this.metaTool.groupSelected !== -1) {
                return 'Ungroup';
            }
            return 'Group';
        },

        showTextPanelOnly() {
            const et = this.currentPage.editingText;
            const textHasFocus = et?.focusInside;
            return textHasFocus && !this.currentPage.editingTable;
        }
    },

    methods: {
        groupBtnClick() {
            if (this.metaTool.groupSelected === -1) {
                this.metaTool.groupObjects();
            } else {
                this.metaTool.ungroupObjects();
            }
        },

        setGroupCollapsed(k, v) {
            this.groupsCollapsed[k] = v;
        },

        getGroupCollapsed(k) {
            let gc = this.groupsCollapsed[k];
            if ((gc == null)) {
                gc = false;
            }
            return gc;
        },

        updateProp(k, v) {
            this.currentPage.logUserAction('propPanel', 'updateProp ' + k + ' to ' + v);
            const posProps = this.posPropsNames;
            const textProps = this.textPropsNames;
            const tableProps = this.tablePropsNames;
            const animationProps = [...PGR.animation.properties, 'animation-on'];
            const seoProps = [...PGR.seo.properties];
            this.propEditing = k;

            // Свойства текста
            if (_.indexOf(textProps, k) !== -1) {

                if (this.mode === 'group') {
                    this.target.forEach(function (t) {
                        if (t.type === 'table') {
                            t.vueObj?.setProperty(k, v);
                        } else {
                            t.vueObj?.$refs.text?.setProperty(k, v);
                        }
                    });

                } else {

                    this.textPropEditing = true;
                    this.currentPage.editingText.setProperty(k, v);
                }
                return;
            } else {
                if (!this.textPropEditing) {
                    const historyHandler = this.currentPage.historyHandlerOverride;
                    historyHandler?.forcedBlur();
                }
            }

            // Z-index
            if (k === 'order') {
                this.$store.commit('moveObjects', {
                    indexes: this.currentPage.editingObjects?.map(t => t.vueObj.index),
                    direction: v
                });
                return;
            }

            // Свойства таблицы
            if (_.indexOf(tableProps, k) !== -1) {
                this.currentPage.editingTable.setProperty(k, v);
                return;
            }

            // Свойства анимаций
            if (_.indexOf(animationProps, k) !== -1) {

                let animationOnObjectsIds, order, targets;
                switch (this.mode) {
                    case 'object':
                        targets = [this.target];
                        break;
                    case 'group':
                        targets = this.target;
                        break;
                }

                const slide = targets[0]?.vueObj.mySlideObject;

                if (k === 'animation-order') {
                    if (targets instanceof Array) {

                        animationOnObjectsIds = _.flattenDeep(slide.animations);
                        const targetsIds = targets.map(t => t.id);

                        let allTargetsHaveOneAnimationOrder = false;
                        if (this.animationProps['animation-order'].value !== -1) {
                            allTargetsHaveOneAnimationOrder = true;
                        }

                        // Добавляем анимации в объекты без них
                        targets.forEach(t => {

                            if (animationOnObjectsIds.includes(t.id)) {
                                return;
                            }

                            if (Object.keys(t?.vueObj).length > 0) {
                                if ((t.animation == null)) {
                                    t.vueObj.setAnimationProperty(
                                        undefined,
                                        undefined,
                                        this.pureAnimationProps
                                    );
                                }
                            }
                        });

                        // Удаляем id'шники из существующих анимаций

                        const filteredSlideAnimations = slide.animations.map(sa => sa.filter(function (id) {
                            if (targetsIds.includes(id)) {
                                return false;
                            }
                            return true;
                        }));

                        slide.animations = filteredSlideAnimations;

                        order = v;
                        order--;

                        if (allTargetsHaveOneAnimationOrder) {
                            slide.animations.splice(order, 0, []);
                        }

                        // Добавляем id'шники
                        targets.forEach(t => {

                            if (Object.keys(t?.vueObj).length > 0) {

                                if ((slide.animations[order] == null)) {
                                    slide.animations.push([]);
                                }

                                if (!slide.animations[order].includes(t.id)) {
                                    slide.animations[order].push(t.id);
                                }
                            }
                        });

                        // Чистим анимации от пустых массивов
                        slide.animations = slide.animations.filter(a => !(Array.isArray(a) && (a.length === 0)));
                    }

                    return;
                }

                if (k === 'animation-on') {

                    // Если надо включить
                    if (v) {
                        const ao = this.animationProps['animation-order'];

                        if (ao.value === -1) {
                            order = Math.max(...Object.keys(
                                ao.options
                            ));
                        } else {
                            order = ao.value;
                        }
                        order--;

                        animationOnObjectsIds = _.flattenDeep(slide.animations);

                        if (targets instanceof Array) {
                            targets.forEach(t => {

                                if (animationOnObjectsIds.includes(t.id)) {
                                    return;
                                }

                                if (Object.keys(t?.vueObj).length > 0) {
                                    if ((t.animation == null)) {
                                        t.vueObj.setAnimationProperty(
                                            undefined,
                                            undefined,
                                            this.pureAnimationProps
                                        );
                                    }

                                    if ((slide.animations[order] == null)) {
                                        slide.animations.push([]);
                                    }

                                    if (!slide.animations[order].includes(t.id)) {
                                        slide.animations[order].push(t.id);
                                    }
                                }
                            });
                        }

                        // Если надо выключить
                    } else {
                        if (targets instanceof Array) {
                            targets.forEach(function (t) {
                                const {
                                    id
                                } = t;
                                slide.animations.forEach(function (a) {
                                    const i = a.indexOf(id);
                                    if (i !== -1) {
                                        a.splice(i, 1);
                                    }
                                });
                            });

                            // Чистим анимации от пустых массивов
                            slide.animations = slide.animations.filter(a => !(Array.isArray(a) && (a.length === 0)));
                        }
                    }

                    return;
                }

                if (targets instanceof Array) {
                    targets.forEach(t => {
                        if (Object.keys(t?.vueObj).length > 0) {

                            if ((t.animation == null)) {

                                t.vueObj.setAnimationProperty(
                                    undefined,
                                    undefined,
                                    this.pureAnimationProps
                                );
                            }

                            if (this.animationProps.value !== -1) {
                                let animationIndex = this.animationProps['animation-order'].value;
                                animationIndex--;

                                if ((slide.animations[animationIndex] == null)) {
                                    slide.animations.push([]);
                                }

                                if (!slide.animations[animationIndex].includes(t.id)) {
                                    slide.animations[animationIndex].push(t.id);
                                }
                            }

                            t.vueObj.setAnimationProperty(
                                k,
                                v,
                                this.pureAnimationProps
                            );
                        }
                    });
                }

                return;
            }

            // Свойства сео
            if (_.indexOf(seoProps, k) !== -1) {
                this.target.vueObj.setSeoProperty(k, v);
                return;
            }

            // Свойства положения
            if (_.indexOf(posProps, k) !== -1) {
                this.posProps[k] = v;
                this.posProps.key = k;
                this.metaTool.updateProps(this.posProps);

                // Свойства стилей
            } else {

                if ((this.mode === 'object') && (this.target != null) &&
                    (Object.keys(this.target?.vueObj).length > 0)) {
                    this.target.vueObj.setProperty(k, v);
                }

                if ((this.mode === 'group') && (this.target != null) &&
                    this.target instanceof Array) {
                    this.target.forEach(function (t) {
                        if (Object.keys(t?.vueObj).length > 0) {
                            t.vueObj.setProperty(k, v);
                        }
                    });
                }

                if ((this.mode === 'slide') && (this.target.bg != null) &&
                    (Object.keys(this.target.bg?.vueObj).length > 0)) {
                    this.target.bg.vueObj.setProperty(k, v);
                    if (k === 'background-image') {
                        this.target.bg.vueObj.setProperty('background-mode', 'image');
                    }
                }
            }

            if (k === 'background-image') {

                Vue.nextTick(function () {
                        this.$store.commit('cleanUnusedMedia');
                        this.currentPage.actualizeMedia();
                    },
                    this);
            }
        },

        calcThemeStyles() {
            this.componentStyles = {
                '.prop-panel': {
                    background: this.getColor('background'),
                    color: this.getColor('color')
                }
            };
        },

        getCollapsedGroupsSettings() {
            let groupsCollapsed = this.currentPage.getSetting('groupsCollapsed');
            if (groupsCollapsed == null) {
                groupsCollapsed = {};
            }
            const gc = this.$store.state.defaultSettings.groupsCollapsed;
            Object.keys(gc).forEach(function (k) {
                if (groupsCollapsed[k] == null) {
                    groupsCollapsed[k] = gc[k];
                }
            });
            return groupsCollapsed;
        },

        previewAnimation() {
            this.currentPage.preview(this.target);
        }
    }
}
</script>

<style lang="scss">
.prop-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;

    &__actions {
        position: relative;
        padding: 0 20px 20px;
    }
}
</style>

<i18n>
{
    "en": {
        "Transitions": "Transitions"
    },
    "ru": {
        "Transitions": "Анимация"
    }
}
</i18n>
