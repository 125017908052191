<template>
    <div class="prop-group" :class="classes">
        <div
            class="prop-group__name"
            v-if="group.groupName != '' && !flags.bgEditingMode"
            @mousedown.prevent="toggleCollapsed"
        >
            <svg width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF" fill-rule="evenodd"></path>
            </svg>
            {{ $t(group.title) }}

            <HtmlSwitch
                v-if="showSwitch"
                :value="switchOn"
                @input="() => toggleSwitch()"
                :class="{'switch__multiple': multiple}"
            />
        </div>
        <property
            v-for="(property, index) in group.properties"
            :is="property.type"
            v-if="isShowProperties(property)"
            :class="'prop-name_'+property.name"
            :key="index"
            :propset="propset"
            :property="property"
            :showAlpha="mode != 'slide'"
        />
        <div class="property" v-if="group.groupClass == 'animation' && propset['animation-on']">
            <div
                class="btn btn_editor-btn"
                :class="{'btn_disabled': currentPage.showAnimations}"
                @click="$emit('preview')"
            >
                {{ $t('preview') }}
            </div>
        </div>
        <div
            class="property__lock-aspect"
            v-if="showLockAspect"
            :class="{'property__lock-aspect_locked': propset['lock-aspect']}"
            @click="updateProp('lock-aspect', !propset['lock-aspect'])"
        >
            <svg width="8" height="14" xmlns="http://www.w3.org/2000/svg">
                <g
                    stroke="#FFF"
                    stroke-width="2"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path
                        d="M7 8.636v1.637a2.727 2.727 0 1 1-5.455 0V8.636m0-3.272V3.727a2.727 2.727 0 1 1 5.455 0v1.637M4.273 4.818v4.364"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import UiColors from "@/mixins/editor/UiColors";

import PropAlign from "@/components/editor/property/PropAlign";
import PropBase from "@/components/editor/property/PropBase";
import PropCaps from "@/components/editor/property/PropCaps";
import PropColor from "@/components/editor/property/PropColor";
import PropCorners from "@/components/editor/property/PropCorners";
import PropTextAlign from "@/components/editor/property/PropTextAlign";
import PropPadding from "@/components/editor/property/PropPadding";
import PropNumber from "@/components/editor/property/PropNumber";
import PropCheckbox from "@/components/editor/property/PropCheckbox";
import PropList from "@/components/editor/property/PropList";
import PropFontFamily from "@/components/editor/property/PropFontFamily";
import PropSelect from "@/components/editor/property/PropSelect";
import PropImage from "@/components/editor/property/PropImage";

import PropDash from "@/components/editor/property/PropDash";
import HtmlSwitch from '@/components/BaseElements/HtmlSwitch';
import PropOrder from "@/components/editor/property/PropOrder";
import PropVideo from "@/components/editor/property/PropVideo";
import PropSlider from "@/components/editor/property/PropSlider";
import PropArrows from "@/components/editor/property/PropArrows";
import PropGradient from "@/components/editor/property/PropGradient";
import PropFontStyle from "@/components/editor/property/PropFontStyle";
import PropColorMode from "@/components/editor/property/PropColorMode";
import PropNumberText from "@/components/editor/property/PropNumberText";
import PropTableBorders from "@/components/editor/property/PropTableBorders";
import PropTableTemplate from "@/components/editor/property/PropTableTemplate";

export default {
    name: 'PropGroup',
    inject: ['currentPage'],
    props: ['group', 'propset', 'proppanel', 'mode'],
    components: {
        HtmlSwitch,
        prop_base: PropBase,
        prop_order: PropOrder,
        prop_checkbox: PropCheckbox,
        prop_number: PropNumber,
        prop_number_text: PropNumberText,
        prop_color: PropColor,
        prop_gradient: PropGradient,
        prop_select: PropSelect,
        prop_slider: PropSlider,
        prop_image: PropImage,
        prop_video: PropVideo,
        prop_caps: PropCaps,
        prop_dash: PropDash,
        prop_corners: PropCorners,
        prop_align: PropAlign,
        prop_list: PropList,
        prop_font_family: PropFontFamily,
        prop_font_style: PropFontStyle,
        prop_text_align: PropTextAlign,
        prop_padding: PropPadding,
        prop_color_mode: PropColorMode,
        prop_table_borders: PropTableBorders,
        prop_table_template: PropTableTemplate,
        prop_arrows: PropArrows
    },
    mixins: [UiColors],
    data: function () {
        return {
            keys: {
                'Fill': 'fill-on',
                'Stroke': 'stroke-on',
                'Animation': 'animation-on',
            }
        };
    },
    computed: {
        flags () {
            return this.$store.state.flags;
        },
        switchOn () {
            let s = false;
            Object.keys(this.keys).forEach((k) => {
                if (this.group.groupName === k && this.propset[this.keys[k]]) {
                    s = true;
                }
            });

            return s && !this.multiple;
        },
        multiple () {
            if (!this.group.groupName) {
                return false;
            }
            if (this.propset.multipleValuesKeys == null) {
                return false;
            }
            return this.propset.multipleValuesKeys.includes(this.keys[this.group.groupName]);
        },
        collapsed () {
            return this.$parent.groupsCollapsed[this.group.groupName];
        },
        showSwitch () {
            if (this.group.groupName === 'Animation') {
                return true;
            }
            if (this.propset['stroke-cap'] != null) {
                return false; // не отображать для линий
            }
            if (this.propset['table-background-color'] != null) {
                return false; // не отображать для таблиц
            }
            if (this.group.groupName === 'Fill' || this.group.groupName === 'Stroke') {
                return true;
            }
            return false;
        },
        classes () {
            let c = '';
            if (this.group.groupClass != null) {
                c += 'prop-group__name_' + this.group.groupClass;
            }

            if (this.collapsed) {
                c += ' prop-group__collapsed';
            }

            return c;
        },
        showLockAspect () {
            const isPos = this.group.groupClass === 'position';
            const asLock = this.propset['lock-aspect'] !== undefined;
            return isPos && asLock;
        }
    },
    methods: {
        toggleCollapsed () {
            this.$parent.setGroupCollapsed(this.group.groupName, !this.collapsed);
        },
        toggleSwitch () {
            this.updateProp(this.keys[this.group.groupName], !this.switchOn);
        },
        updateProp (k, v) {
            if (this.group.groupName === 'Stroke') {
                if (k !== 'stroke-on') {
                    this.$parent.updateProp('stroke-on', true);
                }
            }
            if (this.group.groupName === 'Fill') {
                if (k !== 'fill-on') {
                    this.$parent.updateProp('fill-on', true);
                }
            }
            this.$parent.updateProp(k, v);
        },
        calcThemeStyles () {
            this.componentStyles = {
                '.prop-group__name_align': {
                    background: this.getColor('alignBackground')
                },
                '.prop-group .prop-group__name': {
                    color: this.getColor('titleColor')
                }
            };
        },
        isShowProperties (property) {
            return (
                    this.propset[property.name] != undefined
                    || property.type == 'prop_align'
                    || property.type == 'prop_order'
                )
                && !(this.showLockAspect && property.name == 'lock-aspect')
                && !(this.flags.bgEditingMode && property.name == 'color-mode');
        }
    }
};
</script>

<style lang="scss">
.prop-group {
    position: relative;
    display: block;
    width: 230px;
    box-sizing: border-box;
    padding: 0;
    // max-height: 500px;

    &__name_align {
        height: 25px;
        margin-bottom: 15px;

        &:after {
            display: none;
        }
    }

    &__name_text {
        .prop-name_font-size, .prop-name_line-height, .prop-name_letter-spacing, .prop-name_color, .prop-name_text-background {
            width: 33%;
            position: relative;
            display: inline-block;
            padding-right: 0;
        }

        .prop-name_color, .prop-name_text-background {
            .property__preview {
                margin: 0;
                height: 6px;
            }
        }
    }

    &__name {
        position: relative;
        display: block;
        margin-bottom: 5px;
        box-sizing: border-box;
        color: #777;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
        line-height: 16px;
        padding: 5px 20px;
        cursor: pointer;
        background: rgba(125, 125, 125, .1);

        &:hover {
            background: rgba(125, 125, 125, .2);

            svg {
                opacity: 1;
            }
        }

        svg {
            position: absolute;
            display: block;
            top: 9px;
            left: 6px;
            opacity: .2;
        }

        .switch {
            position: absolute;
            right: 20px;
            top: 9px;
        }
    }

    &__collapsed {
        overflow: hidden;
        max-height: 27px;

        .prop-group__name {
            svg {
                transform: rotate(-90deg);
            }
        }
    }

    &.mode_image, &.mode_undefined {
        .property__type_video {
            .property__preview {
                display: none;
            }

            .property__btn__action_add {
                display: block;
            }
        }

        .prop-name_video-opacity {
            display: none;
        }
    }

    &.mode_video, &.mode_undefined {
        .prop-name_image-opacity {
            display: none;
        }

        .property__type_image {
            .property__preview {
                display: none;
            }

            .property__btn__action_add {
                display: block;
            }
        }
    }

    &.mode_gradient {
        .prop-name_background-color.property__type_color {
            display: none;
        }
    }

    &.mode_solid {
        .prop-name_gradient.property__type_gradient {
            display: none;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "background": "Background",
        "shape": "Shape",
        "fill": "Fill",
        "fillTemplate": "Fill template",
        "stroke": "Stroke",
        "arrows": "Arrows",
        "text": "Text",
        "appearance": "Appearance",
        "animation": "Animation",
        "seo": "Seo",
        "position": "Position",
        "preview": "Preview"
    },
    "ru": {
        "background": "Фон",
        "shape": "Форма",
        "fill": "Заливка",
        "fillTemplate": "Заполнить шаблон",
        "stroke": "Обводка",
        "arrows": "Стрелки",
        "text": "Текст",
        "appearance": "Внешний вид",
        "animation": "Анимация",
        "seo": "Seo",
        "position": "Положение",
        "preview": "Превью"
    }
}
</i18n>
