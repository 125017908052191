<template>
    <div
        class="property property__type_checkbox"
        v-if="propset[property.name] != undefined"
        :class="{'property__hide-sibling': property.name == 'animation-autoplay' && !(active || multiple)}">
        <div
            class="property__checkbox"
            :class="{'property__checkbox_active': active, 'property__checkbox_multiple': multiple}"
            mousedown="propValue = !propValue">
        </div>
        <div class="property__label" @mousedown="propValue = !propValue">
            {{ $t(property.title) }}
        </div>
    </div>
</template>

<script>
import PropBase from '@/components/editor/property/PropBase';

export default {
    name: 'Checkbox',
    props: ['opts'],
    mixins: [PropBase],
    computed: {
        multiple () {
            if (this.propset.multipleValuesKeys == null) {
                return false;
            }
            return this.propset.multipleValuesKeys.includes(this.property.name);
        },
        active () {
            return this.propValue && !this.multiple;
        }
    }
};
</script>

<style lang="scss">
.property {
    &.prop-name_chevron-first, &.prop-name_animation-autoplay {
        width: 100%;
        display: inline-block;
        padding-right: 0;
        border: 0;

        .property__checkbox, .property__label {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__type_checkbox {
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed rgba(255, 255, 255, .05);

        .property__checkbox {
            position: relative;
            display: block;
            height: 14px;
            width: 14px;
            margin-right: 10px;
            border: 2px solid rgba(255, 255, 255, .1);
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            overflow: hidden;
            transition: border-color .3s ease;

            &:before {
                content: '';
                position: absolute;
                display: block;
                border-radius: 50%;
                height: 28px;
                width: 28px;
                margin-top: -7px;
                margin-left: -7px;
                background: rgba(180, 193, 123, 1);
                transform: scale(0);
                transition: transform .3s ease;
            }

            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 0;
                width: 0;
                top: 3px;
                left: 2px;
                transform: rotate(-45deg);
                border-bottom: 2px solid #fff;
                border-left: 2px solid #fff;
                transition: all .3s ease;
                opacity: 0;
            }

            &_active {
                // background:rgba(255,255,255, .1);
                border-color: rgba(180, 193, 123, 1);

                &:before {
                    transform: scale(1);
                }

                &:after {
                    height: 4px;
                    width: 8px;
                    opacity: 1;
                }
            }

            &_multiple {
                border-color: rgba(80, 80, 80, 1);

                &:before {
                    background: rgba(80, 80, 80, 1);
                    transform: scale(1);
                }

                &:after {
                    transform: rotate(0deg);
                    height: 6px;
                    width: 6px;
                    border-bottom: 2px solid rgba(180, 193, 123, 1);
                    border-left: 2px solid rgba(180, 193, 123, 1);
                    border-top: 2px solid rgba(180, 193, 123, 1);
                    border-right: 2px solid rgba(180, 193, 123, 1);
                    background-color: rgba(180, 193, 123, 1);
                    top: 2px;
                    left: 2px;
                    opacity: 1;
                    border-radius: 2px;
                }
            }
        }

        .property__label {
            position: relative;
            display: block;
            color: #777777;
            font-size: 13px;
            cursor: pointer;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "Boxing": "Boxing",
        "Autoplay": "Autoplay",
        "Auto height": "Auto height",
        "Use as header": "Use as header",
        "Use as text": "Use as text",
        "Use image": "Use image",
        "Is first": "Is first"
    },
    "ru": {
        "Boxing": "Boxing",
        "Autoplay": "Автовоспроизведение",
        "Auto height": "Авто высота",
        "Use as header": "Использовать как header",
        "Use as text": "Использовать как текст",
        "Use image": "Использовать изображение",
        "Is first": "Первый"
    }
}
</i18n>

