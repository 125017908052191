<template>
  <div class="property property__type_slider">
    <div class="property__name">{{ property.title }}</div>
    <input
        class="property__input"
        type="range"
        v-bind:name="property.title"
        min="0" max="100"
        v-model="propValue"/>
    <input class="property__input" type="number" v-bind:name="property.title" min="0" max="100" v-model="propValue"/>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'prop_slider',
  mixins: [PropBase],
  data: function() {
    return {};
  },
  computed: {
    propValue: {
      // переписать: приводить 0-100 к диапазону property.min - property.max
      get: function() {
        var val;
        if (this.propset != null) {
          val = Math.round(this.propset[this.property.name] * 100);
        }
        return val;
      },
      set: function(v) {
        var value;
        value = v / 100;
        if ((value == null) || value < this.property.min) {
          value = this.property.min;
        }
        if (value > this.property.max) {
          value = this.property.max;
        }
        this.proppanel.updateProp(this.propset, this.property.name, value);
      }
    }
  }
};
</script>

<style lang="scss">
$track-color: #6b9bd1;
$track-color2: #3e4d67;
$thumb-color: #fff;

$thumb-size: 14px;
$thumb-shadow: 1px 2px 4px 0 rgba(0,0,0,0.1);
$thumb-border-width: 1px;
$thumb-border-color: #6b9bd1;
$track-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.2);
$track-radius: 2px;

@mixin track() {
  width: 100%;
  height: 4px;
  cursor: pointer;
  transition: all .2s ease;
}

@mixin thumb() {
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-size;
  width: $thumb-size;
  border-radius: 50%;
  background: $thumb-color;
  cursor: pointer;
  box-shadow: $thumb-shadow;
}

.property__type_slider{
  position:relative;
  display:block;

  input[type=range] {
    position:relative;
    display:block;
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    background: transparent;
    border:0;

    &:focus {
      outline: none;
    }
    &::-moz-focus-outer {
      border: 0;
    }

    &::-webkit-slider-runnable-track {
      @include track();
      background: $track-color;
      border: none;
      border-radius: $track-radius;
      box-shadow: $track-shadow;
    }

    &::-webkit-slider-thumb {
      @include thumb();
      -webkit-appearance: none;
      margin-top: -5px;
    }

    &::-moz-range-track {
      @include track();
      background: $track-color;
      border: none;
      border-radius: $track-radius;
      box-shadow: $track-shadow;
    }

    &::-moz-range-thumb {
      @include thumb();
    }

    &::-ms-track {
      @include track();
      background: transparent;
      border-color: unset;
      color: transparent;
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper{
      background: $track-color;
      border: none;
      border-radius: $track-radius;
    }
    &::-ms-fill-lower{
      background: $track-color2;
    }

    &::-ms-thumb {
      @include thumb();
    }

  }
  input[type=number]{
    text-align: center;
  }
}

</style>
