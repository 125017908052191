<template>
  <div class="object curve curve__line" :style="posStyles" :class="{selected: isSelected}"
       :data-rdata="getRendererData()">
    <svg
        version="1.1"
        baseProfile="full"
        class="ildar-search"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:ev="http://www.w3.org/2001/xml-events"
        :width="svgParams.width + a * 2"
        :height="svgParams.height + a * 2"
        :viewBox="'0 0 ' + parseFloat(svgParams.width+a*2) + ' ' + parseFloat(svgParams.height+a*2)"
        :style="Object.assign({'position': renderHack ? 'relative' : ''}, fadedStyle)"
        ref="svg"
    >
      <g :style="{transform: 'translate(' + a + 'px, ' + a + 'px)'}">
        <template v-for="(str, key) in arrowPointsStr">
          <polygon v-if="str.simple" :points="str.simple" :style="arrowStyles" :transform="str.rotate"></polygon>
          <path v-if="str.concave" :d="str.concave" :fill="arrowStyles.fill" stroke-linejoin="miter"
                :transform="str.rotate"></path>
          <polyline v-if="str.open" :points="str.open" fill="none" :stroke="lineParams['stroke']"
                    :stroke-width="lineParams['stroke-width']" stroke-linecap="round" stroke-linejoin="miter"
                    :transform="str.rotate"></polyline>
          <circle v-if="str.circle" :cx="arrowPoints[key].center.x" :cy="arrowPoints[key].center.y"
                  :r="arrowPoints[key].radius" :style="arrowStyles"></circle>
          <polygon v-if="str.rhombus" :points="str.rhombus" :style="arrowStyles"
                   :transform="str.rotate"></polygon>
        </template>
        <line :x1="svgParams.coords[0].x + lengthAmend.start.x" :y1="svgParams.coords[0].y + lengthAmend.start.y"
              :x2="svgParams.coords[1].x - lengthAmend.end.x" :y2="svgParams.coords[1].y - lengthAmend.end.y"
              :style="lineParams"></line>
        <line class="curve__selected-border" v-if="!flags.screenshotGeneratorMode"
              :x1="svgParams.coords[0].x + lengthAmend.start.x" :y1="svgParams.coords[0].y + lengthAmend.start.y"
              :x2="svgParams.coords[1].x - lengthAmend.end.x"
              :y2="svgParams.coords[1].y - lengthAmend.end.y"></line>
        <line v-if="!flags.screenshotGeneratorMode" :x1="svgParams.coords[0].x" :y1="svgParams.coords[0].y"
              :x2="svgParams.coords[1].x" :y2="svgParams.coords[1].y"
              :style="{'stroke': 'transparent', 'stroke-width': lineParams['stroke-width'] + 10}"
              @click.right="setMenuTarget"></line>
      </g>
    </svg>
  </div>
</template>

<script>
/* eslint-disable */
import Line from "@/mixins/editor/Line";

export default {
  name: 'Line_curve',
  props: ['opts', 'zi', 'index', 'slideindex', 'styles'],
  mixins: [Line],
  computed: {
    svgParams: function() {
      var complexWay, endX, endY, height, horizWay, noAngleWay, startX, startY, vertWay, width;
      if (this.opts.coords[0].x < this.opts.coords[1].x) {
        horizWay = 'toRight';
      }
      if (this.opts.coords[0].x > this.opts.coords[1].x) {
        horizWay = 'toLeft';
      }
      if (this.opts.coords[0].y < this.opts.coords[1].y) {
        vertWay = 'toBottom';
      }
      if (this.opts.coords[0].y > this.opts.coords[1].y) {
        vertWay = 'toTop';
      }
      if (this.opts.coords[0].y === this.opts.coords[1].y) {
        noAngleWay = 'horizontal';
      }
      if (this.opts.coords[0].x === this.opts.coords[1].x) {
        noAngleWay = 'vertical';
      }
      if (horizWay === 'toRight' && vertWay === 'toBottom') {
        complexWay = 'se';
      }
      if (horizWay === 'toRight' && vertWay === 'toTop') {
        complexWay = 'ne';
      }
      if (horizWay === 'toLeft' && vertWay === 'toTop') {
        complexWay = 'nw';
      }
      if (horizWay === 'toLeft' && vertWay === 'toBottom') {
        complexWay = 'sw';
      }

      width = Math.abs(this.opts.coords[1].x - this.opts.coords[0].x);
      height = Math.abs(this.opts.coords[1].y - this.opts.coords[0].y);
      if (noAngleWay != null) {
        switch (noAngleWay) {
          case 'horizontal':
            startY = endY = 0;
            switch (horizWay) {
              case 'toRight':
                startX = 0;
                endX = width;
                break;
              case 'toLeft':
                startX = width;
                endX = 0;
            }
            break;
          case 'vertical':
            startX = endX = 0;
            switch (vertWay) {
              case 'toBottom':
                startY = 0;
                endY = height;
                break;
              case 'toTop':
                startY = height;
                endY = 0;
            }
        }
      } else {
        switch (complexWay) {
          case 'se':
            startX = 0;
            startY = 0;
            endX = width;
            endY = height;
            break;
          case 'ne':
            startX = 0;
            startY = height;
            endX = width;
            endY = 0;
            break;
          case 'nw':
            startX = width;
            startY = height;
            endX = 0;
            endY = 0;
            break;
          case 'sw':
            startX = width;
            startY = 0;
            endX = 0;
            endY = height;
        }
      }
      if (startX == null) {
        startX = 0;
      }
      if (startY == null) {
        startY = 0;
      }
      if (endX == null) {
        endX = 0;
      }
      if (endY == null) {
        endY = 0;
      }

      return {
        // 10 - магическое число,
        // при меньших значениях лагает визуальная
        // толщина линиии возле нуля ширины
        // или высоты
        width: Math.max(width, 10),
        height: Math.max(height, 10),
        coords: [
          {
            x: startX,
            y: startY
          },
          {
            x: endX,
            y: endY
          }
        ]
      };
    },
    posParams: function() {
      return {
        'top': Math.min(this.opts.coords[1].y, this.opts.coords[0].y),
        'left': Math.min(this.opts.coords[1].x, this.opts.coords[0].x),
        'opacity': this.styleProps.opacity,
        'z-index': this.zi
      };
    },
    posStyles: function() {
      return {
        'top': this.posParams.top + 'px',
        'left': this.posParams.left + 'px',
        'opacity': this.posParams.opacity,
        'z-index': this.posParams['z-index']
      };
    },
    arrowPoints: function() {
      var coords, res, sw, types;
      types = ['start', 'end'];
      sw = this.lineParams['stroke-width'];
      if (sw < 3) {
        sw = 3;
      }
      coords = this.svgParams.coords;
      res = {};
      types.forEach((t, i) => {
        res[t] = {
          center: {
            x: coords[i].x,
            y: coords[i].y
          },
          intersection: {
            x: coords[i].x - this.lineParams['stroke-width'],
            y: coords[i].y
          },
          top1: {
            x: coords[i].x - 3 * sw,
            y: coords[i].y - 1.5 * sw
          },
          top2: {
            x: coords[i].x - 4 * sw,
            y: coords[i].y - 1.8 * sw
          },
          bottom1: {
            x: coords[i].x - 3 * sw,
            y: coords[i].y + 1.5 * sw
          },
          bottom2: {
            x: coords[i].x - 4 * sw,
            y: coords[i].y + 1.8 * sw
          },
          radius: sw
        };
      });
      return res;
    },
    arrowPointsStr: function() {
      var arrowPoints, res, types;
      types = ['start', 'end'];
      res = {};
      arrowPoints = this.arrowPoints;
      types.forEach((t, i) => {
        var a, concave, dots, o, open, r, rhombus, simple;
        if (this.opts.styleProps.arrows[t] === 'none') {
          return;
        }
        res[t] = {};
        res[t].angle = this.arrowAngle;
        o = arrowPoints[t];
        // Простая стрелка
        if (this.opts.styleProps.arrows[t] === 'simpleArrow') {
          simple = '';
          dots = [o.top1, o.center, o.bottom1];
          dots.forEach(function(d) {
            return simple += `${d.x},${d.y} `;
          });
          res[t].simple = simple;
        }
        // Открытая стрелка
        if (this.opts.styleProps.arrows[t] === 'openArrow') {
          open = '';
          dots = [o.top2, o.intersection, o.bottom2];
          dots.forEach(function(d) {
            return open += d.x + ',' + d.y + ' ';
          });
          res[t].open = open;
        }
        // Вогнутая стрелка
        if (this.opts.styleProps.arrows[t] === 'concaveArrow') {
          concave = '';
          dots = [o.top1, o.center, o.bottom1];
          dots.forEach(function(d, j) {
            var command;
            if (j === 0) {
              command = 'M';
            } else {
              command = 'L';
            }
            return concave += `${command} ${d.x} ${d.y} `;
          });
          r = o.radius;
          concave += `C ${o.bottom1.x + r} ${o.bottom1.y - r}, ${o.top1.x + r} ${o.top1.y + r}, ${o.top1.x} ${o.top1.y}`;
          res[t].concave = concave;
        }
        // Ромб
        if (this.opts.styleProps.arrows[t] === 'rhombus') {
          rhombus = '';
          dots = [[-1, 0], [0, -1], [1, 0], [0, 1]];
          dots.forEach(function(d) {
            return rhombus = `${rhombus + (o.center.x + o.radius * d[0])}, ${o.center.y + o.radius * d[1]} `;
          });
          res[t].rhombus = rhombus;
        }
        // Круг
        if (this.opts.styleProps.arrows[t] === 'circle') {
          res[t].circle = true;
        }
        // Поворот
        a = this.arrowAngle;
        if (t === 'start') {
          a += 180;
        }
        return res[t].rotate = `rotate(${a},${o.center.x},${o.center.y})`;
      });
      return res;
    },
    arrowStyles: function() {
      return {
        stroke: this.lineParams.stroke,
        fill: this.lineParams.stroke
      };
    },
    arrowAngle: function() {
      var c;
      c = {
        x: this.virtualCoords[2].x - this.centerCoords.x,
        y: this.virtualCoords[2].y - this.centerCoords.y
      };
      return this.decart2polarCoords(c).fi;
    },
    lengthAmend: function() {
      var linecap, res, sw, types, x, y;
      types = ['start', 'end'];
      sw = this.lineParams['stroke-width'];
      // if sw < 3
      //   sw = 3
      if (this.lineParams['stroke-linecap'] !== 'butt') {
        linecap = sw / 2;
      } else {
        linecap = 0;
      }
      x = Math.cos(this.deg2rad(this.arrowAngle)) * (sw + linecap);
      y = Math.sin(this.deg2rad(this.arrowAngle)) * (sw + linecap);
      res = {};
      types.forEach((t) => {
        if (this.opts.styleProps.arrows[t].indexOf('Arrow') !== -1) {
          return res[t] = {
            x: x,
            y: y
          };
        } else {
          return res[t] = {
            x: 0,
            y: 0
          };
        }
      });
      return res;
    }
  },
  mounted: function() {}
};
</script>

<style lang="scss">
@import "./curve.scss";
</style>
