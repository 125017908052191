<template>
    <!-- eslint-disable -->
    <div class="toolbar">
        <div class="toolbar__item">
            <svg width="21" height="21" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(3 3)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd">
                    <path
                        d="M10 4.375V.625A.625.625 0 0 0 9.375 0H.625A.625.625 0 0 0 0 .625v8.75C0 9.72.28 10 .625 10h3.75"
                        stroke-linejoin="round"/>
                    <circle cx="9.5" cy="9.5" r="5.5"/>
                </g>
            </svg>
            <div class="toolbar__group">
                <div
                    v-for="(item, index) in tools" v-if="item.category == 'shapes'"
                    :key="index" class="toolbar__item"
                    :data-type="item.type"
                    :title="$t(item.name)"
                    :class="{toolbar__item_active: index===currentActive}"
                    @click="clicked(index)"
                    v-html="item.icon"
                />
            </div>
        </div>
        <div
            v-for="(item, index) in tools" :key="index" v-if="item.category == ''" class="toolbar__item"
            :data-type="item.type"
            :title="$t(item.name)"
            :class="{toolbar__item_active: index===currentActive}" @click="clicked(index)"
            v-html="item.icon"
        />
        <div
            class="toolbar__item"
            @mousedown="tableFormBusy = true"
            @mouseup="releaseTableForm"
            @click="showTableForm = true"
            :title="$t('Table')"
        >
            <svg width="20" height="19" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(0 -13)" fill="none" fill-rule="evenodd">
                    <path stroke="#FFF" stroke-width="2" d="M3 17h14v13h-14z"/>
                    <path d="M10 18v11" stroke="#FFF" stroke-linecap="square"/>
                    <path fill="#FFF" d="M4 21h12v1h-12zM4 25h12v1h-12z"/>
                </g>
            </svg>
            <div v-show="showTableForm" class="toolbar__shield" @click.stop="showTableForm = false;"></div>
            <div v-show="showTableForm" class="toolbar__form">
                <prop_number :propset="tableProps" :property="tableRef.cols"></prop_number>
                <prop_number :propset="tableProps" :property="tableRef.rows"></prop_number>
                <div class="property">
                    <div class="property__btn property__btn_confirm" @click.stop="createTable"></div>
                </div>
            </div>
        </div>

        <!--    <div class="toolbar__item">-->
        <!--      <svg width="21" height="20" xmlns="http://www.w3.org/2000/svg">-->
        <!--        <g transform="translate(-449 -13)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"-->
        <!--           stroke-linejoin="round">-->
        <!--          <path d="M452 15v15h15"/>-->
        <!--          <path d="M452.074 25.936l5.134-4.96 2.283 3.476L467 16.895"/>-->
        <!--        </g>-->
        <!--      </svg>-->
        <!--      <div class="toolbar__group">-->
        <!--        <div class="toolbar__item" v-for="(item, index) in tools" v-if="item.category == 'charts'"-->
        <!--             v-bind:data-type="item.type" v-bind:title="item.name"-->
        <!--             v-bind:class="{toolbar__item_active: index===currentActive}" v-html="item.icon"-->
        <!--             v-on:click="clicked(index)"></div>-->
        <!--      </div>-->
        <!--    </div>-->
    </div>
</template>

<script>
/* eslint-disable */
import UiColors from "@/mixins/editor/UiColors";
import PropNumber from "@/components/editor/property/PropNumber";

export default {
    name: 'ToolbarComponent',
    components: {
        prop_number: PropNumber
    },
    mixins: [UiColors],
    data() {
        return {
            tableFormBusy: false, // чтобы не закрывалась при кликах или изменении параметров таблицы
            showTableForm: false,
            tools: [
                {
                    name: 'Rectangle',
                    icon: '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M17 12h14v14H17z" transform="translate(-14 -9)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'rectangle',
                        draw: true,
                        link: false
                    }
                },
                {
                    name: 'Ellipse',
                    icon: '<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><circle cx="58" cy="19" r="8" transform="translate(-47 -9)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linejoin="round"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'circle',
                        draw: true,
                        link: false
                    }
                },
                {
                    name: 'Rounded rectangle',
                    icon: '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><rect x="85" y="12" width="14" height="14" rx="5" transform="translate(-82 -9)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'rectangle',
                        draw: true,
                        link: false,
                        props: {
                            'corner-radius': [50,
                                50,
                                50,
                                50]
                        }
                    }
                },
                {
                    name: 'Rhombus',
                    icon: '<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M5 5H17V17H5z" transform="rotate(45 11 11) translate(0 -1)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'rhombus',
                        draw: true
                    }
                },
                {
                    name: 'Parallelogram',
                    icon: '<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M7 4H18L15 18H4z" transform="translate(0 -1)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'parallelogram',
                        draw: true
                    }
                },
                {
                    // 10:{
                    //   name: 'Polygon'
                    //   icon: '<svg width="22" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M125.608 11l7.609 5.528-2.906 8.944h-9.405L118 16.528z" transform="translate(-115 -8)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>'
                    //   category: 'shapes'
                    //   opts:
                    //     type: 'circle'
                    //     draw: false
                    // }
                    name: 'Triangle',
                    icon: '<svg width="24" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M160 12l8 14h-16z" transform="translate(-148 -8)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'triangle',
                        draw: true
                    }
                },
                {
                    name: 'Chevron start',
                    icon: '<svg width="21" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M17 50v12h10.4885255l4.2825559-5.9329254L27.482274 50H17z" transform="translate(-14 -47)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'chevron',
                        props: {
                            'chevron-first': true
                        },
                        draw: true
                    }
                },
                {
                    name: 'Chevron',
                    icon: '<svg width="22" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M51.7129222 50l3.4388447 6.0911703L51.7337218 62h11.1063313l3.9613557-5.9326123L62.8338563 50H51.7129222z" transform="translate(-48 -47)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'chevron',
                        draw: true
                    }
                },
                {
                    name: 'Arrow west',
                    icon: '<svg width="21" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M85 52v8h7v2.7546376L99.5051529 56 92 49.2453624V52h-7z" transform="matrix(-1 0 0 1 103 -45)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'arrow',
                        props: {
                            'arrow-direction': 'west'
                        },
                        draw: true
                    }
                },
                {
                    name: 'Arrow north',
                    icon: '<svg width="22" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M118.5 51.5v8h7v2.7546376L133.005153 55.5 125.5 48.7453624V51.5h-7z" transform="rotate(-90 46 90.5)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'arrow',
                        props: {
                            'arrow-direction': 'north'
                        },
                        draw: true
                    }
                },
                {
                    name: 'Arrow east',
                    icon: '<svg width="21" height="22" xmlns="http://www.w3.org/2000/svg"><path d="M187 52v8h7v2.7546376L201.505153 56 194 49.2453624V52h-7z" transform="translate(-184 -45)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'arrow',
                        props: {
                            'arrow-direction': 'east'
                        },
                        draw: true
                    }
                },
                {
                    name: 'Arrow south',
                    icon: '<svg width="22" height="21" xmlns="http://www.w3.org/2000/svg"><path d="M152.5 52.5v8h7v2.7546376L167.005153 56.5 159.5 49.7453624V52.5h-7z" transform="matrix(0 1 1 0 -45.5 -149.5)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>',
                    category: 'shapes',
                    opts: {
                        type: 'arrow',
                        props: {
                            'arrow-direction': 'south'
                        },
                        draw: true
                    }
                },
                {
                    name: 'Line Chart',
                    icon: '<svg width="21" height="20" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-449 -13)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linejoin="round"><path d="M452 15v15h15"/><path d="M452.074 25.936l5.134-4.96 2.283 3.476L467 16.895"/></g></svg>',
                    category: 'charts',
                    opts: {
                        type: 'rectangle',
                        draw: false
                    }
                },
                {
                    name: 'Bar chart',
                    icon: '<svg width="19" height="20" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-539 -52)" fill="#FFF" fill-rule="evenodd"><path d="M553 63h3v7h-3zM541 59h3v11h-3zM547 54h3v16h-3z"/></g></svg>',
                    category: 'charts',
                    opts: {
                        type: 'rectangle',
                        draw: false
                    }
                },
                {
                    name: 'Pie chart',
                    icon: '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm1 2.101A5.99 5.99 0 0 1 13.909 7H9V2.101zM8 14a6 6 0 0 1-6-6c0-2.971 2.165-5.421 5-5.899V8c0 .276.112.526.293.707l4.163 4.164A5.912 5.912 0 0 1 8 14zm4.866-2.549L10.414 9h3.485a5.98 5.98 0 0 1-1.033 2.451z" transform="translate(2 2)" fill="#FFF" fill-rule="nonzero"/></svg>',
                    category: 'charts',
                    opts: {
                        type: 'rectangle',
                        draw: false
                    }
                },
                {
                    name: 'Line',
                    icon: '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M15.003.002h-2c-1.103 0-1-.102-1 1v1c0 .178.03.347.075.511l-9.563 9.563a2.017 2.017 0 0 0-.512-.074h-1c-1.103 0-1-.102-1 1v2c0 1.103-.103 1 1 1h2c1.103 0 1 .103 1-1v-1c0-.177-.031-.347-.075-.51l9.563-9.564c.165.043.334.074.512.074h1c1.103 0 1 .103 1-1v-2c0-1.102.103-1-1-1z" transform="translate(2 2)"  fill="#FFF" fill-rule="nonzero"/></svg>',
                    category: '',
                    opts: {
                        type: 'line',
                        draw: true,
                        link: true,
                        props: {
                            'stroke-width': 2,
                            'stroke-on': true
                        }
                    }
                },
                {
                    name: 'Image',
                    icon: '<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g transform="translate(2 2)" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M1 1h14v14H1z"/><circle stroke-linecap="round" stroke-linejoin="round" cx="5.5" cy="5.5" r="1.5"/><path stroke-linecap="round" stroke-linejoin="round" d="M15 11.182L11.562 8 4 15"/></g></svg>',
                    category: '',
                    opts: {
                        showMedia: true,
                        type: 'rectangle',
                        draw: false
                    }
                },
                {
                    name: 'Text',
                    icon: '<svg width="17" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 13c1 0 1.5-1.17 1.5-2V2H2.73a1.5 1.5 0 0 0-1.47 1.205L1 4.5H0V1a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v3.5h-1l-.26-1.295A1.5 1.5 0 0 0 10.27 2H8v9c0 .83.5 2 1.5 2v1h-6v-1z" transform="translate(2 3)" fill="#FFF" fill-rule="nonzero"/></svg>',
                    category: '',
                    opts: {
                        type: 'rectangle',
                        draw: true,
                        props: {
                            'background-color': {
                                'own': [255,
                                    255,
                                    255,
                                    0],
                                'palette': false
                            },
                            'stroke-width': 0,
                            'adjust-to-text': true
                        },
                        afterCreate: [
                            {
                                method: 'addTextEntity',
                                immediate: false
                            },
                            {
                                method: 'adjustChildText',
                                immediate: false
                            }
                        ]
                    }
                }
            ],
            // 5: {
            //   name: 'Table'
            //   icon: '<svg width="20" height="19" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 -13)" fill="none" fill-rule="evenodd"><path stroke="#FFF" stroke-width="2" d="M3 17h14v13h-14z"/><path d="M10 18v11" stroke="#FFF" stroke-linecap="square"/><path fill="#FFF" d="M4 21h12v1h-12zM4 25h12v1h-12z"/></g></svg>'
            //   category: ''
            //   opts:
            //     type: 'rectangle'
            // }
            tableProps: {
                cols: 2,
                rows: 2
            },
            tableRef: {
                cols: {
                    name: 'cols',
                    title: 'Columns',
                    min: 1,
                    max: 10
                },
                rows: {
                    name: 'rows',
                    title: 'Rows',
                    min: 1,
                    max: 10
                }
            }
        };
    },
    computed: {
        currentActive() {
            const tool = this.$store.state.toolbar.currentActive;
            if (tool !== undefined) {
                return tool.index;
            } else {
                return tool;
            }
        }
    },
    watch: {
        '$parent.mousePressed.left'() {
            if (this.$parent.mousePressed.left && this.tableFormBusy) {
                this.tableFormBusy = false;
                return;
            }
            if (this.$parent.mousePressed.left && !this.tableFormBusy) {
                this.showTableForm = false;
            }
        }
    },
    methods: {
        releaseTableForm() {
            const that = this;
            setTimeout(function () {
                that.tableFormBusy = false;

                return false;
            }, 200);
        },
        updateProp(k, v) {
            const propValue = this.tableProps;
            propValue[k] = v;
            this.tableProps = propValue;
        },
        createTable() {
            if (this.currentActive !== undefined) {
                this.$store.commit('setActiveTool', undefined);
            }
            this.showTableForm = false;
            this.$store.commit('addComponent', {
                type: 'table',
                cols: this.tableProps.cols,
                rows: this.tableProps.rows
            });
        },
        clicked(index) {
            if (index === this.currentActive) {
                this.$store.commit('setActiveTool', undefined);
            } else {
                this.$parent.metaTool.clearState();
                if (this.tools[index].opts.showMedia) {
                    this.$parent.showMedia({
                        newImage: true
                    });
                    this.$parent.metaTool.clearState();
                } else {
                    const tool = {
                        index,
                        opts: this.tools[index].opts
                    };
                    if (tool.opts.draw) {
                        this.$store.commit('setActiveTool', tool);
                    } else {
                        this.$store.commit('addComponent', tool.opts);
                    }
                }
            }
        },
        calcThemeStyles() {
            this.componentStyles = {
                '.toolbar__group': {
                    background: this.getColor('groupBg')
                }
            };

            return this.componentStyles;
        }
    }
};
</script>

<style lang="scss">
.toolbar {
    position: relative;
    display: flex;
    align-items: center;
    height: 20px;
    margin: 0;
    padding: 0 5px;
    box-sizing: border-box;
    border-left: 1px solid rgba(255, 255, 255, .1);
    border-right: 1px solid rgba(255, 255, 255, .1);
    // margin-left: auto;

    &__group, &__form {
        position: absolute;
        background: #3E3F41;
        top: 24px;
        width: 227px;
        left: -5px;
        padding: 5px;
        padding-top: 7px;
        box-sizing: border-box;
        border-radius: 3px;
        display: none;
        flex-wrap: wrap;

        .property {
            display: inline-block;
            width: 50%;
            margin-right: -4px;
            padding: 0 7px;

            &:last-child {
                width: 100%;
            }

            .property__btn {
                width: 100%;
                margin-top: 5px;
                background: rgba(255, 255, 255, .1);
            }
        }
    }

    &__form {
        display: block;
        width: 140px;
    }

    &__shield {
        display: block;
        position: absolute;
        left: -10000px;
        right: -10000px;
        top: -10000px;
        bottom: -10000px;
        background-color: transparent;
        z-index: 0;
    }

    &__item {
        position: relative;
        display: block;
        cursor: pointer;
        width: 36px;
        height: 25px;
        box-sizing: border-box;

        svg {
            position: relative;
            display: block;
            margin: 0 auto;
            opacity: 0.5;
        }

        &:hover, &.toolbar__item_active {
            & > svg {
                opacity: 1;
            }

            .toolbar__group {
                display: flex;

                .toolbar__item {
                    height: 32px;
                    padding: 4px 0;
                }
            }
        }
    }

}
</style>

<i18n>
{
    "en": {
        "Line": "Line",
        "Text": "Text",
        "Image": "Image",
        "Table": "Table",
        "Rectangle": "Rectangle",
        "Ellipse": "Ellipse",
        "Rounded rectangle": "Rounded rectangle",
        "Rhombus": "Rhombus",
        "Parallelogram": "Parallelogram",
        "Triangle": "Triangle",
        "Chevron start": "Chevron start",
        "Chevron": "Chevron",
        "Arrow west": "Arrow west",
        "Arrow north": "Arrow north",
        "Arrow east": "Arrow east",
        "Arrow south": "Arrow south"

    },
    "ru": {
        "Line": "Линия",
        "Text": "Текст",
        "Image": "Изображение",
        "Table": "Таблица",
        "Rectangle": "Прямоугольник",
        "Ellipse": "Овал",
        "Rounded rectangle": "Прямоугольник с закругленными углами",
        "Rhombus": "Ромб",
        "Parallelogram": "Паралелограм",
        "Triangle": "Треугольник",
        "Chevron start": "Первый шеврон",
        "Chevron": "Шеврон",
        "Arrow west": "Стрелка влево",
        "Arrow north": "Стрелка вверх",
        "Arrow east": "Стрелка вправо",
        "Arrow south": "Стрелка вниз"
    }
}
</i18n>
