export default {
    slide: ['slideBackground'],
    line: ['appearance', 'stroke', 'arrows', 'animation'],
    rectangle: ['appearance', 'shape', 'fill', 'stroke', 'animation'],
    rhombus: ['appearance', 'fill', 'stroke', 'animation'],
    triangle: ['appearance', 'shape', 'fill', 'stroke', 'animation'],
    parallelogram: ['appearance', 'shape', 'fill', 'stroke', 'animation'],
    chevron: ['appearance', 'shape', 'fill', 'stroke', 'animation'],
    arrow: ['appearance', 'shape', 'fill', 'stroke', 'animation'],
    circle: ['appearance', 'fill', 'stroke', 'animation'],
    table: ['appearance', 'tableFill', 'tableFillTemplate', 'stroke', 'animation'],
    tablePos: ['align', 'tablePosition'],
    linePos: ['align', 'position'],
    shapePos: ['align', 'position'],
    group: ['appearance', 'shape', 'arrows', 'fill', 'stroke', 'animation'],
    groupPos: ['align', 'position']
};
