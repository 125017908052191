<template>
  <div class="property property_type_arrows">
    <div class="property property_type_select">
      <div class="property__name">Start</div>
      <div class="select select_theme_dark">
        <div class="select__input" v-on:click="startSelectOpen = true"><span v-html="icons[propValue.start]"></span>
          <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
            <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF"
                  fill-rule="evenodd"></path>
          </svg>
        </div>
        <div class="select__body" v-show="startSelectOpen">
          <div class="select__body-scrollable">
            <div class="select__option" v-for="(o) in property.options" v-on:mousedown="start = o"><span
                v-html="icons[o]"></span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="property property_type_select">
      <div class="property__name">End</div>
      <div class="select select_theme_dark select_refl">
        <div class="select__input" v-on:click="endSelectOpen = true"><span v-html="icons[propValue.end]"></span>
          <svg class="select__input-svg" width="10" height="8" xmlns="http://www.w3.org/2000/svg">
            <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF"
                  fill-rule="evenodd"></path>
          </svg>
        </div>
        <div class="select__body" v-show="endSelectOpen">
          <div class="select__body-scrollable">
            <div class="select__option" v-for="(o) in property.options" v-on:mousedown="end = o"><span
                v-html="icons[o]"></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'prop_arrows',
  inject: ['currentPage'],
  mixins: [PropBase],
  props: ['propset'],
  data: function() {
    return {
      startSelectOpen: false,
      endSelectOpen: false,
      icons: {
        'none': '<svg width="26" height="5" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M.5.5h25" stroke="#FFF" stroke-linecap="square"/><path fill="none" d="M0-4h26v9H0z"/></g></svg>',
        'simpleArrow': '<svg width="26" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M7 0v7.071L.207 3.536z"/><path fill="none" d="M0-1h26v9H0z"/><path d="M.5 3.5h25" stroke="#FFF" stroke-linecap="square"/></g></svg>',
        'openArrow': '<svg width="26" height="9" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M.5 4.5h25" stroke="#FFF" stroke-linecap="square"/><path stroke="#FFF" d="M7 1L1 4.5l6 3.501"/><path fill="none" d="M0 0h26v9H0z"/></g></svg>',
        'concaveArrow': '<svg width="26" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M7 0C5.926 1.537 5.39 2.703 5.39 3.496c0 .794.536 1.986 1.61 3.575L.207 3.536 7 0z" fill="#FFF"/><path d="M0 3.536L26 3.5" stroke="#FFF" stroke-linecap="square"/><path fill="none" d="M0-1h26v9H0z"/></g></svg>',
        'rhombus': '<svg width="26" height="8" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path fill="#FFF" d="M3.743 0l3.535 3.536-3.535 3.536L.207 3.536z"/><path d="M5.5 3.5h20" stroke="#FFF" stroke-linecap="square"/><path fill="none" d="M0-1h26v9H0z"/></g></svg>',
        'circle': '<svg width="26" height="7" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 -2)" fill="none" fill-rule="evenodd"><path d="M5.5 4.5h20" stroke="#FFF" stroke-linecap="square"/><circle fill="#FFF" cx="2.5" cy="4.5" r="2.5"/><path fill="none" d="M0 0h26v9H0z"/></g></svg>'
      }
    };
  },
  computed: {
    start: {
      get: function() {
        return this.propValue.start;
      },
      set: function(v) {
        return this.submitValue(v, this.propValue.end);
      }
    },
    end: {
      get: function() {
        return this.propValue.end;
      },
      set: function(v) {
        return this.submitValue(this.propValue.start, v);
      }
    }
  },
  methods: {
    submitValue: function(start, end) {
      this.updateProp('arrows', {
        'start': start,
        'end': end
      });
    }
  },
  watch: {
    'currentPage.mousePressed.left': function() {
      if (this.currentPage.mousePressed.left) {
        this.startSelectOpen = false;
        this.endSelectOpen = false;
      }
    }
  }
};
</script>

<style lang="scss">
.property_type_arrows{
  position:relative;
  display:flex;
  padding-top: 0;
  padding-left: 0;

  .property_type_select{
    position:relative;
    display:block;
    // width: 50%;
    width: 80px;
    padding-right: 0;
    box-sizing: border-box;
  }

  .select{
    &__input{
      position:relative;
      display:block;

      &-svg{
        right:3px;
      }

      &-icon{
        position:relative;
        display:block;
        cursor: pointer;
        margin-right: 4px;

        svg{
          position:relative;
          display:block;
          height: 18px;
          width: 18px;
          opacity: 0.5;
        }

        &:hover svg, &.active svg{
          opacity: 1;
        }
      }

      &:hover{
        background:rgba(255,255,255,.1);
        border-color:transparent;

        .select__input-icon svg{
          opacity: 1;
        }
      }
    }

    &__option{
      padding: 0 10px;
    }
    span{
      display: block;
      height: 20px;
    }
    &_refl{
      span svg{
        transform: scale(-1, 1);
      }
    }
  }
}
</style>
