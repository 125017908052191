<template>
    <div class="property property__type_number" :class="'property__mode_'+mode">
        <div class="property__name" v-if="property.title && property.name != 'animation-autoplay-delay'">
            {{ $t(property.title) }}
        </div>
        <div class="property__flex-row">
            <div class="property__text-before" v-if="property.title && property.name == 'animation-autoplay-delay'">
                {{ $t(property.title) }}
            </div>
            <input
                class="property__input"
                type="text"
                :name="property.title"
                :size="inputSize"
                v-model="displayValue"
                @blur="inputBlur"
                @focus="inputFocus"
                @mousedown="mouseDown"
                @dblclick="dblClick"
                @keydown.stop="keyDown"
            />
            <div class="property__input-units" v-if="units">{{ units }}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import PropBase from "@/components/editor/property/PropBase";

export default {
    inject: ['currentPage'],
    name: "PropNumber",
    mixins: [PropBase],
    props: ['length'],
    data() {
        let u = '';
        if (this.property?.units) {
            u = this.property.units;
        }

        return {
            dragging: false,
            mode: 'drag',
            dragStart: 0,
            dragVal: 0,
            dragStep: 0,
            units: u
        };
    },
    watch: {
        'currentPage.mousePressed': function () {
            if (!this.currentPage.mousePressed.left) {
                this.dragging = false;
            }
        },
        dragPos: function () {
            let pow = 2;
            let divider = 10;
            if (this.property?.name === 'cols' || this.property?.name === 'rows') {
                pow = 1;
                divider = 20;
            }

            let k;
            if (this.dragK != null) {
                k = this.dragK;
            } else {
                if (!isNaN(this.maxDist)) {
                    k = this.maxDist / 1000;
                } else {
                    k = 1;
                }
                if (k < 1) {
                    k = 1;
                }
            }
            const dist = this.dragPos - this.dragStart;
            const absDist = Math.abs(dist);
            const dSign = dist / absDist;
            const delta = Math.pow(absDist / divider, pow) * dSign * k;
            let val = parseInt(this.dragVal + delta);

            if (Math.abs(delta) > 100) {
                val = parseInt(val / 10) * 10;
            }
            if (Math.abs(delta) > 1000) {
                val = parseInt(val / 100) * 100;
            }
            this.setPropValueThrottled(val);
        }
    },
    computed: {
        dragK() {
            if ((this.property?.name == null)) {
                return undefined;
            }
            switch (this.property.name) {
                case 'font-size':
                case 'line-height':
                case 'letter-spacing':
                    return 0.5;
                default:
                    return undefined;
            }
        },
        propValue: {
            get: function () {
                var val;
                if (this.propset != null) {
                    val = this.propset[this.property.name];
                    if (this.property.k != null) {
                        val = Math.round(val * this.property.k);
                    }
                    val = parseInt(val);
                    if (this.property.d != null) {
                        val = val + this.property.d;
                    }
                    if (isNaN(val)) {
                        val = this.property.min;
                    }
                }
                return val;
            },
            set: function (value) {
                var val;
                // console.log value
                if (value === '') {
                    return;
                }
                // console.log 'empty value'
                if (isNaN(value)) {
                    return;
                }
                // console.log 'value isNaN'
                val = parseInt(value);
                if (this.property.k != null) {
                    val = val / this.property.k;
                }
                if (this.property.d != null) {
                    val = val - this.property.d;
                }
                if (val < this.property.min) {
                    val = this.property.min;
                }
                if (val > this.property.max) {
                    val = this.property.max;
                }
                if (isNaN(val)) {
                    val = this.property.min;
                }
                this.$parent.updateProp(this.property.name, val);
            }
        },
        multiple: function () {
            if (!this.property) {
                return false;
            }
            if (this.propset.multipleValuesKeys == null) {
                return false;
            }
            return this.propset.multipleValuesKeys.includes(this.property.name);
        },
        displayValue: {
            get: function () {
                if (this.multiple) {
                    return '---';
                }
                return this.propValue;
            },
            set: function (val) {
                this.propValue = val;
            }
        },
        maxDist: function () {
            var k, max, min, p;
            p = this.property;
            if (p == null) {
                return 0;
            }
            k = p != null ? p.k : 0;
            max = p != null ? p.max : 0;
            min = p != null ? p.min : 0;
            if (k == null) {
                k = 1;
            }
            if (max == null) {
                max = 1;
            }
            if (min == null) {
                min = 1;
            }
            return Math.abs((max - min) * k);
        },
        inputSize: function () {
            var len;
            len = 0;
            if (this.length != null) {
                len = this.length;
            } else {
                if ((this.propValue != null)) {
                    len = (this.propValue + '').length;
                }
            }
            len += this.units.length;
            return len;
        },
        dragPos: function () {
            if (!this.dragging || this.mode === 'input') {
                return this.dragStart;
            } else {
                return this.currentPage.rawCoords.x;
            }
        }
    },
    methods: {
        keyDown(e){
            if (e.key==='Enter') {
                $(e.currentTarget).blur();
                this.mode='drag';
                e.preventDefault();
            }
            let d = 1;
            if (e.shiftKey) {
                d = 10;
            }
            switch (e.key) {
                case 'ArrowUp':
                    e.preventDefault();
                    this.setPropValueThrottled(this.propValue + d);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    this.setPropValueThrottled(this.propValue - d);
                    break;
            }
        },
        setPropValue: function (newValue) {
            this.propValue = newValue;
        },
        mouseDown: function (e) {
            // e.preventDefault()
            if (this.mode === 'drag') {
                e.preventDefault();
            }
            // @dragging = true
            // @dragStart = e.clientX
            this.$nextTick(function () {
                this.dragging = true;
                this.dragStart = e.clientX;
                this.dragStep = e.clientX;
                this.dragVal = this.propValue;
            });
        },
        dblClick: function (e) {
            this.mode = 'input';
            $(e.currentTarget).focus();
        },
        inputBlur: function (e) {
            this.mode = 'drag';
        },
        // Заглушка, метод со смыслом используется в prop_number_text
        // @$store.commit('saveHistory', @property.name)
        inputFocus: function () {
        },
        calcThemeStyles: function () {
            return this.componentStyles = {
                '.property__type_number .property__input': {
                    color: this.getColor('inputColor')
                }
            };
        }
    },
    created: function () {
        this.setPropValueThrottled = _.throttle(this.setPropValue, 100);
    }
};
</script>

<style lang="scss">
.property__type_number {
    &.prop-name_chevron-angle, &.prop-name_arrow-angle, &.prop-name_arrow-thickness, &.prop-name_animation-autoplay-delay {
        width: 50%;
        display: inline-block;
        padding-right: 0;
    }

    &.prop-name_animation-autoplay-delay {
        padding-left: 28px !important;

        .property__input-units {
            transform: translate(-70%, 1px);
        }
    }

    .property__name {
        margin-bottom: 0;
    }

    input {
        position: relative;
        display: block;
        font-size: 11px;
        cursor: move;
        max-width: 70px;

        &:hover, &:active, &:focus {
            background: rgba(0, 0, 0, .2);
        }
    }

    &.property__mode_input input {
        cursor: text;
    }

    .property__input-units {
        position: relative;
        display: block;
        margin-left: -3px;
        transform: translateX(-100%);
        font-size: 11px;
        pointer-events: none;
        color: #fff;
    }

    .property__text-before {
        position: relative;
        display: block;
        color: #777777;
        font-size: 13px;
        cursor: pointer;
        transform: translateX(-7px);
    }
}
</style>

<i18n>
{
    "en": {
        "Height": "Height",
        "Width": "Width",
        "Columns": "Columns",
        "Rows": "Rows",
        "Opacity": "Opacity",
        "X": "X",
        "Y": "Y",
        "Rotate": "Rotate",
        "Duration(ms)": "Duration(ms)",
        "Gap": "Gap",
        "Dash": "Dash",
        "Weight": "Weight",
        "in ": "in ",
        "Scale": "Scale",
        "X1": "X1",
        "X2": "X2",
        "Y1": "Y1",
        "Y2": "Y2",
        "Skew": "Skew",
        "Parallax": "Parallax",
        "Fragments": "Fragments",
        "Angle": "Angle",
        "Thickness": "Толщина"
    },
    "ru": {
        "Height": "Высота",
        "Width": "Ширина",
        "Columns": "Столбцы",
        "Rows": "Ряды",
        "Opacity": "Прозрачность",
        "X": "X",
        "Y": "Y",
        "Rotate": "Наклон",
        "Duration(ms)": "Продолжительность(мс)",
        "Gap": "Зазор",
        "Dash": "Ширина",
        "Weight": "Толщина",
        "in ": "в ",
        "Scale": "Маштаб",
        "X1": "X1",
        "X2": "X2",
        "Y1": "Y1",
        "Y2": "Y2",
        "Skew": "Угол",
        "Parallax": "Parallax",
        "Fragments": "Фрагменты",
        "Angle": "Угол",
        "Thickness": "Толщина"
    }
}
</i18n>
