<template>
    <div class="pdf-export-wrapper" style="position: fixed; top: -1000000px; left: -1000000px;">
        <div class="pdf-export-preview-wrapper" id="pdf-export-preview-wrapper" :style="wrapperStyle">
            <SlidePreviewContent :previewHtml="slidePreview" :vjson="vjson" :size="size"/>
        </div>
    </div>
</template>

<script>
import SlidePreviewContent from "@/components/SlidePreviewContent";

export default {
    name: "PdfSlidePreview",
    props: {
        idx: Number
    },
    components: {
        SlidePreviewContent
    },
    computed: {
        wrapperStyle () {
            return {
                position: 'relative',
                width: `${this.size.w}px`,
                minWidth: `${this.size.w}px`,
                height: `${this.size.h}px`,
                minHeight: `${this.size.h}px`,
            };
        },
        previewWidth () {
            return this.$store.state.presentation.size.w;
        },
        size () {
            return this.$store.state.presentation.size;
        },
        slidePreview () {
            return this.$store.state.previewsHtmls[this.idx];
        },
        vjson () {
            return this.$store.state.vjsons.slides[this.idx];
        },
        slide () {
            return this.$store.state.presentation.slides[this.idx];
        },
    },
};
</script>

<style lang="scss">
.pdf-export-preview-wrapper {
    .thumbs-bar__item-inner > .slide {
        transform: none !important;
    }

    .slide__content-trimmer, .slide__content {
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
