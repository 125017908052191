<template>
    <div class="media-browser" @mousedown="mousePressed = true" @mouseup="mouseUp" @click="selectedItems = []">
        <div class="media-browser__title">
            {{ $t('mediaLibrary') }}
        </div>
        <div class="close media-browser__close" @click="$parent.showMedia = false"></div>
        <div class="tabs__head">
            <div class="tabs__head-group">
                <div class="tabs__head-item" :class="{'active':activeTab=='my'}" @click="activeTab='my';getMedia()">
                    {{ $t('myMedia') }}
                </div>
                <propIntegrationSelect
                    :propset="$data"
                    :property="'integrations'"
                    :current="currentIntegration"
                    :active="activeTab=='external'"
                    @integrationselected="currentIntegration = $event"
                    @selectclicked="activeTab='external'"
                />
            </div>
            <div class="tabs__head-group">
                <div
                    class="tabs__head-item tabs__head-item_trash"
                    :class="{'active':activeTab=='deleted'}"
                    @click="activeTab='deleted';getDeletedMedia()"
                    style="height: 16px; width: 14px;"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
                        <g stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"
                           stroke-linejoin="round">
                            <path
                                d="M1.000001 4.000002h12.000006M11.666673 4.000002v9.333338c0 .7363804-.5969543 1.333334-1.333334 1.333334h-6.66667c-.73638043 0-1.33333406-.5969543-1.33333406-1.333334 0 0 0 0 0 0V4.000002m2.000001 2e-7V2.666668v2e-7c-1.1e-7-.73638037.59695363-1.333334 1.333334-1.333334h2.666668c.73638031-3e-8 1.33333394.59695363 1.33333394 1.333334v1.333334M5.66667 7.333337v4.000002M8.333338 7.333337v4.000002"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="tabs__body">
            <div class="spinner" :class="{'spinner_active': busy}" :data-progress="uploadProgress">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                    <path
                        d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
                </svg>
            </div>
            <div
                class="media-browser__message"
                :class="{'media-browser__message_active': showMessage, 'media-browser__message_error': message.error}"
            >
                <div class="media-browser__close" @click="showMessage = false"></div>
                <div class="media-browser__message-title">{{ $t(message.title) }}</div>
                <div class="media-browser__message-description">{{ $t(message.description) }}</div>
            </div>
            <div
                class="tabs__body-item tabs__body-item_my"
                v-show="activeTab=='my'"
                @dragenter.prevent.stop="dropping = true"
            >
                <div
                    class="media-browser__drop-media"
                    v-show="dropping"
                    @dragover.prevent.stop="dropMedia"
                    @dragenter.prevent.stop="dropMedia"
                    @dragleave.prevent.stop="dropping = false"
                    @drop.prevent.stop="dropMedia"
                />
                <div class="spinner" :class="{'spinner_active': myMediaBusy}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
                         stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                        <path
                            d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
                    </svg>
                </div>
                <div
                    class="media-browser__items custom-scroll"
                    v-show="!insertingURL"
                    @scroll="mediaScroll($event, 'getMedia')"
                >
                    <div class="media-browser__item" v-for="m in myMedia" :key="m.id">
                        <div class="close close_bg-on media-browser__item-delete" @click="tryToDelete([m.id])"></div>
                        <div
                            class="media-browser__item-pic"
                            @click.stop="myMediaItemSelected(m)"
                            :class="{'selected': _.indexOf(selectedItems, m.id)!=-1}"
                            :style="{'background-image': `url(${currentPage.storageUrl}${m.thumb_url})`}"
                        />
                    </div>
                </div>
                <div class="media-browser__form" v-show="insertingURL">
                    <p class="media-browser__note" v-html="$t('supportedMedia')">
                    </p>
                    <input
                        class="media-browser__input media-browser__input_url"
                        v-model="linkToUpload"
                        type="text"
                        :placeholder="$t('pasteUrl')"
                    />
                    <div class="btn btn_editor-btn btn_small" @click="insertingURL = false">
                        {{ $t('cancel') }}
                    </div>
                    <div class="btn btn_editor-btn btn_small" @click="checkLink">
                        {{ $t('addMedia') }}
                    </div>
                </div>
                <div class="media-browser__bottom-panel" v-show="!insertingURL">
                    <div class="flex-row" v-show="selectedItems.length < 2"><label class="icon-btn">
                        <div class="icon-btn__icon">
                            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                                   stroke-width="2">
                                    <path
                                        d="M2 11.333v2c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334v-2M10.667 4L8 1.333 5.333 4M8 1.333v9.334"
                                        stroke-width="1.33334"/>
                                </g>
                            </svg>
                        </div>
                        <div class="icon-btn__title">
                            {{ $t('uploadFile') }}
                        </div>
                        <input name="userfile" type="file" value="" @change="chooseFile"/></label>
                        <div class="icon-btn" @click="insertingURL = true; linkToUpload=''">
                            <div class="icon-btn__icon">
                                <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#FFF">
                                        <path
                                            d="M4.635 14a4.65 4.65 0 0 1-3.257-1.334 4.554 4.554 0 0 1-.052-6.477l.929-.943a.737.737 0 0 1 1.053 0 .737.737 0 0 1 0 1.04l-.92.928a3.102 3.102 0 0 0 0 4.42c.588.583 1.382.911 2.21.914a3.117 3.117 0 0 0 2.21-.935l.929-.95a.737.737 0 0 1 1.054 0 .737.737 0 0 1 0 1.038l-.929.958A4.598 4.598 0 0 1 4.664 14zM11.215 8.982a.737.737 0 0 1-.523-.206.737.737 0 0 1 0-1.04l.928-.942a3.102 3.102 0 0 0 0-4.421 3.161 3.161 0 0 0-2.21-.914 3.117 3.117 0 0 0-2.211.936l-.987.965a.737.737 0 0 1-1.054.015.737.737 0 0 1 0-1.04l.928-.957A4.598 4.598 0 0 1 9.336 0a4.65 4.65 0 0 1 3.257 1.334 4.554 4.554 0 0 1 .051 6.477l-.928.943a.737.737 0 0 1-.501.228z"/>
                                        <path
                                            d="M5.158 9.579a.737.737 0 0 1-.523-.214.737.737 0 0 1 0-1.039l3.64-3.684a.737.737 0 0 1 1.053 0 .737.737 0 0 1 0 1.04l-3.64 3.683a.737.737 0 0 1-.53.214z"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="icon-btn__title">
                                {{ $t('insertURL') }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-row" v-show="selectedItems.length >= 2">
                        <div class="icon-btn" @click="tryToDelete(selectedItems)">
                            <div class="icon-btn__icon">
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                                       stroke-width="2" transform="scale(.66667)">
                                        <path
                                            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                        <use transform="translate(10 11)" xlink:href="#path-1"/>
                                        <use transform="translate(14 11)" xlink:href="#path-1"/>
                                    </g>
                                    <defs>
                                        <path id="path-1" d="M0 0v6"/>
                                    </defs>
                                </svg>
                            </div>
                            <div class="icon-btn__title">Delete items</div>
                        </div>
                    </div>
                </div>
            </div>

            <unsplashTab
                v-show="activeTab=='external' && currentIntegration=='unsplash'"
                ref="unsplashTab"
                :visible="activeTab=='external' && currentIntegration=='unsplash'"
                @itemselected="externalItemSelected"
            />
            <iconfinderTab
                ref="iconfinderTab"
                v-show="activeTab=='external' && currentIntegration=='iconfinder'"
                :visible="activeTab=='external' && currentIntegration=='iconfinder'"
                @itemselected="externalItemSelected"
            />
            <giphyTab
                ref="giphyTab"
                v-show="activeTab=='external' && currentIntegration=='giphy'"
                :visible="activeTab=='external' && currentIntegration=='giphy'"
                @itemselected="externalItemSelected"
            />

            <div class="tabs__body-item tabs__body-item_deleted" v-show="activeTab=='deleted'">
                <div class="trash-placeholder" v-if="deletedMedia.length==0">
                    {{ $t('trashIsEmpty') }}
                </div>
                <div class="spinner" :class="{'spinner_active': deletedBusy}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
                         stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
                        <path
                            d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/>
                    </svg>
                </div>
                <div
                    class="media-browser__items custom-scroll"
                    @click="selectedItems = []"
                    @scroll="mediaScroll($event, 'getDeletedMedia')"
                >
                    <div class="media-browser__item" v-for="m in deletedMedia" :key="m.id">
                        <div
                            class="media-browser__item-pic"
                            @click.stop="itemClick(m.id)"
                            :class="{'selected': _.indexOf(selectedItems, m.id)!=-1}"
                            :style="{'background-image': `url(${currentPage.storageUrl}${m.thumb_url})`}"
                        />
                    </div>
                </div>
                <div class="media-browser__bottom-panel" v-if="deletedMedia.length > 0">
                    <div class="flex-row">
                        <div class="icon-btn" v-if="selectedItems.length > 0" @click="restoreItems">
                            <div class="icon-btn__icon" style="transform: scale(1, -1)">
                                <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.933 5.867h-3.666v1.466h3.666a2.2 2.2 0 1 1 0 4.4h-7.03L8.8 9.837 7.763 8.8l-3.148 3.148a.733.733 0 0 0 0 1.037l3.148 3.149L8.8 15.096 6.904 13.2h7.03a3.666 3.666 0 1 0 0-7.333z"
                                        fill="#FFF"/>
                                </svg>
                            </div>
                            <div class="icon-btn__title">
                                {{ $t('restore') }}
                            </div>
                        </div>
                        <div class="icon-btn" @click="showTrashDialog = true">
                            <div class="icon-btn__icon">
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                     xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g fill="none" stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"
                                       stroke-width="2" transform="scale(.66667)">
                                        <path
                                            d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                        <use transform="translate(10 11)" xlink:href="#path-1"/>
                                        <use transform="translate(14 11)" xlink:href="#path-1"/>
                                    </g>
                                    <defs>
                                        <path id="path-1" d="M0 0v6"/>
                                    </defs>
                                </svg>
                            </div>
                            <div class="icon-btn__title">
                                {{ $t('emptyTrash') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="media-browser__dialog" v-if="showDeleteDialog">
            <div class="media-browser__dialog-title">
                {{ $t('deleteFiles') }}
            </div>
            <div class="media-browser__dialog-text">
                {{ $t('deleteDescription') }}
            </div>
            <div class="flex-row">
                <div class="btn btn_editor-btn media-browser__dialog-btn" @click="deleteItems">
                    {{ $t('confirm') }}
                </div>
                <div class="btn btn_editor-btn media-browser__dialog-btn" @click="showDeleteDialog = false">
                    {{ $t('cancel') }}
                </div>
            </div>
        </div>
        <div class="media-browser__dialog" v-if="showTrashDialog">
            <div class="media-browser__dialog-title">
                {{ $t('emptyTrash') }}
            </div>
            <div class="media-browser__dialog-text">
                {{ $t('cannotBeUndone') }}
            </div>
            <div class="flex-row">
                <div class="btn btn_editor-btn media-browser__dialog-btn" @click="emptyTrash">
                    {{ $t('confirm') }}
                </div>
                <div class="btn btn_editor-btn media-browser__dialog-btn" @click="showTrashDialog = false">
                    {{ $t('cancel') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import $ from 'jquery';
import Vue from 'vue';

import Ajax from '@/mixins/Ajax';

import GiphyTab from '@/components/editor/MediaBrowser/components/GiphyTab';
import IconfinderTab from '@/components/editor/MediaBrowser/components/IconfinderTab';
import UnsplashTab from '@/components/editor/MediaBrowser/components/UnsplashTab';
import PropIntegrationSelect from '@/components/editor/property/PropIntegrationSelect';
import { apiDelete, apiPost, apiPut } from '@/models/Api';

export default {
    name: 'Media_browser',
    inject: ['currentPage'],
    props: ['visible'],
    mixins: [Ajax],
    components: {
        unsplashTab: UnsplashTab,
        iconfinderTab: IconfinderTab,
        giphyTab: GiphyTab,
        propIntegrationSelect: PropIntegrationSelect
    },
    data () {
        return {
            mousePressed: false,
            busy: false,
            activeTab: 'my', // my / external / deleted
            editingItem: 0,
            insertingURL: false, // флаг для показа поля ввода ссылки
            linkToUpload: '', // ссылка на файл
            selectedItems: [],
            itemsToDelete: [],
            requestItemsCount: 10,
            myMedia: [],
            myMediaSearch: '',
            myMediaBusy: false,
            deletedMedia: [],
            deletedBusy: false,
            showDeleteDialog: false,
            showTrashDialog: false,
            showMessage: false,
            lastSkip: undefined,
            message: {
                title: 'title',
                description: 'description',
                error: false
            },
            dropping: false, // пользователь пытается бросить файл в media library
            unsplash: {},
            tempMediaItem: {},
            newMediaRequests: {},
            resolvingMedia: [],
            requestId: 0,

            sizeLimit: 5242880,

            uploadProgress: '',

            integrations: {
                unsplash: 'Unsplash',
                iconfinder: 'IconFinder',
                giphy: 'Giphy'
            },
            currentIntegration: 'unsplash'
        };
    },
    computed: {
        media () {
            return this.$store.state.presentation.media;
        }
    },
    watch: {
        insertingURL () {
            if (this.insertingURL) {
                this.$nextTick(function () {
                    $(this.$el).find('.media-browser__input_url')[0].focus();
                });
            }
        },
        activeTab () {
            this.selectedItems = [];
            this.insertingURL = false;
            switch(this.activeTab) {
                case 'my':
                    this.getMedia(true);
                    break;
                case 'deleted':
                    this.getDeletedMedia(true);
                    break;
            }
        },
        'currentPage.mousePressed' () {
            if (!this.currentPage.mousePressed.left && !this.mousePressed) {
                this.$parent.showMedia = false;
                this.$parent.newImage = false;
            }
        },
        visible () {
            if (!this.visible) {
                this.insertingURL = false;
                if (this.tempMediaId != null) {
                    this.resolveTempMedia(this.tempMediaId, function () {
                        // console.log 'temp media resolved'
                    });
                    this.tempMediaId = undefined;
                }
            }

            if (this.visible && (this.myMedia.length === 0)) {
                this.$nextTick(
                    function () {
                        switch(this.activeTab) {
                            case 'my':
                                this.getMedia(true);
                                break;
                            case 'deleted':
                                this.getDeletedMedia(true);
                                break;
                        }
                    });
            }
        }
    },
    methods: {
        nextRequestId () {
            return this.requestId++;
        },
        defaultFailCB () { // FAIL callback
            // console.log 'ERROR '+data.status
            this.busy = false;
            this.myMediaBusy = false;
            this.deletedBusy = false;
            this.message.title = 'Error';
            this.message.description = 'Something went wrong! We are working on the issue.';
            this.showMessage = true;
            this.message.error = true;
            return this.hideMessage(5000);
        },
        getMediaId (id) { // найти медиа объект по id из базы на сервере
            let m = undefined;
            for (let k in this.$store.state.presentation.media) {
                const v = this.$store.state.presentation.media[k];
                if (v.id === id) {
                    m = parseInt(k);
                }
            }
            return m;
        },
        setMedia (opts) {
            let id;
            if (opts.mediaId != null) { // для unsplash
                id = opts.mediaId; // для unsplash
            } else {
                id = this.$store.state.presentation.lastId;
            }
            this.$store.commit('setMedia', opts);
            return id;
        },
        async resolveTempMedia (tempMediaId, callback) {
            const that = this;
            console.log('media 1');
            const tempId = tempMediaId;

            if ((this.media[tempId] == null)) {
                return;
            }

            if (this.resolvingMedia.includes(tempId)) {
                return;
            }

            const d = {}; // data for post request

            const { meta } = this.media[tempId];
            if (meta != null) {
                d['metadata'] = JSON.stringify(this.media[tempId].meta);
                if (meta.ref === 'unsplash') {
                    //### UNSPLASH API guides complience
                    this.$refs.unsplashTab.triggerUnsplashDownload(this.media[tempId].meta.id);
                }
            }

            if (that.media[tempId].dataURI === '') {
                // console.log 'broken blob'
                delete that.media[tempId].dataURI;
                delete that.media[tempId].temp;
                that.media[tempId].url = '';
                that.clearMediaFromEverywhere(tempId);
                return;
            }

            if (that.media[tempId].dataURI) { // значит это blob
                this.currentPage.supportedMedia.map(function (type) {
                    if (that.media[tempId].dataURI.indexOf('data:' + type) !== -1) {
                        d['blob'] = that.media[tempId].dataURI.replace('data:' + type + ';base64,', '');
                    }
                });
            } else { // значит это внешний url
                d['url'] = that.media[tempId].url;
            }

            if (this.currentPage.isTemplate === 'true') {
                d['template_id'] = this.currentPage.presentationId;
            } else {
                d['presentation_id'] = this.currentPage.presentationId;
            }

            const requestId = this.nextRequestId();
            this.newMediaRequests[requestId] = true;
            this.resolvingMedia.push(tempId);

            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/media`, d);

            if (res) {
                delete this.newMediaRequests[requestId];
                this.resolvingMedia.splice(this.resolvingMedia.indexOf(tempId), 1);

                const imgPreloader = document.createElement('img');
                const that = this;
                imgPreloader.onload = function () {
                    const resolvedMediaItem = {
                        id: res.id,
                        type: res.type,
                        url: res.uri
                    };
                    console.log(that.media);
                    if (that.media[tempId]?.meta != null) {
                        resolvedMediaItem.meta = that.media[tempId].meta;
                    }
                    // console.log resolvedMediaItem
                    that.media[tempId] = resolvedMediaItem;

                    $(imgPreloader).remove();

                    console.log('replaceHistory');
                    that.$store.commit('replaceHistory', 'resolveTempMedia');
                    that.myMedia = [];
                    that.getMedia(true);
                };

                if (res) {
                    imgPreloader.src = this.currentPage.storageUrl + res.uri;

                    this.message.title = 'Success';
                    this.message.description = 'File saved to media library!';
                    this.message.error = false;
                    this.showMessage = true;
                    this.hideMessage(2000);

                    if ((callback != null) && (typeof callback === 'function')) {
                        callback(res);
                    }
                } else {
                    this.message.title = 'Error';
                    if (res.error != null) {
                        this.message.description = response.error;
                    } else {
                        this.message.description = 'URL is invalid or file format is not supported! Please, check the link and try again.';
                    }
                    this.message.error = true;
                    this.showMessage = true;
                    this.hideMessage(2000);
                }
            } else {
                delete that.newMediaRequests[requestId];
                this.resolvingMedia.splice(
                    that.resolvingMedia.indexOf(tempId),
                    1
                );
                this.defaultFailCB();
            }
        },
        clearMediaFromEverywhere (mediaId) {

            const p = this.$store.state.presentation;
            p.slides.forEach(function (s) {
                if (s.bg['background-image'] === parseInt(mediaId)) {
                    s.bg['background-image'] = '';
                }

                s.objects.forEach(function (o) {
                    if (o.styleProps['background-image'] === parseInt(mediaId)) {
                        o.styleProps['background-image'] = '';
                    }
                });

            });

            delete p.media[mediaId];
            this.$store.commit('replaceHistory');

        },
        mouseUp () {
            return setTimeout(() => {
                    this.mousePressed = false;
                },
                0);
        },
        chooseFile (e) {
            // console.log e
            if (e.target.files[0] != null) {
                if (this.currentPage.supportedMedia.indexOf(e.target.files[0].type) === -1) {
                    this.currentPage.showError('File type is not supported');
                    return;
                }
                this.uploadMedia(e.target.files[0]);
            }
        },
        async uploadMedia (file) {
            const that = this;
            this.busy = true;
            this.dropping = false;

            const route = 'user/' + this.$store.state.user.id + '/media';
            const d =
                { file };

            if (that.currentPage.isTemplate === 'true') {
                d['template_id'] = that.currentPage.presentationId;
            } else {
                d['presentation_id'] = that.currentPage.presentationId;
            }

            const requestId = that.nextRequestId();
            that.newMediaRequests[requestId] = true;

            that.uploadFile(route, d, function (response) {
                    delete that.newMediaRequests[requestId];
                    that.busy = false;
                    that.uploadProgress = '';
                    // console.log response
                    // if(!response?.data)
                    //   console.log 'no data'
                    if (response) {
                        that.message.title = 'Success';
                        that.message.description = 'File successfully uploaded!';
                        that.showMessage = true;
                        that.message.error = false;
                        that.hideMessage(2000);
                        that.myMedia = [];
                        const count = that.myMedia.length + 1;
                        that.getMedia(true);
                    } else {
                        reject(response.error);
                        that.message.title = 'Error';
                        that.message.description = response.error;
                        that.message.error = true;
                        that.showMessage = true;
                        that.hideMessage(2000);
                    }
                },

                function (data) {
                    that.uploadProgress = '';
                    that.defaultFailCB(data);
                    delete that.newMediaRequests[requestId];
                    reject(data);
                },

                false,

                function (progress) {
                    that.uploadProgress = progress + '%';
                });
        },

        dropMedia (e) {
            if ((e.type === 'drop') && (e.dataTransfer.files[0] != null)) {
                if (this.currentPage.supportedMedia.indexOf(e.dataTransfer.files[0].type) === -1) {
                    this.currentPage.showError('File type is not supported');
                    this.dropping = false;
                    return;
                }
                this.uploadMedia(e.dataTransfer.files[0]);
            }
        },
        async getMedia (initial) {
            if (!this.visible || (this.activeTab !== 'my')) {
                return;
            }

            if (Object.keys(this.newMediaRequests).length > 0) {
                console.warn('deferred getMedia');
                setTimeout(() => this.getMedia(initial), 1000);
                return;
            }

            if (this.myMediaSearch !== '') {
                this.searchMedia();
                return;
            }

            let route = `/api/v1/presentation/${this.currentPage.presentationId}/media`;
            if (this.currentPage.isTemplate === 'true') {
                route = `/api/v1/template/${this.currentPage.presentationId}/media`;
            }

            this.myMediaBusy = true;
            const skip = this.myMedia.length;
            const res = await apiPost(
                route,
                {
                    take: this.requestItemsCount,
                    skip: this.myMedia.length
                }
            );
            this.myMediaBusy = false;

            if (res && Array.isArray(res) && res.length > 0) {
                if (this.lastSkip === skip && skip !== 0) {
                    console.log('myMedia is inconsistent');
                    this.myMedia = [];
                    await this.getMedia(true);
                    return;
                } else {
                    this.lastSkip = skip;
                }

                const newMedia = _.uniqBy(this.myMedia.concat(res), 'id');
                this.myMedia = newMedia;

                if (initial) {
                    const itemsContainer = $(this.$el).find('.tabs__body-item_my .media-browser__items')[0];

                    this.$nextTick(() => {
                        // Равенство выполнится если нет скролла
                        if (itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                            this.getMedia(true);
                        }
                    });
                }
            }
        },

        async getDeletedMedia (initial) {

            if (!this.visible || (this.activeTab !== 'deleted')) {
                return;
            }

            if (Object.keys(this.newMediaRequests).length > 0) {
                console.warn('deferred getDeletedMedia');
                setTimeout(
                    () => {
                        this.getDeletedMedia(initial);
                    },
                    1000
                );
                return;
            }

            this.deletedBusy = true;
            const data = {
                take: this.requestItemsCount,
                skip: this.deletedMedia.length
            };

            if (this.currentPage.isTemplate === 'true') {
                data.template_id = this.currentPage.presentationId;
            } else {
                data.presentation_id = this.currentPage.presentationId;
            }

            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/media/deleted`, data);
            this.deletedBusy = false;

            if (res && res.length > 0) {
                const newMedia = _.uniqBy(this.deletedMedia.concat(res), 'id');
                this.deletedMedia = newMedia;

                if (initial) {
                    const itemsContainer = $(this.$el).find('.tabs__body-item_deleted .media-browser__items')[0];

                    Vue.nextTick(
                        function () {
                            // Равенство выполнится если нет скролла
                            if (itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                                this.getDeletedMedia(true);
                            }
                        },
                        this
                    );
                }
            }
        },
        itemClick (id) {
            if ((this.currentPage.keyPressed[0] === 91) || (this.currentPage.keyPressed[0] === 17) || (this.currentPage.keyPressed[0] === 16)) {
                if (_.indexOf(this.selectedItems, id) === -1) {
                    this.selectedItems.push(id);
                } else {
                    this.selectedItems.splice(_.indexOf(this.selectedItems, id), 1);
                }
            } else {
                this.selectedItems = [id];
            }
        },
        async createNewImage (opts) {
            let {
                size
            } = opts;
            if (size == null) {
                size = await this.currentPage.getImgSize(opts.url);
            }

            this.$store.commit(
                'addComponent',
                {
                    type: 'rectangle',
                    draw: false,
                    size,
                    props: {
                        'background-color': {
                            'own': [255, 255, 255, 0],
                            'palette': false
                        },
                        'stroke-width': 0,
                        'lock-aspect': true
                    },
                    afterCreate: [
                        {
                            method: 'selfSaveHistory',
                            immediate: true
                        }
                    ]
                }
            );
        },

        myMediaItemSelected (m) {
            this.itemClick(m.id);
            this.updateProp(m);
        },
        async updateProp (m) {
            if (this.selectedItems.length > 1) {
                return;
            }

            const size = await this.currentPage.getImgSize(m.url);

            if (this.$parent.newImage) {
                this.createNewImage({ size });
            }
            let mediaId = this.getMediaId(m.id);
            if ((mediaId == null)) {

                let meta = undefined;
                try {
                    meta = JSON.parse(m.metadata);
                } catch (e) {
                }

                mediaId = this.setMedia({
                    id: m.id,
                    url: m.url,
                    meta,
                    type: 'image'
                });
            }

            this.$nextTick(
                function () {
                    this.$parent.updateProp('background-image', mediaId);
                    this.$parent.newImage = false;
                    this.setBgSize(size);
                    this.$nextTick(function () {
                        this.$parent.updateProp('adjustBg');
                    });
                });

        },
        setBgSize (opts) {
            this.$parent.updateProp('background-image-width', opts.width);
            this.$parent.updateProp('background-image-height', opts.height);
        },
        tryToDelete (ids) {
            // TO-DO: проверять selectedItems на использование
            // Если есть используемые - показываем диалог, иначе - удаляем
            this.itemsToDelete = ids;
            this.deleteItems();
            // if !@itemsToDelete? or @itemsToDelete.length == 0
            //   return
            // some = @myMedia.filter((item)->
            //   return true if item.id in ids && item.presentations.length > 0
            // )
            // if some.length > 0
            //   @showDeleteDialog = true
            // else
            //   @deleteItems()

        },
        async deleteItems () {
            this.showDeleteDialog = false;
            this.busy = true;
            const requestId = this.nextRequestId();
            this.newMediaRequests[requestId] = true;

            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/media/delete`, { ids: this.itemsToDelete });
            this.busy = false;
            delete this.newMediaRequests[requestId];

            if (res) {
                this.deletedMedia = [];
                const removed = _.remove(this.myMedia, item => _.indexOf(this.itemsToDelete, item.id) !== -1);
                this.$store.commit('deleteMedia', removed.map(item => item.id));
                this.itemsToDelete = [];
            } else {
                delete this.newMediaRequests[requestId];
                this.defaultFailCB();
            }
        },
        async restoreItems () {
            if (this.selectedItems == null || this.selectedItems.length === 0) {
                return;
            }

            const requestId = this.nextRequestId();
            this.newMediaRequests[requestId] = true;

            this.busy = true;
            const data = { ids: [...this.selectedItems] };
            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/media/restore`, data);
            this.busy = false;

            if (res) {
                delete this.newMediaRequests[requestId];
                this.deletedMedia = this.deletedMedia.filter(item => !data.ids.includes(item.id));

                this.selectedItems = [];
                this.myMedia = [];
            } else {
                // ToDo: api exception
                delete this.newMediaRequests[requestId];
            }
        },
        async updateName (e) {
            await apiPut(
                `/api/v1/user/${this.$store.state.user.id}/media/${this.editingItem}`,
                { name: e.target.value }
            );
        },
        async emptyTrash () {
            this.showTrashDialog = false;
            this.busy = true;

            const data = {};
            if (this.currentPage.isTemplate === 'true') {
                data.template_id = this.currentPage.presentationId;
            } else {
                data.presentation_id = this.currentPage.presentationId;
            }

            const requestId = this.nextRequestId();
            this.newMediaRequests[requestId] = true;

            const res = await apiDelete(`/api/v1/user/${this.$store.state.user.id}/media/deleted`, data);
            this.busy = false;
            delete this.newMediaRequests[requestId];

            if (res) {
                this.deletedMedia = [];
                this.message.title = 'Success';
                this.message.description = 'Trash is empty!';
                this.showMessage = true;
                this.hideMessage(2000);
            }
        },
        async checkLink () {
            this.busy = true;
            this.insertingURL = false; // скрываем форму

            const data = { url: this.linkToUpload };

            if (this.currentPage.isTemplate === 'true') {
                data['template_id'] = this.currentPage.presentationId;
            } else {
                data['presentation_id'] = this.currentPage.presentationId;
            }

            const requestId = this.nextRequestId();
            this.newMediaRequests[requestId] = true;

            const res = await apiPost(`/api/v1/user/${this.$store.state.user.id}/media`, data);
            delete this.newMediaRequests[requestId];
            this.busy = false;

            if (res) {
                this.linkToUpload = '';
                this.message.title = 'Success';
                this.message.description = 'File successfully uploaded!';
                this.showMessage = true;
                this.hideMessage(2000);

                this.myMedia = [];
                this.getMedia(true);
            } else {
                delete this.newMediaRequests[requestId];
                this.defaultFailCB(data);
            }
        },
        hideMessage (t) {
            if ((t == null)) {
                t = 0;
            }
            const that = this;
            setTimeout(function () {
                    that.showMessage = false;
                },
                t);
        },
        mediaScroll (e, cb) {
            const pb = parseInt(getComputedStyle(e.target)['padding-bottom']);

            if (($(e.target).height() + e.target.scrollTop) >= (e.target.scrollHeight - pb)) {
                if (typeof cb === 'function') {
                    cb();
                } else {
                    this[cb]();
                }
                return;
            }
        },
        async externalItemSelected (opts) {

            const {
                url
            } = opts;
            const {
                meta
            } = opts;

            const size = await this.currentPage.getImgSize(url);

            if (this.$parent.newImage) {
                this.createNewImage({ size });
            }

            this.$nextTick(function () {
                let mediaId;
                if (this.tempMediaId != null) {
                    mediaId = this.setMedia({
                        mediaId: this.tempMediaId,
                        url,
                        temp: true,
                        meta
                    });
                } else {
                    mediaId = this.setMedia({
                        url,
                        temp: true,
                        meta
                    });
                    this.tempMediaId = mediaId;
                }
                this.$parent.updateProp('background-image', mediaId);
                this.setBgSize(size);
            });

        },
        async dropFile (file) {

            // Добавляем атрибут preserveAspectRatio="none" для svg,
            // чтобы изображение могло растягиваться при stretch наложении
            if (file.type === 'image/svg+xml') {
                const {
                    name
                } = file;

                const fileContent = await file.text();

                const svgTagPlace = fileContent.indexOf('<svg');
                if (svgTagPlace !== -1) {
                    const res = fileContent.slice(0, svgTagPlace) + '<svg preserveAspectRatio="none"' + fileContent.slice(svgTagPlace + 4);
                    const modifiedSvg = new File(
                        [res],
                        name,
                        {
                            type: 'image/svg+xml'
                        }
                    );
                    file = modifiedSvg;
                }
            }

            const reader = new FileReader();

            reader.addEventListener('load', async e => {

                if (e.total > this.sizeLimit) {
                    const mbSize = Math.round((e.total / 1024 / 1024) * 10) / 10;
                    this.currentPage.showDialog({
                        text: `File must be less than 5MB. You are trying to upload ${mbSize}MB file`,
                        name: 'Files >5MB are not supported'
                    });
                    return;
                }

                const url = e.target.result;

                const size = await this.currentPage.getImgSize(url);

                if (this.$parent.newImage) {
                    this.createNewImage({ size });
                }

                this.$nextTick(function () {

                    // Превращаем в блоб, чтобы не хранить длиннющую строку в сторе
                    const blob = this.dataURItoBlob(url);
                    const blobUrl = URL.createObjectURL(blob);
                    const mediaId = this.setMedia({
                        url: blobUrl,
                        temp: true,
                        dataURI: url
                    });
                    this.tempMediaId = mediaId;
                    this.$parent.updateProp('background-image', mediaId);
                    this.setBgSize(size);
                    console.log('setBgSize');
                    this.resolveTempMedia(mediaId, function () {
                        // console.log 'temp media resolved'
                    });
                    this.tempMediaId = undefined;
                });
            });
            reader.readAsDataURL(file);
        },

        dataURItoBlob (dataURI) {
            let byteString = undefined;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            } else {
                byteString = unescape(dataURI.split(',')[1]);
            }
            // separate out the mime component
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            // write the bytes of the string to a typed array
            const ia = new Uint8Array(byteString.length);
            let i = 0;
            while (i < byteString.length) {
                ia[i] = byteString.charCodeAt(i);
                i++;
            }
            return new Blob([ia], { type: mimeString });
        }
    }
};
</script>

<style lang="scss">
.media-browser {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    width: 290px;
    padding: 0 20px;
    background: #2A2C2D;
    color: #777777;
    z-index: 1;

    &__bottom-panel {
        position: relative;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        height: 42px;
        align-items: center;
        // bottom: 0;
        margin-left: -20px;
        margin-right: -20px;
        background: #333638;
        padding: 0 20px;
        z-index: 1;
        margin-top: auto;

        .flex-row {
            width: 100%;
            justify-content: center;
        }
    }

    &__dialog {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #2A2C2D;
        opacity: 0.97;
        color: #fff;
        z-index: 1;

        &-title {
            position: relative;
            display: block;
            font-weight: bold;
            margin-bottom: 20px;
        }

        &-text {
            position: relative;
            display: block;
            font-size: 14px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .spinner {
        background-color: #2A2C2Df7;
        z-index: 1;

        &:after {
            content: attr(data-progress);
            position: absolute;
            top: calc(50% + 16px);
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
        }
    }

    &__upload-btn {
        position: relative;
        display: inline-block;

        input {
            display: none;
        }
    }

    &__drop-media {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #2A2C2D;
        opacity: .97;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px dashed rgba(255, 255, 255, .2);
            border-radius: 4px;
        }

        &:after {
            content: 'Drop file here!';
            position: relative;
            display: block;
            text-align: center;
            top: 50%;
            margin-top: -10px;
        }
    }

    &__note {
        font-size: 12px;
        padding: 10px;
        margin-top: 0;
        border-radius: 3px;
        color: #aaafbb;
        background: rgba(240, 240, 255, .05);
    }

    &__message {
        position: absolute;
        display: block;
        width: 100%;
        top: 100%;
        transform: translateY(-100%);
        margin-top: -20px;
        background: #4155ff;
        border-radius: 3px;
        color: #fff;
        padding: 0 10px;
        z-index: 2;
        box-sizing: border-box;
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s ease;
        font-size: 12px;

        &_active {
            opacity: 1;
            pointer-events: auto;
        }

        &_error {
            background: #d14040;
        }

        &-title {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 10px;
            line-height: 16px;
            padding: 5px 0;
            color: #fff;
        }

        &-description {
            padding: 5px 0 10px;
        }
    }

    &__search {
        margin-bottom: 20px;

        svg {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    &__input {
        position: relative;
        display: block;
        background: none;
        border: 0;
        border: 1px solid #404447;
        width: 100%;
        height: 32px;
        color: #fff;
        padding: 5px 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
        font-size: 12px;
        border-radius: 3px;
        background-color: #333638;

        &:focus {
            outline: none;
            border-color: #fff5;
        }
    }

    &__title {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
        line-height: 16px;
        padding: 5px 0;
        color: #fff;
        flex-grow: 0;
    }

    &__close {
        position: absolute;
        display: block;
        top: 4px;
        right: 4px;
    }

    .tabs__head {
        background: transparent;
        flex-grow: 0;
        justify-content: space-between;

        &-group {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        &-item {
            color: #fff;
            margin-right: 0px;

            &.active {
                border-color: #10AFFF;
            }

            &_trash {
                border-bottom: none;
                margin-right: 11px;

                svg {
                    stroke: #fff;
                }

                &.active {
                    opacity: 1;

                    svg {
                        stroke: #10AFFF;
                    }
                }
            }
        }
    }

    .tabs__body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        padding: 20px 0 0;
        overflow: visible; // чтобы не обрезать нижнюю панель

        &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .spinner {
                top: auto;
                height: 30px;
            }

            &_integration {
                .media-browser__items {
                    top: 54px;
                }
            }
        }
    }

    .trash-placeholder {
        margin-right: auto;
        margin-left: auto;
    }

    &__items {
        position: absolute;
        top: 0;
        bottom: -30px;
        padding-bottom: 60px;
        display: flex;
        width: 320px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        overflow: auto;
        margin-bottom: 10px;
        margin-left: -10px;
    }

    &__item {
        position: relative;
        display: block;
        width: 120px;
        margin: 0 0 10px 10px;
        cursor: pointer;

        &:hover {
            .media-browser__item-delete {
                display: block;
            }
        }

        &-delete {
            position: absolute;
            display: none;
            right: 3px;
            top: 3px;
            z-index: 1;

            // &:before, &:after{
            //   background: #eee;
            // }
        }

        &-pic {
            position: relative;
            background: rgba(255, 255, 255, .1);
            border-radius: 3px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 120px;
            height: 80px;

            &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 2px solid transparent;
                border-radius: 3px;
            }

            &.selected:after {
                border-color: #10AFFF;
            }

            &:hover:after {
                border-color: #10AFFF88;
            }

            &.selected:hover:after {
                border-color: #10AFFF;
            }
        }

        &-title {
            position: relative;
            display: block;
            width: 100%;
            box-sizing: border-box;
            font-size: 14px;
            text-overflow: ellipsis;
            padding: 5px 20px 5px 0;
            color: #fff;
            outline: none;
            background: none;
            border: 0;

            &:hover + svg {
                opacity: .3;
            }
        }

        svg {
            position: absolute;
            display: block;
            right: 0;
            bottom: 5px;
            opacity: 0;
            right: 0;
        }
    }

    .flex-row {
        position: relative;
        display: flex;
        flex-grow: 0;
    }

    // .btn{
    //   background:rgba(255,255,255, .1);
    //   margin-right: 6px;
    //   font-size: 12px;
    //   padding: 5px 30px;
    // }

    .icon-btn {
        opacity: .6;
        margin: 0 15px;
        padding: 5px 0;

        &:hover {
            opacity: 1;
        }

        &__title {
            color: #fff;
        }

        input {
            display: none;
        }
    }

    .property__btn_confirm {
        position: relative;
        display: block;
        margin: 0 auto;
        // width: 100px;
        height: 35px;
        box-sizing: border-box;
        margin-top: 10px;
    }
}
</style>

<i18n>
{
    "en": {
        "mediaLibrary": "Media library",
        "myMedia": "My media",
        "uploadFile": "Upload file",
        "insertURL": "Insert URL",
        "supportedMedia": "Supported media:Images<br />Supported file types: jpg, png, gif<br />Maximum file size: 5Mb",
        "pasteUrl": "Paste media url here",
        "cancel": "Cancel",
        "addMedia": "Add media",
        "emptyTrash": "Empty trash?",
        "cannotBeUndone": "This action cannot be undone.",
        "confirm": "Confirm",
        "deleteFiles": "Delete files?",
        "deleteDescription": "Some of the files you are trying to delete are currently in use. Thisaction cannot be undone.",
        "emptyTrash": "Empty trash",
        "restore": "Restore",
        "trashIsEmpty": "Your trash is empty",
        "Success": "Success",
        "Error": "Error",
        "title": "title",
        "description": "description",
        "Trash is empty!": "Trash is empty!",
        "File successfully uploaded!": "File successfully uploaded!",
        "File saved to media library!": "File saved to media library!",
        "Something went wrong! We are working on the issue.": "Something went wrong! We are working on the issue.",
        "URL is invalid or file format is not supported! Please, check the link and try again.": "URL is invalid or file format is not supported! Please, check the link and try again."
    },
    "ru": {
        "mediaLibrary": "Изображения",
        "myMedia": "Мои",
        "uploadFile": "Файл",
        "insertURL": "Ссылка",
        "supportedMedia": "Подерживаемые типы: Изображения<br />Подерживаемые форматы: jpg, png, gif<br />Максимальный размер: 5Mb",
        "pasteUrl": "Вставьте ссылку",
        "cancel": "Отмена",
        "addMedia": "Добавить",
        "emptyTrash": "Очистить корзину?",
        "cannotBeUndone": "Это действие не может быть отменено",
        "confirm": "Подтвердить",
        "deleteFiles": "Удалить файлы?",
        "deleteDescription": "Некоторые из файлов, которые вы пытаетесь удалить, в настоящее время используются. Это действие не может быть отменено",
        "emptyTrash": "Очистить корзину",
        "restore": "Восстановить",
        "trashIsEmpty": "Ваша корзина пуста",
        "Success": "Успешно",
        "Error": "Ошибка",
        "title": "title",
        "description": "description",
        "Trash is empty!": "Корзина пуста!",
        "Something went wrong! We are working on the issue.": "Что-то пошло не так! Мы работаем над этим.",
        "File successfully uploaded!": "Файл успешно загружен!",
        "File saved to media library!": "Файл сохранен в медиатеке!",
        "URL is invalid or file format is not supported! Please, check the link and try again.": "URL недействителен или формат файла не поддерживается! Пожалуйста, проверьте ссылку и повторите попытку."
    }
}
</i18n>
