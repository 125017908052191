<template>
  <div class="slide-templates" :style="containerStyle" :class="{'slide-templates_hidden': !isVisible || noTemplates}">
    <div class="slide-templates__item-wrapper">
      <slide-template
        :previewWidth="previewWidth"
        :style="{width: previewWidth + 'px'}"
        :templateData="null"
        :templateIndex="-1"
        @templateclicked="templateclicked(-1)">
      </slide-template>
    </div>
    <div
      class="slide-templates__item-wrapper"
      v-for="(template, templateIndex) in slideTemplates"
      :key="templateIndex"
      ref="templateWrappers">
      <slide-template
        :previewWidth="previewWidth"
        :style="{width: previewWidth + 'px'}"
        :templateData="template"
        :templateIndex="templateIndex"
        @templateclicked="templateclicked(templateIndex)">
      </slide-template>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import SlideTemplate from "@/components/editor/SlideTemplate";

export default {
  name: "SlideTemplates",
  props: ['isVisible', 'previewWidth', 'delta', 'rightBound', 'leftBound', 'leftMargin'],
  components: {
    slideTemplate: SlideTemplate
  },
  data() {
    return {
      selfRect: null,
      templateRect: null,
      templatesInARow: 5,
      magicNumber: 52
    };
  },
  mounted() {
    this.customWatch();
  },
  computed: {
    slideTemplates() {
      return this.$store.state.slideTemplates;
    },
    noTemplates() {
      return this.slideTemplates.length === 0;
    },
    containerTransformStyle() {
      const translateX = `${-this.leftMargin - this.delta + this.templatesDivLeftBoundShift}px`;
      const translateY = `calc(-100% - ${24}px)`;

      return {
        transform: `translate(${translateX}, ${translateY})`
      };
    },
    containerStyle() {
      let res = Object.assign({}, this.containerTransformStyle);
      if (this.slideTemplates.length > this.templatesInARow) {
        res = Object.assign(res, this.maxContainerWidth);
      }

      return res;
    },
    selfRightBound() {
      let ref;
      return (ref = this.selfRect) != null ? ref.right : 0;
    },
    selfLeftBound() {
      let ref;
      return (ref = this.selfRect) != null ? ref.left : 0;
    },
    selfWidth() {
      return this.selfRightBound - this.selfLeftBound;
    },
    boundsDistance() {
      return this.rightBound - this.leftBound;
    },
    templatesDivAndBoundsWidthDiff() {
      return this.boundsDistance - this.selfWidth;
    },
    isTemplatesDivFitsBetweenBounds() {
      return this.boundsDistance > this.selfWidth;
    },
    templatesDivLeftBoundShift() {
      if (this.isTemplatesDivFitsBetweenBounds) {
        return 0;
      } else {
        return this.templatesDivAndBoundsWidthDiff;
      }
    },
    maxContainerWidth() {
      let width;
      if (!this.templateRect) {
        width = 0;
      } else {
        width = `${this.templateRect.width * this.templatesInARow + this.magicNumber}px`;
      }

      return {width};
    }
  },
  methods: {
    customWatch() {
      this.$watch(function() {
        return [this.$root.resizeFlag, this.delta, this.slideTemplates];
      }, function() {
        this.$nextTick(function() {
          this.getRects();
        });
      }, {
        immediate: true,
        deep: true
      });
    },
    templateclicked(templateIndex) {
      this.$store.commit('addSlideFromTemplate', templateIndex);
      this.$emit('templateselected');
    },
    getRects() {
      this.getSelfRect();
      this.getTemplateRect();
    },
    getSelfRect() {
      if (!this.$el) {
        this.selfRect = null;
        return;
      }

      this.selfRect = this.$el.getBoundingClientRect();
    },
    getTemplateRect() {
      const templates = this.$refs.templateWrappers;
      if (templates == null) {
        this.templateRect = null;
        return;
      }

      // eslint-disable-next-line no-return-assign
      return this.templateRect = templates[0].getBoundingClientRect();
    }
  }
};
</script>

<style lang="scss">
.slide-templates{
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transform: translate(-12px, calc(-100% - 24px));
  backface-visibility: hidden;
  cursor: default;
  padding: 10px 8px;
  border: 1px solid #2A2C2D;
  border-radius: 5px;
  opacity: 1;
  pointer-events: auto;
  &:before{
    content: '';
    position: absolute;
    z-index: 0;
    top: -50px;
    left: -50px;
    right: -50px;
    height: calc(100% + 50px + 25px);
  }
  &:after{
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2A2C2D;
  }
  &_hidden{
    visibility: hidden;
  }
  &__item{
    position: relative;
    box-sizing: border-box;
    height: 60px;
    z-index: 1;
    cursor: pointer;
    &-wrapper{
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 5px;
      border: 2px solid transparent;
      z-index: 1;
      &:hover{
        border-color: #10AFFF;
      }
    }
    &_blank{
      background-color: #fff;
    }
  }
}
</style>
