<template>
    <div class="service-bar">
        <!-- For debugging -->
        <!-- <div class="service-bar__item service-bar__item_online" v-if="$parent.mousePressed.left">Flag</div> -->
        <!-- <div class="service-bar__item service-bar__item_offline" v-if="!$parent.mousePressed.left">Flag</div> -->

        <!-- ToDo: переписать это на EventBus -->
        <div v-if="$parent.isOnline && $parent.activeRequests.length === 0"
             class="service-bar__item service-bar__item_online">
            Online
        </div>
        <div v-else-if="$parent.isOnline && $parent.activeRequests.length > 0"
             class="service-bar__item service-bar__item_syncing">
            {{ $t('syncing') }}
        </div>
        <div v-else-if="!$parent.isOnline" class="service-bar__item service-bar__item_offline">
            Offline
        </div>

        <div v-show="activeMessage"
             class="service-bar__item_message"
             :class="serviceBarClasses"
             @mousedown="activeMessage.show = true">
            <svg width="20" height="17" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(3 2)" fill="none" fill-rule="evenodd">
                    <path
                        d="M5.912 1.184l-5.39 8.998A1.273 1.273 0 0 0 1.61 12.09h10.78a1.273 1.273 0 0 0 1.088-1.91l-5.39-8.997a1.273 1.273 0 0 0-2.176 0z"
                        stroke-linecap="round"
                        stroke-linejoin="round"/>
                    <circle cx="7" cy="9.545" r="1"/>
                    <rect x="6.364" y="3.182" width="1.273" height="4.455" rx=".636"/>
                </g>
            </svg>
            <div
                class="service-bar__item-message"
                :class="{'service-bar__item-message_active': activeMessage && activeMessage.show}"
            >
                {{ $t(activeMessageText) }}
                <div class="close" @mousedown.stop="activeMessage.show = false"></div>
            </div>
        </div>

        <div class="service-bar__item" @click="$parent.play()">
            <div class="service-bar__title-wrapper">
                <svg width="12" height="14" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 3.75L0 7.5V0z"
                          transform="translate(3 3)"
                          fill="#FFF"
                          stroke="#FFF"
                          stroke-width="2"
                          fill-rule="evenodd"
                          stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
                <div class="service-bar__item-title">{{ $t('play') }}</div>
            </div>
        </div>

        <ServiceBarDropdown @toogle-list="toogleShareLinkMenu">
            <template #activator>
                <inline-svg width="16" height="14" :src="require('@/assets/icon/share.svg')"/>
                <div class="service-bar__item-title">{{ $t('share') }}</div>
            </template>

            <template #options>
                <PresentationAccessOptions v-show="$parent.shareLinkActive" />
            </template>
        </ServiceBarDropdown>

        <ServiceBarDropdown @toogle-list="setContextMenu('export')">
            <template #activator>
                <inline-svg width="18" height="16" :src="require('@/assets/icon/download.svg')"/>
                <div class="service-bar__item-title">{{ $t('export') }}</div>
            </template>

            <template #options>
                <div
                    class="service-bar__nav context-menu context-menu_dark property__nav"
                    :class="{'context-menu_active': contextMenu === 'export'}"
                >
                    <div class="context-menu__item" @mousedown="exportPDF">PDF</div>
                </div>
            </template>
        </ServiceBarDropdown>

        <ServiceBarDropdown @toogle-list="setContextMenu('view')">
            <template #activator>
                <inline-svg width="24" height="24" :src="require('@/assets/icon/options.svg')"/>
                <div class="service-bar__item-title">{{ $t('view') }}</div>
            </template>

            <template #options>
                <div
                    class="service-bar__nav context-menu context-menu_dark property__nav"
                    :class="{'context-menu_active': contextMenu === 'view'}"
                >
                    <div class="context-menu__item" @mousedown="parentToggleRulers">
                        {{ $parent.showRulers ? $t('hideRulers') : $t('showRulers') }}
                    </div>

                    <div
                        class="context-menu__item"
                        :class="{'context-menu__item_disabled': !$parent.showRulers}"
                        @mousedown="parentToggleGuides"
                    >
                        {{ $parent.showGuides ? $t('hideGuides') : $t('showGuides') }}
                    </div>
                </div>
            </template>
        </ServiceBarDropdown>

        <a class="service-bar__item" href="/docs" target="_blank">
            <div class="service-bar__title-wrapper">
                <inline-svg
                    width="16"
                    height="16"
                    style="transform: translateX(-3px); fill: #fff;"
                    :src="require('@/assets/icon/circeled-question.svg')"
                />
                <div class="service-bar__item-title">{{ $t('help') }}</div>
            </div>
        </a>

        <div class="service-bar__item" @mousedown.stop="setContextMenu('settings')">
            <div class="service-bar__title-wrapper" style="margin-left: 20px;">
                <svg width="7" height="18" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.9 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM1.567 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm10.666 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        transform="rotate(90 1.5 3.5)"
                        fill="#fff"
                        fill-rule="nonzero"
                    />
                </svg>
            </div>
            <div class="service-bar__nav context-menu context-menu_dark property__nav"
                 :class="{'context-menu_active': contextMenu === 'settings'}">
                <div class="context-menu__item" @mousedown.stop="openProjectSettings">
                    {{ $t('projectSettings') }}
                </div>
            </div>
        </div>

        <!-- For debugging -->
        <!--    <div class="service-bar__item">-->
        <!--      <div class="service-bar__title-wrapper">-->
        <!--        <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">-->
        <!--          <g transform="translate(2 2)" fill="#FFF" fill-rule="evenodd">-->
        <!--            <circle opacity=".3" cx="12" cy="12" r="12"/>-->
        <!--            <text font-family="Helvetica" font-size="14">-->
        <!--              <tspan x="8" y="17">?</tspan>-->
        <!--            </text>-->
        <!--          </g>-->
        <!--        </svg>-->
        <!--      </div>-->
        <!--    </div>-->
    </div>
</template>

<script>
/* eslint-disable */
import ajax from '@/mixins/Ajax';
import ServiceBarDropdown from "@/components/editor/layout/ServiceBarDropdown";
import PresentationAccessOptions from "@/components/editor/layout/PresentationAccessOptions";

export default {
    name: 'ServiceBar',
    components: {
        PresentationAccessOptions,
        ServiceBarDropdown
    },
    mixins: [ajax],
    // props:['error', 'errorText']
    data() {
        return {
            navActive: false,
            contextMenu: false,
            activeMessages: []
        };
    },
    computed: {
        activeMessage() {
            return this.activeMessages[this.activeMessages.length - 1];
        },
        activeMessageText() {
            if (this.activeMessage) {
                return this.activeMessage.text;
            } else {
                return '';
            }
        },
        serviceBarClasses() {
            const res = {};
            if (this.activeMessage) {
                res['service-bar__item-message_' + this.activeMessage.type] = true;
            }
            return res;
        }
    },
    watch: {
        '$parent.mousePressed'() {
            if (this.$parent.mousePressed.left) {
                this.contextMenu = false;
            }
        }
    },
    methods: {
        setContextMenu(menu) {
            if (this.$parent.shareLinkActive) {
                this.$parent.shareLinkActive = false;
            }

            if (this.contextMenu === menu) {
                this.contextMenu = false;
            } else {
                this.contextMenu = menu;
            }
        },
        toogleShareLinkMenu() {
            this.contextMenu = null;
            this.$parent.shareLinkActive = !this.$parent.shareLinkActive;
        },
        parentToggleRulers() {
            this.$parent.toggleRulers();
        },
        parentToggleGuides() {
            this.$parent.toggleGuides();
        },
        exportPPTX() {
            this.$parent.saveSlides().then(() => {
                const url = '/api/v1/presentation/' + this.$parent.presentationId + '/export';
                window.location.href = this.base_url + url;
            });
        },
        exportPDF(e) {
            this.$parent.exportToPdf();
        },
        openProjectSettings(e) {
            this.$parent.showProjectSettings();
            this.settingsMenuActive = false;
        },
        showMessage(type, messageText) {
            let timeout;
            const message = {
                text: messageText,
                show: true,
                type
            };
            switch (type) {
                case 'info':
                    timeout = 5000;
                    break;
                case 'warn':
                    timeout = 15000;
            }
            this.activeMessages.push(message);

            if (timeout) {
                setTimeout(() => this.killMessage(message), timeout);
            }

            return message;
        },
        killMessage(message) {
            const index = !message
                ? this.activeMessages.length - 1
                : this.activeMessages.indexOf(message);

            if (index !== -1) {
                this.activeMessages.splice(index, 1);
            }
        }
    }
};
</script>

<style lang="scss">
.service-bar {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 10px 0;

    &__title-wrapper {
        display: flex;
        align-items: center;

        &:hover {
            & > svg, .service-bar__item-title {
                opacity: 1;
            }
        }

    }

    a {
        text-decoration: none;
        color: #fff;
    }

    &__item {
        position: relative;
        display: flex;
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        align-items: center;

        &_message {
            position: relative;
            display: block;
            margin-right: 15px;

            svg {
                cursor: pointer;
                opacity: 1;
                margin-top: 4px;
            }

        }

        &_online, &_offline, &_syncing {
            margin-right: 30px;

            &:after {
                content: '';
                position: absolute;
                right: -4px;
                width: 6px;
                height: 6px;
                border-radius: 10px;
            }
        }

        &_online {
            &:after {
                background-color: #0AFC4C;
            }
        }

        &_offline {
            &:after {
                background-color: #FE2C35;
            }
        }

        &_syncing {
            @keyframes syncblink {
                from {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }

            &:after {
                animation: syncblink 1s infinite ease-in-out;
                background-color: #FEFF7F;
            }
        }

        svg {
            position: relative;
            display: block;
            opacity: 0.5;
        }

        &-title {
            position: relative;
            display: block;
            margin-left: 3px;
            opacity: 0.5;
        }

        &-message {
            position: fixed;
            display: block;
            padding: 10px 40px 10px 20px;
            right: 290px;
            top: 50px;
            // background:#765D36F0;
            // color:#E6C145;
            border-radius: 3px;
            opacity: 0;
            pointer-events: none;
            transition: all .3s ease;

            &_error {
                svg {
                    path {
                        stroke: #b15656;
                    }

                    circle, rect {
                        fill: #b15656;
                    }
                }

                .close {
                    &:before, &:after {
                        background: #ffbfc4;
                    }
                }
            }

            &_warn {
                svg {
                    path {
                        stroke: #E6C145;
                    }

                    circle, rect {
                        fill: #E6C145;
                    }
                }

                .close {
                    &:before, &:after {
                        background: #E6C145;
                    }
                }
            }

            &_info {
                svg {
                    path {
                        stroke: #84b382;
                    }

                    circle, rect {
                        fill: #84b382;
                    }
                }

                .close {
                    &:before, &:after {
                        background: #c4ffc9;
                    }
                }
            }

            &_error &_active {
                background: #b15656f0;
                color: #ffbfc4;
            }

            &_warn &_active {
                background: #765D36F0;
                color: #E6C145;
            }

            &_info &_active {
                background: #84b382f0;
                color: #c4ffc9;
            }

            &_active {
                opacity: 1;
                pointer-events: auto;
            }

            .close {
                position: absolute;
                display: block;
                top: 5px;
                right: 6px;
                background: #0004;

                // &:before, &:after{
                //   background:#E6C145;
                // }
            }
        }
    }

    &__share {
        position: absolute;
        display: block;
        top: 30px;
        width: 330px;
        right: 0;
        background: #222327ee;
        padding: 10px 20px;
        border-radius: 3px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        transition: all .2s ease;

        &-title {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            font-size: 14px;

            span {
                opacity: .5;
                margin-right: 10px;
            }
        }

        .flex-row {
            position: relative;
            display: flex;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            transition: all .2s ease;

            &_visible {
                opacity: 1;
                pointer-events: auto;
            }
        }

        input {
            min-width: 260px;
            background: transparent;
            border: 0;
            color: #fff;
            font-size: 18px;
            outline: none;
        }

        svg {
            position: relative;
            display: block;
            height: 20px;
            width: 20px;
            opacity: 0.3;
            margin-right: 10px;
            transition: opacity, transform .3s ease;
            transform-origin: center;

            &:hover {
                opacity: 1;
            }

            &.share-link-copied {
                transform: scale(1.5);
            }
        }
    }

    &__nav {
        position: absolute;
        display: block;
        z-index: 1;
        top: 30px;
        right: 0;
        min-width: 125px;
    }
}
</style>

<i18n>
{
    "en": {
        "syncing": "Syncing",
        "play": "Play",
        "publicLink": "Public link",
        "share": "Share",
        "export": "Export",
        "view": "View",
        "help": "Help",
        "showRulers": "Show Rulers",
        "hideRulers": "Hide Rulers",
        "showGuides": "Show Guides",
        "hideGuides": "Hide Guides",
        "projectSettings": "Project settings",
        "Update title error: Project named cannot be empty": "Update title error: Project named cannot be empty"
    },
    "ru": {
        "syncing": "Синхронизация",
        "play": "Просмотр",
        "publicLink": "Публичная ссылка",
        "share": "Поделиться",
        "export": "Экспорт",
        "view": "Разметка",
        "help": "Документация",
        "showRulers": "Показать линейки",
        "hideRulers": "Скрыть линейки",
        "showGuides": "Показать направляющие",
        "hideGuides": "Скрыть направляющие",
        "projectSettings": "Настройки презентации",
        "Update title error: Project named cannot be empty": "Ошибка: имя проекта не может быть пустым"
    }
}
</i18n>
