<template>
    <div class="property property__type_corners">
        <div class="property__name">
            {{ $t(property.title) }}
        </div>
        <div class="col-50" v-for="corn in ref">
            <prop_number :propset="cornerProps" :property="corn" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";
import PropNumber from "@/components/editor/property/PropNumber";

export default {
    name: 'propCorners',
    mixins: [PropBase],
    components: {
        prop_number: PropNumber
    },
    data: function () {
        return {
            // uniform: true
            ref: {
                tl: {
                    name: 'tl',
                    min: 0
                }
            }
        };
    },
    // tr:
    //   name: 'tr'
    //   min:0
    // br:
    //   name: 'br'
    //   min:0
    // bl:
    //   name: 'bl'
    //   min:0
    computed: {
        cornerProps: function () {
            return {
                tl: this.propValue[0],
                tr: this.propValue[1],
                br: this.propValue[2],
                bl: this.propValue[3]
            };
        }
    },
    methods: {
        updateProp: function (k, v) {
            this.propValue = [v, v, v, v];
        }
    }
};
</script>

<style lang="scss">
.property__type_corners {
    width: 50%;
    display: inline-block;

    .property {
        padding: 0;

        &__name {
            margin-bottom: 0;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "Corner radius": "Corner radius"
    },
    "ru": {
        "Corner radius": "Радиус углов"
    }
}
</i18n>