<template>
    <div class="text-wrapper" v-bind:style="wrapperStyleProps">
        <div class="text" :contenteditable="!currentPage.isRenderer && slideindex != undefined"
             @keyup.stop="keyup($event)" @keydown.stop="keydown($event)" @mouseenter="mouseenter()"
             @mouseleave="mouseleave()" @mousedown.stop="mousedown($event)" @mouseup.stop="mouseup($event)"
             @focus="focus()" @blur="focusout($event)" @paste.prevent.stop="paste($event)" @cut="cut($event)"
             :style="textStyleProps" spellcheck="false" ref="textContainer" v-show="!svgData">
            <template v-if="currentPage.isRenderer" v-html="contentString"></template>
        </div>
        <svg v-if="svgData && size" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size.width"
             :height="size.height" :viewBox="'0 0 ' + size.width + ' ' + size.height" :x="size.x" :y="size.y"
             style="position: absolute; z-index: 9999; pointer-events: none;" ref="svg">
            <rect v-for="(block) in svgData" v-if="!block.style['do-not-stretch']"
                  :x="block.rect.left / currentPage.scale" :y="block.rect.top / currentPage.scale"
                  :height="(block.rect.bottom - block.rect.top) / currentPage.scale"
                  :width="(block.rect.right - block.rect.left) / currentPage.scale"
                  :fill="block.style['background-color']"></rect>
            <template v-for="(block, blockIndex) in svgData">
                <path :id="'path' + _uid + blockIndex" fill="none" stroke="transparent"
                      :d="`M ${block.rect.left / currentPage.scale} ${block.rect.baseline / currentPage.scale} H ${block.rect.right / currentPage.scale} z`"></path>
                <text v-if="block.content" :font-size="block.style['font-size']"
                      :font-family="block.style['font-family']" :font-weight="block.style['font-weight']"
                      :font-style="block.style['font-style']" :fill="block.style['color']"
                      :text-decoration="block.style['text-decoration']">
                    <textPath
                        :textLength="block.style['do-not-stretch'] ? false : (block.rect.right - block.rect.left) / currentPage.scale"
                        lengthAdjust="spacing" :href="'#path' + _uid + blockIndex">{{ block.content }}
                    </textPath>
                </text>
            </template>
        </svg>
    </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import _ from "lodash";
import $ from "jquery";

import Color from "@/mixins/editor/Color";
import Common from "@/mixins/editor/Common";

import { CurrentPage } from "@/models/CurrentPage";

export default {
    name: 'TextBlock',
    inject: ['currentPage'],
    props: ['opts', 'zi', 'index', 'slideindex', 'size'],
    mixins: [Common, Color],
    data () {
        return {
            mousepressed: false,
            selection: undefined,
            rememberedSelectionOffsets: false,
            focusInside: false,

            block: false,

            refresh: false,

            inputPromise: new Promise(resolve => resolve('initial')),

            shadowStore: {},

            // Убрать эту фигню в справочник
            defaults: {
                'color': [0, 0, 0, 1],
                'font-size': 16,
                'line-height': 18
            },

            pDomProps: [
                'color',
                'font-size',
                'font-family',
                'font-weight',
                'font-style'
            ],

            history: [[], []],

            blurWasForced: false,

            forbiddenChars: ['<', '>'],

            spaceChars: ['\u0009', ' '], // Tab, Space

            svgData: undefined
        };
    },
    mounted () {
        // @selection = window.getSelection()

        this.createShadowStore();

        this.setPropertyQueue = [];

        this.block = false;

        this.renderContent();

    },

    created () {
        this.deleteCharThrottled = _.throttle(this.deleteChar, 70);
    },

    updated () {
        this.shadowStore = _.cloneDeep(this.opts);
        this.renderContent();
    },

    watch: {

        opts: {
            handler () {
                if (this.isPreview()) {
                    this.shadowStore = _.cloneDeep(this.opts);
                    this.renderContent();
                }
            },
            deep: true
        },

        'currentPage.editingText' (newVal, oldVal) {

            const lostSelection = oldVal === this;
            const gotSelection = newVal === this;

            if (lostSelection) {
                this.focusInside = false;
                this.rememberedSelectionOffsets = false;
                if (!this.container.isTableCell && this.isEmpty()) {
                    if (this.container.styleProps['adjust-to-text']) {
                        this.currentPage.metaTool.deleteObjects([this.container.opts.id]);
                    } else {
                        this.myStoreObject.text.ps = [];
                    }
                }
            }
        },

        'currentPage.html2CanvasHack' () {
            if (this.currentPage.html2CanvasHack) {
                this.html2CanvasHackOn();
            } else {
                this.html2CanvasHackOff();
            }
        },

        '$store.state.presentation.theme.palette': {
            deep: true,
            handler () {
                this.createShadowStore();
                this.renderContent();
            }
        },

        'flags.screenshotGeneratorMode' (val) {
            this.$nextTick(function () {
                if (val) {
                    this.getTextGeometry();
                } else {
                    this.svgData = undefined;
                }
            });
        }
    },

    computed: {

        flags () {
            return this.$store.state.flags;
        },

        container () {
            return this.$parent;
        },

        adjustToText () {
            if (this.container.styleProps?.['adjust-to-text'] ||
                this.opts.adjustToText) {
                return true;
            }
            return false;
        },

        // SEEMS OKAY
        textProps () {
            try {
                let pStyle;
                this.refresh;
                let spanStyle = {};
                const selection = this.getSelectionParams();
                const pIndex = selection?.pIndexes?.start;
                const spanIndex = selection?.spanIndexes?.start;

                if ((pIndex != null) && (pIndex >= 0)) {
                    spanStyle = this.shadowStore.ps[pIndex].spans[spanIndex].style;
                    pStyle = this.shadowStore.ps[pIndex].style;
                } else {
                    spanStyle = this.shadowStore.ps[0].spans[0].style;
                    pStyle = this.shadowStore.ps[0].style;
                }
                const textProps = _.extend({}, spanStyle, pStyle, this.shadowStore.style);

                if ((textProps['color'] == null)) {
                    textProps['color'] = this.defaults['color'];
                }

                if ((textProps['background-color'] == null)) {
                    textProps['background-color'] = this.defaults['color'];
                }

                // Под таким именем этот параметр обрабатывается в панельке
                textProps['text-background'] = textProps['background-color'];

                if ((textProps['font-size'] == null)) {
                    textProps['font-size'] = this.defaults['font-size'];
                }

                if ((textProps['line-height'] == null)) {
                    textProps['line-height'] = this.defaults['line-height'];
                }

                // List props

                let startP = selection?.pIndexes?.start;
                let endP = selection?.pIndexes?.end;

                if ((startP == null) || (startP === -1)) {
                    startP = 0;
                }
                if ((endP == null) || (endP === -1)) {
                    if (endP == null) {
                        endP = this.shadowStore.ps.length - 1;
                    }
                }

                let resLt = undefined;
                const resLl = [];
                let sameLt = true;

                for (let i = startP, end = endP; i <= end; i++) {

                    const theP = this.shadowStore.ps[i];

                    if ((theP.spans.length === 1) &&
                        (theP.spans.content === '')) {
                        continue;
                    }

                    const lt = this.shadowStore.ps[i].style['list-type'];
                    const ll = this.shadowStore.ps[i].style['list-level'];

                    if (resLt == null) {
                        resLt = lt;
                    }
                    if (resLl[0] == null) {
                        resLl[0] = ll;
                    }
                    if (resLl[1] == null) {
                        resLl[1] = ll;
                    }

                    if (sameLt && (resLt !== lt)) {
                        resLt = 'mixed';
                        sameLt = false;
                    }

                    if (ll < resLl[0]) {
                        resLl[0] = ll;
                    }

                    if (ll > resLl[1]) {
                        resLl[1] = ll;
                    }
                }

                textProps['list-type'] = resLt;
                textProps['list-level'] = resLl;
                if (this.container.changeAdjustToTextFlag != null) {
                    textProps['auto-height'] = this.adjustToText;
                }


                return textProps;
            } catch (e) {
                // console.log e
                this.$store.state.sentry?.captureException(e);
                this.renderContent();
                return;
            }
        },


        textStyleProps () {
            const res = {};
            if (this.opts.style['color']) {
                res['color'] = 'rgba(' + this.getValidColor(this.opts.style['color']) + ')';
            }
            if (this.opts.style['font-size']) {
                res['font-size'] = this.opts.style['font-size'] + 'px';
            }
            if (this.opts.style['text-align']) {
                res['text-align'] = this.opts.style['text-align'];
            }
            res['word-wrap'] = 'break-word';
            return res;
        },

        wrapperStyleProps () {
            const jc = {
                'left': 'flex-start',
                'right': 'flex-end',
                'center': 'center'
            };

            const ai = {
                'top': 'flex-start',
                'bottom': 'flex-end',
                'middle': 'center'
            };

            let paddings = '';
            this.opts.style['paddings'].map(function (p) {
                paddings += p + 'px ';
            });

            return {
                'justify-content': jc[this.opts.style['text-align']],
                'align-items': ai[this.opts.style['vertical-align']],
                'box-sizing': 'border-box',
                'padding': paddings
            };
        },

        contentString () {
            let that = this;
            let res = '';

            let {
                ps
            } = this.shadowStore;
            if (this.currentPage.isRenderer) {
                ({
                    ps
                } = this.opts);
            }

            let olIndexes = [];

            _.forEach(ps, (p, pIndex) => {

                let styles;
                const lt = p.style['list-type'];
                const lst = p.style['list-style-type'];
                const ll = p.style['list-level'];

                let pString = '<div ';

                if (lt !== 'none') {
                    pString += 'data-list-index="';

                    olIndexes = olIndexes.map(function (el, i) {
                        if (i <= ll) {
                            return el;
                        }
                        return 0;
                    });
                    if (olIndexes[ll] == null) {
                        olIndexes[ll] = 0;
                    }
                    if (lt === 'ul') {
                        pString += lst;
                    }
                    if (lt === 'ol') {
                        pString += this.generateOlNumber(lst, olIndexes[ll]);
                    }
                    olIndexes[ll]++;

                    pString += '"';
                } else {
                    olIndexes = [];
                }

                pString += ' data-index = "';
                pString += pIndex;

                pString += '" style = "';

                if (lt !== 'none') {
                    const ml = ll * p.spans[0].style['font-size'];
                    pString += 'margin-left: ' + ml + 'px;';
                }

                if (this.currentPage.isRenderer) {
                    styles = this.props2styles(p.spans[0].style);
                    pString += this.applyStylesToPs(styles);
                }

                // console.log pString
                pString += 'line-height:';
                pString += p.style['line-height'];
                pString += 'px;';

                pString += '" class = "';
                pString += 'p';
                if (lt !== 'none') {
                    pString += ' li';
                }
                pString += '">';

                _.forEach(p.spans, function (span, spanIndex) {
                    let spanString = '<span data-index = "' + spanIndex + '" style = "';
                    styles = that.props2styles(span.style);
                    _.forEach(styles, function (style, key) {
                        if (key !== 'line-height') {
                            const str = '' + key + ': ' + style + ';';
                            spanString += str;
                        }
                    });
                    spanString += '">';

                    // Добавляем пробел нулевой длины при отсутствии контента
                    // для нормальной работы текста
                    if (span.content === '') {
                        spanString += '\u200b';
                    }

                    // Clearing text content
                    // if document
                    //   d = document.createElement("div")
                    //   d.innerText = d.textContent = span.content
                    //   clearedContent = d.innerHTML
                    // else
                    //   clearedContent = span.content
                    //   clearedContent = span.content.replace(/<script>+/g, '&lt;script&gt;')
                    //   clearedContent = clearedContent.replace(/<\/script>+/g, '&lt;script&gt;')

                    spanString += span.content;
                    spanString += '</span>';
                    pString += spanString;
                });

                pString += '</div>';
                res += pString;
            });

            that = this;
            Vue.nextTick(
                function () {
                    this.refresh = !this.refresh;

                },
                this
            );

            return res;
        },

        fontsUsed () {
            if (!this.svgData) {
                return [];
            } else {
                return _.uniq(this.svgData.map(i => i.style['font-family']));
            }
        }
    },

    methods: {
        createShadowStore () {
            this.shadowStore = _.cloneDeep(this.opts);
        },

        props2styles (props) {
            const styles = {};
            _.each(props, (val, key) => {
                let newVal = val;
                switch(key) {
                    case 'font-size':
                        newVal = val + 'px';
                        break;
                    case 'line-height':
                        newVal = val + 'px';
                        break;
                    case 'letter-spacing':
                        newVal = val + 'px';
                        break;
                    case 'color':
                        newVal = 'rgba(' + this.getValidColor(val) + ')';
                        break;
                    case 'background-color':
                        newVal = 'rgba(' + this.getValidColor(val) + ')';
                        break;
                }

                styles[key] = newVal;
            });
            return styles;
        },

        mouseenter () {
            this.currentPage.overText = true;
        },

        mouseleave () {
            this.currentPage.overText = false;
        },

        mousedown (e) {
            this.currentPage.blockMetatoolHandlers = true;

            if (this.currentPage.menuActive) {
                this.currentPage.menuActive = false;
            }

            this.$emit('textmousedown', e);
            this.mousepressed = true;
        },

        mouseup (e) {
            console.log('Text->textmouseup', e);
            this.currentPage.blockMetatoolHandlers = false;
            this.$emit('textmouseup', e);
            this.mousepressed = false;
            this.refresh = !this.refresh;
        },

        keyup () {
            this.refresh = !this.refresh;
        },

        keydown (e) {
            let isCollapsed;
            this.currentPage.logUserAction('text', 'keydown ' + e.key);

            const esc = e.keyCode === 27;
            const ctrlEnter = (e.keyCode === 13) && (e.ctrlKey || e.metaKey);
            if (esc || ctrlEnter) {
                this.forcedBlur();
                return;
            }

            // Обработка undo и redo
            const zCode = 90;
            const yCode = 89;
            if (this.currentPage.os === 'Mac') {
                if ((e.keyCode === zCode) && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        this.currentPage.metaTool.redo();
                    } else {
                        this.currentPage.metaTool.undo();
                    }
                    return;
                }
            } else {
                if ((e.keyCode === zCode) && e.ctrlKey) {
                    e.preventDefault();
                    this.currentPage.metaTool.undo();
                    return;
                }
                if ((e.keyCode === yCode) && e.ctrlKey) {
                    e.preventDefault();
                    this.currentPage.metaTool.redo();
                    return;
                }
            }

            const inTable = this.$parent.isTableCell;

            if (inTable) {
                const table = this.$parent.$parent;
                // Если this - это текст в ячейке таблицы
                // и выделено несколько ячеек
                if (table.selectedCells.length > 1) {
                    e.preventDefault();
                    return;
                }
            }

            let arrowKeys = ['ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight'];
            const magicKeys = ['Delete', 'Backspace', 'Enter', 'Tab'];
            const magicKeycodes = [66, 73, 85]; //b, i, u

            // Если нажата управляющая клавиша
            if (((e.key.length > 1) || e.ctrlKey || e.altKey || e.metaKey) &&
                !(magicKeys.includes(e.key)) &&
                !(arrowKeys.includes(e.key) && !(e.shiftKey)) &&
                !(magicKeycodes.includes(e.keyCode))) {

                return;
            }

            const selection = this.getSelectionParams();
            const selectionStart = selection.offsets.start;

            if (selection.flags.selectionIsCollapsed) {
                isCollapsed = true;
            } else {
                isCollapsed = false;
            }

            arrowKeys = ['ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight'];

            if (arrowKeys.includes(e.key)) {
                if (inTable) {
                    if (isCollapsed) {
                        switch(e.key) {
                            case arrowKeys[0]:
                                if (selection.flags.atTextStart || this.isEmpty()) {
                                    e.preventDefault();
                                    this.$parent.emitNavigateEvent('up');
                                }
                                break;
                            case arrowKeys[1]:
                                if (selection.flags.atTextStart || this.isEmpty()) {
                                    e.preventDefault();
                                    this.$parent.emitNavigateEvent('left');
                                }
                                break;
                            case arrowKeys[2]:
                                if (selection.flags.atTextEnd || this.isEmpty()) {
                                    e.preventDefault();
                                    this.$parent.emitNavigateEvent('down');
                                }
                                break;
                            case arrowKeys[3]:
                                if (selection.flags.atTextEnd || this.isEmpty()) {
                                    e.preventDefault();
                                    this.$parent.emitNavigateEvent('right');
                                }
                                break;
                        }
                    }
                }
                return;
            }

            e.preventDefault();

            switch(e.key) {
                case 'Delete':
                    if (e.repeat) {
                        this.deleteCharThrottled('forward');
                    } else {
                        this.deleteChar('forward');
                    }
                    break;
                case 'Backspace':
                    if (e.repeat) {
                        this.deleteCharThrottled('backward');
                    } else {
                        this.deleteChar('backward');
                    }
                    break;
                case 'Enter':
                    this.deleteSelected();
                    this.insertNewline();
                    break;
                case 'Tab':
                    if (!inTable) {
                        let howto;
                        if (e.shiftKey) {
                            howto = 'decrease';
                        } else {
                            howto = 'increase';
                        }
                        this.setProperty('list-level', howto);
                    } else {
                        if (e.shiftKey) {
                            this.$parent.emitNavigateEvent('prev');
                        } else {
                            this.$parent.emitNavigateEvent('next');
                        }
                    }
                    break;
                default:
                    if (e.metaKey || e.ctrlKey) {
                        switch(e.keyCode) {
                            case 66: //b
                                var propKey = 'font-weight';
                                var vs = ['bold', 'normal'];
                                var pk = this.textProps[propKey];
                                var propValue = pk === vs[0] ? vs[1] : vs[0];
                                this.setProperty(propKey, propValue);
                                return;
                                break;
                            case 73: //i
                                propKey = 'font-style';
                                vs = ['italic', 'normal'];
                                pk = this.textProps[propKey];
                                propValue = pk === vs[0] ? vs[1] : vs[0];
                                this.setProperty(propKey, propValue);
                                return;
                                break;
                            case 85: //u
                                propKey = 'text-decoration';
                                vs = ['underline', 'none'];
                                pk = this.textProps[propKey];
                                propValue = pk === vs[0] ? vs[1] : vs[0];
                                this.setProperty(propKey, propValue);
                                return;
                                break;
                        }
                    }
                    this.deleteSelected();
                    this.insertText(e.key);
            }

            return false;
        },

        focus () {
            // console.log "focused #{@_uid}"
            this.$emit('textfocus', this);

            // Запоминаем начальное состояние хранилища,
            // чтобы понимать, сохранять при потере фокуса историю
            // или нет
            this.startLocalHistory();

            this.refresh = !this.refresh;
        },

        focusout () {
            // console.log "unfocused #{@_uid}"
            this.$emit('textfocusout', this);
            this.currentPage.blockMetatool = false;

            // Сохраняем историю, если были изменения
            this.endLocalHistory();
        },

        cut (e) {
            const selection = this.getSelectionParams();
            if (selection.flags.allSelected != null) {
                e.preventDefault();
                document.execCommand('copy', false);
                this.deleteSelected();
            }
        },

        paste (e) {
            // console.log e
            this.currentPage.logUserAction(
                'text',
                'paste: ' + e.clipboardData.getData("text/plain")
            );
            const txt = e.clipboardData.getData("text/plain");

            this.deleteSelected();
            this.insertText(txt);

            Vue.nextTick(
                function () {
                    Vue.nextTick(
                        function () {
                            this.$store.commit('saveHistory', 'pasteInText');
                        },
                        this
                    );
                },
                this
            );
        },

        isTextNode (node) {
            if (node.nodeType === Node.TEXT_NODE) {
                return true;
            }
            return false;
        },

        getSelectionParams () {

            let range, startContainer, startOffset;
            if (!this.rememberedSelectionOffsets) {
                const selection = window.getSelection();

                if (selection.type === 'None') {
                    return false;
                }
                if (!selection.containsNode(this.$el, true)) {
                    return false;
                }

                range = selection.getRangeAt(0);

                if (range.startContainer.classList?.contains('text')) {
                    // Если оказались  начале текста,
                    // нужно передвинуть каретку внутрь,
                    // до текстовой ноды
                    const textDiv = range.startContainer;
                    const p = textDiv.childNodes[0];
                    const span = p.childNodes[0];
                    const textNode = span.childNodes[0];
                    range.setStart(textNode, 0);
                }

                // Двигаем каретку на один символ,
                // если она попала между двумя p
                if (!this.isTextNode(range.endContainer)) {
                    if (range.collapsed) {
                        selection.modify("move", "backward", "character");
                    } else {
                        // Костыль для тройного клика по строке
                        // иначе курсор ставится между p-шек
                        // и после этого сбрасывается в начало строки

                        // Дополнительный хак для разных браузеров
                        // modify("extend", "backward", "character") работает по-разному
                        // Сначала схлопываем выделение в конец,
                        // потом смещаем курсор назад (как в ветке выше),
                        // и восстанавливаем начальное выделение
                        const oldRange = selection.getRangeAt(0);
                        ({
                            startContainer
                        } = oldRange);
                        ({
                            startOffset
                        } = oldRange);
                        selection.modify("move", "forward", "character");
                        selection.modify("move", "backward", "character");
                        const curRange = selection.getRangeAt(0);
                        curRange.setStart(startContainer, startOffset);
                    }
                    range = selection.getRangeAt(0);
                }
                if (!this.isTextNode(range.startContainer)) {
                    selection.modify("move", "forward", "character");
                    range = selection.getRangeAt(0);
                }
            } else {
                // console.log _.clone(@rememberedSelectionOffsets)
                range = this.getNewSelectionRange(_.clone(this.rememberedSelectionOffsets));
            }

            const ps = $(this.$el).find('.text').children();

            const startSpanEl = range.startContainer.parentElement;
            const endSpanEl = range.endContainer.parentElement;
            const startPEl = startSpanEl.parentElement;
            const endPEl = endSpanEl.parentElement;

            const startPIndex = ps.index(startPEl);
            const endPIndex = ps.index(endPEl);

            const startPSpans = ps.eq(startPIndex).children();
            const endPSpans = ps.eq(endPIndex).children();

            const startSpanIndex = startPSpans.index(startSpanEl);
            const endSpanIndex = endPSpans.index(endSpanEl);

            // Смещения в символах от начала всего блока с текстом
            const offsets = {
                start: 0,
                end: 0,
                total: 0
            };

            const struct = [];

            ps.each(function (pIndex, p) {

                const spans = $(p).children();
                struct.push(spans.length);

                spans.each(function (spanIndex, span) {

                    offsets.total += span.firstChild.length;

                    if ((pIndex < startPIndex) ||
                        ((pIndex === startPIndex) &&
                            (spanIndex < startSpanIndex))) {
                        offsets.start += span.firstChild.length;
                    } else if ((pIndex === startPIndex) &&
                        (spanIndex === startSpanIndex)) {
                        offsets.start += range.startOffset;
                    }

                    if ((pIndex < endPIndex) ||
                        ((pIndex === endPIndex) &&
                            (spanIndex < endSpanIndex))) {
                        offsets.end += span.firstChild.length;
                    } else if ((pIndex === endPIndex) &&
                        (spanIndex === endSpanIndex)) {
                        offsets.end += range.endOffset;
                    }

                });

            });

            const res = {
                // Смещения начала и конца в выделения
                // в символах от начала текста
                offsets,
                // Индексы р-шек
                pIndexes: {
                    start: startPIndex,
                    end: endPIndex
                },
                // Индексы спанов
                spanIndexes: {
                    start: startSpanIndex,
                    end: endSpanIndex
                },
                // Элементы р-шек
                pNodes: {
                    start: ps[startPIndex],
                    end: ps[endPIndex]
                },
                // Элементы спанов
                spanNodes: {
                    start: startPSpans[startSpanIndex],
                    end: endPSpans[endSpanIndex]
                },
                // Ноды из области выделения
                domNodes: {
                    start: range.startContainer,
                    end: range.endContainer
                },
                // Смещения внутри нод из области выделения
                nodeOffsets: {
                    start: range.startOffset,
                    end: range.endOffset
                },
                struct
            };

            const selectionNodesAreEqual = function () {
                if ((res.pIndexes.start === res.pIndexes.end) &&
                    (res.spanIndexes.start === res.spanIndexes.end)) {
                    return true;
                } else {
                    return false;
                }
            };

            const selectionNodeOffsetsAreEqual = function () {
                if (res.nodeOffsets.start === res.nodeOffsets.end) {
                    return true;
                } else {
                    return false;
                }
            };

            const selectionIsCollapsed = function () {
                if (selectionNodesAreEqual() &&
                    selectionNodeOffsetsAreEqual()) {
                    return true;
                } else {
                    return false;
                }
            };

            const atTheEndOfStartNode = function () {
                if (res.nodeOffsets.start === res.domNodes.start.length) {
                    return true;
                } else {
                    return false;
                }
            };

            const atTheStartOfStartNode = function () {
                if (res.nodeOffsets.start === 0) {
                    return true;
                    // if @atTheEndOfStartNode(selection)
                    //   return 'shiftStartNode'
                } else {
                    return false;
                }
            };

            const atTextStart = function () {
                if (res.offsets.start === 0) {
                    return true;
                } else {
                    return false;
                }
            };

            const atTheEndOfEndNode = function () {
                if (res.nodeOffsets.end === res.domNodes.end.length) {
                    return true;
                } else {
                    return false;
                }
            };

            const atTextEnd = function () {
                if (res.offsets.end === res.offsets.total) {
                    return true;
                } else {
                    return false;
                }
            };

            const atPStart = function () {
                if ((startSpanIndex === 0) && atTheStartOfStartNode()) {
                    return true;
                }
                return false;
            };
            const atPEnd = function () {
                if ((endSpanIndex === (endPSpans.length - 1)) && atTheEndOfEndNode()) {
                    return true;
                }
                return false;
            };

            const allSelected = function () {
                if (atTextStart() && atTextEnd()) {
                    return true;
                } else {
                    return false;
                }
            };

            res.flags = {
                selectionNodesAreEqual: selectionNodesAreEqual(),
                selectionNodeOffsetsAreEqual: selectionNodeOffsetsAreEqual(),
                selectionIsCollapsed: selectionIsCollapsed(),
                atTheEndOfStartNode: atTheEndOfStartNode(),
                atTheStartOfStartNode: atTheStartOfStartNode(),
                atTheEndOfEndNode: atTheEndOfEndNode(),
                atPStart: atPStart(),
                atPEnd: atPEnd(),
                atTextStart: atTextStart(),
                atTextEnd: atTextEnd(),
                allSelected: allSelected()
            };

            return res;
        },

        renderContent () {
            this.inputPromise = new Promise(resolve => resolve('initial'));
            $(this.$el.firstChild).html(this.contentString);
            this.applyStylesToPs();

            // Предположительно, лишний кусок
            // @$nextTick(()=>
            //   if @currentPage.editingText == this
            //     @container.addTextEntity()
            //   return
            // )
        },

        applyStylesToPs (styles) {

            const properties = this.pDomProps;

            // Если метод вызван из редактора в браузере
            if ((styles == null)) {
                const domPs = $(this.$el).find('.p');
                domPs.each(function () {
                    const targetSpan = $(this).find('span').eq(0);
                    properties.forEach(prop => {
                        $(this).css(prop, targetSpan.css(prop));
                    });
                });
                // Если метод вызван при рендеринге строки на сервере
            } else {
                let res = '';
                properties.forEach(function (prop) {
                    res += prop + ': ' + styles[prop] + ';';
                });
                return res;
            }
        },

        setProperty (property, value) {
            // Массив нужен для случая параллельного изменения нескольких свойств

            let pEnd, pStart;
            let data = [{}];

            data[0].property = property;
            data[0].storeValue = value;
            data[0].cssValue = value;

            switch(property) {

                // Если данные пришли из очереди,
                // в поле value уже всё подготовлено для применения
                case 'queue':
                    data = value;
                    break;

                case 'mouseUp':
                    this.uniteSpans();
                    this.$nextTick(function () {
                        this.saveElToShadowStore();
                        this.refresh = !this.refresh;
                    });
                    return;
                    break;

                case 'auto-height':
                    this.container.changeAdjustToTextFlag?.(value);
                    return;
                    break;

                case 'paddings':
                    var selectionOffsets = this.getSelectionOffsets();
                    this.container.myStoreObject.text.style['paddings'] = _.clone(value);
                    // @shadowStore.style['paddings'] = _.clone(value)
                    this.refresh = !this.refresh;
                    if (selectionOffsets != null) {
                        Vue.nextTick(
                            function () {
                                this.setSelectionOffsets(selectionOffsets);
                            },
                            this
                        );
                    }
                    return;
                    break;

                case 'color':
                    data[0].cssValue = 'rgba(' + this.getValidColor(value) + ')';
                    break;

                case 'letter-spacing':
                    data[0].cssValue += 'px';
                    break;

                case 'font-size':
                    // Параллельно меняем 'line-height'
                    if (!this.fzlhRatio) {
                        this.fzlhRatio = this.textProps['font-size'] / this.textProps['line-height'];
                    }
                    if (value < 1) {
                        value = 1;
                        data[0].storeValue = value;
                    }
                    data[0].cssValue = value + 'px';
                    data[1] = {};
                    data[1].property = 'line-height';
                    data[1].storeValue = value / this.fzlhRatio;
                    if (data[1].storeValue < 1) {
                        data[1].storeValue = 1;
                    }
                    data[1].cssValue = data[1].storeValue + 'px';
                    data[1].modifyP = true;
                    break;

                // Очищаем соотношение fz&lh
                case 'line-height':
                    delete this.fzlhRatio;
                    if (value < 1) {
                        value = 1;
                        data[0].storeValue = value;
                    }
                    data[0].cssValue = value + 'px';
                    data[0].modifyP = true;
                    break;

                case 'vertical-align':
                case 'text-align':
                    selectionOffsets = this.getSelectionOffsets();
                    this.container.myStoreObject.text.style[property] = value;
                    this.refresh = !this.refresh;
                    if (selectionOffsets != null) {
                        Vue.nextTick(
                            function () {
                                this.setSelectionOffsets(selectionOffsets);
                            },
                            this
                        );
                    }
                    return;
                    break;

                case 'text-background':
                    data[0].property = 'background-color';
                    data[0].storeValue = value;
                    data[0].cssValue = 'rgba(' + this.getValidColor(value) + ')';
                    break;

                case 'list-level':
                    var selection = this.getSelectionParams();
                    if (selection.pIndexes != null) {
                        pStart = selection.pIndexes.start;
                        pEnd = selection.pIndexes.end;
                    } else {
                        pStart = 0;
                        pEnd = this.shadowStore.ps.length - 1;
                    }
                    var offsets = this.getSelectionOffsets();

                    var {
                        ps
                    } = this.shadowStore;
                    ps.forEach((p, pIndex) => {

                        let newLlv;
                        if ((pIndex < pStart) || (pIndex > pEnd)) {
                            return;
                        }

                        const ll = 'list-level';
                        const lt = 'list-type';
                        const lst = 'list-style-type';

                        const llv = this.shadowStore.ps[pIndex].style[ll];
                        if (value === 'increase') {
                            newLlv = llv + 1;
                        }
                        if (value === 'decrease') {
                            newLlv = Math.max(llv - 1, 0);
                        }

                        this.shadowStore.ps[pIndex].style[ll] = newLlv;
                        this.opts.ps[pIndex].style[ll] = newLlv;

                        const prevP = this.shadowStore.ps[pIndex - 1];
                        const nextP = this.shadowStore.ps[pIndex + 1];
                        let styleCopied = false;

                        if (prevP != null) {
                            if (prevP.style[ll] === newLlv) {
                                this.shadowStore.ps[pIndex].style[lt] = prevP.style[lt];
                                this.shadowStore.ps[pIndex].style[lst] = prevP.style[lst];
                                this.opts.ps[pIndex].style[lt] = prevP.style[lt];
                                this.opts.ps[pIndex].style[lst] = prevP.style[lst];
                                styleCopied = true;
                            }
                        }
                        if (!styleCopied && (nextP != null)) {
                            if (nextP.style[ll] === newLlv) {
                                this.shadowStore.ps[pIndex].style[lt] = nextP.style[lt];
                                this.shadowStore.ps[pIndex].style[lst] = nextP.style[lst];
                                this.opts.ps[pIndex].style[lt] = nextP.style[lt];
                                this.opts.ps[pIndex].style[lst] = nextP.style[lst];
                            }
                        }


                    });

                    this.renderContent();
                    Vue.nextTick(
                        function () {
                            this.setSelectionOffsets(offsets);
                        },
                        this
                    );
                    return;
                    break;

                case 'list':
                    selection = this.getSelectionParams();
                    if (selection.pIndexes != null) {
                        pStart = selection.pIndexes.start;
                        pEnd = selection.pIndexes.end;
                    } else {
                        pStart = 0;
                        pEnd = this.shadowStore.ps.length - 1;
                    }
                    offsets = this.getSelectionOffsets();

                    var lt = 'list-type';
                    var lst = 'list-style-type';

                    var targetIndexes = [];

                    // Функция прохода по массиву p-шек за пределы выделения
                    // и добавления дополнительных р-шек для изменения
                    // (для изменения нужного уровня целиком)
                    var walker = (pIndex, pLlPointer, pIndexBound, comparator, iterator) => {
                        let canGoFurther = true;

                        const minLl = Math.max(this.textProps['list-level'][0], 1);
                        const maxLl = this.textProps['list-level'][1];

                        while (canGoFurther) {

                            if (comparator(pIndex, pIndexBound)) {
                                canGoFurther = false;
                                continue;
                            }

                            const theP = this.shadowStore.ps[pIndex];
                            const theLl = theP.style['list-level'];

                            if (theLl < minLl) {
                                canGoFurther = false;
                                pIndex = iterator(pIndex);
                                continue;
                            }

                            if (theLl > pLlPointer) {
                                pIndex = iterator(pIndex);
                                continue;

                            } else {
                                pLlPointer = theLl;
                                targetIndexes.push(pIndex);
                            }

                            if (pIndex === pIndexBound) {
                                canGoFurther = false;
                                pIndex = iterator(pIndex);
                                continue;
                            }

                            pIndex = iterator(pIndex);
                        }
                    };

                    if (value[lt] !== 'none') {
                        // Идем вверх
                        walker(
                            pStart,
                            this.shadowStore.ps[pStart].style['list-level'],
                            0,
                            function (a, b) {
                                if (a < b) {
                                    return true;
                                }
                                return false;
                            },
                            i => i - 1);

                        // Идем вниз
                        walker(
                            pEnd,
                            this.shadowStore.ps[pEnd].style['list-level'],
                            this.shadowStore.ps.length - 1,
                            function (a, b) {
                                if (a > b) {
                                    return true;
                                }
                                return false;
                            },
                            i => i + 1);
                    }

                    // Добавляем выделенные
                    for (let i = pStart, end = pEnd; i <= end; i++) {
                        targetIndexes.push(i);
                    }

                    ({
                        ps
                    } = this.shadowStore);
                    ps.forEach((p, pIndex) => {
                        if (targetIndexes.includes(pIndex)) {
                            this.shadowStore.ps[pIndex].style[lt] = value[lt];
                            this.shadowStore.ps[pIndex].style[lst] = value[lst];
                            this.opts.ps[pIndex].style[lt] = value[lt];
                            this.opts.ps[pIndex].style[lst] = value[lst];
                        }
                    });

                    this.renderContent();
                    Vue.nextTick(
                        function () {
                            this.setSelectionOffsets(offsets);
                        },
                        this
                    );
                    return;
                    break;
            }

            const actual = this.canApplyPropertyToCurrentStructure();

            // Делаем только если данные пришли не из очереди
            // В данных очереди это действие уже производилось
            if (property !== 'queue') {

                if (this.block || !actual) {
                    this.setPropertyQueue.push(data);
                    if (!actual) {
                        this.changeStructure();
                    }
                    return;
                }
            }

            this.setPropertyToSelection(data);

            this.refresh = !this.refresh;
            if (window.getSelection().type !== 'None') {
                this.$el.firstChild.firstChild.focus();
            }
            this.flushSetPropertyQueue();

            this.$nextTick(function () {
                this.emitOverflowEvent('setproperty');
            });

        },

        flushSetPropertyQueue () {
            if (this.setPropertyQueue.length > 0) {
                const data = this.setPropertyQueue.shift();
                this.setProperty('queue', data);
            } else {
                this.refresh = !this.refresh;
                return;
            }

        },

        setPropertyToSelection (data) {
            let pIndexes, spanIndexes;
            this.currentPage.logUserAction(
                'text',
                'setPropertyToSelection ' + JSON.stringify(this.getSelectionOffsets())
            );

            const selection = this.getSelectionParams();
            if (!selection || selection.flags.selectionIsCollapsed) {
                pIndexes = {
                    start: 0,
                    end: this.shadowStore.ps.length - 1
                };
                spanIndexes = {
                    start: 0,
                    end: this.shadowStore.ps.last().spans.length - 1
                };
            } else {
                ({
                    pIndexes
                } = selection);
                ({
                    spanIndexes
                } = selection);
            }

            const domPs = $(this.$el.firstChild).children();

            for (var i = pIndexes.start, {
                end
            } = pIndexes; i <= end; i++) {

                var from, to;
                if (i === pIndexes.start) {
                    from = spanIndexes.start;
                } else {
                    from = 0;
                }

                if (i === pIndexes.end) {
                    to = spanIndexes.end;
                } else {
                    to = this.shadowStore.ps[i].spans.length - 1;
                }

                var domSpans = domPs.eq(i).children();

                for (var j = from, end1 = to; j <= end1; j++) {

                    data.forEach(dataItem => {

                        if (dataItem.modifyP) {

                            this.setPropertyToShadowStoreElem(
                                dataItem.property,
                                dataItem.storeValue,
                                i,
                                undefined
                            );

                            this.setPropertyToDomElem(
                                dataItem.property,
                                dataItem.cssValue,
                                domPs.eq(i)
                            );

                        } else {

                            this.setPropertyToShadowStoreElem(
                                dataItem.property,
                                dataItem.storeValue,
                                i,
                                j
                            );

                            this.setPropertyToDomElem(
                                dataItem.property,
                                dataItem.cssValue,
                                domSpans.eq(j)
                            );
                        }

                    });
                }
            }

            this.applyStylesToPs();

        },

        setPropertyToShadowStoreElem (property, value, pIx, sIx) {
            if (property === 'queue') {
                return;
            }
            if (sIx != null) {
                this.shadowStore.ps[pIx].spans[sIx].style[property] = value;
                this.opts.ps[pIx].spans[sIx].style[property] = value;
            } else {
                this.shadowStore.ps[pIx].style[property] = value;
                this.opts.ps[pIx].style[property] = value;
            }
        },

        setPropertyToDomElem (property, value, jQel) {
            if (property === 'queue') {
                return;
            }
            jQel.css(property, value);
        },

        refocus () {
            const that = this;
            setTimeout(
                function () {
                    if (window.getSelection().type !== 'None') {
                        that.$el.firstChild.focus();
                    }
                },
                300
            );
        },

        forcedBlur () {
            if (this.blurWasForced) {
                return;
            }
            this.blurWasForced = true;
            this.endLocalHistorySync();
            this.$el.firstChild.blur();
        },

        // Метод меняет структуру текста
        changeStructure () {
            const that = this;

            this.block = true;

            const {
                ps
            } = this.shadowStore;
            const selection = this.getSelectionOffsets();

            if ((selection == null)) {
                throw new Error('Trying to change structure of text with no selection');
            }

            const selectionSize = selection.end - selection.start;
            const res = [];
            let offset = 0;

            ps.forEach((p, pIndex) => {

                const pCopy = {
                    spans: [],
                    style: _.cloneDeep(p.style)
                };

                // Заполняем новую строку спанами
                p.spans.forEach((span, spanIndex) => {

                    // Значение смещения на конце элемента
                    let divided;
                    const offsetPlusElemSize = offset + span.content.length;

                    // Флаги попадания точек начала и конца
                    // выделения внутрь спана
                    const startInside = (selection.start >= offset) &&
                        (selection.start < offsetPlusElemSize);

                    const endInside = (selection.end > offset) &&
                        (selection.end <= offsetPlusElemSize);

                    // Обе точки внутри
                    if (startInside && endInside) {
                        divided = this.divideSpan(span, selection.start - offset);
                        if (divided[0] != null) {
                            pCopy.spans.push(divided[0]);
                        }

                        const divided2 = this.divideSpan(divided[1], selectionSize);
                        if (divided2[0] != null) {
                            pCopy.spans.push(divided2[0]);
                        }
                        if (divided2[1] != null) {
                            pCopy.spans.push(divided2[1]);
                        }

                        // Внутри начальная точка
                    } else if (startInside) {
                        divided = this.divideSpan(span, selection.start - offset);
                        if (divided[0] != null) {
                            pCopy.spans.push(divided[0]);
                        }
                        if (divided[1] != null) {
                            pCopy.spans.push(divided[1]);
                        }

                        // Внутри конечная точка
                    } else if (endInside) {
                        divided = this.divideSpan(span, selection.end - offset);
                        if (divided[0] != null) {
                            pCopy.spans.push(divided[0]);
                        }
                        if (divided[1] != null) {
                            pCopy.spans.push(divided[1]);
                        }

                        // Не сработало ни одно из условий
                    } else {
                        pCopy.spans.push(span);
                    }

                    // Увеличиваем смещение
                    offset = offsetPlusElemSize;

                });

                res.push(pCopy);
            });

            // Перезаписываем объект
            this.shadowStore.ps = res;
            this.opts.ps = res;

            // Находим спаны, которые можно объединить
            // Не надо делать тут, слетает выделение
            // @uniteSpans()

            this.renderContent();

            // Даем закончиться рендеру,
            // без таймаута не применяется выделение

            Vue.nextTick(
                function () {
                    that.setSelectionOffsets(selection);
                },
                this
            );
        },

        // Метод разбивает спан на два
        divideSpan (span, offset) {

            const newSpans = [];

            if (offset === 0) {
                newSpans[1] = _.cloneDeep(span);
                return newSpans;
            }

            if (offset === span.content.length) {
                newSpans[0] = _.cloneDeep(span);
                return newSpans;
            }

            newSpans[0] = _.cloneDeep(span);
            newSpans[1] = _.cloneDeep(span);
            newSpans[0].content = span.content.slice(0, offset);
            newSpans[1].content = span.content.slice(offset);

            return newSpans;
        },

        // Получает абсолютные смещения начала и конца выделения
        getSelectionOffsets () {
            return _.clone(this.getSelectionParams().offsets);
        },

        getNewSelectionRange (offsets, atPEnd) {

            if ((offsets == null)) {
                return;
            }

            const ps = $(this.$el).find('.text').children();
            const newRange = document.createRange();

            ps.each(function (pIndex, p) {

                const spans = $(p).children();

                spans.each(function (spanIndex, span) {

                    if (offsets.start >= span.firstChild.length) {

                        const lastSpanAndLastP = (spanIndex === (spans.length - 1)) &&
                            (pIndex === (ps.length - 1));

                        // Отдельное условие для последнего спана текста
                        // Без этого условия начало выделения не установится
                        // и оно начнется с document

                        // Также не нужно переходить на следующий p,
                        // если пришел флаг atPEnd
                        // (чтобы курсор не перескакивал на следующий p
                        // при удалении последнего символа строки)
                        if ((offsets.start === span.firstChild.length) &&
                            (lastSpanAndLastP || atPEnd)) {
                            newRange.setStart(span.firstChild, offsets.start);
                            offsets.start = undefined;
                        } else {
                            offsets.start -= span.firstChild.length;
                        }
                    } else if (offsets.start != null) {
                        newRange.setStart(span.firstChild, offsets.start);
                        offsets.start = undefined;
                    }

                    if (offsets.end > span.firstChild.length) {
                        offsets.end -= span.firstChild.length;
                    } else if (offsets.end != null) {
                        newRange.setEnd(span.firstChild, offsets.end);
                        offsets.end = undefined;
                    }
                });

            });

            // Проверка на случай,
            // если что-то пошло не так,
            // чтобы выделение не выходило
            // за пределы объекта
            if ((newRange.startContainer === document) ||
                (newRange.endContainer === document)) {
                console.log('Error:');
                console.log('One part of selection is document, something gone wrong');
                newRange.setStart(ps[0].firstChild.firstChild, 0);
                newRange.setEnd(ps[0].firstChild.firstChild, 0);
            }
            return newRange;
        },

        // Устанавливает выделение по двум точкам
        setSelectionOffsets (offsets, atPEnd) {

            const newRange = this.getNewSelectionRange(offsets, atPEnd);

            if (!this.currentPage.doNotRestoreSelectionFlag) {
                const selection = window.getSelection();
                selection.removeAllRanges();
                if (newRange) {
                    selection.addRange(newRange);
                }
            }

            const that = this;
            Vue.nextTick(
                function () {
                    that.block = false;
                    that.flushSetPropertyQueue();
                },
                this
            );

        },

        // Объединяет спаны (если возможно)
        uniteSpans (opts) {

            let doNotRender;
            if (opts != null) {
                ({
                    doNotRender
                } = opts);
            }

            const that = this;

            const selection = this.getSelectionOffsets();

            const ps = _.cloneDeep(this.shadowStore.ps);
            let wasChange = false;

            let loopLimiter = 0;

            var checkFunc = function (p) {
                let wasChangeThisLoop = false;
                p.spans.forEach(function (span, i) {
                    if ((i !== (p.spans.length - 1)) && !(span == null)) {
                        const self = p.spans[i];
                        const next = p.spans[i + 1];
                        if (_.isEqual(self.style, next?.style)) {
                            const forbiddenChars = that.forbiddenChars.includes(self.content) || that.forbiddenChars.includes(next.content);
                            const emptyContent = (self.content === '') || (next.content === '');
                            if (forbiddenChars && !emptyContent) {
                                return;
                            }
                            self.content += next.content;
                            delete p.spans[i + 1];
                            wasChangeThisLoop = true;
                            wasChange = true;
                        }
                    }
                });
                p.spans = _.compact(p.spans);

                if (wasChangeThisLoop) {
                    if (loopLimiter < 100) {
                        loopLimiter++;
                        checkFunc(p);
                    } else {
                        throw new Error('Loop limit reached, check code');
                    }
                }
            };

            ps.forEach(function (p, pIndex) {

                checkFunc(p);

            });

            if (wasChange) {
                this.shadowStore.ps = ps;
                this.opts.ps = ps;
                if (!doNotRender) {
                    this.renderContent();

                    // Даем закончиться рендеру,
                    // без таймаута не применяется выделение
                    this.$nextTick(function () {
                        this.setSelectionOffsets(selection);
                    });
                }
            }

            return wasChange;
        },

        // Сохраняем текст во временное хранилище
        saveElToShadowStore () {
            const {
                ps
            } = this.shadowStore;

            const domPs = $(this.$el).find('.p');
            const psBound = ps.length;
            let psCounter = 0;

            const psCopy = [];

            ps.forEach(function (p, pIndex) {

                const domSpans = domPs.filter('[data-index=' + pIndex + ']').find('span');
                const spansBound = p.spans.length;
                let spansCounter = 0;

                const spansCopy = [];

                p.spans.forEach(function (span, spanIndex) {

                    const domSpan = domSpans.eq(spansCounter);
                    const domSpanIndex = parseInt(domSpan.attr('data-index'));

                    if (domSpanIndex === spanIndex) {
                        const elem = _.cloneDeep(p.spans[spanIndex]);

                        // Проверка на пустой спан
                        // Изначально проверка была на innerText, но при text-transform: uppercase
                        // в innerText находится уже трансформированный текст, а в innerHTML нет.
                        // Поэтому проверка старая (чтобы не сломать ничего), а контент берем из другого места
                        if (domSpan[0].innerText !== '') {
                            elem.content = domSpan[0].innerHTML.replace(/\u200b/g, "");
                            if (elem.content !== '') {
                                spansCopy.push(elem);
                            }
                        }

                        spansCounter++;
                    }
                });

                if (spansCopy.length === 0) {
                    spansCopy.push(_.cloneDeep(p.spans[0]));
                    spansCopy.last().content = '';
                }

                psCopy.push({ style: p.style, spans: spansCopy });

                psCounter++;

            });

            // Сохраняем всё в хранилище
            this.shadowStore.ps = _.cloneDeep(psCopy);
            if ($(this.$el).find('.p[data-index=0] span[data-index=0]').length === 0) {
                this.renderContent();
                this.container.addTextEntity();
            } else {
                this.opts.ps = _.cloneDeep(this.shadowStore.ps);
                this.emitOverflowEvent('saveElToShadowStore');
            }

            this.saveLocalHistory();

        },

        deleteSelected () {
            this.inputPromise = this.inputPromise.then(
                () => {
                    return new Promise((resolve, reject) => {
                        const selection = this.getSelectionParams();

                        // Выходим, если нет курсора
                        if (!selection) {
                            resolve(true);
                            return;
                        }

                        // Выходим, если нет выделения
                        if (selection.offsets.start === selection.offsets.end) {
                            resolve(true);
                            return;

                            // Удаляем выделенное
                        } else {

                            let collapsedOffsets = {
                                start: selection.offsets.start,
                                end: selection.offsets.start
                            };

                            const newPs = [];

                            const {
                                ps
                            } = this.shadowStore;

                            ps.forEach(function (p, pIndex) {

                                // Если строка вне области выделения
                                let newP, newSpans;
                                if ((pIndex < selection.pIndexes.start) ||
                                    (pIndex > selection.pIndexes.end)) {
                                    newPs.push(p);
                                }

                                // Если строка в начале выделения
                                if (pIndex === selection.pIndexes.start) {

                                    newSpans = [];
                                    p.spans.forEach(function (span, spanIndex) {

                                        // Если спан до области выделения
                                        if (spanIndex < selection.spanIndexes.start) {
                                            newSpans.push(_.cloneDeep(span));
                                        }

                                        // Если спан в начале выделения
                                        if (spanIndex === selection.spanIndexes.start) {
                                            const newSpan = _.cloneDeep(span);
                                            newSpan.content = newSpan.content.slice(
                                                0,
                                                selection.nodeOffsets.start
                                            );
                                            if (newSpan.content !== '') {
                                                return newSpans.push(newSpan);
                                            }
                                        }
                                    });

                                    newP = _.cloneDeep(p);
                                    newP.spans = newSpans;
                                    newPs.push(newP);
                                }

                                // Если строка в конце выделения
                                if (pIndex === selection.pIndexes.end) {

                                    newSpans = [];
                                    p.spans.forEach(function (span, spanIndex) {

                                        // Если спан после области выделения
                                        if (spanIndex > selection.spanIndexes.end) {
                                            newSpans.push(_.cloneDeep(span));
                                        }

                                        // Если спан в конце выделения
                                        if (spanIndex === selection.spanIndexes.end) {
                                            const newSpan = _.cloneDeep(span);
                                            newSpan.content = newSpan.content.slice(
                                                selection.nodeOffsets.end
                                            );
                                            if (newSpan.content !== '') {
                                                return newSpans.push(newSpan);
                                            }
                                        }
                                    });

                                    if (newSpans.length > 0) {
                                        // Если ещё не было ни одной строки
                                        if (newPs.length === 0) {
                                            newP = _.cloneDeep(p);
                                            newP.spans = newSpans;
                                            newPs.push(newP);
                                        } else {
                                            newPs.last()?.spans.push(...newSpans);
                                        }
                                    }
                                }

                                // Если строка оказалась без спанов
                                if ((newPs.last() != null) && (newPs.last().spans.length === 0)) {
                                    const newSpan = _.cloneDeep(p.spans[0]);
                                    newSpan.content = '';
                                    return newPs.last()?.spans.push(newSpan);
                                }

                            });

                            // Если было удалено всё
                            if (newPs.length === 0) {
                                const newP = _.cloneDeep(ps[0]);
                                newP.spans = [newP.spans[0]];
                                // Добавляем пробел нулевой длины,
                                // чтобы можно было поставить курсор
                                newP.spans[0].content = '';
                                newPs.push(newP);
                            }

                            this.shadowStore.ps = newPs;

                            this.renderContent();

                            Vue.nextTick(
                                function () {
                                    this.saveElToShadowStore();

                                    // Сместить каретку на символ вправо для компенсации пробела нулевой длины
                                    if (this.isEmpty()) {
                                        collapsedOffsets = {
                                            start: 1,
                                            end: 1
                                        };
                                    }

                                    this.setSelectionOffsets(collapsedOffsets, selection.flags.atPEnd);
                                    Vue.nextTick(
                                        function () {
                                            resolve(true);
                                        },
                                        this
                                    );
                                },
                                this
                            );
                        }


                    });
                },
                () => {
                    console.log('deleteSelected error');
                    this.inputPromise = new Promise(resolve => resolve('initial'));
                });

        },

        gluePs (startPIndex) {

            if ((startPIndex == null) || (startPIndex < 0)) {
                return;
            }

            this.inputPromise = this.inputPromise.then(
                () => {
                    return new Promise((resolve, reject) => {

                        let atPEnd;
                        const selection = this.getSelectionParams();

                        // Выходим, если нет курсора
                        if (!selection || !selection.flags.selectionIsCollapsed) {
                            console.log('TextError: wrong or no selection');
                            resolve(true);
                            return;
                        }

                        // Запоминаем выделение
                        // для восстановления курсора
                        const selectionOffset = {
                            start: selection.offsets.start,
                            end: selection.offsets.end
                        };

                        if (this.shadowStore.ps[startPIndex].spans[0].content === '') {
                            selectionOffset.start -= 1;
                            selectionOffset.end -= 1;
                        }
                        if ((this.shadowStore.ps[startPIndex].spans[0].content !== '') &&
                            (this.shadowStore.ps[startPIndex + 1].spans[0].content === '')) {
                            atPEnd = true;
                        }

                        const newPs = [];

                        const {
                            ps
                        } = this.shadowStore;

                        ps.forEach(function (p, pIndex) {

                            if ((pIndex <= startPIndex) ||
                                (pIndex > (startPIndex + 1))) {
                                newPs.push(p);
                            } else {

                                const filteredSpans = p.spans.filter(span => span.content.length > 0);

                                if ((newPs.last().spans.length === 1) &&
                                    (newPs.last().spans[0].content === '')) {
                                    newPs.last().spans = [];
                                    newPs.last().spans.push(...p.spans);
                                } else {
                                    newPs.last().spans.push(...filteredSpans);
                                }
                            }

                        });

                        // Удаляем пустые спаны (если есть)
                        // По идее ненужная проверка, так как
                        // пустых спанов быть не должно
                        // Но на всякий случай оставлю
                        newPs.forEach(function (p, pIndex) {
                            p.spans.forEach(function (span, spanIndex) {
                                if ((spanIndex > 0) &&
                                    ((span.content === '') || (span.content === '\u200b'))) {
                                    // console.log 'Check text'
                                    p.spans[spanIndex] = undefined;
                                }
                            });
                            p.spans = _.compact(p.spans);
                        });

                        this.shadowStore.ps = newPs;

                        this.renderContent();

                        return Vue.nextTick(
                            function () {
                                this.saveElToShadowStore();
                                this.setSelectionOffsets(selectionOffset, atPEnd);
                                Vue.nextTick(
                                    function () {
                                        resolve(true);
                                    },
                                    this
                                );
                            },
                            this
                        );

                    });
                },
                () => {
                    console.log('gluePs error');
                    this.inputPromise = new Promise(resolve => resolve('initial'));
                });
        },

        // REFACTOR THIS
        deleteChar (direction) {
            this.inputPromise = this.inputPromise.then(
                () => {
                    return new Promise((resolve, reject) => {

                        let exclusion, glueIndex;
                        const selectionParams = this.getSelectionParams();
                        const {
                            offsets
                        } = selectionParams;
                        const {
                            flags
                        } = selectionParams;
                        const spanIndex = selectionParams.spanIndexes.start;
                        const pIndex = selectionParams.pIndexes.start;

                        const spanContent = this.shadowStore.ps[pIndex].spans[spanIndex].content;

                        const noTextContentCase = (spanIndex === 0) && (spanContent.length === 0);

                        if (flags.selectionIsCollapsed) {

                            if (direction === 'forward') {
                                if (flags.atTextEnd || this.isEmpty()) {
                                    resolve(true);
                                    return;
                                }
                                if (flags.atPEnd) {
                                    exclusion = true;
                                    glueIndex = selectionParams.pIndexes.start;
                                } else if (flags.atPStart &&
                                    (spanIndex === 0) &&
                                    (this.shadowStore.ps[pIndex].spans[spanIndex].content === '')) {
                                    exclusion = true;
                                    glueIndex = selectionParams.pIndexes.start;
                                    window.getSelection().modify("move", "forward", "character");
                                } else if (flags.atPStart &&
                                    (spanIndex === 0) &&
                                    (this.shadowStore.ps[pIndex].spans[spanIndex].content.length === 1)) {
                                    exclusion = true;
                                    window.getSelection().modify("extend", "forward", "character");
                                    document.execCommand('insertHTML', false, '\u200b');
                                } else {
                                    window.getSelection().modify(
                                        "extend",
                                        "forward",
                                        "character"
                                    );
                                }
                            }
                            // offsets.end = Math.min(offsets.total, offsets.end + 1)
                            // @setSelectionOffsets(offsets)

                            if (direction === 'backward') {
                                if (flags.atTextStart || this.isEmpty()) {
                                    resolve(true);
                                    return;
                                }
                                if (flags.atPStart) {
                                    exclusion = true;
                                    glueIndex = selectionParams.pIndexes.start - 1;
                                } else if (flags.atPEnd &&
                                    (spanIndex === 0) &&
                                    (this.shadowStore.ps[pIndex].spans[spanIndex].content === '')) {
                                    exclusion = true;
                                    glueIndex = selectionParams.pIndexes.start - 1;
                                    window.getSelection().modify(
                                        "move",
                                        "backward",
                                        "character"
                                    );
                                } else if (flags.atPEnd &&
                                    (spanIndex === 0) &&
                                    (this.shadowStore.ps[pIndex].spans[spanIndex].content.length === 1)) {
                                    exclusion = true;
                                    for (let i = 1, end = selectionParams.nodeOffsets.start; i <= end; i++) {
                                        window.getSelection().modify(
                                            "extend",
                                            "backward",
                                            "character"
                                        );
                                    }
                                    document.execCommand('insertHTML', false, '\u200b');
                                } else {
                                    window.getSelection().modify(
                                        "extend",
                                        "backward",
                                        "character"
                                    );
                                }
                            }
                        }
                        // offsets.start = Math.max(0, offsets.start - 1)
                        // @setSelectionOffsets(offsets)

                        return Vue.nextTick(
                            function () {
                                if (exclusion) {
                                    if (glueIndex != null) {
                                        this.gluePs(glueIndex);
                                    } else {
                                        this.saveElToShadowStore();
                                    }
                                } else {
                                    this.deleteSelected();
                                }
                                resolve(true);
                            },
                            this
                        );
                    });
                },
                () => {
                    console.log('deleteChar error');
                    this.inputPromise = new Promise(resolve => resolve('initial'));
                });
        },

        insertNewline () {
            this.inputPromise = this.inputPromise.then(
                () => {
                    return new Promise((resolve, reject) => {

                        const selection = this.getSelectionParams();

                        if (this.shadowStore.ps[selection.pIndexes.start].spans[0].content === '') {
                            const moveForward = true;
                        }

                        // Выходим, если нет курсора
                        if (!selection || !selection.flags.selectionIsCollapsed) {
                            console.log('TextError: wrong or no selection');
                            resolve(true);
                            return;
                        }

                        const startPIndex = selection.pIndexes.start;
                        const {
                            flags
                        } = selection;

                        // Хак, если курсор в начале р-шки
                        let adder = 0;
                        if (flags.selectionIsCollapsed &&
                            flags.atPStart) {
                            adder = 1;
                        }

                        // Запоминаем выделение
                        // для восстановления курсора
                        const selectionOffset = {
                            start: selection.offsets.start + adder,
                            end: selection.offsets.end + adder
                        };

                        const newPs = [];

                        const {
                            ps
                        } = this.shadowStore;

                        let newP1 = undefined;
                        let newP2 = undefined;

                        ps.forEach(function (p, pIndex) {
                            // Если строка вне курсора
                            if ((pIndex < selection.pIndexes.start) ||
                                (pIndex > selection.pIndexes.end)) {
                                newPs.push(p);
                                return;
                            }

                            // Если нужная строка
                            if (pIndex === selection.pIndexes.start) {

                                newP1 = _.cloneDeep(p);
                                newP2 = _.cloneDeep(p);
                                newP1.spans = [];
                                newP2.spans = [];

                                p.spans.forEach(function (span, spanIndex) {

                                    // Если спан до области выделения
                                    if (spanIndex < selection.spanIndexes.start) {
                                        newP1.spans.push(_.cloneDeep(span));
                                    }

                                    // Если спан после области выделения
                                    if (spanIndex > selection.spanIndexes.end) {
                                        newP2.spans.push(_.cloneDeep(span));
                                    }

                                    // Если нужно разбить
                                    if (spanIndex === selection.spanIndexes.start) {

                                        const newSpan1 = _.cloneDeep(span);
                                        const newSpan2 = _.cloneDeep(span);
                                        newSpan1.content = '';
                                        newSpan2.content = '';

                                        newSpan1.content = span.content.slice(
                                            0,
                                            selection.nodeOffsets.start
                                        );
                                        newSpan2.content = span.content.slice(
                                            selection.nodeOffsets.start
                                        );

                                        if (newSpan1.content !== '') {
                                            newP1.spans.push(newSpan1);
                                        }
                                        if (newSpan2.content !== '') {
                                            newP2.spans.push(newSpan2);
                                        }
                                    }

                                });

                                if (newP1.spans.length === 0) {
                                    const newP1Span = _.cloneDeep(p.spans[0]);
                                    newP1Span.content = '';
                                    newP1.spans.push(newP1Span);
                                }
                                if (newP2.spans.length === 0) {
                                    const newP2Span = _.cloneDeep(p.spans.last());
                                    newP2Span.content = '';
                                    newP2.spans.push(newP2Span);
                                }

                                return newPs.push(newP1, newP2);
                            }

                        });

                        this.shadowStore.ps = newPs;

                        this.renderContent();

                        return Vue.nextTick(
                            function () {
                                this.saveElToShadowStore();
                                this.setSelectionOffsets(selectionOffset);
                                Vue.nextTick(
                                    function () {
                                        if ((newP2.spans.length === 1) && (newP2.spans[0].content === '')) {
                                            window.getSelection().modify(
                                                "move",
                                                "forward",
                                                "character"
                                            );
                                        }
                                        resolve(true);
                                    },
                                    this
                                );
                            },
                            this
                        );
                    });
                },
                () => {
                    console.log('insertNewline error');
                    this.inputPromise = new Promise(resolve => resolve('initial'));
                });

        },

        insertText (text) {

            this.inputPromise = this.inputPromise.then(
                () => {
                    return new Promise((resolve, reject) => {

                        // Проверяем из скольки строк состоит текст
                        const newLineRegExp = /(?:\r\n|\r|\n)/g;
                        const newPsContent = text.split(newLineRegExp);

                        const newContent = newPsContent.map(newPContent => {

                            const chars = newPContent.split('');

                            const spans = [];
                            let spanContent = '';

                            chars.forEach((c, ci) => {
                                if (this.forbiddenChars.includes(c)) {
                                    if (spanContent !== '') {
                                        spans.push(spanContent);
                                        spanContent = '';
                                    }
                                    spans.push(c);
                                } else {
                                    spanContent += c;
                                }

                                if ((ci === (chars.length - 1)) && (spanContent !== '')) {
                                    spans.push(spanContent);
                                }
                            });

                            if (spans.length === 0) {
                                spans.push('');
                            }

                            return spans;
                        });

                        // Запоминаем позицию выделения
                        let selectionParams = this.getSelectionParams();
                        let selectionOffsets = selectionParams.offsets;
                        const cursorPositionFromEnd = selectionOffsets.total - selectionOffsets.end;
                        const {
                            atPEnd
                        } = selectionParams.flags;
                        const pIndex = selectionParams.pIndexes.start;
                        const spanIndex = selectionParams.spanIndexes.start;
                        const nodeOffset = selectionParams.nodeOffsets.start;

                        const {
                            ps
                        } = this.shadowStore;
                        const pStyleSample = _.cloneDeep(ps[pIndex].style);
                        const spanStyleSample = _.cloneDeep(ps[pIndex].spans[spanIndex].style);

                        const newPTemplate = {
                            spans: [],
                            style: pStyleSample
                        };
                        const newSpanTemplate = {
                            content: '',
                            style: spanStyleSample
                        };

                        const newPs = [];

                        ps.forEach((p, pi) => {
                            if (pi !== pIndex) {
                                newPs.push(p);
                            } else {
                                let newP = _.cloneDeep(p);
                                newP.spans = [];
                                p.spans.forEach(function (s, si) {
                                    if (si !== spanIndex) {
                                        newP.spans.push(s);
                                    } else {
                                        // Вот тут вся мякотка
                                        const splittedSpanContent = [
                                            s.content.slice(0, nodeOffset),
                                            s.content.slice(nodeOffset)
                                        ];
                                        s.content = splittedSpanContent[0];
                                        newP.spans.push(s);

                                        newContent.forEach(function (np, npi) {
                                            if (npi !== 0) {
                                                newPs.push(newP);
                                                newP = _.cloneDeep(newPTemplate);
                                            }
                                            np.forEach(function (ns, nsi) {
                                                const newSpan = _.cloneDeep(newSpanTemplate);
                                                newSpan.content = ns;
                                                newP.spans.push(newSpan);
                                            });

                                        });

                                        const newSpan = _.cloneDeep(newSpanTemplate);
                                        newSpan.content = splittedSpanContent[1];
                                        newP.spans.push(newSpan);
                                    }

                                });

                                newPs.push(newP);
                            }
                        });

                        this.opts.ps = _.cloneDeep(newPs);
                        this.shadowStore.ps = _.cloneDeep(newPs);
                        this.uniteSpans({ doNotRender: true });
                        this.renderContent();

                        this.$nextTick(function () {
                            selectionParams = this.getSelectionParams();
                            selectionOffsets = selectionParams.offsets;
                            const targetPosition = selectionOffsets.total - cursorPositionFromEnd;
                            this.setSelectionOffsets({ start: targetPosition, end: targetPosition }, atPEnd);
                            this.saveLocalHistory();
                        });


                        resolve(true);

                    });
                },
                () => {
                    console.log('insertText error');
                    this.inputPromise = new Promise(resolve => resolve('initial'));
                });

        },

        // Проверяем на совпадение размеры текста и контейнера
        checkOverflow () {
            let res;
            if (this.$el != null) {

                const that = this;

                const ps = $(this.$el).find('.p');

                if (ps.length === 0) {
                    return 0;
                }

                const pt = this.opts.style.paddings[0];
                const pb = this.opts.style.paddings[2];

                let sw = this.container.styleProps?.['stroke-width'];
                if ((sw == null)) {
                    sw = 0;
                }

                const totalHeight = $(this.$el).find('.text')[0].scrollHeight;

                // Если родитель div
                let containerHeight = this.container.$el.offsetHeight;
                // Если родитель svg
                if (containerHeight == null) {
                    containerHeight = this.container.$el.getBBox().height;
                }
                res = ((totalHeight + pt + pb + (sw * 2)) - containerHeight) * this.currentPage.scale;
            }

            return res;
        },

        emitOverflowEvent (from) {
            if (!this.isPreview() && this.adjustToText) {
                Vue.nextTick(
                    function () {
                        const overflow = this.checkOverflow();
                        if (overflow) {
                            this.$emit('overflow', overflow);
                        }
                    },
                    this
                );
            }
        },

        isEmpty () {
            if ((this.shadowStore.ps.length === 1) &&
                (this.shadowStore.ps[0].spans.length === 1) &&
                ((this.shadowStore.ps[0].spans[0].content === '\u200b') ||
                    (this.shadowStore.ps[0].spans[0].content.length === 0))) {
                return true;
            }
            return false;
        },

        canApplyPropertyToCurrentStructure () {
            const selection = this.getSelectionParams();
            const {
                flags
            } = selection;

            if (flags != null) {
                if (!(flags.allSelected ||
                    flags.selectionIsCollapsed ||
                    (flags.atTheStartOfStartNode &&
                        flags.atTheEndOfEndNode))) {
                    return false;
                }
            }

            return true;
        },

        rememberSelection () {

            if (!this.rememberedSelectionOffsets) {
                this.rememberedSelectionOffsets = this.getSelectionOffsets();
            }

        },

        restoreSelection () {
            if (this.rememberedSelectionOffsets) {
                const offsets = _.clone(this.rememberedSelectionOffsets);
                this.rememberedSelectionOffsets = false;
                this.$nextTick(function () {
                    this.setSelectionOffsets(offsets);
                });
            }
        },

        startLocalHistory () {

            this.initialStore = _.cloneDeep(this.shadowStore);

            this.history = [[this.initialStore], []];

            this.currentPage.historyHandlerOverride = this;
            this.focusInside = true;
        },

        endLocalHistorySync () {
            if (!_.isEqual(this.initialStore, this.shadowStore)) {
                this.$store.commit('saveHistorySync', 'synctextfocusout');
            }

            this.history = null;

            this.currentPage.historyHandlerOverride = null;
        },

        endLocalHistory () {
            if (this.blurWasForced) {
                this.blurWasForced = false;
                return;
            }

            if (!_.isEqual(this.initialStore, this.shadowStore)) {
                this.$store.commit('saveHistory', 'textfocusout');
            }

            this.history = null;

            this.currentPage.historyHandlerOverride = null;
        },

        saveLocalHistory () {
            if (this.currentPage.historyHandlerOverride === this) {
                window.log('saveLocalHistory');
                const store = _.cloneDeep(this.shadowStore);
                this.history[0].push(store);
                this.history[1] = [];
            }
        },

        historyUndo () {
            window.log('historyUndo');
            if (this.history[0].length > 1) {
                this.history[1].push(this.history[0].pop());
                this.opts.ps = _.cloneDeep(this.history[0].last()).ps;
                this.opts.style = _.cloneDeep(this.history[0].last()).style;
            } else {
                this.currentPage.metaTool.undo(true);
                $(this.$el).find('.text')[0].blur();
            }
        },

        historyRedo () {
            window.log('historyRedo');
            if (this.history[1].length > 0) {
                this.history[0].push(this.history[1].pop());
                this.opts.ps = _.cloneDeep(this.history[0].last()).ps;
                this.opts.style = _.cloneDeep(this.history[0].last()).style;
            }
        },

        html2CanvasHackOn () {
            const tw = this.$el;

            const t = $(this.$el).find('.text')[0];
            const twcr = tw.getBoundingClientRect();
            const tcr = t.getBoundingClientRect();

            const twp = {
                top: twcr.top,
                left: twcr.left,
                width: twcr.width,
                height: twcr.height
            };

            const tp = {
                top: tcr.top,
                left: tcr.left,
                width: tcr.width,
                height: tcr.height
            };

            this.oldTextCss =
                { textAlign: t.style.textAlign };

            t.style.maxWidth = 'none';
            t.style.maxHeight = 'none';
            t.style.position = 'absolute';
            t.style.left = ((tp.left - twp.left) / this.currentPage.scale) + 'px';
            t.style.top = ((tp.top - twp.top) / this.currentPage.scale) + 'px';
            t.style.width = ((tp.width) / this.currentPage.scale) + 'px';
            t.style.height = ((tp.height) / this.currentPage.scale) + 'px';
            t.style.visibility = 'hidden';

        },

        html2CanvasHackOff () {
            const t = $(this.$el).find('.text')[0];

            t.style.maxWidth = null;
            t.style.maxHeight = null;
            t.style.position = null;
            t.style.left = null;
            t.style.top = null;
            t.style.width = null;
            t.style.height = null;
            t.style.visibility = null;

        },

        getTextEl () {
            const textEl = $(this.$el).find('.text')[0];

            const res = {};

            res.el = textEl.cloneNode(true);
            res.el.style.visibility = null;
            res.rect = textEl.getBoundingClientRect();

            return res;
        },

        getTextTarget () {
            return this;
        },

        generateOlNumber (type, index) {
            switch(type) {
                case 'arabicPeriod':
                    return (index + 1) + '.';
                    break;
                case 'arabicParenR':
                    return (index + 1) + ')';
                    break;
                case 'romanUcPeriod':
                    return this.romanizeInt(index + 1, true) + '.';
                    break;
                case 'alphaUcPeriod':
                    return this.letterizeInt(index, true) + '.';
                    break;
                case 'alphaLcParenR':
                    return this.letterizeInt(index, false) + ')';
                    break;
                case 'alphaLcPeriod':
                    return this.letterizeInt(index, false) + '.';
                    break;
                case 'romanLcPeriod':
                    return this.romanizeInt(index + 1, false) + '.';
                    break;
            }
        },

        romanizeInt (num, uc) {
            let roman = '', i;
            if (uc) {
                var lookup = {
                    M: 1000,
                    CM: 900,
                    D: 500,
                    CD: 400,
                    C: 100,
                    XC: 90,
                    L: 50,
                    XL: 40,
                    X: 10,
                    IX: 9,
                    V: 5,
                    IV: 4,
                    I: 1
                };
            } else {
                var lookup = {
                    m: 1000,
                    cm: 900,
                    d: 500,
                    cd: 400,
                    c: 100,
                    xc: 90,
                    l: 50,
                    xl: 40,
                    x: 10,
                    ix: 9,
                    v: 5,
                    iv: 4,
                    i: 1
                };
            }
            for (i in lookup) {
                while (num >= lookup[i]) {
                    roman += i;
                    num -= lookup[i];
                }
            }
            return roman;
        },

        letterizeInt (num, uc) {
            let end, start;
            while (num > 25) {
                num -= 26;
            }
            if (uc) {
                start = 0x41;
                end = 0x5A;
            } else {
                start = 0x61;
                end = 0x7A;
            }
            return String.fromCharCode(start + num);
        },

        textBlockGenerator (style) {
            return {
                style: {
                    'font-family': style['font-family'],
                    'font-stretch': style['letter-spacing'],
                    'font-size': style['font-size'] + 'px',
                    'font-weight': style['font-weight'],
                    'font-style': style['font-style'],
                    'color': 'rgba(' + this.getValidColor(style['color']) + ')',
                    'background-color': 'rgba(' + this.getValidColor(style['background-color']) + ')',
                    'text-decoration': style['text-decoration']
                },
                content: '',
                rect: {
                    top: undefined,
                    left: undefined,
                    right: undefined,
                    baseline: undefined,
                    bottom: undefined
                }
            };
        },

        getTextGeometry () {
            const initialSelection = window.getSelection();
            let initialRange = false;
            try {
                initialRange = initialSelection.getRangeAt(0);
            } catch (error) {
            }

            const selfRect = this.$el.getBoundingClientRect();
            const xShift = -selfRect.left;
            const yShift = -selfRect.top;

            let textBlocks = [];

            let pointer = 0;

            this.shadowStore.ps.forEach((p, pIndex) => {

                let isList, listText, pDivLeft;
                if (p.style['list-type'] !== 'none') {
                    isList = true;
                    listText = undefined;
                    pDivLeft = undefined;
                }

                p.spans.forEach((span, spanIndex) => {


                    let block = null;

                    const {
                        length
                    } = span.content;
                    for (let i = 0, end = length; i < end; i++) {
                        const newRange = this.getNewSelectionRange({ start: pointer, end: ++pointer });

                        const currentChar = span.content[i];
                        const nextChar = span.content[i + 1];

                        let currentCharIsSpace = false;
                        if (this.spaceChars.includes(currentChar)) {
                            currentCharIsSpace = true;
                        }

                        let nextCharIsSpace = false;
                        if (this.spaceChars.includes(nextChar)) {
                            nextCharIsSpace = true;
                        }

                        const spanEl = newRange.endContainer.parentElement;
                        const baselineRatio = this.baselineRatio(spanEl);

                        const rect = newRange.getBoundingClientRect();

                        if (!currentCharIsSpace) {

                            if (isList) {
                                const pDiv = spanEl.parentElement;
                                const pStyle = pDiv.style;
                                const pFz = pStyle.fontSize;
                                const pFf = pStyle.fontFamily;
                                const pFw = pStyle.fontWeight;
                                if (listText == null) {
                                    listText = pDiv.getAttribute('data-list-index');
                                }
                                if (pDivLeft == null) {
                                    pDivLeft = pDiv.getBoundingClientRect().left;
                                }
                                const listBlock = this.textBlockGenerator(span.style);
                                listBlock.rect.left = pDivLeft;
                                listBlock.rect.top = rect.top;
                                listBlock.rect.baseline = rect.bottom - ((rect.bottom - rect.top) * baselineRatio);
                                listBlock.rect.bottom = rect.bottom;
                                listBlock.rect.right = rect.left;
                                listBlock.content = listText;
                                listBlock.style['do-not-stretch'] = true;
                                listBlock.style['font-size'] = pFz;
                                listBlock.style['font-family'] = pFf;
                                listBlock.style['font-weight'] = pFw;
                                delete listBlock.style['text-decoration'];
                                textBlocks.push(listBlock);
                                isList = false;
                            }

                            if (block == null) {
                                block = this.textBlockGenerator(span.style);
                            }
                            if (block.rect.left == null) {
                                block.rect.left = rect.left;
                            }
                            if (block.rect.top == null) {
                                block.rect.top = rect.top;
                            }
                            if (block.rect.baseline == null) {
                                block.rect.baseline = rect.bottom - ((rect.bottom - rect.top) * baselineRatio);
                            }
                            if (block.rect.bottom == null) {
                                block.rect.bottom = rect.bottom;
                            }
                            if (block.rect.right == null) {
                                block.rect.right = rect.right;
                            }

                            if ((block.rect.top !== rect.top) || currentCharIsSpace) {
                                textBlocks.push(block);
                                block = this.textBlockGenerator(span.style);
                                block.rect.left = rect.left;
                                block.rect.top = rect.top;
                                block.rect.baseline = rect.bottom - ((rect.bottom - rect.top) * baselineRatio);
                                block.rect.bottom = rect.bottom;
                            } else {
                                block.rect.right = rect.right;
                            }

                        } else { // (if currentCharIsSpace)
                            const spaceBlock = this.textBlockGenerator(span.style);
                            spaceBlock.rect.left = rect.left;
                            spaceBlock.rect.top = rect.top;
                            spaceBlock.rect.baseline = rect.bottom - ((rect.bottom - rect.top) * baselineRatio);
                            spaceBlock.rect.bottom = rect.bottom;
                            spaceBlock.rect.right = rect.right;
                            textBlocks.push(spaceBlock);
                        }

                        if (block != null) {

                            block.content += currentChar;

                            if ((i === (length - 1)) || nextCharIsSpace) {
                                block.rect.right = rect.right;
                                textBlocks.push(block);
                                block = null;
                            }
                        }
                    }

                });
            });

            textBlocks = textBlocks.map(function (tb) {
                tb.rect.top += yShift;
                tb.rect.baseline += yShift;
                tb.rect.bottom += yShift;
                tb.rect.left += xShift;
                tb.rect.right += xShift;
                return tb;
            });
            this.svgData = textBlocks;
            return textBlocks;
        },

        baselineRatio (elem) {
            // Get the baseline in the context of whatever element is passed in.
            elem = elem || document.body;

            const container = document.createElement('div');
            container.style.display = "block";
            container.style.position = "absolute";
            container.style.bottom = "0";
            container.style.right = "0";
            container.style.width = "0px";
            container.style.height = "0px";
            container.style.margin = "0";
            container.style.padding = "0";
            container.style.visibility = "hidden";
            container.style.overflow = "hidden";
            container.style['letter-spacing'] = "0";

            // Intentionally unprotected style definition.
            const small = document.createElement('span');
            const large = document.createElement('span');

            // Large numbers help improve accuracy.
            small.style.fontSize = "0px";
            large.style.fontSize = "2000px";

            small.innerHTML = "X";
            large.innerHTML = "X";

            container.appendChild(small);
            container.appendChild(large);

            // Put the element in the DOM for a split second.
            elem.appendChild(container);
            const smalldims = small.getBoundingClientRect();
            const largedims = large.getBoundingClientRect();
            elem.removeChild(container);

            // Calculate where the baseline was, percentage-wise.
            const baselineposition = smalldims.top - largedims.top;
            const {
                height
            } = largedims;

            return 1 - (baselineposition / height);
        },

        generateSvg () {
            if (!this.$refs.svg) {
                return '';
            }
            const svgData = this.$refs.svg.outerHTML;
            const svgDoc = new DOMParser().parseFromString(svgData, 'image/svg+xml');
            const svgNS = "http://www.w3.org/2000/svg";
            const defs = svgDoc.createElementNS(svgNS, 'defs');
            const style = svgDoc.createElementNS(svgNS, 'style');

            const fontsData = [].concat(...this.fontsUsed.map(f => this.currentPage.fontsDataCss[f]));

            style.innerHTML = fontsData.join('\n');
            defs.appendChild(style);
            svgDoc.documentElement.appendChild(defs);
            const str = new XMLSerializer().serializeToString(svgDoc.documentElement);
            const uri = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(str);
            return str;
        }
    }
};

</script>

<style lang="scss">
.text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}

.text {
    position: relative;
    display: block;
    width: 100%;
    // margin-top: auto;
    // margin-bottom: auto;
    max-width: 100%;
    max-height: 100%;
    min-width: 5px;
    outline: none;

    .text-selected & {
        background-color: #8881;
    }

    .p {
        position: relative;
    }

    .li {
        &:before {
            display: inline-block;
            content: attr(data-list-index);
            min-width: 1.3em;
        }
    }

    span {
        position: relative;
        white-space: pre-wrap;
        outline: none;
    }

    span::selection {
        color: inherit;
        background: rgba(200, 200, 222, 1);
    }
}
</style>
