<template>
    <div class="property property_type_history">
        <div class="property__btn"
            :title="$t('undo')"
            :class="classesUndo"
            @click="$parent.metaTool.undo()"
            @mousedown.prevent="">
            <svg width="20" height="11" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-995 -19)" fill="none" fill-rule="evenodd">
                    <path d="M996 16h18v18h-18z" />
                    <path
                        d="M1005.375 22a7.848 7.848 0 0 0-5.175 1.95l-2.7-2.7V28h6.75l-2.715-2.715a5.965 5.965 0 0 1 3.84-1.41 6.009 6.009 0 0 1 5.7 4.125l1.777-.585c-1.042-3.142-3.99-5.415-7.477-5.415z"
                        fill="#FFF" fill-rule="nonzero" />
                </g>
            </svg>
        </div>
        <div class="property__btn"
            :title="$t('redo')"
            :class="classesRedo"
            @click="$parent.metaTool.redo()"
            @mousedown.prevent=""
        >
            <svg width="20" height="11" xmlns="http://www.w3.org/2000/svg">
                <g transform="translate(-1038 -19)" fill="none" fill-rule="evenodd">
                <path d="M1057 16h-18v18h18z" />
                    <path
                        d="M1047.625 22c1.987 0 3.787.742 5.175 1.95l2.7-2.7V28h-6.75l2.715-2.715a5.965 5.965 0 0 0-3.84-1.41 6.009 6.009 0 0 0-5.7 4.125l-1.777-.585c1.042-3.142 3.99-5.415 7.477-5.415z"
                        fill="#FFF" fill-rule="nonzero" />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'PropHistory',
    data() {
        return {};
    },
    computed: {
        classesUndo() {
            let ref;
            const globalUndo = this.$store.state.historyAvailible.undo;
            const textUndo = ((ref = this.$parent.historyHandlerOverride) != null ? ref.history[0].length : 0) > 1;

            return {
                'property__btn_active': globalUndo || textUndo
            };
        },
        classesRedo() {
            let ref;
            const globalRedo = this.$store.state.historyAvailible.redo;
            const textRedo = ((ref = this.$parent.historyHandlerOverride) != null ? ref.history[1].length : 0) > 0;

            return {
                'property__btn_active': globalRedo || textRedo
            };
        }
    }
};
</script>

<style lang="scss">
.property_type_history {
  position: relative;
  display: flex;
  padding: 0 10px;
  height: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  .property__btn {
    position: relative;
    display: block;
    width: 100%;
    padding: 3px 10px;
    transition: opacity .3s ease;
    opacity: .3;
    cursor: default;

    &_active {
      opacity: 1;
      cursor: pointer;

      svg {
        opacity: 0.7;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }

    svg {
      position: relative;
      display: block;
      height: 11px;
      width: 20px;
      opacity: .5;
    }
  }
}
</style>


<i18n>
{
    "en": {
        "undo": "Undo",
        "redo": "Redo"
    },
    "ru": {
        "undo": "Отменить",
        "redo": "Повторить"
    }
}
</i18n>
