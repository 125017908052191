const template = `
<div class="media-browser__item">
    <div class="media-browser__item-pic" :style="{'background-image': 'url('+src+')'}" v-on:click="itemClick" v-bind:class="{'selected': selected==id}"></div>
</div>
`;
/* eslint-disable */
export default {
    name: 'integration-thumb',
    template,
    props: ['id', 'src', 'selected'],
    data: function () {
        return {};
    },
    methods: {
        itemClick: function () {
            this.$emit('itemselected');
        }
    }
};
