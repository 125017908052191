/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';

import Color from '@/mixins/editor/Color';
import Common from '@/mixins/editor/Common';
import Text from '@/components/editor/Text';
import TextEntity from '@/mixins/editor/TextEntity';

import { CurrentPage } from '@/models/CurrentPage';

export default {
    name: 'Object_mixin',
    props: ['opts'],
    inject: ['currentPage'],
    mixins: [
        Common,
        Color,
        TextEntity
    ],
    components: {
        text_block: Text
    },
    data() {
        return {
            isTextSelected: false,
            isSelected: false
        };
    },

    mounted() {
        this.html = this.$el;
        this.updateVueLink();

        if(this.opts.shiftAfterCreation) {
            Vue.delete(this.myStoreObject, 'shiftAfterCreation');
            const shiftedCoords = _.cloneDeepWith(this.virtualCoords, function(value) {
                if(!(value instanceof Object)) {
                    return value + 20;
                }
            });
            this.virtualCoords = shiftedCoords;
        }

        if(this.opts.selectSelf) {
            this.opts.selectSelf = false;
            this.currentPage.metaTool.selectLastObject();
        }

        if(this.opts.afterCreate) {
            this.opts.afterCreate.map((toRun, i, ac) => {
                if(toRun.immediate) {
                    this[toRun.method](toRun.payload);
                    ac[i] = false;
                }
            });
        }

    },

    watch: {
        //Deprecated
        'currentPage.html2CanvasHack'() {
            if(!this.isPreview()) {
                if(this.currentPage.html2CanvasHack) {
                    Vue.nextTick(
                        function() {
                            this.drawTextFake();
                        },
                        this
                    );
                } else {
                    this.removeTextFake();
                }
            }
        }
    },

    updated() {
        this.$nextTick(function() {
            this.updateVueLink();
        });
    },

    computed: {

        flags() {
            return this.$store.state.flags;
        },

        a() {
            if(this.flags.screenshotGeneratorMode) {
                return this.$store.state.defaults.svgExtend;
            } else {
                return 0;
            }
        },

        ready() {
            if(!this.opts.creating) {
                return true;
            }
            return false;
        },

        styleProps() {
            return this.opts.styleProps;
        },

        selfSize() {
            const c = this.opts.coords;
            return {
                width: Math.round(Math.abs(c[1].x - c[0].x)),
                height: Math.round(Math.abs(c[1].y - c[0].y))
            };
        },

        fadedStyle() {
            const bgEditingModeOn = this.$store.state.flags.bgEditingMode;
            const someObjectsSelected = (this.currentPage.editingObjects != null) && (this.currentPage.editingObjects.length > 0);
            const thisSelected = someObjectsSelected && (this.currentPage.editingObjects[0].vueObj === this);
            if(bgEditingModeOn && !thisSelected) {
                return { opacity: 0.3 };
            }
            return {};
        },
    },

    methods: {

        setProperty(k, v) {

            let newVal;
            if(k === 'adjustBg') {
                this.adjustSelfSizeByBg();
                return;
            }

            if(k === 'lock-aspect') {
                if(v && this.styleProps['adjust-to-text']) {
                    this.changeAdjustToTextFlag?.(false);
                }
            }

            if((k === 'stroke-width') && this.isLine && (v < 1)) {
                v = 1;
            }
            if(v instanceof Object) {
                newVal = _.cloneDeep(v);
            } else {
                newVal = v;
            }

            Vue.set(this.opts.styleProps, k, newVal);

        },

        setSeoProperty(k, v) {
            if((this.opts.seo == null)) {
                Vue.set(
                    this.opts,
                    'seo',
                    {
                        'seo-header': false,
                        'seo-text': false,
                        'seo-image': false
                    }
                );
            }

            if((k === 'seo-header') && v) {
                if(this.opts.seo['seo-text']) {
                    Vue.set(this.opts.seo, 'seo-text', false);
                }
            }
            if((k === 'seo-text') && v) {
                if(this.opts.seo['seo-header']) {
                    Vue.set(this.opts.seo, 'seo-header', false);
                }
            }

            Vue.set(this.opts.seo, k, v);
        },

        setAnimationProperty(k, v, animationProps) {

            if((this.opts.animation == null)) {
                Vue.set(this.opts, 'animation', {});
                Object.keys(animationProps).forEach(ak => {
                    Vue.set(this.opts.animation, ak, animationProps[ak]);
                });
            }

            if(k != null) {
                Vue.set(this.opts.animation, k, v);
            }

        },

        applyStyles(styles) {

            if(this.styleProps != null) {
                if(Object.keys(styles.styleProps).length > 0) {
                    Object.keys(styles.styleProps).forEach(k => {
                        if(this.styleProps[k] != null) {
                            this.setProperty(k, styles.styleProps[k]);
                        }
                    });
                }
                if(Object.keys(styles.styleProps).length > 0) {
                    if(this.$refs.text != null) {
                        Object.keys(styles.textProps).forEach(k => {
                            this.$refs.text.setProperty(k, styles.textProps[k]);
                        });
                        this.$refs.text.setProperty('mouseUp');
                        this.$refs.text.renderContent();
                    }
                }
            }
        },

        setMenuTarget(e, metaToolFlag) {
            e.preventDefault();
            e.stopPropagation();
            const meInEditingObjects = this.currentPage.editingObjects.some(
                obj => {
                    if(obj.vueObj === this) {
                        return true;
                    }
                    return false;
                });
            if(!meInEditingObjects && !metaToolFlag) {
                this.currentPage.metaTool.clearState();
                this.currentPage.metaTool.addObjectToEditor(this.opts.id);
                this.currentPage.metaTool.drawSelectedSpace();
                this.$nextTick(function() {
                    this.currentPage.metaTool.setMenuTarget(e);
                });
                return;
            }

            const { text } = this.$refs;
            if(text?.getSelectionOffsets() != null) {
                this.currentPage.menuTarget = 'text';
            } else {
                this.currentPage.menuTarget = 'object';
                const groupItem = _.find(this.currentPage.menuItems.object, o => o.event === 'group');
                const ungroupItem = _.find(
                    this.currentPage.menuItems.object,
                    o => o.event === 'ungroup');
                const addTextItem = _.find(
                    this.currentPage.menuItems.object,
                    o => o.event === 'addText');
                groupItem.hidden = true;
                ungroupItem.hidden = true;
                if(this.textAvailable) {
                    if((this.$refs.text == null) || this.$refs.text.isEmpty()) {
                        addTextItem.hidden = false;
                    } else {
                        addTextItem.hidden = true;
                    }
                }
            }

            this.currentPage.showContextMenu(e);
        },

        setReady() {
            Vue.delete(this.myStoreObject, 'creating');
            if(this.setReadyCustom != null) {
                this.setReadyCustom();
            }
        },

        updateVueLink(force) {
            if(force == null) {
                force = false;
            }
            if((this.options.index != null) && (this.options.slideIndex != null)) {
                const object = this.$store.state.presentation
                    .slides[this.options.slideIndex]
                    .objects[this.options.index];
                if(object === undefined) {
                    return;
                }
                const target = object?.vueObj;
                // проверяем если vueObj пустой
                if((Object.keys(target).length === 0) ||
                    (target.$el?.__vue__ == null) ||
                    // Refactor this
                    force ||
                    true) {
                    this.$store.commit(
                        'setObjectVue',
                        {
                            id: this.opts.id,
                            slideId: this.mySlideObject.id,
                            vueObj: this
                        }
                    );
                }
            }
        },

        // Метод возвращает true если в массиве
        // есть $el
        isSelfInDomElemsArray(array) {
            return array.includes(this.$el);
        },

        dblClick() {
            this.addTextEntity();
        },

        textfocus(e) {
            this.currentPage.blockMetatool = true;
            this.currentPage.editingText = e;
            if(this.currentPage.editingObjects.length === 0) {
                this.currentPage.metaTool.editingObjects.push(this.myStoreObject);
                this.currentPage.metaTool.drawSelectedSpace();
            }
            this.isTextSelected = true;
        },

        textfocusout(e) {
            this.isTextSelected = false;
        },

        // Deprecated
        drawTextFake() {
            const myDomRect = this.$el.getBoundingClientRect();
            const {
                scale
            } = this.currentPage;

            this.getTextEls().forEach(textEl => {
                const fake = textEl.el;
                fake.style.left = ((textEl.rect.left - myDomRect.left) / scale) + 'px';
                fake.style.top = ((textEl.rect.top - myDomRect.top) / scale) + 'px';

                return $(this.$el).find('.text-fake').append(fake);
            });
        },

        // Deprecated
        removeTextFake() {
            $(this.$el).find('.text-fake').empty();
        },

        getTextEls() {
            if(this.$refs.text != null) {
                return [this.$refs.text.getTextEl()];
            } else {
                return [];
            }
        },

        selfSaveHistory() {
            this.$store.commit('saveHistory', 'selfSaveHistory');
        },

        adjustSelfSizeByBg() {
            const sp = this.styleProps;
            const sw = this.selfSize.width;
            if(sp != null) {
                if(!sp['lock-aspect']) {
                    return;
                }
                const bw = sp['background-image-width'];
                const bh = sp['background-image-height'];
                if((bw != null) && (bh != null)) {
                    const ratio = bw / bh;
                    this.setSize(sw, sw / ratio);

                    this.$nextTick(function() {
                        this.currentPage.metaTool.drawSelectedSpace();
                    });
                }
            }
        },

        getAnimation() {
            const {
                animation
            } = this.myStoreObject;
            const defaultAnimation = this.$store.state.defaultAnimationState;

            if(animation) {
                const next = {};
                const params = _.clone(animation);

                switch(animation['animation-effect']) {
                    case 'none':
                        next.opacity = 0;
                        params['animation-duration'] = 0;
                        break;
                    case 'top':
                        next.opacity = 0;
                        next.ty = -0.05;
                        break;
                    case 'bottom':
                        next.opacity = 0;
                        next.ty = 0.05;
                        break;
                    case 'left':
                        next.opacity = 0;
                        next.tx = -0.05;
                        break;
                    case 'right':
                        next.opacity = 0;
                        next.tx = 0.05;
                        break;
                    case 'zoomIn':
                        next.opacity = 0;
                        next.scale = 0.1;
                        break;
                    case 'zoomOut':
                        next.opacity = 0;
                        next.scale = 2;
                        break;
                    case 'fade':
                        next.opacity = 0;
                        break;
                }

                return { next, params };
            } else {
                return false;
            }
            return this.myStoreObject.animation;
        },

        getVjson() {
            return {
                html: this.$el.innerHTML,
                styles: _.cloneDeep(this.posParams),
                classList: this.$el.classList.value,
                id: this.myStoreObject.id,
                animation: this.getAnimation()
            };
        },

        getRendererData() {
            let res;
            if(this.currentPage.isRenderer) {
                res = {
                    styles: _.cloneDeep(this.posParams),
                    id: this.myStoreObject.id,
                    animation: this.getAnimation()
                };
            } else {
                res = false;
            }
            return JSON.stringify(res);
        }
    }
};
