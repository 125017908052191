<script>
/* eslint-disable */
import _ from "lodash";

import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Circle_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'circle'
    };
  },
  computed: {
    magnetCoordsDecart() {
      return [
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[1].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        },
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        }
      ];
    },
    ellipParams() {
      const res = {
        rx: Math.max(this.svgParams.width - this.border.width, 0) / 2,
        ry: Math.max(this.svgParams.height - this.border.width, 0) / 2,
        cx: this.svgParams.width / 2,
        cy: this.svgParams.height / 2
      };

      if (this.styleProps['stroke-type'] === 'dashed') {
        res['stroke-dash'] = this.styleProps['stroke-dash'].join(', ');
      }

      return _.assign(res, this.commonShapeParams);
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
