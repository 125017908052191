<script>
/* eslint-disable */
import $ from 'jquery';
import Vue from 'vue';

import Unsplash, { toJson } from 'unsplash-js';
import TabMixin from '@/components/editor/MediaBrowser/components/TabMixin';

export default {
    inject: ['currentPage'],
    name: 'unsplash-tab',
    mixins: [TabMixin],
    data: function() {
        return {
            name: 'unsplash',
            unsplash: {},
            searchPlaceholder: 'Search media...'
        };
    },
    watch: {
        'currentPage.ukey': {
            immediate: true,
            handler: function(newVal) {
                if(newVal !== '') {
                    this.unsplashAuth(newVal);
                }
            }
        }
    },
    computed: {
        searchResultsUrls: function() {
            return this.searchResults.map(function(r) {
                return r.urls.thumb;
            });
        }
    },
    methods: {
        browse: function(clearResults, initial) {
            var itemsPerPage, page, query, that;
            if(!this.visible) {
                return;
            }
            itemsPerPage = 20;
            page = 1;
            that = this;
            query = this.search;
            if(query === '') {
                query = 'random';
            }
            if(query.length < 3) {
                this.searchResults = [];
                return;
            }
            if(clearResults) {
                this.searchResults = [];
            }
            if(this.searchResults.length > 0) {
                page = this.searchResults.length / itemsPerPage + 1;
            }
            // if !@busy
            this.busy = true;
            this.unsplash.search.photos(query, page, itemsPerPage).then(toJson).then(function(json) {
                var itemsContainer;
                that.busy = false;
                // console.log 'unsplash.search.photos'
                // console.log json.results
                if(that.searchResults === []) {
                    that.searchResults = json.results;
                } else {
                    that.searchResults = that.searchResults.concat(json.results);
                }
                if(initial) {
                    itemsContainer = $(that.$el).find('.media-browser__items')[0];
                    Vue.nextTick(function() {
                        if(json.results.length < itemsPerPage) {
                            return;
                        }
                        // Равенство выполнится если нет скролла
                        if(itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                            this.browse(false, true);
                        }
                    }, that);
                }
            });
        },
        itemSelected: function(id) {
            var res, size, usr;
            usr = this.searchResults;
            size = this.currentPage.presentation.size;
            res = {
                url: usr[id].urls.raw + '&w=' + size.w,
                meta: {
                    id: usr[id].id,
                    name: usr[id].user.name,
                    link: usr[id].user.username,
                    ref: 'unsplash'
                }
            };
            this.$emit('itemselected', res);
        },
        unsplashAuth: function(ukey) {
            return this.unsplash = new Unsplash({
                accessKey: ukey
            });
        },
        triggerUnsplashDownload: function(id) {
            this.unsplash.photos.getPhoto(id).then(toJson).then((json) => {
                this.unsplash.photos.downloadPhoto(json);
            });
        }
    }
};
</script>
