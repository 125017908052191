
<script>
/* eslint-disable */
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Rhombus_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'rhombus'
    };
  },
  computed: {
    rhombusPath() {
      const rc = this.rhombusCoords;
      let res = "";
      res += `M${rc[0].x} ${rc[0].y}`;
      res += `L${rc[1].x} ${rc[1].y}`;
      res += `L${rc[2].x} ${rc[2].y}`;
      res += `L${rc[3].x} ${rc[3].y}`;
      res += "z";

      return res;
    },
    rhombusCoords() {
      const rp = this.rectParams;
      return [
        {
          x: rp.x + rp.width / 2,
          y: rp.y
        },
        {
          x: rp.x + rp.width,
          y: rp.y + rp.height / 2
        },
        {
          x: rp.x + rp.width / 2,
          y: rp.y + rp.height
        },
        {
          x: rp.x,
          y: rp.y + rp.height / 2
        }
      ];
    },
    textClipMaskCoords() {
      let a, angle, borderProection, c1, c2, rc, res, rp, tana;
      rc = this.rhombusCoords;
      rp = this.rectParams;
      borderProection = {};
      angle = 0;
      c1 = rp.height;
      c2 = rp.width;
      tana = c2 / c1;
      a = Math.atan(tana);
      borderProection.x = (this.border.width / 2) / Math.cos(a);
      borderProection.y = (this.border.width / 2) / Math.sin(a);
      res = [
        {
          x: rc[0].x,
          y: rc[0].y + borderProection.y
        },
        {
          x: rc[1].x - borderProection.x,
          y: rc[1].y
        },
        {
          x: rc[2].x,
          y: rc[2].y - borderProection.y
        },
        {
          x: rc[3].x + borderProection.x,
          y: rc[3].y
        }
      ];
      return res;
    },
    textLimiterParams() {
      let res, sm01, sm03, sm23, tcmc;
      tcmc = this.textClipMaskCoords;
      sm03 = this.sectionMiddle(tcmc[0], tcmc[3]);
      sm01 = this.sectionMiddle(tcmc[0], tcmc[1]);
      sm23 = this.sectionMiddle(tcmc[2], tcmc[3]);
      res = {
        x: sm03.x,
        y: sm03.y,
        width: sm01.x - sm03.x,
        height: sm23.y - sm03.y
      };
      return res;
    },
    magnetCoordsDecart() {
      return [
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[0].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[1].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        },
        {
          x: (this.opts.coords[0].x + this.opts.coords[1].x) / 2 - this.centerCoords.x,
          y: this.opts.coords[1].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x - this.centerCoords.x,
          y: (this.opts.coords[0].y + this.opts.coords[1].y) / 2 - this.centerCoords.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
