/* eslint-disable */
export default {
  name: 'Common_mixin',
  props: ['zi', 'index', 'slideindex'],
  computed: {
    options() {
      return {
        index: this.index,
        slideIndex: this.slideindex,
        vue: this
      };
    },
    mySlideObject() {
      return this.$store.state.presentation.slides[this.slideindex];
    },
    myStoreObject() {
      return this.mySlideObject.objects[this.index];
    }
  },
  methods: {
    isPreview() {
      return this.options.slideIndex == null;
    }
  }
};
