<template>
    <div class="property property_type_list">
        <div class="property__name">{{ $t(property.title) }}</div>
        <div class="property__flex-row">
            <prop_list_select :property="ref['ol']" :propset="propset" />
            <prop_list_select :property="ref['ul']" :propset="propset" />
            <div class="property__btn" @mousedown.prevent="increaseLevel" :class="{'disabled': incDisabled}">
                <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 1h16" stroke="#FFF" stroke-width="2" opacity=".5"/>
                        <path d="M9 7h7M0 7h4" stroke="#FFF" stroke-width="2"/>
                        <path d="M0 13h16" stroke="#FFF" stroke-width="2" opacity=".5"/>
                        <path fill="#FFF" d="M3.001 10L3 4l4 2.994z"/>
                    </g>
                </svg>
            </div>
            <div class="property__btn" @mousedown.prevent="decreaseLevel" :class="{'disabled': decDisabled}">
                <svg width="16" height="14" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M0 1h16" stroke="#FFF" stroke-width="2" opacity=".5"/>
                        <path d="M9 7h7M3 7h4" stroke="#FFF" stroke-width="2"/>
                        <path d="M0 13h16" stroke="#FFF" stroke-width="2" opacity=".5"/>
                        <path fill="#FFF" d="M3.999 4L4 10 0 7.006z"/>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import ref from '@/components/editor/PropPanel/prop_types';
import PropBase from "@/components/editor/property/PropBase";
import PropListSelect from "@/components/editor/property/PropListSelect";

export default {
    name: 'prop_list',
    mixins: [PropBase],
    props: ['propset'],
    components: {
        prop_list_select: PropListSelect
    },
    data: function () {
        return {
            ref: ref
        };
    },
    computed: {
        listLevel: {
            get: function () {
                var ll, ref1;
                ll = [0, 0];
                if ((((ref1 = this.propset) != null ? ref1['list-level'] : undefined) != null)) {
                    ll = this.propset['list-level'];
                }
                return ll;
            },
            set: function (val) {
                this.updateProp('list-level', val);
            }
        },
        incDisabled: function () {
            var d, ref1;
            d = false;
            if (((ref1 = this.property) != null ? ref1.maxlevel : undefined) <= this.listLevel[1]) {
                d = true;
            }
            return d;
        },
        decDisabled: function () {
            var d;
            d = false;
            if (0 >= this.listLevel[0]) {
                d = true;
            }
            return d;
        }
    },
    methods: {
        increaseLevel: function () {
            if (!this.incDisabled) {
                this.updateProp('list-level', 'increase');
            }
        },
        decreaseLevel: function () {
            if (!this.decDisabled) {
                this.updateProp('list-level', 'decrease');
            }
        }
    }
};
</script>

<style lang="scss">
.property_type_list {

    .close {
        position: absolute;
        display: block;
        top: 11px;
        right: 0px;
    }

    .property {
        width: 40px;
        margin-right: 10px;
        padding: 0;
    }

    .property__btn {
        height: 24px;
        width: 24px;
        margin-right: 15px;
        margin-left: 5px;
        text-align: center;
        padding-top: 2px;

        &:last-child {
            margin-right: 0;
        }

        &.disabled {
            pointer-events: none;
            cursor: default;

            & svg {
                opacity: .15;
            }
        }
    }
}
</style>

<i18n>
{
    "en": {
        "List": "List"
    },
    "ru": {
        "List": "Список"
    }
}
</i18n>
