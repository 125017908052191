<template>
  <div class="property property__type_table-borders">
    <div class="property__btn" @click="propValue = ['rowsAlt', !propValue.rowsAlt]"
         :class="{'property__btn__active': propValue.rowsAlt}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-276 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <g transform="translate(19)">
                  <rect width="14" height="4" opacity=".2"/>
                  <rect y="5" width="14" height="4"/>
                  <rect id="a" y="10" width="14" height="4" opacity=".2"/>
                  <rect width="4" height="14" opacity=".2"/>
                  <rect x="5" width="4" height="14" opacity=".2"/>
                  <rect x="10" width="4" height="14" opacity=".2"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="property__btn" @click="propValue = ['colsAlt', !propValue.colsAlt]"
         :class="{'property__btn__active': propValue.colsAlt}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-333 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <g transform="translate(76)">
                  <rect width="14" height="4" opacity=".2"/>
                  <rect y="5" width="14" height="4" opacity=".2"/>
                  <rect id="a" y="10" width="14" height="4" opacity=".2"/>
                  <rect width="4" height="14" opacity=".2"/>
                  <rect x="5" width="4" height="14"/>
                  <rect x="10" width="4" height="14" opacity=".2"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="property__btn" @click="propValue = ['firstRow', !propValue.firstRow]"
         :class="{'property__btn__active': propValue.firstRow}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-257 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <rect width="14" height="4"/>
                <rect y="5" width="14" height="4" opacity=".2"/>
                <rect id="a" y="10" width="14" height="4" opacity=".2"/>
                <rect width="4" height="14" opacity=".2"/>
                <rect x="5" width="4" height="14" opacity=".2"/>
                <rect x="10" width="4" height="14" opacity=".2"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="property__btn" @click="propValue = ['lastRow', !propValue.lastRow]"
         :class="{'property__btn__active': propValue.lastRow}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-295 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <g transform="translate(38)">
                  <rect width="14" height="4" opacity=".2"/>
                  <rect y="5" width="14" height="4" opacity=".2"/>
                  <rect id="a" y="10" width="14" height="4"/>
                  <rect width="4" height="14" opacity=".2"/>
                  <rect x="5" width="4" height="14" opacity=".2"/>
                  <rect x="10" width="4" height="14" opacity=".2"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="property__btn" @click="propValue = ['firstCol', !propValue.firstCol]"
         :class="{'property__btn__active': propValue.firstCol}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-314 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <g transform="translate(57)">
                  <rect width="14" height="4" opacity=".2"/>
                  <rect y="5" width="14" height="4" opacity=".2"/>
                  <rect id="a" y="10" width="14" height="4" opacity=".2"/>
                  <rect width="4" height="14"/>
                  <rect x="5" width="4" height="14" opacity=".2"/>
                  <rect x="10" width="4" height="14" opacity=".2"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="property__btn" @click="propValue = ['lastCol', !propValue.lastCol]"
         :class="{'property__btn__active': propValue.lastCol}">
      <svg version="1.1" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(-352 -633)" fill="#D8D8D8">
            <g transform="translate(243 572)">
              <g transform="translate(14 61)">
                <g transform="translate(95)">
                  <rect width="14" height="4" opacity=".2"/>
                  <rect y="5" width="14" height="4" opacity=".2"/>
                  <rect id="a" y="10" width="14" height="4" opacity=".2"/>
                  <rect width="4" height="14" opacity=".2"/>
                  <rect x="5" width="4" height="14" opacity=".2"/>
                  <rect x="10" width="4" height="14"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'propTableTemplate',
  mixins: [PropBase]
};
</script>

<style lang="scss">
.property__type_table-borders{
  padding: 10px 0 10px 18px;
  .property__btn{
    height: 19px;
    width: 19px;
    box-sizing: border-box;
    background: none;
    margin:0 2px 0 0;
    border:2px solid transparent;

    &:last-child{
      margin: 0;
    }

    svg{
      position:relative;
      display:block;
      width: 15px;
      // margin: 2px 0 0 2px;
    }
    &__active{
      //background: rgba(255,255,255, .2);
      border-color:#1695D2;
      svg{
        opacity: 1;
      }
    }
  }
}
</style>
