<template>
    <div
        class="property property__type_number property__type_scale"
        :class="`property__mode_${mode}`">
        <input
            class="property__input"
            type="text"
            v-model="propValue"
            v-on:blur="inputBlur"
            v-on:mousedown="mouseDown"
            @keydown.stop=""
            v-on:dblclick="dblClick"/>
        <div class="property__input-units">%</div>
        <div class="property__nav-btn" @mousedown.stop="menuActive = !menuActive">
            <svg width="10" height="8" xmlns="http://www.w3.org/2000/svg">
                <path transform="rotate(180 23.5 7.024)" d="M42 8l3 4.049h-6z" fill="#FFF" fill-rule="evenodd"/>
            </svg>
        </div>
        <div
            class="context-menu context-menu_dark property__nav"
            :class="{'context-menu_active': menuActive == true}"
        >
            <div class="context-menu__item" v-on:mousedown="scale100">100%</div>
            <div class="context-menu__item" v-on:mousedown="fitScreen">{{ $t('fitCanvas') }}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";
import PropNumber from "@/components/editor/property/PropNumber";

export default {
    name: "PropScale",
    inject: ['currentPage'],
    mixins: [
        PropBase,
        PropNumber
    ],
    data() {
        return {
            menuActive: false
        };
    },
    computed: {
        propValue: {
            get() {
                return Math.round(this.currentPage.scale * 100);
            },
            set(val) {
                this.currentPage.setScale(val);
            }
        }
    },
    watch: {
        'currentPage.mousePressed'() {
            if (this.currentPage.mousePressed.left) {
                this.menuActive = false;
            }
        },
        dragging() {
            if (!this.dragging) {
                this.propValue = this.propValue;
            }
        }
    },
    methods: {
        fitScreen() {
            this.currentPage.fitScreen();
        },
        scale100() {
            this.propValue = 100;
            return this.currentPage.navigate = {
                tx: 0,
                ty: 0
            };
        }
    }
};
</script>

<style lang="scss">
.property.property__type_scale {
    position: relative;
    display: flex;
    height: 48px;
    align-items: center;

    .context-menu {
        pointer-events: auto;
    }

    .property__name, input {
        position: relative;
        display: block;
        width: 37px;
        padding: 0 5px;
        margin-left: 0;
    }

    input {
        background: rgba(0, 0, 0, .2);
    }

    .property__nav-btn {
        position: relative;
        display: block;
        height: 18px;
        padding: 4px 2px;
        box-sizing: border-box;
        margin-left: 2px;
        border-radius: 2px;
        background: rgba(0, 0, 0, .2);
        cursor: pointer;
        left: -5px;

        &:hover {
            background: rgba(255, 255, 255, .1);

            svg {
                opacity: 1
            }
        }

        svg {
            position: relative;
            display: block;
            opacity: .5;
        }
    }

    .property__nav {
        position: absolute;
        display: block;
        top: 38px;
        left: 0;
        width: auto;
    }
}
</style>


<i18n>
{
    "en": {
        "fitCanvas": "Fit canvas"
    },
    "ru": {
        "fitCanvas": "Смаштабировать"
    }
}
</i18n>
