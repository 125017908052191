<template>
  <div class="themes-editor">
    <div class="themes-editor__header">
      <prop_theme_select v-if="$store.state.presentation && $store.state.presentation.theme" @editingthemechanged="editTheme" ref="theme_selector"></prop_theme_select>
      <div class="themes-editor__theme-id" v-if="theme">{{ theme.id }}</div>
      <div class="themes-editor__save-btn" v-if="theme" @click="$refs.theme_selector.saveTheme()">Save</div>
    </div>
    <div class="themes-editor__instruments">
      <template v-if="theme">
        <prop_color :propset="theme.palette" :property="{name: property}" :showAlpha="false" :nested="true"
                    :currentPalette="activePalette" @propertychanged="changeProperty" @newpalette="setNewPalette"
                    ref="theme_color"></prop_color>
        <div class="themes-editor__options-selector"><label class="themes-editor__dark-option"><input
            type="checkbox" v-model="dark"/>Dark theme</label>
          <div class="themes-editor__options-list">
            <div class="themes-editor__options-list-item" v-for="option in options"
                 @click="selectedOption = option.k"
                 :class="{'themes-editor__options-list-item_active': selectedOption == option.k}">
              <div class="themes-editor__options-list-item-preview" :style="previewStyles[option.k]"></div>
              <div class="themes-editor__options-list-item-name">{{ option.v }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="themes-editor__preview-wrapper">
      <div class="themes-editor__preview" v-if="theme" :style="previewStyles.slideBg">
        <div class="themes-editor__h themes-editor__h_1" :style="previewStyles.h"></div>
        <div class="themes-editor__h themes-editor__h_2" :style="previewStyles.h"></div>
        <div class="themes-editor__stroke themes-editor__stroke_1" :style="previewStyles.objectStroke"></div>
        <div class="themes-editor__stroke themes-editor__stroke_2" :style="previewStyles.objectStroke"></div>
        <div class="themes-editor__text themes-editor__text_1" :style="previewStyles.text"></div>
        <div class="themes-editor__text themes-editor__text_2" :style="previewStyles.text"></div>
        <div class="themes-editor__text themes-editor__text_3" :style="previewStyles.text"></div>
        <div class="themes-editor__shape-border" :style="previewStyles.objectStroke">
          <div class="themes-editor__shape" :style="previewStyles.objectBg"></div>
        </div>
        <div class="themes-editor__text themes-editor__text_1 themes-editor__text_s"
             :style="previewStyles.text"></div>
        <div class="themes-editor__text themes-editor__text_2 themes-editor__text_s"
             :style="previewStyles.text"></div>
        <div class="themes-editor__text themes-editor__text_3 themes-editor__text_s"
             :style="previewStyles.text"></div>
        <div class="themes-editor__table">
          <div class="themes-editor__table-r" :style="previewStyles.tableAcc">
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-stroke" :style="previewStyles.tableStroke"></div>
          </div>
          <div class="themes-editor__table-r themes-editor__table-r_odd" :style="previewStyles.tableBg1">
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-stroke" :style="previewStyles.tableStroke"></div>
          </div>
          <div class="themes-editor__table-r themes-editor__table-r_even" :style="previewStyles.tableBg2">
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-stroke" :style="previewStyles.tableStroke"></div>
          </div>
          <div class="themes-editor__table-r themes-editor__table-r_odd" :style="previewStyles.tableBg1">
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
            <div class="themes-editor__table-text" :style="previewStyles.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropThemeSelect from "@/components/editor/property/PropThemeSelect";
import PropColor from "@/components/editor/property/PropColor";

export default {
  name: 'Themes_editor',
  components: {
    prop_theme_select: PropThemeSelect,
    prop_color: PropColor
  },
  data: function() {
    return {
      theme: undefined,
      property: 'acc1l50',
      selectedOption: undefined,
      options: [
        {
          v: 'Slide background',
          k: 'slideBg'
        },
        {
          v: 'Shape background',
          k: 'objectBg'
        },
        {
          v: 'Stroke',
          k: 'objectStroke'
        },
        {
          v: 'Text',
          k: 'text'
        },
        {
          v: 'Headers',
          k: 'h'
        },
        {
          v: 'Table bg odd',
          k: 'tableBg1'
        },
        {
          v: 'Table bg even',
          k: 'tableBg2'
        },
        {
          v: 'Table header',
          k: 'tableAcc'
        },
        {
          v: 'Table stroke',
          k: 'tableStroke'
        }
      ]
    };
  },
  computed: {
    activePalette: function() {
      var ref;
      return (ref = this.theme) != null ? ref.palette : 0;
    },
    dark: {
      get: function() {
        var ref;
        return (ref = this.activePalette) != null ? ref.isDark : 0;
      },
      set: function(val) {
        this.$refs.theme_color.invertPalette();
      }
    },
    previewStyles: function() {
      var styles;
      if (this.theme == null) {
        return;
      }
      styles = {};
      Object.keys(this.theme.colorOptions).forEach((k) => {
        var color;
        color = this.theme.palette[this.theme.colorOptions[k]];
        return styles[k] = {
          'background-color': `rgb(${color})`
        };
      });
      return styles;
    }
  },
  methods: {
    editTheme: function(id) {
      this.theme = this.$store.state.themes[id];
    },
    setNewPalette: function(palette) {
      this.theme.palette = palette;
    },
    updateProp: function(prop, val) {
      this.theme.palette[prop] = val;
      switch (prop) {
        case 'acc1l50':
        case 'acc2l50':
        case 'mut1l50':
        case 'mut2l50':
          this.$refs.theme_color.recalc(prop.replace('l50', ''));
      }
    },
    changeProperty: function(val) {
      if (!this.selectedOption) {
        this.property = val;
      } else {
        this.theme.colorOptions[this.selectedOption] = val;
        this.selectedOption = undefined;
      }
    }
  }
};
</script>

<style lang="scss">
.themes-editor{
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &__header{
    box-sizing: border-box;
    background-color: #333638;
    width: 100%;
    height: 51px;
    padding: 9px 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    .property__name{
      display: none;
    }
    .property{
      padding: 0 0;
      width: 188px;
      height: 40px;
    }
  }

  &__theme-id{
    color: #fff;
  }

  &__instruments{
    color: #fff;
    box-sizing: border-box;
    background-color: #2a2c2d;
    height: calc(100% - 51px);
    width: 462px;
    padding: 27px;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    .property__tabs{
      padding: 10px 7px;
      &:before{
        bottom: 0;
      }
    }
  }

  &__options{
    font-size: 14px;
    &-list{
      &-item{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &_active{
          outline: 1px solid #fff8;
          outline-offset: 3px;
        }
        &-preview{
          width: 35px;
          height: 26px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
    }
  }

  &__dark-option{
    input{
      margin-right: 10px;
    }
  }

  &__preview-wrapper{
    box-sizing: border-box;
    background-color: #1d1e1f;
    height: calc(100% - 51px);
    width: calc(100% - 462px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  &__save-btn{
    color: #ddd;
    cursor: pointer;
    &:hover{
      color: #fff;
    }
  }
  &__preview{
    position: relative;
    width: 65%;
    height: 80%;
    padding: 70px 60px;
    box-sizing: border-box;
  }
  &__h{
    height: 5.6%;
    position: absolute;
    &_1{
      width: 42.5%;
      top: 10.6%;
      left: 7.7%;
    }
    &_2{
      width: 30%;
      top: 20.7%;
      left: 7.7%;
    }
  }
  &__stroke{
    height: 1.2%;
    position: absolute;
    width: 42.5%;
    &_1{
      top: 31.1%;
      left: 7.7%;
    }
    &_2{
      top: 57.4%;
      left: 7.7%;
    }
  }
  &__text{
    height: 2.7%;
    left: 7.7%;
    position: absolute;
    &_1{
      width: 35.8%;
      top: 38.5%;
    }
    &_2{
      width: 28%;
      top: 43.6%;
    }
    &_3{
      width: 30%;
      top: 48.7%;
    }
    &_s{
      left: 58.9%;
      transform-origin: left center;
      transform: scale(0.7, 1);
    }
  }
  &__shape-border{
    position: absolute;
    box-sizing: border-box;
    width: 41.6%;
    height: 27.5%;
    left: 53%;
    top: 31%;
    padding: 1.5%;
  }
  &__shape{
    width: 100%;
    height: 100%;
  }
  &__table{
    position: absolute;
    left: 7.7%;
    width: 86.9%;
    top: 67.6%;
    height: 24.8%;
    display: flex;
    flex-direction: column;
    &-r{
      position: relative;
      width: 100%;
      height: 25%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    &-stroke{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
    }
  }
  &__table-text{
    position: relative;
    height: 0.5rem;
    width: 25%;
  }
}
</style>
