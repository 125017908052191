<template>
    <div class="property property_type_order">
        <div class="property__name" v-if="property.title">
            {{ $t(property.title) }}
        </div>
        <div class="property__btns">
            <div class="property__btn" title="send to back" v-on:click="move('up')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-792 -15)" fill="none" fill-rule="evenodd">
                        <path fill="#7B7E7F" opacity=".5" d="M802.099 20.987l8.037 4.067-8.041 4.047L794 25.08z"/>
                        <path fill="#FFF" d="M802.099 16.987l8.037 4.067-8.041 4.047L794 21.08z"/>
                    </g>
                </svg>
                <div class="property__btn-label">
                    {{ $t('up') }}
                </div>
            </div>
            <div class="property__btn" title="down" v-on:click="move('down')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-831 -15)" fill="none" fill-rule="evenodd">
                        <path fill="#FFF" d="M841.099 20.987l8.037 4.067-8.041 4.047L833 25.08z"/>
                        <path fill="#7B7E7F" opacity=".803" d="M841.099 16.987l8.037 4.067-8.041 4.047L833 21.08z"/>
                    </g>
                </svg>
                <div class="property__btn-label">
                    {{ $t('down') }}
                </div>
            </div>
            <div class="property__btn" title="up" v-on:click="move('toFront')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-869 -13)" fill="none" fill-rule="evenodd">
                        <path fill="#7B7E7F" opacity=".8" d="M879.099 22.987l8.037 4.067-8.041 4.047L871 27.08z"/>
                        <path fill="#7B7E7F" opacity=".8" d="M879.099 18.987l8.037 4.067-8.041 4.047L871 23.08z"/>
                        <path fill="#FFF" d="M879.099 14.987l8.037 4.067-8.041 4.047L871 19.08z"/>
                    </g>
                </svg>
                <div class="property__btn-label">
                    {{ $t('toFront') }}
                </div>
            </div>
            <div class="property__btn" title="send to front" v-on:click="move('toBack')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-908 -13)" fill="none" fill-rule="evenodd">
                        <path fill="#FFF" d="M918.099 22.987l8.037 4.067-8.041 4.047L910 27.08z"/>
                        <path fill="#7B7E7F" opacity=".8" d="M918.099 18.987l8.037 4.067-8.041 4.047L910 23.08z"/>
                        <path fill="#7B7E7F" opacity=".8" d="M918.099 14.987l8.037 4.067-8.041 4.047L910 19.08z"/>
                    </g>
                </svg>
                <div class="property__btn-label">
                    {{ $t('toBack') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// `import prop_base from '../prop_base/prop_base.coffee'`
/* eslint-disable */
export default {
    name: 'prop_order',
    // mixins:[prop_base]
    props: ['property'],
    methods: {
        move: function (dir) {
            // @$root.logUserAction('propOrder', "moveObjectOrder #{dir} #{indexes}")
            // indexes = @targets.map((t)->t.vueObj.index)
            // @$store.commit('moveObjects',{
            //   indexes: @targets.map((t)->t.vueObj.index)
            //   direction: dir
            // })
            this.$parent.updateProp('order', dir);
        }
    }
};

</script>

<style lang="scss">
.property_type_order {
    position: relative;
    padding: 10px 20px;

    .property__btns {
        position: relative;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        margin: 0 -10px;
    }

    .property__btn {
        // height: 20px;
        width: 38px;
        margin-right: 10px;
        position: relative;
        display: block;
    }

    svg {
        position: relative;
        display: block;
        width: auto;
        margin: 0 auto;
    }

    .property__btn-label {
        position: relative;
        font-size: 9px;
        text-align: center;
        opacity: .7;
    }

}
</style>

<i18n>
{
    "en": {
        "Order": "Order",
        "up": "Up",
        "down": "Down",
        "toFront": "To front",
        "toBack": "To back"
    },
    "ru": {
        "Order": "Порядок",
        "up": "Вверх",
        "down": "вниз",
        "toFront": "Вперед",
        "toBack": "Назад"
    }
}
</i18n>