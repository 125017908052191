/* eslint-disable */

export default {
  name: 'Polar_mixin',
  methods: {
    power2(val){
      return Math.pow(val, 2);
    },

    // Перевод декартовых координат
    // (относительно центра объекта) в полярные и наоборот
    decart2polarCoords(decartCoords){
      const angle = Math.atan2(decartCoords.y,decartCoords.x);
      const fi = this.rad2deg(angle);

      return {
        r: Math.sqrt(this.power2(decartCoords.x) + this.power2(decartCoords.y)),
        fi
      };
    },

    polar2decartCoords(polarCoords){
      return {
        x: polarCoords.r * Math.cos(this.deg2rad(polarCoords.fi)),
        y: polarCoords.r * Math.sin(this.deg2rad(polarCoords.fi))
      };
    },

    rad2deg(rad){
      return (rad * 180) / Math.PI;
    },

    deg2rad(deg){
      return (deg * Math.PI) / 180;
    },

    normalizeDegrees(angle){
      if (angle === 0) {
        return angle;
      }
      let distToPositive = 0;
      if (angle < 0) {
        distToPositive = 360 - (360*parseInt(angle / 360));
      }
      angle += distToPositive;
      angle -= 360*parseInt(angle / 360);
      return angle;
    },

    gradCoords(cx, cy, ang){

      let botX, botY, secQuadrant, swap, topX, topY;
      ang = this.normalizeDegrees(ang);

      // Achtung! Здесь много тригонометрии и магии
      // Независимо от угла, все вычисления производим в первой четверти

      // Если угол больше 180, в конце нужно обменять точки
      if (ang > 180) {
        ang -= 180;
        swap = true;
      }

      // Переворачиваем оси на втором квадранте
      if (ang > 90) {
        secQuadrant = true;
        ang = ang - 90;
        [cx, cy] = [cy, cx];
      }

      // См. картинку в исходниках
      const gradAngle = this.deg2rad(ang);
      const diagAngle = Math.atan(cy/cx);

      const angle3 = (Math.PI/2) - gradAngle - diagAngle;

      const diag = Math.sqrt((4*(cx*cx)) + (4*(cy*cy)));

      const c = Math.sin(angle3)*diag;

      const dx = (Math.cos(gradAngle) * c) / 2;
      const dy = (Math.sin(gradAngle) * c) / 2;

      // 4 координаты, они в разном порядке присваиваются далее

      const c1 = (cx*2) - dx;
      const c2 = (cy*2) + dy;
      const c3 = 0 + dx;
      const c4 = 0 - dy;

      // Порядок присвоения - результат неосознанного программирования
      // Для первого квадранта
      if (!secQuadrant) {
        topX = c1;
        topY = c4;
        botX = c3;
        botY = c2;

        // Для второго квадранта
      } else {
        topX = c2;
        topY = c1;
        botX = c4;
        botY = c3;
      }

      // Для >180
      if (swap) {
        [topX, botX] = [botX, topX];
        [topY, botY] = [botY, topY];
      }

      return [topX, topY, botX, botY];
    }
  }
};
