<template>
    <div
        class="presentation"
        @mousedown="onmousedown($event)"
        v-if="slides!=undefined"
        :style="styles"
        :data-rdata="getRendererData()"
        :class="{'presentation_boxed': boxed}"
    >
        <div class="presentation__scale" :style="scaleStyles">
            <div class="bg-trimmer" :style="bgTrimmerStyles">
                <div class="backgrounds" :style="bgStyles">
                    <slide-background
                        v-for="(slide, bgIndex) in slides"
                        :key="slide.id"
                        :bgIndex="bgIndex"
                        :active="slide.active && !currentPage.isRenderer"
                        :order="orderMap[bgIndex]"
                        :styles="slide.bg"
                        :boxed="presentation.boxed"
                        :slideEars="slideEars">
                    </slide-background>
                </div>
            </div>
            <div class="slides-trimmer" :style="trimmerStyles">
                <div class="slides" :style="slideStyles">
                    <slide
                        v-for="(slide, slideIndex) in slides"
                        :key="slide.id"
                        :slideIndex="slideIndex"
                        :contentTrimmerStyles="contentTrimmerStyles"
                        :bgAndContentDifference="bgAndContentDifference"
                        :slideContent="slide"
                        :order="orderMap[slideIndex]"
                        :active="slide.active && !currentPage.isRenderer">
                    </slide>
                    <div class="slideEars" v-if="slideEars && !boxed">
                        <template v-if="slideEars.hor > 0">
                            <div class="slideEar slideEar_left"
                                 :style="{'width': slideEars.hor + '%', 'left': -slideEars.hor + '%'}">
                            </div>
                            <div class="slideEar slideEar_right"
                                 :style="{'width': slideEars.hor + '%', 'right': -slideEars.hor + '%'}">
                            </div>
                        </template>
                        <template v-if="slideEars.ver > 0">
                            <div class="slideEar slideEar_top"
                                 :style="{'height': slideEars.ver + '%', 'top': -slideEars.ver + '%'}">
                            </div>
                            <div class="slideEar slideEar_bottom"
                                 :style="{'height': slideEars.ver + '%', 'bottom': -slideEars.ver + '%'}">
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <meta-tool
            v-if="!currentPage.isRenderer"
            :mouseDown="mousedown.left || mousedown.right"
            :mouseEvent="mouseEvent"
            :presentationOffset="presentationOffset"
            :scale="scale"
            :zeroPointOffset="zeroPointOffset"
            :groups="groups"
            ref="metaTool">
        </meta-tool>
        <screenshot_generator
            ref="screenshot_generator"
            :presentationOffset="presentationOffset"
            :presentation="presentation"
            :boxed="boxed || flags.pdfGeneratorMode"
            :vw="vw"
            :vh="vh"
            :slideEars="slideEars">
        </screenshot_generator>
    </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import _ from "lodash";

// import canvas_bg from '../../../../components/canvas_bg/canvas_bg.coffee';

import Slide from "@/components/editor/Slide";
import MetaTool from "@/components/editor/MetaTool";
import SlideBackground from "@/components/editor/SlideBackground";
import ScreenshotGenerator from "@/components/editor/ScreenshotGenerator";

export default {
    inject: ['currentPage'],
    name: "PresentationComponent",
    components: {
        slideBackground: SlideBackground,
        // canvasBg: canvas_bg,
        slide: Slide,
        metaTool: MetaTool,
        screenshot_generator: ScreenshotGenerator
    },
    props: ['navigate', 'bgScale', 'vw', 'vh'],
    data () {
        return {
            // Ловушка для события нажатой мыши
            mousedown: {
                left: false,
                right: false
            },
            mouseEvent: {},
            wasMountedEvent: false,
            ready: false
        };
    },
    watch: {
        // Следим за отпусканием кнопки мыши
        // Необходимо для правильной работы кнопок за пределами $el компонента
        appMousedown () {
            if (this.currentPage.isRenderer) {
                return;
            }
            if (!this.appMousedown.left) {
                this.mousedown.left = false;
            }
        }
    },
    computed: {
        flags () {
            return this.$store.state.flags;
        },
        // Ссылки на состояния приложения
        appMousedown () {
            if (this.currentPage.isRenderer) {
                return;
            }
            return {
                left: this.currentPage.mousePressed.left,
                right: this.currentPage.mousePressed.right
            };
        },
        rawCoords () {
            return {
                x: this.currentPage.rawCoords.x,
                y: this.currentPage.rawCoords.y
            };
        },
        scale () {
            return this.currentPage.scale;
        },
        presentation () {
            return this.$store.state.presentation;
        },
        styles () {
            if (this.currentPage.isRenderer) {
                return;
            }
            return {
                'transform': 'translate(' + this.navigate.tx + 'px, ' + this.navigate.ty + 'px)'
            };
        },
        size () {
            return this.presentation.size;
        },
        slides () {
            return this.presentation.slides;
        },
        orderMap () {
            if (this.slides == null) {
                return [];
            }
            let activeFound = false;
            return this.presentation.slides.map(function (slide) {
                if (slide.active) {
                    activeFound = true;
                    return 'active';
                }
                if (activeFound) {
                    return 'next';
                } else {
                    return 'prev';
                }
            });
        },
        currentActive () {
            return this.$store.getters.currentActiveSlide;
        },
        groups () {
            let ref, ref1;
            return (ref = this.$store.state.presentation) != null ? (ref1 = ref.slides[this.currentActive]) != null ? ref1.groups : 0 : 0;
        },
        // Смещение координат начала презентации относительно окна
        presentationOffset () {
            let offsetX, offsetY;
            // Achtung!!!
            // Бесполезные строки кода ниже -
            // очень даже полезные
            // Не удалять
            // Привязывает флаги событий,
            // так как нельзя реактивно привязаться к $el
            this.wasMountedEvent;
            this.currentPage.resizeFlag;
            this.animationRunning;
            if (!!this.$el?.getBoundingClientRect) {
                offsetX = this.$el.getBoundingClientRect().left;
                offsetY = this.$el.getBoundingClientRect().top;
            } else {
                offsetX = 0;
                offsetY = 0;
            }
            return {
                x: offsetX,
                y: offsetY
            };
        },
        slideEars () {
            if (!this.ready) {
                return;
            }
            if (this.currentPage.isRenderer) {
                return;
            }
            this.currentPage.resizeFlag;

            // ToDo: исправление обращения может в будущем нужно будет вернуть старый вариант...
            // const presentationRect = this.$el.getBoundingClientRect();
            // const prw = presentationRect.width * this.scale;
            // const prh = presentationRect.height * this.scale;

            let prw, prh;
            if (!!this.$el?.getBoundingClientRect) {
                prw = this.$el.getBoundingClientRect().width * this.scale;
                prh = this.$el.getBoundingClientRect().height * this.scale;
            } else {
                prw = 0;
                prh = 0;
            }


            const bgw = prw * this.bgScale;
            const bgh = prh * this.bgScale;
            const slw = this.size.w * this.scale;
            const slh = this.size.h * this.scale;
            return {
                ver: ((bgh / slh - 1) * 100) / 2,
                hor: ((bgw / slw - 1) * 100) / 2
            };
        },
        zeroPointOffset () {
            let offsetX, offsetY, slide;
            if ((this.$store.state.presentation.slides != null)) {
                slide = this.$store.state.presentation.slides[this.currentActive];
            }
            if (slide !== undefined && slide.vueObj.$el !== undefined) {
                offsetX = slide.vueObj.$el.getBoundingClientRect().left;
                offsetY = slide.vueObj.$el.getBoundingClientRect().top;
            } else {
                offsetX = 0;
                offsetY = 0;
            }
            return {
                x: Math.round(offsetX - this.presentationOffset.x),
                y: Math.round(offsetY - this.presentationOffset.y)
            };
        },
        slideStyles () {
            let left, top, transform;
            if (this.size != null) {
                if (this.currentPage.isRenderer) {
                    transform = 'none';
                    top = '0';
                    left = '0';
                } else {
                    transform = 'translate3D(-50%, -50%, 0)';
                    top = '50%';
                    left = '50%';
                }
                return {
                    'width': this.size.w + 'px',
                    'height': this.size.h + 'px',
                    top,
                    left,
                    transform
                };
            } else {
                return {};
            }
        },
        bgTrimmerStyles () {
            let scale;
            if (this.boxed) {
                return this.trimmerStyles;
            }
            if (this.currentPage.isRenderer) {
                scale = 1;
            } else {
                scale = this.bgScale;
            }

            return {
                'transform': 'translate3D(-50%, -50%, 0) scale(' + this.bgScale + ')',
                'top': '50%',
                'left': '50%'
            };
        },
        bgStyles () {
            if (this.boxed) {
                return this.slideStyles;
            } else {
                return {};
            }
        },
        scaleStyles () {
            if (this.currentPage.isRenderer) {
                return;
            }
            return {
                'transform': 'scale(' + this.scale + ') translate3d(0, 0, 0)'
            };
        },
        selfSizes () {
            return {
                width: Math.round(this.bgScale * this.vw),
                height: Math.round(this.bgScale * this.vh)
            };
        },
        trimmerStyles () {
            // Ширина и высота должны быть четными
            // иначе возникает рассинхрон в отображении
            // контента слайда
            if (this.boxed) {
                return this.slideStyles;
            }
            let w = this.selfSizes.width;
            let h = this.selfSizes.height;
            if (w % 2 !== 0) {
                w--;
            }
            if (h % 2 !== 0) {
                h--;
            }
            return {
                width: w + 'px',
                height: h + 'px',
                top: Math.round(this.vh / 2) + 'px',
                left: Math.round(this.vw / 2) + 'px'
            };
        },
        contentTrimmerStyles () {
            if (this.currentPage.isRenderer) {
                return {
                    top: '0px',
                    left: '0px',
                    width: this.size.w + 'px',
                    height: this.size.h + 'px'
                };
            }
            const w = this.selfSizes.width;
            const h = this.selfSizes.height;
            return {
                top: (this.size.h - h) / 2 + 'px',
                left: (this.size.w - w) / 2 + 'px',
                width: w + 'px',
                height: h + 'px'
            };
        },
        bgAndContentDifference () {
            const w = this.selfSizes.width;
            const h = this.selfSizes.height;

            return {
                x: w / this.size.w,
                y: h / this.size.h
            };
        },
        boxed () {
            return this.presentation.boxed;
        }
    },
    mounted () {
        const that = this;
        this.$store.commit('setActiveSlide', 0);
        _.delay(function () {
            // Задержка переключения флага для завершения прорисовки элементов
            that.wasMountedEvent = true;
        }, 10);
        Vue.nextTick(function () {
            this.ready = true;
        }, this);
    },
    methods: {
        onmousedown (e) {
            if (e.which === 1) {
                this.mousedown.left = true;
                this.mouseEvent = e;
            }
        },
        getRendererData () {
            let res = false;
            if (this.currentPage.isRenderer) {
                res = {
                    animation: this.presentation.animation
                };
            }

            return JSON.stringify(res);
        }
    }
};
</script>

<style lang="scss">
.presentation {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: visible;

    &_boxed {
        .bg-trimmer {
            transform: translate(-50%, -50%);
        }
    }
}

.presentation__scale {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backface-visibility: hidden;
}

.slides-trimmer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.bg-trimmer {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

.backgrounds {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;

    .slide-bg__screen {
        display: block;
    }
}

.slide-bg {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    // background: #fff;
    backface-visibility: hidden;
    overflow: hidden;

    & &__image, & &__color {
        position: absolute;
        display: block;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        backface-visibility: hidden;
    }

    & &__color {
        top: .7px;
        right: .7px;
        bottom: .7px;
        left: .7px;
    }

    & &__video {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            object-fit: cover;
        }
    }
}

.slides {
    position: relative;
    overflow: visible;
    z-index: 1;
}

.slideEar {
    pointer-events: none;
    box-sizing: border-box;
    background-color: #888;
    opacity: .25;
    position: absolute;
    z-index: 9999;

    &_top {
        width: 100%;
        left: 0;
    }

    &_bottom {
        width: 100%;
        left: 0;
    }

    &_left {
        height: 100%;
        top: 0;
    }

    &_right {
        height: 100%;
        top: 0;
    }
}
</style>
