<template>
    <div class="rulers">
        <div v-show="showGuides" class="rulers__shadow-guides">
          <div v-if="shadowGuide.x !== false"
              class="rulers__shadow-guide rulers__shadow-guide_x"
              :style="{left: shadowGuide.x + 'px'}"></div>
          <div v-if="shadowGuide.y !== false"
              class="rulers__shadow-guide rulers__shadow-guide_y"
              :style="{top: shadowGuide.y + 'px'}"></div>
        </div>
        <div class="ruler ruler__hor"
            @click.right="currentPage.menuTarget='ruler'"
            @click.left="addGuide($event, 'x')"
            @mousewheel="mousewheel($event, 'tx')"
            @mouseenter="setShadowGuide($event, 'x')"
            @mousemove="setShadowGuide($event, 'x')"
            @mouseleave="hideShadowGuide('x')">
          <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20000 24"
              :style="svgStyle"
              data-html2canvas-ignore="data-html2canvas-ignore">
            <line x1="10000" y1="0" x2="20000" y2="0" :style="horParams2"></line>
            <line x1="10000" y1="0" x2="0" y2="0" :style="horParams2"> </line>
            <line x1="10000" y1="0" x2="20000" y2="0" :style="horParams"></line>
            <line x1="10000" y1="0" x2="0" y2="0" :style="horParams"></line>
          </svg>
          <div class="scale-numbers" :style="scaleNumbersStylesHor">
            <div v-for="(number, index) in scaleNumbersHor"
                :key="index"
                class="scale-numbers__item"
                :style="number[1]">{{ number[0] }}</div>
          </div>
        </div>
        <div class="ruler ruler__ver"
            @click.right="currentPage.menuTarget='ruler'"
            @click.left="addGuide($event, 'y')"
            @mousewheel="mousewheel($event, 'ty')"
            @mouseenter="setShadowGuide($event, 'y')"
            @mousemove="setShadowGuide($event, 'y')"
            @mouseleave="hideShadowGuide('y')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20000" :style="svgStyle">
            <line x1="0" y1="10000" x2="0" y2="20000" :style="verParams2"></line>
            <line x1="0" y1="10000" x2="0" y2="0" :style="verParams2"></line>
            <line x1="0" y1="10000" x2="0" y2="20000" :style="verParams"></line>
            <line x1="0" y1="10000" x2="0" y2="0" :style="verParams"></line>
          </svg>
          <div class="scale-numbers" :style="scaleNumbersStylesVer">
            <div v-for="(number, index) in scaleNumbersVer"
                :key="index"
                class="scale-numbers__item"
                :style="number[1]">{{ number[0] }}</div>
          </div>
        </div>
        <div v-show="showGuides" class="rulers__guides">
            <div
                v-for="(guide, guideIndex) in scaledGuides.x"
                :key="`x-${guideIndex}`"
                class="rulers__guide rulers__guide_x"
                :style="{left: guide + 'px'}"
                @mousedown="moveGuideIndex.x = guideIndex"
            >
                <div v-if="guideVal.x !== false && moveGuideIndex.x == guideIndex && !deleteAreaCondition.x"
                    class="rulers__guide-val rulers__guide-val_x">
                    {{ guideVal.x }}
                </div>
            </div>
            <div v-for="(guide, guideIndex) in scaledGuides.y"
                :key="`y-${guideIndex}`"
                class="rulers__guide rulers__guide_y"
                :style="{top: guide + 'px'}"
                @mousedown="moveGuideIndex.y = guideIndex"
            >
                <div v-if="guideVal.y !== false && moveGuideIndex.y == guideIndex && !deleteAreaCondition.y"
                    class="rulers__guide-val rulers__guide-val_y">
                    {{ guideVal.y }}
                </div>
            </div>
        </div>
        <div class="rulers__delete-area"
            :class="{'rulers__delete-area_active': deleteAreaCondition.x || deleteAreaCondition.y, 'rulers__delete-area_visible': moveGuideIndex.x !== false || moveGuideIndex.y !== false}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16">
            <g stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <path d="M1.000001 4.000002h12.000006M11.666673 4.000002v9.333338c0 .7363804-.5969543 1.333334-1.333334 1.333334h-6.66667c-.73638043 0-1.33333406-.5969543-1.33333406-1.333334 0 0 0 0 0 0V4.000002m2.000001 2e-7V2.666668v2e-7c-1.1e-7-.73638037.59695363-1.333334 1.333334-1.333334h2.666668c.73638031-3e-8 1.33333394.59695363 1.33333394 1.333334v1.333334M5.66667 7.333337v4.000002M8.333338 7.333337v4.000002" />
            </g>
          </svg>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import UiColors from '@/mixins/editor/UiColors';

export default {
    name: 'RulersComponent',
    inject: ['currentPage'],
    mixins: [UiColors],
    props: {
        scale: {
            type: Number,
            default: 1
        },
        mouseCoords: {
            type: Object,
            default: null
        },
        mousePressed: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            styleProps: {
                'stroke-color': '#aaa',
                'stroke-width': 11
            },

            styleProps2: { // стили второстепенных делений
                'stroke-color': '#aaa',
                'stroke-width': 4
            },

            svgStyle: {
                'line-height': 0
            },

            svgRefresh: false,
            rulerWidth: 25,

            shadowGuide: {
                x: false,
                y: false
            },

            moveGuideIndex: {
                x: false,
                y: false
            }
        };
    },

    watch: {
        mouseCoords: {
            deep: true,
            handler(val) {
                if(this.moveGuideIndex.x !== false) {
                    this.moveGuide('x', this.moveGuideIndex.x, val.x);
                }
                if(this.moveGuideIndex.y !== false) {
                    this.moveGuide('y', this.moveGuideIndex.y, val.y);
                }
            }
        },
        mousePressed: {
            deep: true,
            handler(val) {
                if(!val.left) {
                    if(this.deleteAreaCondition.x) {
                        this.deleteGuide('x', this.moveGuideIndex.x);
                        this.moveGuideIndex.x = false;
                        return;
                    }
                    if(this.deleteAreaCondition.y) {
                        this.deleteGuide('y', this.moveGuideIndex.y);
                        this.moveGuideIndex.y = false;
                        return;
                    }

                    if(this.moveGuideIndex.x !== false) {
                        this.moveGuideIndex.x = false;
                        this.$store.commit('saveHistory', 'moved x guide');
                    }
                    if(this.moveGuideIndex.y !== false) {
                        this.moveGuideIndex.y = false;
                        this.$store.commit('saveHistory', 'moved y guide');
                    }
                }
            }
        }
    },

    computed: {

        presentation() {
            return this.$store.state.presentation;
        },

        showGuides() {
            return !this.currentPage.scaling && this.currentPage.showRulers && this.currentPage.showGuides;
        },

        guides() {
            return this.$store.state.activeSlide.guides;
        },

        guideVal() {
            let x = undefined;
            let y = undefined;
            if(this.moveGuideIndex.x !== false) {
                x = this.guides.x[this.moveGuideIndex.x];
            }
            if(this.moveGuideIndex.y !== false) {
                y = this.guides.y[this.moveGuideIndex.y];
            }

            return { x, y };
        },

        gap() {
            switch(true) {

                case 0 <= this.scale && this.scale <= 0.5:
                    return 200;
                    break;

                case 0.5 < this.scale && this.scale <= 1:
                    return 100;
                    break;

                case 1 < this.scale && this.scale <= 2:
                    return 50;
                    break;

                case 2 < this.scale && this.scale <= 5:
                    return 20;
                    break;
            }
            return 10;
        },

        gap2() { // второстепенные деления
            switch(true) {
                case 0 <= this.scale && this.scale <= 0.5:
                    return 20;
                    break;

                case 0.5 < this.scale && this.scale <= 1:
                    return 10;
                    break;

                case 1 < this.scale && this.scale <= 2:
                    return 5;
                    break;

                case 2 < this.scale && this.scale <= 5:
                    return 2;
                    break;
            }
            return 1;
        },

        scaledGap() {
            const g = (this.gap * this.scale) - 1;
            return g;
        },

        scaledGap2() { // второстепенные деления
            const g = (this.gap2 * this.scale) - 1;
            return g;
        },

        scaleNumbersFrequency() {
            const m = 1;
            return m;
        },

        scaleNumbersHor() {
            const n = [[
                0,
                'left:0'
            ]];
            for(let i = 1; i <= 100; i++) {
                n.push([
                    i * this.gap * this.scaleNumbersFrequency,
                    'left: ' + (i * (this.scaledGap + 1) * this.scaleNumbersFrequency) + 'px'
                ]);
                n.push([
                    -i * this.gap,
                    'left: -' + (i * (this.scaledGap + 1) * this.scaleNumbersFrequency) + 'px'
                ]);
            }
            //console.log n
            return n;
        },

        tx() {
            return (this.currentPage.navigate.tx + this.rulerWidth) - this.offsetX;
        },

        ty() {
            return (this.currentPage.navigate.ty + this.rulerWidth) - this.offsetY;
        },

        scaleNumbersStylesHor() {
            const s =
                { 'transform': 'translateX(' + (this.tx) + 'px)' };
            return s;
        },

        scaleNumbersVer() {
            const n = [[
                0,
                'top:0'
            ]];
            for(let i = 1; i <= 100; i++) {
                n.push([
                    i * this.gap * this.scaleNumbersFrequency,
                    'top: ' + (i * (this.scaledGap + 1) * this.scaleNumbersFrequency) + 'px'
                ]);
                n.push([
                    -i * this.gap,
                    'top: -' + (i * (this.scaledGap + 1) * this.scaleNumbersFrequency) + 'px'
                ]);
            }
            //console.log n
            return n;
        },

        scaleNumbersStylesVer() {
            const s =
                { 'transform': 'translateY(' + (this.ty) + 'px)' };
            return s;
        },


        offsetX() {
            this.currentPage.resizeFlag;
            let ox = 0;
            if(this.currentPage.presentation?.size != null) {
                ox = -Math.round((this.currentPage.$refs.presentation.zeroPointOffset.x));
            }
            //console.log ox
            return ox;
        },

        offsetY() {
            this.currentPage.resizeFlag;
            let oy = 0;
            if(this.currentPage.presentation?.size != null) {
                oy = -Math.round((this.currentPage.$refs.presentation.zeroPointOffset.y));
            }
            //console.log ox
            return oy;
        },

        horParams() {
            // этот лютый костыль нужен, чтобы отрицательная часть шкалы отображалась
            this.svgRefresh = !this.svgRefresh;
            this.svgStyle =
                { 'line-height': +this.svgRefresh + 'px' };
            // конец костыля

            const styles = {
                'stroke': this.styleProps['stroke-color'],
                'stroke-width': this.styleProps['stroke-width'],
                'stroke-linecap': 'butt',
                'stroke-dasharray': 'stroke-dash',
                'stroke-dasharray': '1 ' + this.scaledGap,
                'transform': 'translateX(' + (this.tx) + 'px)',
                'stroke-dashoffset': 0
            };
            return styles;
        },

        horParams2() {
            const s = _.clone(this.horParams);
            s['stroke'] = this.styleProps2['stroke-color'];
            s['stroke-width'] = this.styleProps2['stroke-width'];
            s['stroke-dasharray'] = '1 ' + this.scaledGap2;
            s['transform'] = 'translateX(' + (this.tx) + 'px)';
            return s;
        },

        verParams() {
            const styles = _.clone(this.horParams);
            styles['transform'] = 'translateY(' + (this.ty) + 'px)';
            return styles;
        },

        verParams2() {
            const styles = _.clone(this.horParams2);
            styles['transform'] = 'translateY(' + (this.ty) + 'px)';
            return styles;
        },

        scaledGuides() {
            const x = this.guides.x.map(val => {
                val *= this.scale;
                val += this.tx;
                return Math.round(val);
            });
            const y = this.guides.y.map(val => {
                val *= this.scale;
                val += this.ty;
                return Math.round(val);
            });

            return { x, y };
        },

        deleteAreaCondition() {
            const x = this.scaledGuides.x[this.moveGuideIndex.x] < this.rulerWidth;
            const y = this.scaledGuides.y[this.moveGuideIndex.y] < this.rulerWidth;
            return { x, y };
        }
    },
    methods: {
        calcThemeStyles() {
            return this.componentStyles = {
                '.rulers .ruler': {
                    background: this.getColor('rulerBg')
                },
                '.rulers:after': {
                    background: this.getColor('rulersAfterBg')
                },
                '.scale-numbers__item': {
                    color: this.getColor('rulerColor')
                }
            };
        },

        mousewheel(e, type) {
            const diff = e.deltaY;
            this.currentPage.navigate[type] -= diff;
            this.currentPage.checkNavigateOverflow();
        },

        setShadowGuide(evt, axis) {
            if(!this.showGuides) {
                return;
            }

            switch(axis) {
                case 'x':
                    if(this.deleteAreaCondition.y) {
                        return;
                    }
                    break;
                case 'y':
                    if(this.deleteAreaCondition.x) {
                        return;
                    }
                    break;
            }

            let c = evt[`client${axis.toUpperCase()}`];
            c -= this.getSelfOffsets()[axis];
            this.shadowGuide[axis] = c;
        },

        hideShadowGuide(axis) {
            this.shadowGuide[axis] = false;
        },

        addGuide(evt, axis) {
            this.currentPage.checkBgEditingCondition();
            if(!this.showGuides) {
                return;
            }
            let c = evt[`client${axis.toUpperCase()}`];
            c -= this.getSelfOffsets()[axis];
            c -= this[`t${axis}`];
            const val = Math.round(c / this.scale);

            const guidesArr = this.guides[axis];
            if(!guidesArr.includes(val)) {
                guidesArr.push(val);
                this.$store.commit('saveHistory', `added ${axis} guide`);
            }
        },

        moveGuide(axis, index, val) {
            this.currentPage.checkBgEditingCondition();
            if(!this.showGuides) {
                return;
            }
            val -= this.getSelfOffsets()[axis];
            val -= this[`t${axis}`];
            val = Math.round(val / this.scale);
            this.$set(
                this.guides[axis],
                index,
                val
            );
        },

        deleteGuide(axis, index) {
            if(!this.showGuides) {
                return;
            }
            this.guides[axis].splice(index, 1);
            this.$store.commit('saveHistory', `deleted ${axis} guide`);
        },

        getSelfOffsets() {
            const rect = this.$el.getBoundingClientRect();
            return {
                x: rect.left,
                y: rect.top
            };
        }
    }
};
</script>

<style lang="scss">
.rulers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background: #ddd;
    border-bottom: 1px solid rgba(170, 170, 170, .3);
    border-right: 1px solid rgba(170, 170, 170, .2);
  }

  .ruler {
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    overflow: hidden;
    pointer-events: auto;

    &__hor {
      right: 0;
      height: 25px;
      border-bottom: 1px solid rgba(170, 170, 170, .2);
      box-sizing: border-box;

      svg {
        position: absolute;
        display: block;
        width: 20000px;
        height: 100%;
        overflow: visible;
        left: -10000px;
        top: 23px;
      }
    }

    &__ver {
      bottom: 0;
      width: 25px;
      border-right: 1px solid rgba(170, 170, 170, .3);
      box-sizing: border-box;

      svg {
        position: absolute;
        display: block;
        height: 20000px;
        width: 100%;
        overflow: visible;
        top: -10000px;
        left: 22px;
      }
    }
  }

  &__guide {
    position: absolute;
    z-index: 5;
    pointer-events: auto;
    background-color: #0f0;

    &:after {
      content: '';
      position: absolute;
    }

    &_x {
      top: 0;
      width: 1px;
      transform: translateX(-1px);
      height: 25px;

      &:after {
        top: 0;
        bottom: 0;
        left: -5px !important;
        width: 10px;
        cursor: col-resize;
      }
    }

    &_y {
      left: 0;
      height: 1px;
      transform: translateY(-1px);
      width: 25px;

      &:after {
        left: 0;
        right: 0;
        top: -5px;
        height: 10px;
        cursor: row-resize;
      }
    }
  }

  &__guide-val {
    color: #0f0;
    font-size: 10px;
    position: absolute;

    &_x {
      top: 28px;
      left: 6px;
    }

    &_y {
      top: 6px;
      left: 28px;
    }
  }

  &__shadow-guide {
    position: absolute;
    z-index: 5;
    pointer-events: none;
    background-color: transparent;

    &_x {
      top: 0;
      width: 1px;
      height: 100%;
      border-left: 1px dashed #0f0;
      transform: translateX(1px);
    }

    &_y {
      left: 0;
      height: 1px;
      width: 100%;
      border-top: 1px dashed #0f0;
      transform: translateY(1px);
    }
  }

  &__delete-area {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #202122;
    left: 0;
    top: 0;
    z-index: 5;
    pointer-events: auto;

    svg {
      opacity: 0;
      stroke: #fff;
      transform: scale(0.6) translateY(-3px);
    }

    &_visible {
      svg {
        opacity: 1;
      }
    }

    &_active {
      svg {
        stroke: #f00;
      }
    }
  }
}

.scale-numbers {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 0;
  left: 0;

  .ruler__ver & {
    top: 0;
    left: 0;
  }

  &__item {
    position: absolute;
    top: 0;
    font-size: 10px;
    color: #a2b1c1;
    transform: translateX(-50%);

    .ruler__ver & {
      transform-origin: left top;
      transform: rotate(-90deg) translate(-50%, 2px);
    }
  }
}
</style>
