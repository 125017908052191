/* eslint-disable */
import Vue from "vue";
import $ from 'jquery';

export const ApplySettings = () => {
  const debug = process.env.NODE_ENV !== 'production';

  if ((document.elementsFromPoint == null) && (document.msElementsFromPoint != null)) {
    document.elementsFromPoint = document.msElementsFromPoint;
  }

  if (Array.prototype.last == null) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.last = function() {
      return this[this.length - 1];
    };
  }

  if (String.prototype.lastChar == null) {
    // eslint-disable-next-line no-extend-native
    String.prototype.lastChar = function() {
      return this[this.length - 1];
    };
  }

  if (String.prototype.dropLastChar == null) {
    // eslint-disable-next-line no-extend-native
    String.prototype.dropLastChar = function() {
      return this.substring(0, this.length - 1);
    };
  }

  window.log = function(str) {
    const now = new Date();
    if (debug) {
      const line = `<p><span class='date'>${now.toUTCString()}</span>${str}</p>`;
      $('.debug-log__container').prepend(line);
    }
  };

  $('body').on('click', '.debug-log__toggle', function() {
    $('.debug-log').toggleClass('active');
  });

  Vue.config.devtools = debug;

  Vue.config.performance = false;

  // Регистрируем глобальную пользовательскую директиву `v-focus`
  // Используется в свойствах слайда prop_image, prop_video
  Vue.directive('focus', {
    // Когда привязанный элемент вставлен в DOM...
    inserted(el) {
      // Переключаем фокус на элемент
      el.focus();
      // Выделяем поле
      el.setSelectionRange(0, el.value.length);
    }
  });

  return debug;
  console.log('Применили тупейшие настройки удобности...');
}
