<template>
    <div class="context-menu" :class="{'context-menu_active':active}" :style="style">
        <div
                class="context-menu__item"
                v-for="(item, itemIndex) in items"
                :key="itemIndex"
                v-show="!item.hidden"
                :class="{'context-menu__item_disabled': item.disabled, 'context-menu__item_split': item.split}"
                @mousedown.left.stop=""
                @click.left.stop="menuItemClicked(item.event)"
        >
            {{ $t(item.title) }}
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import {CurrentPage} from "@/models/CurrentPage";

export default {
    name: 'ContextMenu',
    props: ['active', 'items', 'coords', 'maxCoords', 'width'],
    data() {
        return {
            el: 0,
            height: 0
        };
    },
    computed: {
        trX() {
            if (this.coords.x + this.width > this.maxCoords.x) {
                return '-100%';
            }
            return 0;
        },
        trY() {
            // eslint-disable-next-line no-unused-expressions
            this.coords; // для реактивности
            if (this.el === 0) {
                return 0;
            }
            if (this.coords.y + this.height > this.maxCoords.y) {
                return this.maxCoords.y - (this.coords.y + this.height) - 5 + 'px';
            }
            return 0;
        },
        style() {
            return {
                top: this.coords.y + 'px',
                left: this.coords.x + 'px',
                width: this.width + 'px',
                transform: 'translate(' + this.trX + ', ' + this.trY + ')'
            };
        }
    },
    methods: {
        menuItemClicked(evt) {
            this.$emit('context_menu_event', evt);
            CurrentPage(this).menuActive = false;
        }
    },
    mounted() {
        this.el = this.$el;
        this.height = this.el.getBoundingClientRect().height;
    },
    updated() {
        this.el = this.$el;
        this.height = this.el.getBoundingClientRect().height;
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/vars.scss";

.context-menu {
  padding: 0;
  color: $space_gray;
  background: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  border-radius: 3px;
  overflow: hidden;
  transition: opacity .1s ease, transform 0s linear .1s;
  opacity: 0;
  transform: scale(1, 0);
  pointer-events: none;

  &_dark {
    background: #3E3F41;
    color: #eee;

    .context-menu__item {
      border-color: rgba(255, 255, 255, .1);

      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, .1);
      }
    }
  }

  &_active {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
    transition: opacity .3s ease, transform 0s linear;
    pointer-events: auto;

    // .context-menu__item{
    //   pointer-events:auto;
    // }
  }

  &__item {
    position: relative;
    display: block;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 20px;
    cursor: pointer;
    // pointer-events:auto;

    &:last-child {
      border: none;
    }

    &:hover {
      background: #ddd;
    }

    &_disabled {
      background: none;
      color: #aaa;
      pointer-events: none;
    }

    &_split {
      border-bottom: 1px solid #d3d3d3;
    }
  }
}
</style>

<i18n>
{
    "en": {
        "copy": "Copy",
        "cut": "Cut",
        "delete": "Delete",
        "addTextDoubleClick": "Add text (double click)",
        "copyStyle": "Copy style",
        "applyStyle": "Apply style",
        "group": "Group",
        "ungroup": "Ungroup",
        "paste": "Paste",
        "mergeCells": "Merge cells",
        "splitHorizontal": "Split horizontal",
        "splitVertical": "Split vertical",
        "insertRowAbove": "Insert row above",
        "insertRowBelow": "Insert row below",
        "insertColumnRight": "Insert column right",
        "insertColumnLeft": "Insert column left",
        "deleteRow": "Delete row",
        "deleteColumn": "Delete column",
        "showRulers": "Show rulers",
        "hideRulers": "Hide rulers",
        "showGuides": "Show guides",
        "hideGuides": "Hide guides",
        "addGuide": "Add guide",
        "selectAll": "Select all",
        "setSlideBackground":  "Set slide background",
        "createNewObject": "Create new object"
    },
    "ru": {
        "copy": "Копировать",
        "cut": "Вырезать",
        "delete": "Удалить",
        "addTextDoubleClick": "Добавить текст (двойной клик)",
        "copyStyle": "Копировать стиль",
        "applyStyle": "Применить стиль",
        "group": "Сгруппировать",
        "ungroup": "Разгрупировать",
        "paste": "Вставить",
        "mergeCells": "Объединить ячейки",
        "splitHorizontal": "Разделить по горизонтали",
        "splitVertical": "Разделить по вертикали",
        "insertRowAbove": "Вставить строку выше",
        "insertRowBelow": "Вставить строку ниже",
        "insertColumnRight": "Вставить столбец справа",
        "insertColumnLeft": "Вставить столбец слева",
        "deleteRow": "Удалить строку",
        "deleteColumn": "Удалить столбец",
        "showRulers": "Показать линейки",
        "hideRulers": "Скрыть линейки",
        "showGuides": "Показать руководства",
        "hideGuides": "Скрыть направляющие",
        "addGuide": "Добавить направляющую",
        "selectAll": "Выбрать все",
        "setSlideBackground":  "Фон слайда",
        "createNewObject": "Создать новый объект"
    }
}
</i18n>
