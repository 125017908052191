<template>
    <div class="property property__type_color-mode">
        <div class="property__name">{{ $t(property.title) }}</div>
        <div class="property__preview" @click="showPicker">
            <div class="property__preview-fill" v-bind:style="propStyle"></div>
        </div>
        <div class="property__tabs" v-show="showTabs" v-on:mousedown="tabsMouseDown=true"
            v-on:mouseup="tabsMouseDown=false">
            <div class="property__tab" v-bind:class="{'property__tab__active':mode=='solid'}"
                v-on:mousedown="setValue('solid')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <rect x="16" y="14" width="20" height="20" rx="1" transform="translate(-16 -14)" fill="#656B6E"
                        fill-rule="evenodd" />
                </svg>
            </div>
            <div class="property__tab" v-bind:class="{'property__tab__active':mode=='linear'}"
                v-on:mousedown="setValue('linear')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
                            <stop stop-color="#9EA4A7" offset="0%" />
                            <stop stop-color="#1D2122" offset="100%" />
                        </linearGradient>
                    </defs>
                    <rect x="46" y="14" width="20" height="20" rx="1" transform="translate(-46 -14)" fill="url(#a)"
                        fill-rule="evenodd" />
                </svg>
            </div>
            <div class="property__tab" v-bind:class="{'property__tab__active':mode=='radial'}"
                v-on:mousedown="setValue('radial')">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient fx="50%" fy="50%" r="94.553%" id="b">
                            <stop stop-color="#242526" offset="0%" />
                            <stop stop-color="#9EA4A7" offset="100%" />
                        </radialGradient>
                    </defs>
                    <rect x="76" y="14" width="20" height="20" rx="1" transform="translate(-76 -14)" fill="url(#b)"
                        fill-rule="evenodd" />
                </svg>
            </div>
            <div class="property__tabs-body">
                <prop_color
                    v-show="mode=='solid'"
                    ref="propColor"
                    :propset="propset"
                    :property="ref.color"
                    :nested="true"
                    :showAlpha="showAlpha"
                    @confirm="showTabs = false"
                />
                <prop_gradient
                    :propset="propset"
                    :property="ref.gradient"
                    @confirm="showTabs = false;"
                    @picker_closed="tabsMouseDown = false;"
                    :showAlpha="showAlpha"
                    v-show="mode!='solid'"
                />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import _ from 'lodash';

import PropBase from '@/components/editor/property/PropBase';
import PropColor from '@/components/editor/property/PropColor';
import PropGradient from '@/components/editor/property/PropGradient';

export default {
    inject: ['currentPage'],
    name: 'propColorMode',
    mixins: [PropBase],
    props: {
        showAlpha: {
            type: Boolean,
            default: true
        }
    },
    components: {
        prop_color: PropColor,
        prop_gradient: PropGradient
    },
    data: function() {
        return {
            tabsMouseDown: false,
            showTabs: false,
            ref: {
                color: {
                    name: 'background-color'
                },
                gradient: {
                    name: 'gradient'
                }
            }
        };
    },
    watch: {
        // tabsMouseDown:->
        //   console.log '@tabsMouseDown '+@tabsMouseDown
        //   return
        'currentPage.mousePressed.left': function() {
            // console.log @$root.mousePressed.left, @tabsMouseDown
            if(this.currentPage.mousePressed.left && !this.tabsMouseDown) {
                this.showTabs = false;
            }
        }
    },
    // @$nextTick(()->
    //   @showTabs = false
    //   return
    // )
    computed: {
        color: function() {
            return this.propset['background-color'];
        },
        gradient: function() {
            return this.propset['gradient'];
        },
        points: {
            get: function() {
                if(this.gradient != null) {
                    return this.gradient.points;
                }
            },
            set: function(val) {
                //console.log val
                this.gradient.points = val;
            }
        },
        type: {
            get: function() {
                if(this.gradient != null) {
                    return this.gradient.type;
                }
            },
            set: function(val) {
                this.gradient.type = val;
            }
        },
        mode: function() {
            var that;
            if(this.propValue === 'gradient') {
                if(this.gradient.type === 'linear') {
                    return 'linear';
                } else {
                    return 'radial';
                }
                that = this;
            }
            return 'solid';
        },
        propStyle: function() {
            if(this.propValue === 'gradient') {
                return this.gradientStyle;
            } else {
                return this.colorStyle;
            }
        },
        colorStyle: function() {
            return 'background-color: rgba(' + this.getValidColor(this.color) + ')';
        },
        gradientStyle: function() {
            var gradCss, gradient, styles;
            gradient = this.gradient;
            gradCss = '';
            switch(gradient.type) {
                case 'linear':
                    gradCss += 'linear-gradient(';
                    gradCss += gradient.angle + 'deg';
                    break;
                case 'radial':
                    gradCss += 'radial-gradient(at ';
                    gradCss += gradient.center[0] + '% ' + gradient.center[1] + '%';
            }
            _.each(gradient.points, (point) => {
                gradCss += ', rgba(' + this.getValidColor(point.color) + ') ' + point.position + '%';
            });
            gradCss += ')';
            styles = {
                'background-image': gradCss
            };
            return styles;
        }
    },
    methods: {
        showPicker: function() {
            this.showTabs = true;
            Vue.nextTick(function() {
                return this.$refs.propColor.updateColorPicker();
            }, this);
        },
        setValue: function(val) {
            var g;
            switch(val) {
                case 'solid':
                    this.propValue = 'solid';
                    Vue.nextTick(function() {
                        return this.$refs.propColor.updateColorPicker();
                    }, this);
                    break;
                case 'linear':
                    this.propValue = 'gradient';
                    g = this.gradient;
                    g.type = 'linear';
                    // @propset.gradient = g
                    this.$parent.updateProp('gradient', g);
                    break;
                case 'radial':
                    this.propValue = 'gradient';
                    g = this.gradient;
                    g.type = 'radial';
                    // @propset.gradient = g
                    this.$parent.updateProp('gradient', g);
            }
        }
    }
};

</script>

<style lang="scss">
.property__type_color-mode {
  // padding-top: 0;

  .property {
    padding: 0;
  }

  .property__preview {
    position: relative;
    margin: 0;
    height: 20px;
    width: 40px;
    cursor: pointer;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);

    &-fill {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  & > .property__tabs {
    position: fixed;
    top: 48px;
    right: 0;
    left: auto;
    height: 100vh;
    width: 230px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}
</style>

<i18n>
{
    "en": {
        "Color": "Color"
    },
    "ru": {
        "Color": "Цвет"
    }
}
</i18n>
