<script>
/* eslint-disable */

import Vue from 'vue';
import $ from 'jquery';
import TabMixin from '@/components/editor/MediaBrowser/components/TabMixin';

export default {
    inject: ['currentPage'],
    name: 'giphy-tab',
    mixins: [TabMixin],
    data: function() {
        return {
            searchPlaceholder: 'Search gifs...',
            giphyKey: '',
            name: 'giphy'
        };
    },
    watch: {
        'currentPage.gkey': {
            immediate: true,
            handler: function(newVal) {
                if(newVal !== '') {
                    this.giphyKey = newVal;
                }
            }
        }
    },
    computed: {
        searchResultsUrls: function() {
            return this.searchResults.map(function(r) {
                return r.images.preview_gif.url;
            });
        }
    },
    methods: {
        itemSelected: function(id) {
            var item, ref, ref1, res, sr;
            sr = this.searchResults;
            item = sr[id];
            res = {
                url: item.images.downsized_medium.url,
                meta: {
                    preview: item.images.preview_gif.url,
                    name: (ref = item.user) != null ? ref.display_name : undefined,
                    link: (ref1 = item.user) != null ? ref1.profile_url : undefined,
                    id: item.id,
                    ref: 'giphy'
                }
            };
            this.$emit('itemselected', res);
        },
        browse: function(clearResults, initial) {
            var app, e, itemsPerPage, offset, page, query, url, url1, url2, url3, url4;
            try {
                if(!this.visible) {
                    return;
                }
                app = this;
                itemsPerPage = 20;
                page = 1;
                query = this.search;
                if(clearResults) {
                    this.searchResults = [];
                }
                if(this.searchResults.length > 0) {
                    page = this.searchResults.length / itemsPerPage + 1;
                }
                offset = (page - 1) * itemsPerPage;
                url1 = 'https://api.giphy.com/v1/gifs/';
                if(query.length > 0) {
                    url2 = 'search';
                    url4 = `&q=${query}`;
                } else {
                    url2 = 'trending';
                    url4 = '';
                }
                url3 = `?api_key=${this.giphyKey}&limit=${itemsPerPage}&offset=${offset}`;
                url = url1.concat(url2, url3, url4);
                this.busy = true;
                $.ajax({
                    type: 'GET',
                    url: url,
                    async: true,
                    success: function(data) {
                        var itemsContainer;
                        app.busy = false;
                        if(app.searchResults === []) {
                            app.searchResults = data.data;
                        } else {
                            app.searchResults = app.searchResults.concat(data.data);
                        }
                        if(initial) {
                            itemsContainer = $(app.$el).find('.media-browser__items')[0];
                            Vue.nextTick(function() {
                                if(data.data.length < itemsPerPage) {
                                    return;
                                }
                                // Равенство выполнится если нет скролла
                                if(itemsContainer.scrollHeight === itemsContainer.clientHeight) {
                                    this.browse(false, true);
                                }
                            }, app);
                        }
                    },
                    error: function(data) {
                        console.log('error giphy');
                        console.log(data);
                    }
                });
            } catch(error) {
                e = error;
                console.log(e);
            }
        }
    }
};

</script>

<style>

</style>
