<template>
  <div class="property property__type_align">
    <div class="property__btn" title="left" v-on:click="align('left')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#FFF" fill-rule="evenodd">
          <path opacity=".5" d="M5 3h6v4H5zM0 0h2v18H0z"/>
          <path d="M5 11h13v4H5z"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" title="center" v-on:click="align('center')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path fill="#FFF" opacity=".5" d="M5.967 3h6v4h-6z"/>
          <path fill="#FFF" d="M2 11h14v4H2z"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" title="right" v-on:click="align('right')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#FFF" fill-rule="evenodd">
          <path opacity=".5" d="M7 3h6v4H7zM16 0h2v18h-2z"/>
          <path d="M0 11h13.211v4H0z"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" title="top" v-on:click="align('top')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#FFF" fill-rule="evenodd">
          <path opacity=".5" d="M11 5h4v7h-4zM0 0h18v2H0z"/>
          <path d="M3 5h4v13H3z"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" title="middle" v-on:click="align('middle')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path fill="#FFF" opacity=".5" d="M11 6h4v6h-4z"/>
          <path fill="#FFF" d="M3 2h4v14H3z"/>
        </g>
      </svg>
    </div>
    <div class="property__btn" title="bottom" v-on:click="align('bottom')">
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#FFF" fill-rule="evenodd">
          <path opacity=".5" d="M11 6h4v7h-4zM0 16h18v2H0z"/>
          <path d="M3 0h4v13H3z"/>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  inject: ['currentPage'],
  name: 'prop_align',
  mixins: [PropBase],
  methods: {
    align: function(dir) {
      this.currentPage.metaTool.alignObjects(dir);
      this.$store.commit('saveHistory', this.property.name);
    }
  }
};
</script>

<style lang="scss">
.property__type_align{
  padding: 3px 5px;
  height: 25px;
  background: rgba(0,0,0, .2);
  display: flex;
  justify-content: space-around;
  .property__btn{
    position:relative;
    display:block;
    height: 18px;
    background: none;
    margin:0 0 0 5px;
  }
}
</style>
