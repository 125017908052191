<template>
  <div
      class="prop-panel__separate"
      v-bind:class="{'prop-panel__hid': !(propSetName == 'textProps' && show)}"
      v-bind:style="styles" v-on:mouseup="mouseUp">
    <prop_group v-bind:group="propGroup" v-bind:proppanel="propPanel" v-bind:propset="propSet"></prop_group>
  </div>
</template>

<script>
/* eslint-disable */
import propTypes from '@/components/editor/PropPanel/prop_types';
import PGR from '@/components/editor/PropPanel/prop_groups_reference';
import TPGR from '@/components/editor/PropPanel/types_prop_groups_reference';

import PropGroup from "@/components/editor/PropGroup";

export default {
  inject: ['currentPage'],
  name: 'Prop_panel_separate',
  components: {
    prop_group: PropGroup
  },
  data: function() {
    return {
      //mode: 'slide' # / slide / group / object - перенесено в computed
      groupName: 'slideBackground', //'text/ fill / ...'
      propSetName: 'styleProps' // / textProps / posProps
    };
  },
  computed: {
    show: function() {
      if (!this.currentPage.metaTool.duringProcess && (this.currentPage.editingText != null)) {
        return true;
      }
      return false;
    },
    coords: function() {
      var c;
      c = {};
      if ((this.target != null) && this.show) {
        c = this.currentPage.metaTool.opts.coords[0];
      }
      //console.log JSON.stringify(c)
      return c;
    },
    styles: function() {
      var canvasEl, margin, maxX, maxY, size, styles, x, y;
      if (this.coords == null) {
        return {};
      }
      // размер панели
      size = {
        h: $(this.$el).height(),
        w: $(this.$el).width()
      };
      //console.log size
      // отступы от краев и объекта
      margin = 20;
      canvasEl = this.currentPage.$refs.presentation.$el.parentElement.getBoundingClientRect();
      maxX = canvasEl.right - canvasEl.left;
      maxY = canvasEl.bottom - canvasEl.top;
      //console.log maxX
      styles = {};
      x = this.coords.x + this.currentPage.navigate.tx;
      if ((x == null) || x < 20) {
        x = 20;
      }
      if (x + size.w + margin > maxX) {
        x = maxX - size.w - margin;
      }
      styles.left = x + 'px';
      y = this.coords.y - size.h - margin + this.currentPage.navigate.ty;
      if ((y == null) || y < 20) {
        y = 20;
      }
      if (y + size.h + margin > maxY) {
        y = maxY - size.h - margin;
      }
      styles.top = y + 'px';
      //console.log styles
      return styles;
    },
    classes: function() {
      var c;
      c = '';
      if (this.mode === 'slide') {
        c = 'mode_' + this.propSet['background-mode'];
      }
      if (this.mode === 'object') {
        c = 'stroke-type_' + this.propSet['stroke-type'];
        c += ' mode_' + this.propSet['color-mode'];
      }
      if ((this.target != null) && this.target.type === 'line') {
        c = 'stroke-type_' + this.propSet['stroke-type'];
      }
      return c;
    },
    propPanel: function() {
      return this;
    },
    target: function() {
      if (this.currentPage.editingObjects.length > 1) { //выделено несколько объектов
        return this.currentPage.editingObjects;
      }
      if (this.currentPage.editingObjects.length === 1) { //выделен 1 объект
        return this.currentPage.editingObjects[0];
      }
      if (this.currentPage.editingObjects.length === 0) { // ничего не выделено, показываем свойства активного слайда
        return this.$store.state.activeSlide;
      }
      return [];
    },
    mode: function() {
      if (this.currentPage.editingObjects.length > 1) { //выделено несколько объектов
        this.propSetName = 'styleProps';
        this.groupName = 'align';
        return 'group';
      }
      if (this.currentPage.editingObjects.length === 1) { //выделен 1 объект
        this.propSetName = 'textProps';
        this.groupName = 'text';
        return 'object';
      }
      if (this.currentPage.editingObjects.length === 0) { // ничего не выделено, показываем свойства активного слайда
        this.propSetName = 'styleProps';
        this.groupName = 'slideBackground';
        return 'slide';
      }
    },
    propSet: function() {
      var ref, ref1, ref2, ref3, ref4;
      switch (this.mode) {
        case 'object':
          if (this.propSetName === 'textProps' && (this.currentPage.editingText != null)) {
            return this.currentPage.editingText[this.propSetName];
          }
          if (this.propSetName === 'styleProps' && (((ref = this.target) != null ? (ref1 = ref.vueObj) != null ? ref1[this.propSetName] : 0 : 0) != null)) {
            return this.target.vueObj[this.propSetName];
          }
          break;
        case 'slide':
          if ((((ref2 = this.target) != null ? (ref3 = ref2.bg) != null ? (ref4 = ref3.vueObj) != null ? ref4[this.propSetName] : 0 : 0 : 0) != null)) {
            return this.target.bg.vueObj[this.propSetName];
          }
      }
      return [];
    },
    propGroup: function() {
      var group;
      if (this.groupName === '') {
        return {};
      }
      group = {
        groupName: PGR[this.groupName]['groupName'],
        groupClass: PGR[this.groupName]['groupClass'],
        properties: PGR[this.groupName]['properties'].map(function(propType) {
          return propTypes[propType];
        })
      };
      return group;
    },
    metaTool: function() {
      return this.currentPage.$refs.presentation.$refs.metaTool;
    }
  },
  methods: {
    updateProp: function(propSet, propName, value) {
      var ref;
      if (this.propSetName === 'textProps') {
        if ((ref = this.currentPage.editingText) != null) {
          ref.setProperty(propName, value);
        }
      }
    },
    mouseUp: function() {
      var ref;
      // console.log 'mouseUp'
      if ((ref = this.currentPage.editingText) != null) {
        ref.setProperty('mouseUp', 'true');
      }
    }
  }
};
</script>

<style lang="scss">
.prop-panel__separate{
  position: absolute;
  display: block;
  top:10px;
  left:10px;
  padding: 0;
  background-color: #f5f5f5;
  z-index: 100;
  opacity:.2;
  transition: opacity .3s;

  &:hover{
    opacity:1;
  }

  &.prop-panel__hid{
    opacity: 0;
    transition:none;
    transform: translateY(-10000px);
  }

  .prop-group{
    width: 200px;
    margin: 0;
    padding: 10px;

    &__name_text{
      //width: auto; - дает неверные размеры панели если она прижата вправо
      transition: none;
      width: 546px;

      .prop-group__name{
        display: none;
      }

      .property{
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;

        &.prop-name_font-size, &.prop-name_line-height{
          width: 80px;
        }
      }
    }
  }
}
</style>
