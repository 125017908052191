<template>
    <div class="property property__type_caps">
        <div class="property__name">
            {{ $t(property.title) }}
        </div>
        <div class="property__btn"
             v-on:click="propValue = 'butt'"
             v-bind:class="{'property__btn__active': propValue == 'butt'}">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g fill="#FFF" fill-rule="evenodd">
                    <path opacity=".15" d="M0 0h16v16H0z"/>
                    <path d="M0 7h16v2H0z"/>
                    <path d="M0 6h4v4H0z"/>
                </g>
            </svg>
        </div>
        <div class="property__btn"
             v-on:click="propValue = 'square'"
             v-bind:class="{'property__btn__active': propValue == 'square'}">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g fill="#FFF" fill-rule="evenodd">
                    <path opacity=".15" d="M0 0h16v16H0z"/>
                    <path d="M6 7h10v2H6z"/>
                    <path d="M6 6h4v4H6z"/>
                </g>
            </svg>
        </div>
        <div class="property__btn"
             v-on:click="propValue = 'round'"
             v-bind:class="{'property__btn__active': propValue == 'round'}">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                <g fill="#FFF" fill-rule="evenodd">
                    <path d="M8 0h8v16H8A8 8 0 1 1 8 0z" opacity=".15"/>
                    <path d="M6 7h10v2H6z"/>
                    <path d="M6 6h4v4H6z"/>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
import PropBase from '@/components/editor/property/PropBase';

export default {
    name: 'propCaps',
    mixins: [PropBase],
    watch: {
        propValue () {
            this.$store.commit('saveHistory', this.property.name);
        }
    }
};
</script>

<style lang="scss">
.property__type_caps {

    .property__btn {
        height: 18px;
        width: 18px;
        background: none;
        margin: 0 6px 0 0;

        &__active svg {
            opacity: 1;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "Caps": "Caps"
    },
    "ru": {
        "Caps": "Форма"
    }
}
</i18n>
