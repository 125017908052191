/* eslint-disable */

export default class User {
  constructor() {
    this.name = '';
    this.email = '';
    this.email_verified = 1;
    this.verified = 1;
    this.projects_used = 0;
    this.projects_quota = 0;
    this.storage_used = 0;
    this.storage_quota = 0;
    this.role = 0;
    this.isLoaded = false;
  }

  isAdmin() {
    switch (this.role) {
      case 0:
        return false;
      case 1:
        return true;
    }
  }

  setUserData(userDataObject) {
    Object.keys(userDataObject).forEach((key) => {
      this[key] = userDataObject[key];
    });
    this.isLoaded = true;
  }
};
