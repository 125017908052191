
<script>
/* eslint-disable */
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Arrow_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'arrow'
    };
  },
  computed: {
    arrowPath: function() {
      var ac, res;
      ac = this.arrowCoords;
      res = "";
      ac.forEach(function(c, i) {
        var l;
        if (!c.hidden) {
          if (i === 0) {
            l = 'M';
          } else {
            l = 'L';
          }
          return res += `${l}${c.x} ${c.y}`;
        }
      });
      res += "z";
      return res;
    },
    arrowCoords: function() {
      var ad, angle, maxAngle, res, rp, textOffset, thickness, xOffset, yOffset;
      rp = _.clone(this.rectParams);
      ad = this.styleProps['arrow-direction'];
      switch (ad) {
        case 'north':
        case 'south':
          [rp.width, rp.height] = [rp.height, rp.width];
      }
      thickness = this.styleProps['arrow-thickness'] * rp.height / 100;
      yOffset = (rp.height - thickness) / 2;
      angle = this.styleProps['arrow-angle'];
      maxAngle = this.rad2deg(Math.atan(rp.width / (rp.height / 2)));
      if (isNaN(maxAngle)) {
        maxAngle = 0;
      }
      angle = Math.min(angle, maxAngle);
      xOffset = (rp.height / 2) / (Math.tan(this.deg2rad(90 - angle)));
      xOffset = Math.min(xOffset, rp.width);
      textOffset = yOffset / (Math.tan(this.deg2rad(90 - angle)));
      textOffset = Math.min(textOffset, rp.height / 2);
      res = [
        {
          x: rp.x,
          y: rp.y + yOffset
        },
        {
          x: rp.width - xOffset,
          y: rp.y + yOffset
        },
        {
          x: rp.width - xOffset,
          y: rp.y
        },
        {
          x: rp.width,
          y: rp.height / 2
        },
        {
          x: rp.width - xOffset,
          y: rp.height
        },
        {
          x: rp.width - xOffset,
          y: rp.height - yOffset
        },
        {
          x: rp.x,
          y: rp.height - yOffset
        },
        {
          x: rp.width - xOffset + textOffset,
          y: rp.y + yOffset,
          hidden: true
        },
        {
          x: rp.width - xOffset + textOffset,
          y: rp.height - yOffset,
          hidden: true
        },
        {
          x: rp.x,
          y: rp.height / 2,
          hidden: true
        }
      ];
      res = res.map(function(c) {
        switch (ad) {
          case 'south':
            [c.y, c.x] = [c.x, c.y];
            c.x = rp.height - c.x;
            break;
          case 'north':
            [c.y, c.x] = [c.x, c.y];
            c.y = rp.width - c.y;
            break;
          case 'west':
            c.x = rp.width - c.x;
        }
        return c;
      });
      return res;
    },
    textLimiterParams: function() {
      var ac, dots, res, rp, x, xDots, y, yDots;
      ac = this.arrowCoords;
      rp = this.rectParams;
      dots = [ac[0], ac[6], ac[7], ac[8]];
      xDots = dots.map(function(d) {
        return d.x;
      });
      yDots = dots.map(function(d) {
        return d.y;
      });
      x = Math.min(...xDots);
      y = Math.min(...yDots);
      res = {
        x: x,
        y: y,
        height: Math.max(...yDots) - y,
        width: Math.max(...xDots) - x
      };
      return res;
    },
    magnetCoordsDecart: function() {
      return [
        {
          x: this.opts.coords[0].x + this.arrowCoords[9].x - this.centerCoords.x,
          y: this.opts.coords[0].y + this.arrowCoords[9].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x + this.arrowCoords[2].x - this.centerCoords.x,
          y: this.opts.coords[0].y + this.arrowCoords[2].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x + this.arrowCoords[3].x - this.centerCoords.x,
          y: this.opts.coords[0].y + this.arrowCoords[3].y - this.centerCoords.y
        },
        {
          x: this.opts.coords[0].x + this.arrowCoords[4].x - this.centerCoords.x,
          y: this.opts.coords[0].y + this.arrowCoords[4].y - this.centerCoords.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
