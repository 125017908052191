<template>
  <div class="property property__type_font-style">
    <div class="property__name">{{ property.title }}</div>
    <div class="property__flex-row">
      <div class="property__btn" title="Bold" @mousedown.prevent="setProp('bold')"
           v-bind:class="{'property__btn__active': propValue.bold}">
        <svg width="13" height="14" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.896 6.979c1.257.292 2.14.647 2.647 1.064.73.592 1.094 1.36 1.094 2.305 0 .996-.4 1.82-1.201 2.47C10.452 13.606 9.023 14 7.148 14H.42v-.361c.612 0 1.027-.057 1.245-.171a.997.997 0 0 0 .459-.445c.088-.182.132-.631.132-1.347V3.082c0-.716-.044-1.167-.132-1.353a.965.965 0 0 0-.464-.444c-.221-.11-.635-.166-1.24-.166V.758h6.348c1.517 0 2.59.135 3.222.405.632.27 1.13.674 1.494 1.211.365.537.547 1.108.547 1.714 0 .638-.23 1.206-.693 1.704-.462.498-1.276.894-2.442 1.187zm-3.554-.264c.924 0 1.606-.104 2.046-.313.44-.208.776-.501 1.01-.879.235-.377.352-.859.352-1.445 0-.586-.116-1.066-.347-1.44a2.201 2.201 0 0 0-.99-.855c-.43-.195-1.12-.29-2.071-.283v5.215zm0 .762v4.248l-.01.488c0 .351.09.617.269.796.179.179.444.268.795.268.521 0 1.001-.115 1.44-.346.44-.231.777-.567 1.012-1.006.234-.44.351-.93.351-1.47 0-.618-.143-1.173-.43-1.665a2.316 2.316 0 0 0-1.181-1.03c-.501-.196-1.25-.29-2.246-.283z"
                fill="#FFF" fill-rule="evenodd"/>
        </svg>
      </div>
      <div class="property__btn" title="Italic" @mousedown.prevent="setProp('italic')"
           v-bind:class="{'property__btn__active': propValue.italic}">
        <svg width="10" height="14" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.932 13.639L5.824 14H.375l.137-.361c.547-.013.908-.059 1.084-.137.286-.11.498-.264.634-.459.215-.306.437-.853.665-1.64l2.304-7.989c.196-.664.293-1.165.293-1.504a.714.714 0 0 0-.127-.43.785.785 0 0 0-.386-.268c-.172-.062-.509-.093-1.01-.093l.117-.361h5.117l-.107.361c-.417-.006-.726.04-.928.137-.293.13-.516.316-.669.556-.153.241-.35.775-.59 1.602l-2.296 7.988c-.208.736-.312 1.205-.312 1.407 0 .162.04.3.122.415a.77.77 0 0 0 .39.263c.18.062.552.113 1.119.152z"
                fill="#FFF" fill-rule="evenodd"/>
        </svg>
      </div>
      <div class="property__btn" title="Underline" @mousedown.prevent="setProp('underline')"
           v-bind:class="{'property__btn__active': propValue.underline}">
        <svg width="15" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#FFF" fill-rule="evenodd">
            <path d="M9.541 1.12V.757h4.688v.361h-.499c-.52 0-.92.218-1.2.654-.137.202-.206.671-.206 1.407v5.37c0 1.329-.132 2.36-.395 3.092-.264.732-.782 1.36-1.553 1.884-.772.524-1.821.787-3.15.787-1.445 0-2.542-.251-3.29-.752a4.072 4.072 0 0 1-1.592-2.022c-.209-.58-.313-1.667-.313-3.262V3.102c0-.814-.112-1.348-.337-1.602-.224-.254-.587-.38-1.089-.38H.107V.757H5.83v.361h-.508c-.547 0-.937.173-1.172.518-.162.234-.244.722-.244 1.465v5.771c0 .514.047 1.104.142 1.768.094.664.265 1.181.513 1.552.247.371.603.677 1.069.918.465.241 1.037.362 1.714.362.866 0 1.64-.19 2.324-.567.684-.377 1.15-.86 1.401-1.45.251-.59.376-1.587.376-2.993V3.102c0-.827-.09-1.345-.273-1.553-.254-.287-.632-.43-1.133-.43h-.498zM0 17h14v1H0z"/>
          </g>
        </svg>
      </div>
      <div class="property__btn" title="Stroke" @mousedown.prevent="setProp('line-through')"
           v-bind:class="{'property__btn__active': propValue.linethrough}">
        <svg width="11" height="15" xmlns="http://www.w3.org/2000/svg">
          <g fill="#FFF" fill-rule="evenodd">
            <path d="M9.17.455v4.58h-.361c-.118-.879-.328-1.579-.63-2.1a3.277 3.277 0 0 0-1.294-1.24 3.58 3.58 0 0 0-1.739-.459c-.677 0-1.236.207-1.68.62-.442.414-.663.884-.663 1.412 0 .403.14.771.42 1.103.403.488 1.364 1.14 2.88 1.953 1.238.664 2.082 1.174 2.535 1.529.452.354.8.773 1.045 1.254.244.482.366.987.366 1.514 0 1.003-.39 1.867-1.167 2.593-.778.726-1.78 1.089-3.003 1.089-.384 0-.746-.03-1.084-.088-.202-.033-.62-.152-1.255-.357-.635-.205-1.037-.307-1.206-.307a.511.511 0 0 0-.386.146c-.094.098-.164.3-.21.606h-.361V9.762h.361c.17.95.397 1.662.684 2.134.286.472.724.864 1.313 1.176.59.313 1.236.469 1.939.469.814 0 1.457-.215 1.929-.645.472-.43.708-.937.708-1.523 0-.325-.09-.654-.269-.986-.179-.332-.457-.642-.835-.928-.254-.195-.947-.61-2.08-1.245-1.133-.635-1.939-1.141-2.417-1.519-.479-.377-.842-.794-1.089-1.25a3.1 3.1 0 0 1-.371-1.504c0-.95.365-1.769 1.094-2.456C3.073.798 4 .455 5.127.455c.703 0 1.449.173 2.236.518.365.162.622.244.772.244.169 0 .307-.05.415-.152.107-.1.193-.304.259-.61h.36z"/>
            <path d="M0 7h11v1H0z"/>
          </g>
        </svg>
      </div>
      <div class="property__btn" title="Uppercase" @mousedown.prevent="setProp('uppercase')"
           v-bind:class="{'property__btn__active': propValue.uppercase}">
        <svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
          <g fill="#FFF" fill-rule="evenodd">
            <path d="M5.693 13.71c-.918.71-1.494 1.12-1.728 1.231a2.64 2.64 0 0 1-1.123.245c-.619 0-1.128-.212-1.529-.635-.4-.423-.6-.98-.6-1.67 0-.436.098-.814.293-1.133.267-.443.73-.86 1.391-1.25s1.76-.866 3.296-1.426v-.351c0-.892-.141-1.504-.424-1.836-.284-.332-.695-.498-1.236-.498-.41 0-.735.11-.976.332-.248.221-.371.475-.371.761l.02.567c0 .3-.077.53-.23.693a.786.786 0 0 1-.601.244.76.76 0 0 1-.59-.254c-.154-.169-.23-.4-.23-.693 0-.56.286-1.074.86-1.543.572-.469 1.376-.703 2.411-.703.794 0 1.445.133 1.953.4.384.202.668.518.85.948.117.28.176.853.176 1.718v3.038c0 .852.016 1.375.049 1.567.032.192.086.32.16.386a.383.383 0 0 0 .26.097.533.533 0 0 0 .273-.068c.137-.085.4-.322.79-.713v.547c-.728.977-1.425 1.465-2.089 1.465-.319 0-.573-.11-.762-.332-.188-.222-.286-.6-.293-1.133zm0-.634V9.668c-.983.39-1.617.667-1.904.83-.514.287-.882.586-1.103.898a1.736 1.736 0 0 0-.332 1.026c0 .469.14.858.42 1.167.28.31.602.464.966.464.495 0 1.146-.326 1.953-.977zm12.334-2.51H12.9l-.898 2.09c-.221.515-.332.899-.332 1.153 0 .201.096.379.288.532.192.153.607.252 1.245.298V15h-4.17v-.361c.554-.098.912-.225 1.074-.381.332-.313.7-.947 1.104-1.904l4.658-10.899h.342l4.61 11.016c.37.885.707 1.46 1.01 1.723.303.264.724.412 1.265.445V15H17.87v-.361c.527-.026.884-.114 1.07-.264a.68.68 0 0 0 .278-.547c0-.286-.13-.739-.39-1.357l-.802-1.905zm-.273-.722l-2.246-5.352-2.305 5.352h4.55z"/>
            <path d="M10.5 2.5l-4 2v-4l4 2z" fill-rule="nonzero"/>
            <path d="M1 2h6v1H1z"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";

export default {
  name: 'prop_font_style',
  mixins: [PropBase],
  data: function() {
    return {};
  },
  computed: {
    target: function() {
      return this.proppanel.target;
    },
    propValue: {
      get: function() {
        var val;
        val = {
          bold: false,
          italic: false,
          underline: false,
          linethrough: false,
          uppercase: false
        };
        if ((this.propset != null)) {
          if (this.propset['font-weight'] === 'bold') {
            val.bold = true;
          }
          if (this.propset['font-style'] === 'italic') {
            val.italic = true;
          }
          if ((this.propset['text-decoration'] != null) && this.propset['text-decoration'].indexOf('underline') !== -1) {
            val.underline = true;
          }
          if ((this.propset['text-decoration'] != null) && this.propset['text-decoration'].indexOf('line-through') !== -1) {
            val.linethrough = true;
          }
          if (this.propset['text-transform'] === 'uppercase') {
            val.uppercase = true;
          }
        }
        return val;
      },
      set: function(val) {}
    }
  },
  // console.log val
  methods: {
    setProp: function(val) {
      var key;
      //console.log @propValue
      key = '';
      switch (val) {
        case 'bold':
          this.propValue.bold = !this.propValue.bold;
          key = 'font-weight';
          if (!this.propValue.bold) {
            val = 'normal';
          }
          break;
        case 'italic':
          this.propValue.italic = !this.propValue.italic;
          key = 'font-style';
          if (!this.propValue.italic) {
            val = 'normal';
          }
          break;
        case 'line-through':
          this.propValue.linethrough = !this.propValue.linethrough;
          key = 'text-decoration';
          val = this.calcTextDecoration();
          break;
        case 'underline':
          this.propValue.underline = !this.propValue.underline;
          key = 'text-decoration';
          val = this.calcTextDecoration();
          break;
        case 'uppercase':
          this.propValue.uppercase = !this.propValue.uppercase;
          key = 'text-transform';
          if (!this.propValue.uppercase) {
            val = 'none';
          }
      }
      this.updateProp(key, val);
    },
    // @$root.editingText.setProperty(key, val)
    calcTextDecoration: function() {
      var lt, ul, val;
      lt = this.propValue.linethrough;
      ul = this.propValue.underline;
      if (lt && ul) {
        val = 'line-through underline';
      }
      if (lt && !ul) {
        val = 'line-through';
      }
      if (!lt && ul) {
        val = 'underline';
      }
      if (!lt && !ul) {
        val = 'none';
      }
      return val;
    }
  }
};
</script>

<style lang="scss">
.property__type_font-style{
  position:relative;
  display:block;

  .property__flex-row{
    align-items: center;
  }

  .property__btn{
    position:relative;
    display:block;
    text-align: center;
    padding:4px 0;
    box-sizing: border-box;
    vertical-align: top;
    margin-right: 21px;

    &:last-child{
      margin-right: 0;
    }

    svg{
      position:relative;
      display:block;
      height: 18px;
    }

    &__active svg{
      opacity: 1;
    }
  }
}
</style>
