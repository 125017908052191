/* eslint-disable */

var Converter,
  indexOf = [].indexOf;

export default Converter = (function() {
  class Converter {
    constructor() {
      return;
    }

    actualizeJsonSchema(json, store) {
      var i, j, ref, ref1;
      if (json.schemaVersion == null) {
        json.schemaVersion = -1;
      }
      // Выставляем версию схемы для новой презы
      if (json.schemaVersion === -1) {
        json.schemaVersion = this.schemaVersion;
        json.theme = _.cloneDeep(store.state.themes['1']);
        json.animation = {
          type: "slide",
          duration: 1500,
          easing: "easeInOut",
          diff: 0.8,
          direction: 'x',
          fragments: 4,
          color: {
            "own": [128, 128, 128, 1],
            "palette": false
          }
        };
        json.lastId = 1;
        json.media = {};
        json.timeStamp = Date.now();
        json.slides = [];
        json.guides = {
          x: [],
          y: []
        };
        json.boxed = false;
        return json;
      }
// Запускаем конверетеры для старой презы
      for (i = j = ref = json.schemaVersion, ref1 = this.schemaVersion; j < ref1; i = j += 1) {
        json = this.converters[i](json, store);
        console.log(`changed json version from ${i} to ${i + 1}`);
      }
      return json;
    }

  };

  Converter.prototype.schemaVersion = 11;

  Converter.prototype.converters = [
    // 0->1
    function(json,
             store) {
      delete json.name;
      json.schemaVersion = 1;
      return json;
    },
    // 1->2
    function(json,
             store) {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        if (key === 'text' && (value.ps != null) && value.ps instanceof Array) {
          value.ps.forEach(function(p,
                                    pIndex,
                                    ps) {
            var lh;
            lh = 1;
            p.spans.forEach(function(span,
                                     spanIndex,
                                     spans) {
              if (lh < span.style['line-height']) {
                lh = span.style['line-height'];
              }
              delete span.style['line-height'];
            });
            p.style['line-height'] = lh;
          });
          return value;
        }
      };
      json.schemaVersion = 2;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 2->3
    function(json,
             store) {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        if (value.type === 'line') {
          value.styleProps.arrows = {
            start: 'none',
            end: 'none'
          };
          return value;
        }
      };
      json.schemaVersion = 3;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 3->4
    function(json,
             store) {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        if (key === 'animation') {
          value.direction = 'x';
          value.fragments = 4;
          value.color = [128,
            128,
            128,
            1];
          return value;
        }
      };
      json.schemaVersion = 4;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 4->5
    (json,
     store) => {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        var clone,
          keys,
          newValue;
        keys = ['color',
          'background-color',
          'stroke-color',
          'fill'];
        if (indexOf.call(keys,
          key) >= 0) {
          if (Array.isArray(value)) {
            clone = _.clone(value);
            newValue = {
              palette: false,
              own: clone
            };
            return newValue;
          }
        }
      };
      json.schemaVersion = 5;
      json.theme = _.cloneDeep(store.state.themes['1']);
      return _.cloneDeepWith(json,
        customizr);
    },
    // 5->6
    (json,
     store) => {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        var clone;
        if (key === 'styleProps') {
          clone = _.clone(value);
          if (clone.arrows == null) {
            clone['lock-aspect'] = false;
          }
          clone['opacity'] = 1;
          return clone;
        }
      };
      json.schemaVersion = 6;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 6->7
    (json,
     store) => {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        var clone;
        if (key === 'slides') {
          clone = _.cloneDeep(value);
          clone.forEach(function(c) {
            if (c.animations == null) {
              c.animations = [];
            }
          });
          return clone;
        }
      };
      json.schemaVersion = 7;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 7->8
    (json,
     store) => {
      var customizr;
      customizr = function(value,
                           key,
                           obj) {
        var clone;
        if (key === 'animation') {
          clone = _.cloneDeep(value);
          if (clone.type === 'slide') {
            clone.diff = 0;
          }
          if (clone.type === 'complex') {
            clone.type = 'slide';
          }
          if (clone.easing === 'ease') {
            clone.easing = 'easeInOut';
          }
          if (clone.easing === 'linear') {
            clone.easing = 'none';
          }
          return clone;
        }
      };
      json.schemaVersion = 8;
      return _.cloneDeepWith(json,
        customizr);
    },
    // 8->9
    (json,
     store) => {
      var cloned;
      cloned = _.cloneDeep(json);
      cloned.slides.forEach(function(s) {
        s.guides = {
          x: [],
          y: []
        };
      });
      cloned.schemaVersion = 9;
      return cloned;
    },
    // 9->10
    (json,
     store) => {
      var cloned,
        customizr,
        types;
      cloned = _.cloneDeep(json);
      cloned.boxed = false;
      cloned.slides.forEach(function(s) {
        s.bg['image-custom-scale'] = 1;
        s.bg['image-shift-x'] = 0;
        s.bg['image-shift-y'] = 0;
      });
      types = ['arrow',
        'chevron',
        'circle',
        'parallelogram',
        'rectangle',
        'rhombus',
        'triangle'];
      customizr = function(value,
                           key,
                           obj) {
        var clone;
        if ((value != null) && types.includes(value.type)) {
          clone = _.cloneDeep(value);
          clone.styleProps['image-custom-scale'] = 1;
          clone.styleProps['image-shift-x'] = 0;
          clone.styleProps['image-shift-y'] = 0;
          return clone;
        }
      };
      cloned.schemaVersion = 10;
      return _.cloneDeepWith(cloned,
        customizr);
    },
    // 10->11
    (json,
     store) => {
      var cloned,
        customizr,
        theme;
      // Проверяем анимации на потенциальный баг
      json.slides = json.slides.map(function(slide) {
        var objectsIds;
        objectsIds = slide.objects.map(function(o) {
          return o.id;
        });
        slide.animations = slide.animations.map(function(a) {
          return a.filter(function(ai) {
            return objectsIds.includes(ai);
          });
        });
        // Чистим анимации от пустых массивов
        slide.animations = slide.animations.filter(function(a) {
          return !(Array.isArray(a) && a.length === 0);
        });
        return slide;
      });
      cloned = _.cloneDeep(json);
      theme = json.theme;
      customizr = function(value,
                           key,
                           obj) {
        var clone;
        if (key === 'tableData') {
          clone = _.cloneDeep(value);
          clone.style = {
            tableBg1Color: {
              palette: theme.colorOptions.tableBg1,
              own: false
            },
            tableBg2Color: {
              palette: theme.colorOptions.tableBg2,
              own: false
            },
            tableAccColor: {
              palette: theme.colorOptions.tableAcc,
              own: false
            },
            rowsAlt: true,
            colsAlt: false,
            firstCol: false,
            lastCol: false,
            firstRow: true,
            lastRow: false,
            opacity: 1
          };
          return clone;
        }
      };
      cloned.schemaVersion = 11;
      return _.cloneDeepWith(cloned,
        customizr);
    }
  ];

  return Converter;

}).call(this);
