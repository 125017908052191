// @flow

import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { ProcessMsgType } from '@/enum/Message';

export default {
    data: () => ({
        exportSlideIdx: null,
        exportData: {
            doc: null,
        }
    }),
    methods: {
        async exportOneSlideToPdf (slideIdx, iMax) {
            return new Promise(resolve => {
                this.setStatus({
                    loop: false,
                    text: 'processingSlide',
                    type: ProcessMsgType.COUNT,
                    percentage: ((slideIdx + 1) / iMax) * 100,
                    count: (slideIdx + 1) + '/' + iMax
                });
    
                this.exportSlideIdx = slideIdx;
    
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.$nextTick(async () => {
                            if (slideIdx !== 0) {
                                this.exportData.doc.addPage(this.exportData.format, this.exportData.orientation);
                            }
    
                            try {
                                const dataUrl = await htmlToImage.toJpeg(document.getElementById('pdf-export-preview-wrapper'));
                                let img = new Image();
                                img.src = dataUrl;
    
                                const {ptw, pth} = this.exportData;
                                await this.exportData.doc.addImage(img, 'JPEG', 0, 0, ptw, pth, undefined, 'FAST');
    
                                resolve('exported');
                            } catch (error) {
                                this.showErrorToast('exportToPdfError');
                                throw new Error('Export to PDF exeption!!!!');
                            }
                        });
                    });
                });
            });
        },
        async exportToPdf () {
            this.processMessage.on = true;
            this.processMessage.name = this.$t('exportToPdf');
            this.processMessage.text = 'generatingSlides';
            this.processMessage.loop = true;
            this.processMessage.cancellable = false;
    
            this.exportData.ptw = this.presentation.size.w * 0.75;
            this.exportData.pth = this.presentation.size.h * 0.75;
            this.exportData.orientation = 'l';
            this.exportData.format = [this.exportData.ptw, this.exportData.pth];
            
            this.exportData.doc = new jsPDF({
                unit: 'pt',
                format: this.exportData.format,
                orientation: this.exportData.orientation,
            });
    
    
            const iMax = this.$store.state.presentation.slides.length;
            for(let slideIdx = 0; slideIdx < iMax; slideIdx++) {
                await this.exportOneSlideToPdf(slideIdx, iMax);
            }
    
            await this.exportData.doc.save(`${this.$store.state.title}.pdf`);
            
            this.exportSlideIdx = null;
            this.setStatus({ destroy: true });
            this.exportData.doc = null;
        },
    }
};
