/* eslint-disable */
export default {
  colors: {
    bg1: "#1D1F20",
    bg2: "#2A2C2D",
    bg3: "#242626",
    bg4: "#333638",
    bg5: "#202122",
    bg6: "#3E3F41",
    f1: "#FFFFFF",
    f2: "#777777",
    f3: "#B4C17B",
    f4: "#979797",
    d1: "#10AFFF"
  },
  layout: {
    Editor: {
      background: "bg1",
      headerBg: "bg4"
    },
    ToolbarComponent: {
      groupBg: "bg6"
    },
    PropPanel: {
      background: "bg2",
      color: "f2"
    },
    PropGroup: {
      alignBackground: "bg5",
      titleColor: "f1"
    },
    PropNumber: {
      inputColor: "f3"
    },
    PropColor: {
      tabColor: "bg4",
      hashColor: "f3"
    },
    PropScale: {
      inputColor: "f3"
    },
    RulersComponent: {
      rulerBg: "bg3",
      rulersAfterBg: "bg5",
      rulerColor: "f4"
    },
    SlidePreviews: {
      background: "bg5",
      activeColor: "d1"
    }
  }
};
