<template>
  <g class="cell-wrapper" @mousedown.left="mousedown($event)" @mouseup.left="mouseup($event)" @mousedown.right="mousedown($event)" @mouseup.right="mouseup($event)" :class="{'cell-wrapper__selected': selected}" @mouseenter="mouseenter()" @mouseleave="mouseleave()">
    <polygon class="cell" :points="opts.pos.x1+','+opts.pos.y1+' '+opts.pos.x2+','+opts.pos.y1+' '+opts.pos.x2+','+opts.pos.y2+' '+opts.pos.x1+','+opts.pos.y2+' '" :style="{'fill': 'rgba(' + getValidColor(opts.style.fill) + ')'}"></polygon>
    <polygon class="cell_selected-cover" v-if="selected && !currentPage.html2CanvasHack" :points="opts.pos.x1+','+opts.pos.y1+' '+opts.pos.x2+','+opts.pos.y1+' '+opts.pos.x2+','+opts.pos.y2+' '+opts.pos.x1+','+opts.pos.y2+' '" style="fill: rgba(0, 64, 255, .3);"></polygon>
    <foreignObject :x="textSize.x" :y="textSize.y" :width="textSize.width" :height="textSize.height">
      <text_block :opts="opts.text" :slideindex="slideindex" :zi="10" :index="0" :size="textSize" ref="text" @overflow="overflow($event)" @textfocus="textfocus($event)" @textfocusout="textfocusout($event)" @textmousedown="textmousedown($event)" @textmouseup="textmouseup($event)"></text_block>
    </foreignObject>
    <g v-if="textSvg" v-html="textSvg"></g>
    <g class="cell__geometry_handlers" v-if="!currentPage.isRenderer">
      <line class="cell__geometry_handlers__top" :x1="opts.pos.x1" :y1="opts.pos.y1" :x2="opts.pos.x2" :y2="opts.pos.y1" @mousedown="geomHandlerMousedown('top')"></line>
      <line class="cell__geometry_handlers__right" :x1="opts.pos.x2" :y1="opts.pos.y1" :x2="opts.pos.x2" :y2="opts.pos.y2" @mousedown="geomHandlerMousedown('right')"></line>
      <line class="cell__geometry_handlers__bottom" :x1="opts.pos.x2" :y1="opts.pos.y2" :x2="opts.pos.x1" :y2="opts.pos.y2" @mousedown="geomHandlerMousedown('bottom')"></line>
      <line class="cell__geometry_handlers__left" :x1="opts.pos.x1" :y1="opts.pos.y2" :x2="opts.pos.x1" :y2="opts.pos.y1" @mousedown="geomHandlerMousedown('left')"></line>
    </g>
  </g>
</template>

<script>
/* eslint-disable */
import Vue from "vue";

import Color from "@/mixins/editor/Color";
import TextEntity from "@/mixins/editor/TextEntity";

import Text from "@/components/editor/Text";

export default {
  name: 'TableCell',
  inject: ['currentPage'],
  props: ['opts', 'selected', 'slideindex'],
  mixins: [TextEntity, Color],
  components: {
    text_block: Text
  },
  data(){
    return {
      borderPressed: false,
      textAvailable: true,
      isTableCell: true,
      textSvg: ''
    };
  },

  watch: {
    'currentPage.mousePressed.left'() {
      if (!this.currentPage.mousePressed.left) {
        return this.borderPressed = false;
      }
    },

    'flags.screenshotGeneratorMode'(val){
      if (val) {
        // First tick - change self state
        this.$nextTick(function(){
          // Second tick - change text state
          this.$nextTick(function(){
            // Third tick - change text DOM
            return this.$nextTick(function(){
              if (val) {
                this.generateTextSVG();
                return;
              }
            });
          });
        });
      } else {
        this.textSvg = '';
      }
    }
  },

  computed: {

    flags() {
      return this.$store.state.flags;
    },

    myStoreObject() {
      return this.$parent.myStoreObject.tableData
          .rows[this.opts.row].cells[this.opts.cell];
    },

    myPlaceholders() {
      const res = [];
      for (let i = 0, end = this.opts.rowspan; i < end; i++) {
        for (let j = 0, end1 = this.opts.colspan; j < end1; j++) {
          res.push([this.opts.borders.top + i, this.opts.borders.left + j]);
        }
      }

      return res;
    },

    textSize() {
      const {x1, y1, x2, y2} = this.opts.pos;

      return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1
      };
    }
  },

  methods: {
    overflow(e){
      // if e > 0
      //   @$parent.moveHorBorder(@opts.borders.bottom, e)
    },

    mouseenter() {
      this.$emit('cellmouseenter', this);
      this.currentPage.overText = true;
    },

    mouseleave() {
      this.$emit('cellmouseleave', this);
      this.currentPage.overText = false;
    },

    textmousedown(e){
      e.vueTarget = this;
      if (e.which === 1) {
        Vue.set(
            this.currentPage.mousePressed,
            'left',
            true
        );
      }
      this.$emit('cellmousedown', e);
    },

    textmouseup(e){
      e.vueTarget = this;
      if (e.which === 1) {
        Vue.set(
            this.currentPage.mousePressed,
            'left',
            false
        );
      }
      this.$emit('cellmouseup', e);
    },

    mousedown(e){
      e.vueTarget = this;
      // Если левая кнопка
      if (e.which === 1) {
        Vue.set(
            this.currentPage.mousePressed,
            'left',
            true
        );
      }
      // Нужно для предотвращения обработки мыши метатулом
      // при клике внутри ячейки
      if (this.currentPage.metaTool.tableStateModifier === undefined) {
        e.stopPropagation();
      }
      if (!this.borderPressed) {
        this.addTextEntity();
      }
      this.$emit('cellmousedown', e);

    },

    mouseup(e){
      e.vueTarget = this;
      // Если левая кнопка
      if (e.which === 1) {
        Vue.set(
            this.currentPage.mousePressed,
            'left',
            false
        );
      }
      this.$emit('cellmouseup', e);
      // Нужно для предотвращения обработки мыши метатулом
      // при клике внутри ячейки
      if ((this.currentPage.metaTool.tableStateModifier === undefined) &&
          (this.currentPage.metaTool.state === 'editing')) {
        e.stopPropagation();
      }
    },

    focus() {
      const objs = this.currentPage.metaTool.editingObjects;
      if ((objs.length !== 1) || (objs[0] !== this.$parent)) {
        this.currentPage.metaTool.clearState();
        this.currentPage.metaTool.addObjectToEditor(this.$parent.opts.id);
      }
      this.currentPage.metaTool.drawSelectedSpace();
      this.currentPage.editingText = this.$parent;
    },

    textfocus() {
      this.currentPage.blockMetatool = true;
      this.currentPage.editingText = this.$parent;
      if ((this.currentPage.editingObjects.length === 0) ||
          (this.currentPage.editingObjects[0].vueObj !== this.$parent)) {
        this.currentPage.metaTool.clearState();
        this.currentPage.metaTool.editingObjects.push(this.$parent.myStoreObject);
        this.currentPage.metaTool.drawSelectedSpace(true);
      }
    },

    textfocusout() {

    },

    focusout() {
      // console.log "focusout"
    },

    // Проверяем на совпадение размеры текста и контейнера
    checkYOverflow(){

      return this.$refs.text.checkOverflow();
    },

    checkXOverflow(){
      if ((this.$el != null) && (this.opts.text.ps[0].spans[0] != null)) {
        let minWidth = 0;
        minWidth += this.opts.text.ps[0].spans[0].style['font-size'];
        if (this.opts.text.style?.paddings != null) {
          minWidth += this.opts.text.style.paddings[1];
          minWidth += this.opts.text.style.paddings[3];
        }

        const currentWidth = this.opts.pos.x2 - this.opts.pos.x1;
        const diff = minWidth - currentWidth;
        return diff;
      }
    },

    // Обработчик нажатия на границы ячеек
    geomHandlerMousedown(border){
      let type;
      this.borderPressed = true;

      switch (border) {
        case 'top':
          type = 'hor';
          break;
        case 'bottom':
          type = 'hor';
          break;
        case 'left':
          type = 'ver';
          break;
        case 'right':
          type = 'ver';
          break;
      }

      this.currentPage.metaTool.tableStateModifier = type;
      this.currentPage.metaTool.selectedTableBorder = this.opts.borders[border];

      this.$emit('geomhandlermousedown');
    },

    isMyPlaceholder(row, cell){
      if (row === undefined) {
        if ((cell >= this.opts.borders.left) &&
            (cell < this.opts.borders.right)) {
          return true;
        }
      }
      if (cell === undefined) {
        if ((row >= this.opts.borders.top) &&
            (row < this.opts.borders.bottom)) {
          return true;
        }
      }
      if ((row >= this.opts.borders.top) &&
          (row < this.opts.borders.bottom) &&
          (cell >= this.opts.borders.left) &&
          (cell < this.opts.borders.right)) {
        return true;
      }
      return false;
    },

    emitNavigateEvent(direction){
      this.$emit('navigate', direction);
    },

    generateTextSVG() {
      if (this.$refs.text) {
        const svgData = this.$refs.text.generateSvg();
        this.textSvg = svgData;
      }
    }
  }
};

</script>

<style lang="scss">
.cell{
  stroke: transparent;
  stroke-width: 1px;
}
.cell__geometry_handlers{
  &>*{
    position: relative;
    z-index: 2;
    stroke-width: 10px;
    stroke: black;
    opacity: 0;
  }
  .table:not(.text-selected) &{
    pointer-events: none;
  }
}
.cell__geometry_handlers__top, .cell__geometry_handlers__bottom{
  cursor: row-resize;
}
.cell__geometry_handlers__left, .cell__geometry_handlers__right{
  cursor: col-resize;
}
</style>
