<template>
    <div class="transitions" @mousedown="mousePressed = true" @mouseup="mouseUp">
        <div class="transitions__title">
            {{ $t('transitions') }}
        </div>
        <div class="close transitions__close" @click="$parent.showTransitions = false"></div>
        <prop_select :propset="transProps" :property="types" />
        <prop_number v-if="propsConditions.duration" :propset="transProps" :property="ref.duration" />
        <prop_number v-if="propsConditions.fragments" :propset="transProps" :property="ref.fragments" />
        <prop_color v-if="propsConditions.fragments" :propset="transProps" :property="ref.color" :showAlpha="false" />
        <prop_select v-if="propsConditions.direction" :propset="transProps" :property="ref.direction" />
        <prop_select v-if="propsConditions.easing" :propset="transProps" :property="ref.easing" />
        <prop_number v-if="propsConditions.diff" :propset="transProps" :property="ref.diff" />
        <div class="property">
            <div
                class="btn btn_editor-btn"
                @click="currentPage.preview()"
                :class="{'btn_disabled': currentPage.showAnimations || currentPage.presentation.slides.length < 2}"
            >
                {{ $t('preview') }}
            </div>
        </div>
        <div class="property__btn property__btn_transitions" @click="$parent.showTransitions = false">
            {{ $t('apply') }}
        </div>
    </div>
</template>

<script>
/* eslint-disable */


import PropBase from '@/components/editor/property/PropBase';
import PropColor from '@/components/editor/property/PropColor';
import PropNumber from '@/components/editor/property/PropNumber';
import PropSelect from '@/components/editor/property/PropSelect';

export default {
    name: 'Transitions',
    mixins: [PropBase],
    inject: ['currentPage'],
    components: {
        prop_number: PropNumber,
        prop_select: PropSelect,
        prop_color: PropColor
    },
    data: function() {
        return {
            mousePressed: false,
            selectOpen: false,
            type: '',
            ref: {
                type: {
                    title: 'Type',
                    name: 'type',
                    options: {
                        none: 'None',
                        fade: 'Fade',
                        slide: 'Slide',
                        vortex: 'Vortex',
                        morphing: 'Morphing',
                        screen: 'Screen'
                    }
                },
                duration: {
                    name: 'duration',
                    title: 'Duration(ms)',
                    min: 0,
                    max: 5000
                },
                direction: {
                    name: 'direction',
                    title: 'Direction',
                    options: {
                        x: 'Horizontal',
                        y: 'Vertical'
                    },
                    default: 'x'
                },
                fragments: {
                    name: 'fragments',
                    title: 'Fragments',
                    min: 1,
                    max: 8,
                    default: 4
                },
                color: {
                    name: 'color',
                    title: 'Color'
                },
                easing: {
                    title: 'Easing',
                    name: 'easing',
                    options: {
                        none: 'None',
                        easeIn: 'EaseIn',
                        easeOut: 'EaseOut',
                        easeInOut: 'EaseInOut'
                    },
                    default: 'easeInOut'
                },
                diff: {
                    title: 'Parallax',
                    name: 'diff',
                    min: -1,
                    max: 1,
                    k: 100
                }
            },
            webglTransitions: ['vortex', 'morphing'],
            notAvailableWebgl: false,
            asterisk: {
                webgl: 'May run slowly on low-end devices',
                notAvailableWebgl: 'Not available with gifs or videos on slide background'
            }
        };
    },
    mounted: function() {
        this.checkWebglAvailability();
    },
    computed: {
        transitions: function() {
            if(this.$store.state.presentation.animation != null) {
                return this.$store.state.presentation.animation;
            }
            return {
                type: 'fade',
                duration: 500,
                easing: 'linear',
                diff: 0
            };
        },
        types: function() {
            var res;
            res = this.ref.type;
            res.asterisk = {};
            this.webglTransitions.forEach((t) => {
                if(this.notAvailableWebgl) {
                    res.asterisk[t] = this.asterisk.notAvailableWebgl;
                    if(res.blocked == null) {
                        res.blocked = {};
                    }
                    return res.blocked[t] = true;
                } else {
                    return res.asterisk[t] = this.asterisk.webgl;
                }
            });
            return res;
        },
        transProps: function() {
            return {
                type: this.transitions.type,
                duration: this.transitions.duration,
                direction: this.transitions.direction,
                fragments: this.transitions.fragments,
                easing: this.transitions.easing,
                diff: this.transitions.diff,
                color: this.transitions.color
            };
        },
        propsConditions: function() {
            var types;
            types = _.clone(this.ref.type.options);
            _.forEach(types, (v, k) => {
                types[k] = this.transitions.type === k;
            });
            return {
                duration: !types.none,
                direction: !types.none && !types.vortex && !types.fade && !types.screen,
                fragments: types.screen,
                easing: types.fade || types.slide,
                diff: types.slide
            };
        }
    },
    methods: {
        mouseUp: function() {
            return setTimeout(() => {
                this.mousePressed = false;
            }, 200);
        },
        updateProp: function(k, v) {
            this.$store.commit('setSlideAnimation', {
                key: k,
                value: v
            });
        },
        // console.log k, v
        checkWebglAvailability: function() {
            var notAvailableWebgl, slides;
            slides = this.$store.state.presentation.slides;
            notAvailableWebgl = false;
            slides.forEach(function(s) {
                if(s.bg.vueObj.checkDynamicMedia()) {
                    return notAvailableWebgl = true;
                }
            });
            this.notAvailableWebgl = notAvailableWebgl;
        }
    },
    watch: {
        'currentPage.mousePressed.left': function() {
            if(!this.currentPage.mousePressed.left) {
                this.selectOpen = false;
                this.mousePressed = false;
            }
        }
    }
};
</script>

<style lang="scss">
.property__btn_transitions {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 230px;
    padding: 7px 0;
    font-size: 14px;
    text-align: center;
    background: rgba(255, 255, 255, .05);

    &:hover {
        color: #fff;
        background: rgba(255, 255, 255, .1);
    }
}

.transitions {
    position: absolute;
    display: block;
    box-sizing: border-box;
    top: 0;
    right: 0;
    bottom: 0;
    width: 230px;
    padding: 0 20px;
    background: #2A2C2D;
    color: #777777;
    height: 100%;
    z-index: 1;

    &__title {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
        line-height: 16px;
        padding: 5px 0;
        color: #fff;
        flex-grow: 0;
    }

    &__close {
        position: absolute;
        display: block;
        top: 4px;
        right: 8px;
    }

    .property__tabs {
        left: -8px;
        top: 60px;
    }

    .property {
        padding: 10px 0;
    }
}
</style>

<i18n>
{
    "en": {
        "transitions": "Transitions",
        "preview": "Preview",
        "apply": "Apply"
    },
    "ru": {
        "transitions": "Анимация",
        "preview": "Просмотр",
        "apply": "Применить"
    }
}
</i18n>
