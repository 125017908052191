
<script>
/* eslint-disable */
import Shape from "@/mixins/editor/Shape";

export default {
  name: 'Triangle_shape',
  mixins: [Shape],
  data: function() {
    return {
      type: 'triangle'
    };
  },
  computed: {
    trianglePath: function() {
      var res, tc;
      tc = this.triangleCoords;
      res = "";
      res += `M${tc[0].x} ${tc[0].y}`;
      res += `L${tc[1].x} ${tc[1].y}`;
      res += `L${tc[2].x} ${tc[2].y}`;
      res += "z";
      return res;
    },
    triangleCoords: function() {
      var res, rp, to;
      rp = this.rectParams;
      to = this.styleProps['top-offset'];
      res = [
        {
          x: rp.x,
          y: rp.height
        },
        {
          x: rp.x + rp.width * to / 100,
          y: rp.y
        },
        {
          x: rp.width,
          y: rp.height
        }
      ];
      return res;
    },
    textClipMaskCoords: function() {
      var a1, a2, b1, b2, c1, c2, dx1, dx2, kat, point1, point2, point3, res, rp, tana, tc, to;
      tc = this.triangleCoords;
      to = this.styleProps['top-offset'];
      rp = this.rectParams;
      // Вычисляем смещение левой нижней внутренней точки
      c1 = rp.height;
      c2 = rp.width * to / 100;
      tana = c2 / c1;
      a1 = Math.atan(tana);
      b1 = (Math.PI / 2 - a1) / 2;
      dx1 = this.border.width / Math.tan(b1);
      point1 = tc[0].x + dx1 / 2;
      // Вычисляем смещение правой нижней внутренней точки
      c1 = rp.height;
      c2 = rp.width - rp.width * to / 100;
      tana = c2 / c1;
      a2 = Math.atan(tana);
      b2 = (Math.PI / 2 - a2) / 2;
      dx2 = this.border.width / Math.tan(b2);
      point2 = tc[2].x - dx2 / 2;
      // Вычисляем точку на отрезке point1-point2
      // для построения вертикального катета
      // до верхней внутренней точки треугольника
      point3 = point1 + (point2 - point1) * to / 100;
      // Вычисляем катет до верхней внутренней точки
      // Условие ниже нужно для обработки граничных вариантов (0, 100)
      if (a1 > a2) {
        kat = (point3 - point1) / Math.tan(a1);
      } else {
        kat = (point2 - point3) / Math.tan(a2);
      }
      res = [
        {
          // Левая нижняя
          x: tc[0].x + dx1 / 2,
          y: tc[0].y - this.border.width / 2
        },
        {
          // Верхняя
          x: point3,
          y: tc[0].y - this.border.width / 2 - kat
        },
        {
          // Правая нижняя
          x: tc[2].x - dx2 / 2,
          y: tc[2].y - this.border.width / 2
        }
      ];
      return res;
    },
    textLimiterParams: function() {
      var res, sm01, sm12, tcmc;
      tcmc = this.textClipMaskCoords;
      sm01 = this.sectionMiddle(tcmc[0], tcmc[1]);
      sm12 = this.sectionMiddle(tcmc[1], tcmc[2]);
      res = {
        x: sm01.x,
        y: sm01.y,
        width: sm12.x - sm01.x,
        height: tcmc[0].y - sm01.y
      };
      return res;
    },
    magnetCoordsDecart: function() {
      var c, cc, offset;
      offset = this.styleProps['top-offset'] / 100;
      c = this.opts.coords;
      cc = this.centerCoords;
      return [
        {
          x: (c[0].x + (c[1].x - c[0].x) * offset) - cc.x,
          y: c[0].y - cc.y
        },
        {
          x: c[1].x - cc.x,
          y: c[1].y - cc.y
        },
        {
          x: c[0].x - cc.x,
          y: c[1].y - cc.y
        }
      ];
    }
  }
};
</script>

<style lang="scss">
@import "./shape.scss";
</style>
