<template>
  <!-- eslint-disable -->
  <div class="hints" style="transform: translate(-1px, -1px)">
    <div class="guides" v-show="showGuides">
      <div class="track track_vertical" v-for="guide in guides.x" :style="{'left': Math.round(scaleCoord('x', guide)) + 'px'}">{{guide}}</div>
      <div class="track track_horizontal" v-for="guide in guides.y" :style="{'top': Math.round(scaleCoord('y', guide)) + 'px'}"></div>
    </div>
    <template v-if="hintsCondition">
      <div class="tracks" v-if="hintsCondition != 'alt'">
        <div class="track track_horizontal" v-if="visibleTrack.horizontal" :style="{'top': Math.round(visibleTrack.horizontal) + 'px'}"></div>
        <div class="track track_vertical" v-if="visibleTrack.vertical" :style="{'left': Math.round(visibleTrack.vertical) + 'px'}"></div>
      </div>
      <div class="hints">
        <div class="hint hint_horizontal" v-if="distanceHints.horizontal" v-for="hint in distanceHints.horizontal" :style="hint.style">
          <div class="hint-track" :style="hint.ht1Style"></div>
          <div class="hint-track" :style="hint.ht2Style"></div>
          <div class="hint-value" v-if="hint.value">{{ hint.value }}</div>
        </div>
        <div class="hint hint_vertical" v-if="distanceHints.vertical" v-for="hint in distanceHints.vertical" :style="hint.style">
          <div class="hint-track" :style="hint.ht1Style"></div>
          <div class="hint-track" :style="hint.ht2Style"></div>
          <div class="hint-value" v-if="hint.value">{{ hint.value }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';

export default {
  inject: ['currentPage'],
  name: 'Hints',
  // mixins: [coords_mixin]
  props: ['mouseCoords', 'scaledMouseCoords', 'allObjects', 'editingObjects', 'useSelfCenter', 'scale', 'zeroPointOffset', 'metaToolTracks', 'metaToolUnscaledTracks', 'state', 'hintsCondition', 'rotate', 'virtualCoords', 'creating', 'shiftPressed'],

  data() {
    return {
      tracks: {
        horizontal: [],
        vertical: []
      },

      visibleTrack: {
        horizontal: undefined,
        vertical: undefined
      },

      centeringTracks: {
        horizontal: [],
        vertical: []
      },

      treshold: 5,

      mouseCoordsSnapshot: {
        x: undefined,
        y: undefined
      },

      verticalHintsCondition: false,
      horizontalHintsCondition: false,

      surroundersMode: {
        x: false,
        y: false
      },

      layer1: {
        top: null,
        right: null,
        bottom: null,
        left: null
      },

      layer2: {
        top: null,
        right: null,
        bottom: null,
        left: null
      },

      topObject: null,

      stateDots: [
        'topleft',
        'top',
        'topright',
        'right',
        'bottomright',
        'bottom',
        'bottomleft',
        'left'
      ]
    };
  },

  watch: {
    hintsCondition: {
      immediate: true,
      handler(val, oldVal){
        if (val) {
          this.init();
        }
        if (oldVal === 'alt') {
          if (this.topObject != null) {
            this.topObject.vueObj.isSelected = false;
            this.topObject = null;
          }
        }
      }
    },
    metaToolTracks: {
      deep: true,
      handler() {
        if (this.hintsCondition) {
          this.checkOverlap();
        }
      }
    },
    mouseCoords: {
      deep: true,
      handler() {
        if (this.hintsCondition) {
          const snapshotExists = this.mouseCoordsSnapshot.x || this.mouseCoordsSnapshot.y;
          const creating = this.creating && (this.editingObjects?.[0] != null);
          if (snapshotExists || (creating && !this.shiftPressed)) {
            this.checkOverlap();
          }
        }
      }
    }
  },

  computed: {
    showGuides() {
      return !this.currentPage.scaling && this.currentPage.showRulers && this.currentPage.showGuides;
    },

    scaledTracks() {
      const horizontal = this.tracks.horizontal.map(coord=> {
        return {
          value: (coord.value*this.scale) + this.zeroPointOffset.y,
          root: (coord.root*this.scale) + this.zeroPointOffset.x
        };
      });
      const vertical = this.tracks.vertical.map(coord=> {
        return {
          value: (coord.value*this.scale) + this.zeroPointOffset.x,
          root: (coord.root*this.scale) + this.zeroPointOffset.y
        };
      });
      const res = {
        horizontal,
        vertical
      };
      return res;
    },

    notEditingObjects() {
      return this.allObjects.filter(object=> {

        if (this.editingObjects.includes(object)) {
          return false;
        }

        return true;
      });
    },

    distanceHints() {
      const res = {
        horizontal: [],
        vertical: []
      };

      if (this.hintsCondition === 'alt') {
        this.altDistanceHintsCoords.forEach(d=> {
          if (d.y) {
            res.horizontal.push(this.getDistanceHintStyle(d, 'horizontal'));
          }
          if (d.x) {
            res.vertical.push(this.getDistanceHintStyle(d, 'vertical'));
          }
        });
      } else {
        this.distanceHintsCoords.forEach(d=> {
          if (d.y && this.horizontalHintsCondition) {
            res.horizontal.push(this.getDistanceHintStyle(d, 'horizontal'));
          }
          if (d.x && this.verticalHintsCondition) {
            res.vertical.push(this.getDistanceHintStyle(d, 'vertical'));
          }
        });
      }
      return res;
    },

    distanceHintsCoords() {

      const hints = [];

      const mtusct = this.metaToolUnscaledTracks.bySides;
      const mtt = this.metaToolTracks.bySides;

      _.forEach(this.layer1, (val, key)=> {
        if (val != null) {
          let directionModifier, k1, k2, way;
          let hint2, obj1, obj2, oTrack1, oTrack2, tracks1, tracks2, vueObj1, vueObj2;
          switch (key) {
            case 'left':
              way = 'horizontal';
              directionModifier = -1;
              k1 = 'left';
              k2 = 'right';
              break;
            case 'right':
              way = 'horizontal';
              directionModifier = 1;
              k1 = 'right';
              k2 = 'left';
              break;
            case 'top':
              way = 'vertical';
              directionModifier = -1;
              k1 = 'top';
              k2 = 'bottom';
              break;
            case 'bottom':
              way = 'vertical';
              directionModifier = 1;
              k1 = 'bottom';
              k2 = 'top';
              break;
          }

          switch (way) {
            case 'horizontal':

              if (!this.surroundersMode.x) {
                return;
              }

              var y = mtt.top.value + ((mtt.bottom.value - mtt.top.value)/2);

              var obj = val.object;
              var {
                vueObj
              } = obj;
              var oTracks = vueObj.selfTracks.bySides;
              var middlesDiffs = this.getTracksMiddlesDiffs(mtusct, oTracks);
              var middlesDiff = middlesDiffs.yDiff;

              var hint1 = {
                y,
                x1: this.scaleCoord('x', mtusct[k1].value),
                x2: this.scaleCoord('x', (mtusct[k1].value + (val.distance*directionModifier))),
                diff1: 0,
                diff2: middlesDiff*this.scale
              };

              hints.push(hint1);

              if (this.layer2[k1] && !this.layer1[k2]) {
                // show aside
                obj1 = this.layer1[k1].object;
                vueObj1 = obj1.vueObj;
                tracks1 = vueObj1.selfTracks.bySides;
                oTrack1 = tracks1[k1].value;
                obj2 = this.layer2[k1].object;
                vueObj2 = obj2.vueObj;
                tracks2 = vueObj2.selfTracks.bySides;
                oTrack2 = tracks2[k2].value;

                middlesDiffs = this.getTracksMiddlesDiffs(mtusct, tracks2);
                middlesDiff = middlesDiffs.yDiff;

                hint2 = {
                  y,
                  x1: this.scaleCoord('x', oTrack1),
                  x2: this.scaleCoord('x', oTrack2),
                  diff1: hint1.diff2,
                  diff2: middlesDiff*this.scale
                };

                hints.push(hint2);
              }
              break;

            case 'vertical':

              if (!this.surroundersMode.y) {
                return;
              }

              var x = mtt.left.value + ((mtt.right.value - mtt.left.value)/2);

              obj = val.object;
              ({
                vueObj
              } = obj);
              oTracks = vueObj.selfTracks.bySides;
              middlesDiffs = this.getTracksMiddlesDiffs(mtusct, oTracks);
              middlesDiff = middlesDiffs.xDiff;

              hint1 = {
                x,
                y1: this.scaleCoord('y', mtusct[k1].value),
                y2: this.scaleCoord('y', (mtusct[k1].value + (val.distance*directionModifier))),
                diff1: 0,
                diff2: middlesDiff*this.scale
              };

              hints.push(hint1);

              if (this.layer2[k1] && !this.layer1[k2]) {
                // show aside
                obj1 = this.layer1[k1].object;
                vueObj1 = obj1.vueObj;
                tracks1 = vueObj1.selfTracks.bySides;
                oTrack1 = tracks1[k1].value;
                obj2 = this.layer2[k1].object;
                vueObj2 = obj2.vueObj;
                tracks2 = vueObj2.selfTracks.bySides;
                oTrack2 = tracks2[k2].value;

                middlesDiffs = this.getTracksMiddlesDiffs(mtusct, tracks2);
                middlesDiff = middlesDiffs.xDiff;

                hint2 = {
                  x,
                  y1: this.scaleCoord('y', oTrack1),
                  y2: this.scaleCoord('y', oTrack2),
                  diff1: hint1.diff2,
                  diff2: middlesDiff*this.scale
                };

                hints.push(hint2);
              }
              break;
          }
        }
      });

      return hints;
    },

    altDistanceHintsCoords() {
      // Если нужно нарисовать расстояние до объекта,
      // над которым находится курсор

      const hints = [];

      const mtusct = this.metaToolUnscaledTracks.bySides;
      const mtt = this.metaToolTracks.bySides;


      const domPuncture = this.$parent.punctureDOM();

      const puncturedObjects = this.notEditingObjects.map(function(o){
        if (o.vueObj.isSelfInDomElemsArray(domPuncture)) {
          return o;
        } else {
          return false;
        }
      }).filter(o => o).sort((a, b)=> {
        const ai = this.allObjects.indexOf(a);
        const bi = this.allObjects.indexOf(b);
        return ai - bi;
      });

      const topObject = puncturedObjects[puncturedObjects.length - 1];

      // Если попали на какой-то объект
      if (topObject) {

        let hint, middlesDiff, x, y;
        if (this.topObject != null) {
          this.topObject.vueObj.isSelected = false;
        }

        topObject.vueObj.isSelected = true;
        this.topObject = topObject;

        const oTracks = topObject.vueObj.selfTracks.bySides;

        const middlesDiffs = this.getTracksMiddlesDiffs(mtusct, oTracks);

        // Слева
        if (oTracks.right.value < mtusct.left.value) {
          y = mtt.top.value + ((mtt.bottom.value - mtt.top.value)/2);

          middlesDiff = middlesDiffs.yDiff;

          hint = {
            y,
            x1: mtt.left.value,
            x2: this.scaleCoord('x', oTracks.right.value),
            diff1: 0,
            diff2: middlesDiff * this.scale,
            value: Math.floor(mtusct.left.value - oTracks.right.value)
          };

          hints.push(hint);
        }

        // Справа
        if (oTracks.left.value > mtusct.right.value) {
          y = mtt.top.value + ((mtt.bottom.value - mtt.top.value)/2);

          middlesDiff = middlesDiffs.yDiff;

          hint = {
            y,
            x1: mtt.right.value,
            x2: this.scaleCoord('x', oTracks.left.value),
            diff1: 0,
            diff2: middlesDiff * this.scale,
            value: Math.floor(oTracks.left.value - mtusct.right.value)
          };

          hints.push(hint);
        }

        // Сверху
        if (oTracks.bottom.value < mtusct.top.value) {
          x = mtt.left.value + ((mtt.right.value - mtt.left.value)/2);

          middlesDiff = middlesDiffs.xDiff;

          hint = {
            x,
            y1: mtt.top.value,
            y2: this.scaleCoord('y', oTracks.bottom.value),
            diff1: 0,
            diff2: middlesDiff * this.scale,
            value: Math.floor(mtusct.top.value - oTracks.bottom.value)
          };

          hints.push(hint);
        }

        // Снизу
        if (oTracks.top.value > mtusct.bottom.value) {
          x = mtt.left.value + ((mtt.right.value - mtt.left.value)/2);

          middlesDiff = middlesDiffs.xDiff;

          hint = {
            x,
            y1: mtt.bottom.value,
            y2: this.scaleCoord('y', oTracks.top.value),
            diff1: 0,
            diff2: middlesDiff * this.scale,
            value: Math.floor(oTracks.top.value - mtusct.bottom.value)
          };

          hints.push(hint);
        }
      }

      return hints;
    },

    guides() {
      return this.$store.state.activeSlide.guides;
    },

    metaToolPerimeterDots() {

      const res = [];

      this.virtualCoords.forEach((vc, vci)=> {
        res.push(_.clone(vc));

        let next = this.virtualCoords[vci + 1];
        if (next == null) { next = this.virtualCoords[0]; }
        res.push({
          x: (vc.x + next.x) / 2,
          y: (vc.y + next.y) / 2
        });

      });

      return res;
    },

    stateBasedTracks() {

      const stateIndex = this.stateDots.indexOf(this.state);
      const res = this.metaToolPerimeterDots[stateIndex];
      if (!res) {
        return;
      }

      if ((this.rotate === 0) || (this.rotate === 180)) {
        if ((this.state === 'top') || (this.state === 'bottom')) {
          delete res.x;
        }
        if ((this.state === 'left') || (this.state === 'right')) {
          delete res.y;
        }
      }
      if ((this.rotate === 90) || (this.rotate === -90)) {
        if ((this.state === 'top') || (this.state === 'bottom')) {
          delete res.y;
        }
        if ((this.state === 'left') || (this.state === 'right')) {
          delete res.x;
        }
      }
      // if @rotate != 0 && @rotate != 90 && @rotate != -90 && @rotate != 180
      //   return

      const tracks = {};
      if (res.x != null) {
        tracks.vertical = [{value: res.x}];
      }
      if (res.y != null) {
        tracks.horizontal = [{value: res.y}];
      }

      return tracks;
    }
  },

  methods: {

    init() {
      this.visibleTrack = {
        horizontal: undefined,
        vertical: undefined
      };
      this.calcObjectsTracks();
    },

    scaleCoord(axis, coord){
      return (coord * this.scale) + this.zeroPointOffset[axis];
    },

    unscaleCoord(axis, coord){
      return (coord - this.zeroPointOffset[axis]) / this.scale;
    },

    getObjectWithLessDiff(arr){
      return _.sortBy(arr, [function(o){
        if ((o == null)) {
          return Infinity;
        }
        return Math.abs(o.diff);
      }
      ])[0];
    },

    checkTracksOverlapHelper(type){

      let targetTracks;
      if (this.creating) {
        targetTracks = {
          horizontal: [{value: this.mouseCoords.y}],
          vertical: [{value: this.mouseCoords.x}]
        };

      } else {
        if (this.state !== 'moving') {
          targetTracks = _.cloneDeep(this.stateBasedTracks);
        } else {
          if (this.useSelfCenter) {
            targetTracks = _.cloneDeep(this.metaToolTracks.withCenter);
          } else {
            targetTracks = _.cloneDeep(this.metaToolTracks.withoutCenter);
          }
        }
      }

      const overlaps = [];

      if ((targetTracks?.[type] == null)) {
        return [];
      }

      targetTracks[type].forEach(selfTrack=> {

        this.scaledTracks[type].forEach(track=> {
          const diff = track.value - selfTrack.value;
          if (Math.abs(diff) < this.treshold) {
            // Рассчитать визуальный трек до объекта совпадения
            const newOverlap = {
              diff,
              trackValue: track.value,
              track
            };
            overlaps.push(newOverlap);
          }
        });

        if (this.showGuides) {
          let guidesType;
          switch (type) {
            case 'vertical':
              guidesType = 'x';
              break;
            case 'horizontal':
              guidesType = 'y';
              break;
          }

          this.guides[guidesType].forEach(guide=> {
            const scaledGuide = this.scaleCoord(guidesType, guide);
            const diff = scaledGuide - selfTrack.value;
            if (Math.abs(diff) < this.treshold) {
              // Рассчитать визуальный трек до объекта совпадения
              const newOverlap = {
                diff,
                trackValue: scaledGuide,
                track: {
                  value: scaledGuide,
                  root: 0
                }
              };

              overlaps.push(newOverlap);
            }
          });
        }

      });

      return overlaps;
    },

    calcObjectsTracks(){

      let ver = [];
      let hor = [];

      this.notEditingObjects.forEach(object=> {

        const {
          selfTracks
        } = object.vueObj;
        if (!selfTracks) {
          return;
        }

        const tracks = selfTracks.withCenter;

        ver = ver.concat(tracks.vertical);
        hor = hor.concat(tracks.horizontal);

      });

      const {
        size
      } = this.$store.state.presentation;

      ver.slideTracksCoords = [
        [0, size.h / 2],
        [size.w / 2, size.h / 2],
        [size.w, size.h / 2],
        [0, size.h / 2],
        [size.w / 2, size.h / 2],
        [size.w, size.h / 2]
      ];
      ver.push({value: 0, root: size.h / 2});
      ver.push({value: size.w, root: size.h / 2});
      ver.push({value: size.w / 2, root: size.h / 2});
      hor.push({value: 0, root: size.w / 2});
      hor.push({value: size.h, root: size.w / 2});
      hor.push({value: size.h / 2, root: size.w / 2});

      this.tracks.vertical = _.uniqBy(ver, 'value');
      this.tracks.horizontal = _.uniqBy(hor, 'value');

    },

    checkOverlap() {
      let vOverlap, hOverlap;

      let recalcSurrounders = false;

      // Возможно, сделать рефактор, дублирование кода

      if (this.mouseCoordsSnapshot.x) {
        const xDiff = Math.abs(this.mouseCoordsSnapshot.x - this.mouseCoords.x);
        if (xDiff > this.treshold) {
          this.mouseCoordsSnapshot.x = undefined;
          this.visibleTrack.vertical = undefined;
          this.$emit('clearOverlap', 'x');
        }
      } else {
        const vOverlaps = this.checkTracksOverlapHelper('vertical');
        const vsOverlap = this.getSurroundersOverlap('vertical');
        vOverlap = this.getObjectWithLessDiff([...vOverlaps, ...[vsOverlap]])

        if (vsOverlap != null) {
          recalcSurrounders = true;
          this.horizontalHintsCondition = true;
        } else {
          this.horizontalHintsCondition = false;
        }
        if (vOverlap === vsOverlap) {
          this.surroundersMode.x = true;
        } else {
          this.surroundersMode.x = false;
        }
      }

      if (this.mouseCoordsSnapshot.y) {
        const yDiff = Math.abs(this.mouseCoordsSnapshot.y - this.mouseCoords.y);
        if (yDiff > this.treshold) {
          this.mouseCoordsSnapshot.y = undefined;
          this.visibleTrack.horizontal = undefined;
          this.$emit('clearOverlap', 'y');
        }
      } else {
        const hOverlaps = this.checkTracksOverlapHelper('horizontal');
        const hsOverlap = this.getSurroundersOverlap('horizontal');
        hOverlap = this.getObjectWithLessDiff([...hOverlaps, ...[hsOverlap]]);

        if (hsOverlap != null) {
          recalcSurrounders = true;
          this.verticalHintsCondition = true;
        } else {
          this.verticalHintsCondition = false;
        }
        if (hOverlap === hsOverlap) {
          this.surroundersMode.y = true;
        } else {
          this.surroundersMode.y = false;
        }
      }

      // Вертикальные
      if (vOverlap && !this.mouseCoordsSnapshot.x) {
        if (vOverlap.trackValue) {
          this.visibleTrack.vertical = Math.round(vOverlap.trackValue);
        }
        this.mouseCoordsSnapshot.x = this.mouseCoords.x + vOverlap.diff;
        this.$emit(
            'overlap',
            {
              axis: 'x',
              value: this.mouseCoordsSnapshot.x
            }
        );
      }
      // Горизонтальные
      if (hOverlap && !this.mouseCoordsSnapshot.y) {
        if (hOverlap.trackValue) {
          this.visibleTrack.horizontal = Math.round(hOverlap.trackValue);
        }
        this.mouseCoordsSnapshot.y = this.mouseCoords.y + hOverlap.diff;
        this.$emit(
            'overlap',
            {
              axis: 'y',
              value: this.mouseCoordsSnapshot.y
            }
        );
      }

      if (recalcSurrounders) {
        this.$nextTick(function(){
          this.calcMetatoolSurrounders({remember: true});
        });
      }

    },

    getSurroundersOverlap(type){

      let center, diff, key1, key2, t1, t2;
      if (this.creating) {
        return;
      }

      const surrounders = this.calcMetatoolSurrounders();
      const {
        layer1
      } = surrounders;
      const {
        layer2
      } = surrounders;
      const targetTracks = this.metaToolUnscaledTracks;
      let res = undefined;

      switch (type) {
        case 'vertical':
          var axis = 'x';
          key1 = 'right';
          key2 = 'left';
          break;
        case 'horizontal':
          axis = 'y';
          key1 = 'bottom';
          key2 = 'top';
          break;
      }


      const side1 = layer1[key1];
      const side2 = layer1[key2];
      const side1_2 = layer2[key1];
      const side2_2 = layer2[key2];

      // Если между двумя
      if ((side1 != null) && (side2 != null)) {
        t1 = side1.location.track;
        t2 = side2.location.track;
        center = Math.abs((t1 - t2) / 2) + Math.min(t1, t2);
        const mtCenter = targetTracks.withCenter[type][2].value;
        diff = (center - mtCenter)*this.scale;
        if (Math.abs(diff) < this.treshold) {
          res = {
            // trackValue: @scaleCoord(axis, center)
            diff,
            aside: false,
            t1,
            t2,
            type
          };
        }
      }

      // Если в стороне
      const before = side2 && side2_2 && !side1;
      const after = side1 && side1_2 && !side2;
      const aside = before || after;
      if (aside) {
        let distance, mtSide;
        if (before) {
          t1 = side2.location.track;
          t2 = side2_2.location.track;
          ({
            distance
          } = side2_2);
          mtSide = targetTracks.withCenter[type][0].value;
        }

        if (after) {
          t1 = side1.location.track;
          t2 = side1_2.location.track;
          distance = -side1_2.distance;
          mtSide = targetTracks.withCenter[type][1].value;
        }

        center = t1 + distance;
        diff = (center - mtSide)*this.scale;
        if (Math.abs(diff) < this.treshold) {
          res = {
            // trackValue: @scaleCoord(axis, center)
            diff,
            aside: true,
            t1,
            t2,
            type
          };
        }
      }

      if (res) {
        res.layer1 = layer1;
        res.layer2 = layer2;
      }
      return res;
    },

    calcMetatoolSurrounders(opts){
      const layer1 = this.calcSurrounders();

      const layer2 = {
        top: null,
        right: null,
        bottom: null,
        left: null
      };

      _.forEach(
          layer1,
          (val, key)=> {
            if (val != null) {
              const tracks = val.object.vueObj.selfTracks.bySides;
              const surrounders = this.calcSurrounders(tracks);
              if (surrounders[key] != null) {
                layer2[key] = surrounders[key];
              }
            }
          });
      if ((opts != null) && opts.remember) {
        this.layer1 = layer1;
        this.layer2 = layer2;
      }
      return {layer1, layer2};
    },


    calcSurrounders(targetTracks){
      // Метод находит ближайшее окружение объекта по 4-м направлениям

      if (!targetTracks) {
        targetTracks = this.metaToolUnscaledTracks.bySides;
      }

      const {
        abs
      } = Math;

      const res = {
        top: null,
        right: null,
        bottom: null,
        left: null
      };

      this.notEditingObjects.forEach(object=> {

        const objectTracks = object.vueObj.selfTracks.bySides;

        const location = this.getRelativeLocation(targetTracks, objectTracks);
        if (location != null) {
          const distance = abs(location.value);
          // Если по этому направлению уже есть объект,
          // нужно оставить только ближайший
          const l = res[location.type];
          if ((l == null) || (abs(l.distance) > distance)) {
            res[location.type] = {object, distance, location};
          }
        }

      });
      return res;
    },

    getRelativeLocation(sourceTracks, targetTracks){

      const st = sourceTracks;
      const tt = targetTracks;

      const top = (tt.top.value < st.top.value) && (tt.bottom.value < st.top.value);
      const bottom = (tt.top.value > st.bottom.value) && (tt.bottom.value > st.bottom.value);
      const left = (tt.left.value < st.left.value) && (tt.right.value < st.left.value);
      const right = (tt.left.value > st.right.value) && (tt.right.value > st.right.value);

      if (top && !(left || right)) {
        ({
          type: 'top',
          axis: 'y',
          value: Math.abs(tt.bottom.value - st.top.value),
          track: tt.bottom.value,
          root: tt.bottom.root
        });
      }
      if (bottom && !(left || right)) {
        ({
          type: 'bottom',
          axis: 'y',
          value: Math.abs(tt.top.value - st.bottom.value),
          track: tt.top.value,
          root: tt.top.root
        });
      }
      if (left && !(top || bottom)) {
        ({
          type: 'left',
          axis: 'x',
          value: Math.abs(tt.right.value - st.left.value),
          track: tt.right.value,
          root: tt.right.root
        });
      }
      if (right && !(top || bottom)) {
        ({
          type: 'right',
          axis: 'x',
          value: Math.abs(tt.left.value - st.right.value),
          track: tt.left.value,
          root: tt.left.root
        });
      }
    },

    getTracksMiddlesDiffs(tracks1, tracks2){
      const sides1 = tracks1;
      const sides2 = tracks2;
      const y1 = (sides1.bottom.value + sides1.top.value)/2;
      const x1 = (sides1.left.value + sides1.right.value)/2;
      const y2 = (sides2.bottom.value + sides2.top.value)/2;
      const x2 = (sides2.left.value + sides2.right.value)/2;
      const yDiff = y1 - y2;
      const xDiff = x1 - x2;
      return {xDiff, yDiff};
    },

    getDistanceHintStyle(d, type){

      let ht1Side, ht2Side;
      const style = {};
      const ht1Style = {};
      const ht2Style = {};
      const {
        value
      } = d;

      switch (type) {

        case 'horizontal':

          var maxX = Math.max(d.x1, d.x2);
          var minX = Math.min(d.x1, d.x2);

          style.left = `${minX}px`;
          style.width = `${maxX - minX}px`;
          style.top = `${d.y}px`;

          if (d.x1 > d.x2) {
            ht1Side = 'right';
            ht2Side = 'left';
          } else {
            ht1Side = 'left';
            ht2Side = 'right';
          }

          ht1Style[ht1Side] = "0px";
          ht1Style.height = `${Math.abs(d.diff1)}px`;
          if (d.diff1 < 0) {
            ht1Style.top = "0px";
          } else {
            ht1Style.bottom = "0px";
          }

          ht2Style[ht2Side] = "0px";
          ht2Style.height = `${Math.abs(d.diff2)}px`;
          if (d.diff2 < 0) {
            ht2Style.top = "0px";
          } else {
            ht2Style.bottom = "0px";
          }
          break;

        case 'vertical':
          var maxY = Math.max(d.y1, d.y2);
          var minY = Math.min(d.y1, d.y2);

          style.top = `${minY}px`;
          style.height = `${maxY - minY}px`;
          style.left = `${d.x}px`;

          if (d.y1 > d.y2) {
            ht1Side = 'bottom';
            ht2Side = 'top';
          } else {
            ht1Side = 'top';
            ht2Side = 'bottom';
          }

          ht1Style[ht1Side] = "0px";
          ht1Style.width = `${Math.abs(d.diff1)}px`;
          if (d.diff1 < 0) {
            ht1Style.left = "0px";
          } else {
            ht1Style.right = "0px";
          }

          ht2Style[ht2Side] = "0px";
          ht2Style.width = `${Math.abs(d.diff2)}px`;
          if (d.diff2 < 0) {
            ht2Style.left = "0px";
          } else {
            ht2Style.right = "0px";
          }
          break;
      }

      const res = {style, ht1Style, ht2Style};
      if (value) {
        res.value = value;
      }
      return res;
    },

    stickDot(coord){
      const dotTracks = {
        horizontal: [{value: coord.y}],
        vertical: [{value: coord.x}]
      };

      const keys = {
        horizontal: 'h',
        vertical: 'v'
      };

      const axis = {
        horizontal: 'y',
        vertical: 'x'
      };

      const res = {
        h: [],
        v: []
      };

      const scaledTreshold = this.treshold*this.scale;

      Object.keys(keys).forEach(key=> {

        dotTracks[key].forEach(dotTrack=> {
          this.scaledTracks[key].forEach(track=> {
            const diff = track.value - dotTrack.value;
            if (Math.abs(diff) < scaledTreshold) {
              // Рассчитать визуальный трек до объекта совпадения
              const newOverlap =
                  {diff};
              res[keys[key]].push(newOverlap);
            }
          });
          this.guides[axis[key]].forEach(guide=> {
            const scaledGuide = this.scaleCoord(axis[key], guide);
            const diff = scaledGuide - dotTrack.value;
            if (Math.abs(diff) < scaledTreshold) {
              // Рассчитать визуальный трек до объекта совпадения
              const newOverlap =
                  {diff};
              res[keys[key]].push(newOverlap);
            }
          });
        });
      });

      const h = this.getObjectWithLessDiff(res.h);
      const v = this.getObjectWithLessDiff(res.v);
      return {
        x: v ? v.diff / this.scale : 0,
        y: h ? h.diff / this.scale : 0
      };
    }
  }
};
</script>

<style lang="scss">
.track, .hint{
  position: absolute;
  z-index: 10;
  pointer-events: none;
}
.track{
  background-color: #0ff;
}
.hint{
  background-color: #0f0;
  &:before, &:after{
    content: '';
    position: absolute;
    background-color: #0f0;
  }
  .hint-track{
    position: absolute;
    background-color: #0f0;
  }
}
.hint-value{
  position: absolute;
  font-size: 12px;
  color: #0f0;
}
.track_horizontal, .hint_horizontal{
  height: 1px;
  left: -10000px;
  right: -10000px;
  .hint-track{
    width: 1px;
  }
  .hint-value{
    left: 50%;
    transform: translateX(-50%);
    top: -18px;
  }
  &:before, &:after{
    height: 10px;
    width: 1px;
    top: -5px;
  }
  &:before{
    left: 0;
  }
  &:after{
    right: 0;
  }
}
.track_vertical, .hint_vertical{
  width: 1px;
  top: -10000px;
  bottom: -10000px;
  .hint-track{
    height: 1px;
  }
  .hint-value{
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
  }
  &:before, &:after{
    width: 10px;
    height: 1px;
    left: -5px;
  }
  &:before{
    top: 0;
  }
  &:after{
    bottom: 0;
  }
}

.guides{
  .track{
    background-color: #0f0;
  }
}
</style>
