<template>
    <div class="property property__type_padding">
        <div class="property__name">{{ $t(property.title) }}</div>
        <div class="col-25" v-for="side in ref">
            <prop_number v-bind:propset="paddingProps" v-bind:property="side"></prop_number>
            <div class="property__label">{{ side.label }}</div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import PropBase from "@/components/editor/property/PropBase";
import PropNumber from "@/components/editor/property/PropNumber";

export default {
    name: 'prop_padding',
    mixins: [PropBase],
    components: {
        prop_number: PropNumber
    },
    data() {
        return {
            uniform: true,
            ref: {
                t: {
                    name: 't',
                    label: this.$t('top'),
                    min: 0,
                    max: 999,
                    units: 'px'
                },
                r: {
                    name: 'r',
                    label: this.$t('right'),
                    min: 0,
                    max: 999,
                    units: 'px'
                },
                b: {
                    name: 'b',
                    label: this.$t('bottom'),
                    min: 0,
                    max: 999,
                    units: 'px'
                },
                l: {
                    name: 'l',
                    label: this.$t('left'),
                    min: 0,
                    max: 999,
                    units: 'px'
                }
            }
        };
    },
    computed: {
        paddingProps: function () {
            var p;
            p = {};
            if (this.propValue != null) {
                p = {
                    t: this.propValue[0],
                    r: this.propValue[1],
                    b: this.propValue[2],
                    l: this.propValue[3]
                };
            }
            return p;
        }
    },
    methods: {
        updateProp: function (k, v) {
            //@propValue = [v, v, v, v]
            switch (k) {
                case 't':
                    this.propValue = [v, this.propValue[1], this.propValue[2], this.propValue[3]];
                    break;
                case 'r':
                    this.propValue = [this.propValue[0], v, this.propValue[2], this.propValue[3]];
                    break;
                case 'b':
                    this.propValue = [this.propValue[0], this.propValue[1], v, this.propValue[3]];
                    break;
                case 'l':
                    this.propValue = [this.propValue[0], this.propValue[1], this.propValue[2], v];
            }
        }
    }
};
</script>

<style lang="scss">
.property__type_padding {
    .property {
        padding: 0;
    }

    .property__label {
        font-size: 10px;
    }

    .property__type_number {
        .property__input {
            width: 100%;
        }

        .property__input-units {
            margin-left: -11px;
        }
    }
}
</style>

<i18n>
{
    "en": {
        "Padding": "Padding",
        "top": "Top",
        "right": "Right",
        "bottom": "Bottom",
        "left": "Left"
    },
    "ru": {
        "Padding": "Отступ",
        "top": "Сверху",
        "right": "Справа",
        "bottom": "Снизу",
        "left": "Слева"
    }
}
</i18n>

